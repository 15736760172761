import React, { Component } from "react";
import LoadingWindow from '../../components/LoadingWindow';
import { create_Delete_Update_Information, getInformationWithData } from "../../services/CABE";
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { getIndexElement } from "../../functions/searchInObject";
import { pricesFormat } from "../../functions/pricesFormat";
import { confirmCloseAlert } from "../../functions/alerts";
import ExcelDocument from "../../components/ExcelDocument";
import { getDataSet } from "../../functions/generateDataSetExcel";
import { getValueCookie } from "../../services/cookieService";
import { getDateFromReports } from "../../functions/dateFormat";
import { OrderArray } from "../../functions/generalFunctions";
export default class PriceMarginProdLine extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        FilterProductLines: [],
        ProductLines: [],
        order:true
    }

    async getPriceMargins() {
        this.ModalLoading.current.showState(true);
        const data = {}
        const result = await getInformationWithData('/Items/Margin/Alt', data)
        if (result.status.code === 1) {
            this.setState({ ProductLines: result.data, FilterProductLines: result.data })
        }
        this.ModalLoading.current.showState(false);
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchProductLines=async(e)=>{
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.ProductLines
        } else {
            Filter = this.state.ProductLines.filter((detail) => {
                if (this.Contains(detail.ProductLineDesc, searchValue) ){
                    return detail
                } else {
                    return null
                }
            })
        }
        
        await this.setState({ FilterProductLines: Filter })
        await this.refreshInputs()
    }

    refreshInputs(){
        
        for (const item of this.state.FilterProductLines) {
            if(document.getElementById('priceMarginProdLine_'+item.ProductLineDesc)){
                document.getElementById('priceMarginProdLine_'+item.ProductLineDesc).value=item.DiscountMarkup
            }
            
        }
    }

    EnableMarginPrice(id){
        if(document.getElementById(id)){
            document.getElementById(id).removeAttribute('disabled')
        }
    }
    changePriceMargin=async(e)=>{
        if(e.key==='Enter'){
            const id=e.target.id.split('_')
            if(id.length===2){
                const temporal=this.state.ProductLines
                const temporal2=this.state.FilterProductLines
                const index=getIndexElement(temporal,'ProductLineDesc',id[1])
                const index2=getIndexElement(temporal2,'ProductLineDesc',id[1])

                if(index!==-1){
                    const data={
                        DiscountMarkup:Number(e.target.value),
                        ProductLine:temporal[index].ProductLine
                    }
                    const result=await create_Delete_Update_Information('/Items/Margin/Update/Alt',data)
                    if(result.status.code===1){
                        temporal[index].DiscountMarkup=pricesFormat(e.target.value)
                        temporal2[index2].DiscountMarkup=pricesFormat(e.target.value)
                        this.setState({FilterProductLines:temporal2,ProductLines:temporal})
                        document.getElementById(e.target.id).setAttribute('disabled','disabled')
                        confirmCloseAlert('correct','The Price Margin of : ' +id[1]+' has changed successfully!')
                    }else{
                        confirmCloseAlert('incorrect','Whoops, something is wrong. Try again!')
                    }
                }

            }
        }

    }
   async GeneralOrder(key, asc, name) {
        const temporal = this.state[name]
        const Order = OrderArray(temporal, key, asc)
        await this.setState({ [name]: Order, order: !asc })
        await this.refreshInputs()
    }

    

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Price Margin by Product Line</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please click on Load Margin Prices.</p>
                </div>
                <div className="row pb-3">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row pb-2">
                            <div className="col-12 pb-4">
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getPriceMargins()} type="button" className="btn purpleButton btn-lg">Load Margin Prices</button>
                                </div>
                            </div>
                            <div hidden={this.state.ProductLines.length===0} className="col-12 pb-4">
                                <input onChange={this.searchProductLines} className="form-control form-control-lg" placeholder='Search by Product Line...' id='SearchMarginPriceProdLine' type="text" />
                            </div>
                            <div hidden={this.state.ProductLines.length===0} className="col-12 pb-4">
                                <div className="d-grid gap-2">
                                    <ExcelDocument id={"ExcelProdLineMarginPrice"} data={getDataSet(this.state.ProductLines, ['Product Line', 'Price Margin %'], ['ProductLineDesc','DiscountMarkup'])} sheetname={"Margin Price Product Lines"} archname={"Margin Price Product Lines " + getValueCookie('Company') + " DATE " + getDateFromReports()}/>
                                </div>
                            </div>
                            <div hidden={this.state.ProductLines.length===0} className="col-12 tableFixHead">
                                <table className='table table-hover align-middle'>
                                    <thead className='thead'>
                                        <tr className='bg-primary text-white'>
                                            <th onClick={() => this.GeneralOrder('ProductLineDesc', this.state.order, 'FilterProductLines')} className='text-center bg-primary '>
                                                <div className='d-flex flex-column align-items-center gap-2'>
                                                    <div className='col-12'>Product Line</div>
                                                    <div className='col-12'><BsFileArrowUpFill className="sort-icon"/><BsFileArrowDownFill className="sort-icon" /></div>
                                                </div>
                                            </th>
                                            <th onClick={() => this.GeneralOrder('DiscountMarkup', this.state.order, 'FilterProductLines')} className='text-center bg-primary '>
                                                <div className='d-flex flex-column align-items-center gap-2'>
                                                    <div className='col-12'>Price Margin %</div>
                                                    <div className='col-12'><BsFileArrowUpFill className="sort-icon"/><BsFileArrowDownFill className="sort-icon" /></div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.FilterProductLines.map((item, i) => (
                                            <tr key={i} hidden={item.Visible === 0}>

                                                <td className='text-center textTableSize'>{item.ProductLineDesc}</td>
                                                <td className='text-center'>
                                                    <div onDoubleClick={()=>this.EnableMarginPrice('priceMarginProdLine_' + item.ProductLineDesc)} className="input-group input-group-lg flex-nowrap">
                                                        
                                                        <input disabled type="number" id={'priceMarginProdLine_' + item.ProductLineDesc} min={0} step={0.01} defaultValue={item.DiscountMarkup} onKeyDown={this.changePriceMargin} className="form-control text-center inputResize" />
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>

                </div>
            </div>
        )
    }
}