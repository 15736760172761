import React, { Component } from 'react'
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import "../../../css/pickingSystem-style.css"
import LoadingWindow from '../../../components/LoadingWindow';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { getInformationWithData } from '../../../services/CABE';
import { getIndexElement } from '../../../functions/searchInObject';
import { getValueCookie } from '../../../services/cookieService';
import { GeneralInformation, ScanningByItemCode, ScanningByLineProd, ScanningByOrderNo, UsersScanningReport } from './Class';
import { MobileDisp, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { ConvertDateFormatMAS90ToQueryFormat, DateFormatMAS90, FormatQueryReturnDate, getActualDateUTC, getDateFromReports } from '../../../functions/dateFormat';
import { OrderPDF } from '../../../components/OrderPDF';
import { getPrintHeaderCompany } from '../../../functions/companyInformation';
import ExcelDocument from '../../../components/ExcelDocument';
import { getDataSet } from '../../../functions/generateDataSetExcel';
import { DownloadOrderPDF } from '../../../components/DownloadOrderPDF';
export default class ScanningReportView extends Component {

    state = {
        companyPrintHeader: getPrintHeaderCompany(),
        AllInformation: [],
        ByProdLine: [],
        ByProdLineFilter: [],
        ByItemCode: [],
        ByItemCodeFilter: [],
        ByOrders: [],
        ByOrdersFilter: [],
        ByUsers: [],
        ByUsersFilter: [],
        AllByUsers: [],
        order: true,
        StartPeriod: '',
        EndPeriod: '',
        reportPrint: 'All'

    }


    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    async getScanning() {
        this.ModalLoading.current.showState(true);
        const data = {
            Start: document.getElementById('startScanningFilterDate').value,
            End: document.getElementById('endScanningFilterDate').value,
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            idCompany: Number(getValueCookie('CompanyId')),
            priceLevel: undefined,
            Component: null
        }
        this.setState({ StartPeriod: data.Start, EndPeriod: data.End })
        const invent = await getInformationWithData('/Items/get', data)
        let ByItemCode = []
        let ByOrderNO = []
        let ByProductLine = []
        let ByUserName = []
        let Information = []
        const result = await getInformationWithData('/sales/get/detail/scanner', data)
        const POResult = await getInformationWithData('/purchase/get/detail/scanner', data)
        const INResult = await getInformationWithData('/invoice/get/header/scanner', data)
        console.log(INResult)

        if (result.status.code === 1) {

            for (const item of result.data) {
                if (item.QuantityShipped > 0) {
                    const indexItemCode = getIndexElement(ByItemCode, 'ItemCode', item.ItemCode)
                    const indexOrderNo = getIndexElement(ByOrderNO, 'OrderNo', item.SalesOrderNo)
                    const inventIndex = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                    const indexUser = getIndexElement(ByUserName, 'DispatchUser', item.DispatchUser)

                    if (indexUser === -1) {
                        const user = new UsersScanningReport(item.DispatchUser)
                        ByUserName.push(user)
                    }

                    let desc = ''
                    let prodLine = ''
                    let haveUPC = false
                    item.haveUPC = 'No'
                    if (item.HaveUPC === 1) {
                        haveUPC = true
                        item.haveUPC = 'Yes'
                    }
                    if (inventIndex !== -1) {
                        desc = (!(invent.data[inventIndex].completeDesc) || invent.data[inventIndex].completeDesc === "null") ? invent.data[inventIndex].abbreviatedDesc : invent.data[inventIndex].completeDesc
                        prodLine = invent.data[inventIndex].ProductLineDesc
                    } else {
                        desc = item.ItemCodeDesc
                    }

                    if (inventIndex !== -1) {
                        const indexProductLine = await getIndexElement(ByProductLine, 'ProdLine', invent.data[inventIndex].ProductLineDesc)
                        if (indexProductLine === -1) {
                            let newProdLine = new ScanningByLineProd(invent.data[inventIndex].ProductLineDesc)
                            newProdLine.addItemToProdLine(item)
                            ByProductLine.push(newProdLine)
                        } else {
                            ByProductLine[indexProductLine].addItemToProdLine(item)
                        }
                    }

                    if (indexItemCode === -1) {
                        let newItemCode = new ScanningByItemCode(item.ItemCode, prodLine, desc, haveUPC)
                        newItemCode.addItemCode(item)
                        ByItemCode.push(newItemCode)

                    } else {
                        ByItemCode[indexItemCode].addItemCode(item)
                    }

                    if (indexOrderNo === -1) {
                        let newOrder = new ScanningByOrderNo(item.SalesOrderNo, 'Sales Order', item.SalesOrderDate, item.DispatchUser, item.CustomerName)
                        newOrder.addNewProd(item)
                        ByOrderNO.push(newOrder)
                    } else {
                        ByOrderNO[indexOrderNo].addNewProd(item)
                    }
                    Information.push(new GeneralInformation(item.SalesOrderNo, 'Sales Order', item.CustomerNo, item.CustomerName, item.ItemCode, prodLine, desc, item.DispatchUser,  item.RegisterType === 's'&&item.haveUPC==='Yes' ? 'Scanning' : 'Manual', item.QuantityShipped, FormatQueryReturnDate(item.SalesOrderDate),item.haveUPC))


                }
            }
        }

        if (POResult.status.code === 1) {

            for (const item of POResult.data) {
                if (item.QuantityShipped > 0) {
                    const indexItemCode = getIndexElement(ByItemCode, 'ItemCode', item.ItemCode)
                    const indexOrderNo = getIndexElement(ByOrderNO, 'OrderNo', item.SalesOrderNo)
                    const inventIndex = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                    const indexUser = getIndexElement(ByUserName, 'DispatchUser', item.DispatchUser)

                    if (indexUser === -1) {
                        const user = new UsersScanningReport(item.DispatchUser)
                        ByUserName.push(user)
                    }
                    let desc = ''
                    let prodLine = ''
                    let haveUPC = false
                    item.haveUPC = 'No'

                    if (item.HaveUPC === 1) {
                        item.haveUPC = 'Yes'
                        haveUPC = true
                    }

                    if (inventIndex !== -1) {
                        desc = (!(invent.data[inventIndex].completeDesc) || invent.data[inventIndex].completeDesc === "null") ? invent.data[inventIndex].abbreviatedDesc : invent.data[inventIndex].completeDesc
                        prodLine = invent.data[inventIndex].ProductLineDesc

                    } else {
                        desc = item.ItemCodeDesc
                    }

                    if (inventIndex !== -1) {
                        const indexProductLine = await getIndexElement(ByProductLine, 'ProdLine', invent.data[inventIndex].ProductLineDesc)
                        if (indexProductLine === -1) {
                            let newProdLine = new ScanningByLineProd(invent.data[inventIndex].ProductLineDesc)
                            newProdLine.addItemToProdLine(item)
                            ByProductLine.push(newProdLine)
                        } else {
                            ByProductLine[indexProductLine].addItemToProdLine(item)
                        }
                    }

                    if (indexItemCode === -1) {
                        let newItemCode = new ScanningByItemCode(item.ItemCode, prodLine, desc, haveUPC)
                        newItemCode.addItemCode(item)
                        ByItemCode.push(newItemCode)

                    } else {
                        ByItemCode[indexItemCode].addItemCode(item)
                    }

                    if (indexOrderNo === -1) {
                        let newOrder = new ScanningByOrderNo(item.SalesOrderNo, 'Purchase Order', item.SalesOrderDate, item.DispatchUser, item.CustomerName)
                        newOrder.addNewProd(item)
                        ByOrderNO.push(newOrder)
                    } else {
                        ByOrderNO[indexOrderNo].addNewProd(item)
                    }
                    Information.push(new GeneralInformation(item.SalesOrderNo, 'Purchase Order', item.CustomerNo, item.CustomerName, item.ItemCode, prodLine, desc, item.DispatchUser,  item.RegisterType === 's'&&item.haveUPC==='Yes' ? 'Scanning' : 'Manual', item.QuantityShipped, FormatQueryReturnDate(item.SalesOrderDate),item.haveUPC))


                }
            }
        }

        if (INResult.status.code === 1) {
            for (const item of INResult.data) {

                if (item.QuantityShipped > 0 && item.CommentText) {
                    let scannInfo = item.CommentText.split('||')

                    if (scannInfo.length === 3) {

                        item.RegisterType = scannInfo[1]
                        item.DispatchUser = scannInfo[2]
                        //Aqui se hace una separacion de la info de scaneo
                        const indexItemCode = getIndexElement(ByItemCode, 'ItemCode', item.ItemCode)
                        const indexOrderNo = getIndexElement(ByOrderNO, 'OrderNo', item.InvoiceNo)
                        const inventIndex = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                        const indexUser = getIndexElement(ByUserName, 'DispatchUser', item.DispatchUser)

                        if (indexUser === -1) {
                            const user = new UsersScanningReport(item.DispatchUser)
                            ByUserName.push(user)
                        }

                        let desc = ''
                        let prodLine = ''
                        let haveUPC = false
                        item.haveUPC = 'No'
                        if (inventIndex !== -1) {
                            desc = (!(invent.data[inventIndex].completeDesc) || invent.data[inventIndex].completeDesc === "null") ? invent.data[inventIndex].abbreviatedDesc : invent.data[inventIndex].completeDesc
                            prodLine = invent.data[inventIndex].ProductLineDesc

                            if (invent.data[inventIndex].upc || invent.data[inventIndex].caseupc || invent.data[inventIndex].upc2) {
                                if (invent.data[inventIndex].upc !== '' || invent.data[inventIndex].upc2 !== '' || invent.data[inventIndex].caseupc !== '') {
                                    if (invent.data[inventIndex].upc !== 'null' || invent.data[inventIndex].upc2 !== 'null' || invent.data[inventIndex].caseupc !== 'null') {
                                        haveUPC = true
                                        item.haveUPC = 'Yes'
                                    }
                                }

                            }

                        } else {
                            desc = item.ItemCodeDesc
                        }

                        if (inventIndex !== -1) {
                            const indexProductLine = await getIndexElement(ByProductLine, 'ProdLine', invent.data[inventIndex].ProductLineDesc)
                            if (indexProductLine === -1) {
                                let newProdLine = new ScanningByLineProd(invent.data[inventIndex].ProductLineDesc)
                                newProdLine.addItemToProdLine(item)
                                ByProductLine.push(newProdLine)
                            } else {
                                ByProductLine[indexProductLine].addItemToProdLine(item)
                            }
                        }

                        if (indexItemCode === -1) {
                            let newItemCode = new ScanningByItemCode(item.ItemCode, prodLine, desc, haveUPC)
                            newItemCode.addItemCode(item)
                            ByItemCode.push(newItemCode)

                        } else {
                            ByItemCode[indexItemCode].addItemCode(item)
                        }

                        if (indexOrderNo === -1) {
                            let newOrder = new ScanningByOrderNo(item.InvoiceNo, 'Direct Invoice', ConvertDateFormatMAS90ToQueryFormat(item.InvoiceDate), item.DispatchUser, item.BillToName)
                            newOrder.addNewProd(item)
                            ByOrderNO.push(newOrder)
                        } else {
                            ByOrderNO[indexOrderNo].addNewProd(item)
                        }
                        Information.push(new GeneralInformation(item.InvoiceNo, 'Direct Invoice', item.CustomerNo, item.BillToName, item.ItemCode, prodLine, desc, item.DispatchUser,  item.RegisterType === 's'&&item.haveUPC==='Yes' ? 'Scanning' : 'Manual', item.QuantityShipped, DateFormatMAS90(item.InvoiceDate),item.haveUPC))
                    }
                }
            }
        }




        for (const element of ByUserName) {

            element.TotalEscaneos = 0
            element.TotalManuales = 0
            element.PorcentajeEcaneos = 0
            element.PorcentajeManual = 0
            element.Total = 0
            element.TotalUPC = 0
            element.Detail = []
            for (const order of ByOrderNO) {
                if (order.User === element.DispatchUser) {
                    element.TotalEscaneos += order.TotalScanningProds
                    element.TotalManuales += order.TotalManualProds
                    element.TotalUPC += order.TotalUPC
                    element.Total += order.Total
                    element.Detail.push(order)
                }
            }
            element.PorcentajeEcaneos = element.TotalUPC > 0 ? ((element.TotalEscaneos / element.TotalUPC) * 100) : 0
            element.PorcentajeManual = element.TotalUPC > 0 ? ((element.TotalManuales / element.TotalUPC) * 100) : 0


        }



        


        this.setState({
            ByProdLine: ByProductLine,
            ByProdLineFilter: ByProductLine,
            ByItemCode: ByItemCode,
            ByItemCodeFilter: ByItemCode,
            ByOrders: ByOrderNO,
            ByOrdersFilter: ByOrderNO,
            ByUsers: ByUserName,
            ByUsersFilter: ByUserName,
            AllByUsers: ByUserName,
            AllInformation: Information
        })

        this.ModalLoading.current.showState(false);
    }

    OrderInformation(array, key, state) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [state]: order, order: !this.state.order })
    }

    searchScanning = (e) => {
        const id = e.target.id
        const searchValue = e.target.value
        let Filter = []
        switch (id) {
            case "searchScannByItemCode":
                if (searchValue === '') {
                    Filter = this.state.ByItemCode
                } else {
                    Filter = this.state.ByItemCode.filter((detail) => {
                        if (
                            this.Contains(detail.ItemCode, searchValue) ||
                            this.Contains(detail.ProdLine, searchValue) ||
                            this.Contains(detail.Description, searchValue)
                        ) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ ByItemCodeFilter: Filter })
                break;
            case "searchScannByProdLine":
                if (searchValue === '') {
                    Filter = this.state.ByProdLine
                } else {
                    Filter = this.state.ByProdLine.filter((detail) => {
                        if (
                            this.Contains(detail.ProdLine, searchValue)

                        ) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ ByProdLineFilter: Filter })
                break;
            case "searchScannByOrders":
                if (searchValue === '') {
                    Filter = this.state.ByOrders
                } else {
                    Filter = this.state.ByOrders.filter((detail) => {
                        if (
                            this.Contains(detail.OrderNo, searchValue)
                        ) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ ByOrdersFilter: Filter })
                break;
            case "searchScannByUserName"://DispatchUser
                if (searchValue === '') {
                    Filter = this.state.ByUsers
                } else {
                    Filter = this.state.ByUsers.filter((detail) => {
                        if (
                            this.Contains(detail.DispatchUser, searchValue)
                        ) {
                            return detail
                        } else {
                            return null
                        }
                    })
                }
                this.setState({ ByUsersFilter: Filter })
                break;

            default:
                break;
        }
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    formatPDFScanningByUsers(Detail) {
        let Format = []
        for (const Order of Detail) {
            if (this.state.reportPrint === 'All' || Order.Type === this.state.reportPrint) {
                const data = {
                    OrderNo: Order.OrderNo,
                    CustomerName: Order.CustomerName,
                    Type: Order.Type,
                    Date: FormatQueryReturnDate(Order.Date),
                    TotalScanningProds: NumberFormat(Order.TotalScanningProds),
                    TotalManualProds: NumberFormat(Order.TotalManualProds),
                    Total: NumberFormat(Order.Total),
                    PercentageManual: NumberFormat(pricesFormat(Order.PercentageManual)) + '%',
                    PercentageScanning: NumberFormat(pricesFormat(Order.PercentageScanning)) + '%',
                    TotalUPC: NumberFormat(Order.TotalUPC)
                }
                Format.push(data)
            }

        }

        return Format
    }


    valueRadioButton = async e => {
        let stat = e.target.value
        await this.setState({ reportPrint: stat })
        await this.FilterByUsers()
    }

    FilterByUsers() {
        let temporal = []
        for (const user of this.state.AllByUsers) {
            let newuser = new UsersScanningReport(user.DispatchUser)
            for (const order of user.Detail) {
                if (this.state.reportPrint === 'All' || order.Type === this.state.reportPrint) {
                    newuser.TotalEscaneos += order.TotalScanningProds
                    newuser.TotalManuales += order.TotalManualProds
                    newuser.TotalUPC += order.TotalUPC
                    newuser.Total += order.Total
                    newuser.Detail.push(order)
                }
            }
            newuser.PorcentajeEcaneos = newuser.TotalUPC > 0 ? ((newuser.TotalEscaneos / newuser.TotalUPC) * 100) : 0
            newuser.PorcentajeManual = newuser.TotalUPC > 0 ? ((newuser.TotalManuales / newuser.TotalUPC) * 100) : 0
            temporal.push(newuser)
        }
        this.setState({ ByUsers: temporal, ByUsersFilter: temporal })
    }

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Scanning Report</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select a start and end date.</p>
                </div>
                <p className='text-center display-1 pb-2' ></p>
                <div className='row d-flex justify-content-center'>
                    <div className='col-sm-10'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="startScanningFilterDate">Start</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startScanningFilterDate' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="endScanningFilterDate">End</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endScanningFilterDate' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 InputTextBolder text-center d-flex align-items-center'>
                                <button onClick={() => this.getScanning()} type="button" className="btn blueButton btn-lg w-100"><AiOutlineReload /> Load Data</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-sm-12 col-md-4 mx-auto my-3">
                            <ExcelDocument
                                hidden={this.state.AllInformation.length === 0}
                                archname={'Scanning Report ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                sheetname2={['By Product Line', 'By ItemCode', 'By Users', 'All Information']}
                                sheetname={'By No Order'}
                                data={getDataSet(this.state.ByOrders, ['Order No', 'Customer No', 'Type', 'Date', 'Total Scanning Products', 'Percentage Scanning Products', 'Total Manual Products', 'Percentage Manual Products', 'Total Products with UPC', 'Total Products', 'User'], ['OrderNo', 'CustomerName', 'Type', 'Date', 'TotalScanningProds', 'PercentageScanning', 'TotalManualProds', 'PercentageManual', 'TotalUPC', 'Total', 'User'])}
                                data2={[
                                    getDataSet(this.state.ByProdLine, ['Product Line', 'Total Scanning Products', 'Percentage Scanning Products', 'Total Manual Products', 'Percentage Manual Products', 'Total Products with UPC', 'Total Products'], ['ProdLine', 'TotalScanningProds', 'PercentageScanning', 'TotalManualProds', 'PercentageManual', 'TotalUPC', 'Total']),
                                    getDataSet(this.state.ByItemCode, ['Item Code', 'Product Line', 'Have UPC', 'Description', 'Total Times Scanning', 'Percentage Scanning Products', 'Total Times Manual', 'Percentage Manual Products', 'Total'], ['ItemCode', 'ProdLine', 'HaveUPC', 'Description', 'TotalScanningProds', 'PercentageScanning', 'TotalManualProds', 'PercentageManual', 'Total']),
                                    getDataSet(this.state.ByUsers, ['User Name', 'Total Scanning', 'Percentage Scanning', 'Total Manual', 'Percentage Manual', 'Total Items with UPC', 'Total'], ['DispatchUser', 'TotalEscaneos', 'PorcentajeEcaneos', 'TotalManuales', 'PorcentajeManual', 'TotalUPC', 'Total']),
                                    getDataSet(this.state.AllInformation, ['Order No', 'Type', 'Customer/Vendor No', 'Customer/Vendor Name', 'Item Code', 'Product Line', 'Description', 'User Name', 'Register Type','Have UPC', 'Quantity Shipped/Received', 'Date'], ['OrderNo', 'Type', 'CustomerNo', 'CustomerName', 'ItemCode', 'ProdLine', 'ItemCodeDesc', 'UserName', 'RegisterType','HaveUPC', 'DispatchQuantity', 'Date'])
                                ]}
                            />
                        </div>
                    </div>
                    {
                        this.state.AllInformation.length === 0 ?
                            <div className='col-sm-12'></div>
                            :
                            <div className='col-sm-12'>
                                <ul className="nav nav-tabs" id="ScanningControls" role="tablist">
                                    <li hidden={false} className="nav-item" role="presentation">
                                        <button id='ReportScannByOrders' className="nav-link active" data-bs-toggle="tab" data-bs-target="#ScannByOrders" type="button" role="tab" aria-controls="ScannByOrders" aria-selected="true"> By No Order</button>
                                    </li>
                                    <li hidden={false} className="nav-item" role="presentation">
                                        <button id='ReportScannByProdLine' className="nav-link" data-bs-toggle="tab" data-bs-target="#ScannByProdLine" type="button" role="tab" aria-controls="ScannByProdLine" aria-selected="false">By Product Line</button>
                                    </li>
                                    <li hidden={false} className="nav-item" role="presentation">
                                        <button id='ReportScannByItemCode' className="nav-link" data-bs-toggle="tab" data-bs-target="#ScannByItemCode" type="button" role="tab" aria-controls="ScannByItemCode" aria-selected="false">By Item Code</button>
                                    </li>
                                    <li hidden={false} className="nav-item" role="presentation">
                                        <button id='ReportScannByUser' className="nav-link" data-bs-toggle="tab" data-bs-target="#ScannByUser" type="button" role="tab" aria-controls="ScannByUser" aria-selected="false">By Users</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="allScanningTab">
                                    <div className="tab-pane fade show active" id="ScannByOrders" role="tabpanel" aria-labelledby="ScannByOrders">
                                        <div className='row pt-4'>
                                            <div className='col-12 pb-3'>
                                                <input onChange={this.searchScanning} className="form-control form-control-lg" placeholder='Search by Order No...' id='searchScannByOrders' type="text" />
                                            </div>
                                            <div className='col-12 tableFixHead'>
                                                <table className='table align-middle table-hover'>
                                                    <thead className='thead'>
                                                        <tr className='text-light text-center'>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'OrderNo', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Order No</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'CustomerName', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Customer Name</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'Type', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Type</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'Date', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Date</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'TotalScanningProds', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Scanning Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'PercentageScanning', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Scanning Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'TotalManualProds', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Manual Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'PercentageManual', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Manual Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'Total', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Products with UPC</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'Total', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByOrdersFilter, 'Total', 'ByOrdersFilter')} className='bg-dark'><div className='row'><div className='col-12'>User</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbody'>
                                                        {

                                                            this.state.ByOrdersFilter.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='text-start'>{item.OrderNo}</td>
                                                                    <td className='text-start'>{item.CustomerName}</td>
                                                                    <td className='text-center'>{item.Type}</td>
                                                                    <td className='text-center'>{FormatQueryReturnDate(item.Date)}</td>
                                                                    <td className='text-center'>{item.TotalScanningProds}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PercentageScanning))} %</td>
                                                                    <td className='text-center'>{item.TotalManualProds}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PercentageManual))} %</td>
                                                                    <td className='text-center'>{item.TotalUPC}</td>
                                                                    <td className='text-center'>{item.Total}</td>
                                                                    <td className='text-center'>{item.User}</td>

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="ScannByProdLine" role="tabpanel" aria-labelledby="ScannByProdLine">
                                        <div className='row pt-4'>
                                            <div className='col-12 pb-3'>
                                                <input onChange={this.searchScanning} className="form-control form-control-lg" placeholder='Search by Product Line...' id='searchScannByProdLine' type="text" />
                                            </div>
                                            <div className='col-12 tableFixHead'>
                                                <table className='table align-middle table-hover'>
                                                    <thead className='thead'>
                                                        <tr className='text-light text-center'>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'ProdLine', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'TotalScanningProds', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Scanning Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'PercentageScanning', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Scanning Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'TotalManualProds', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Manual Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'PercentageManual', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Manual Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'TotalUPC', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Products with UPC</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByProdLineFilter, 'Total', 'ByProdLineFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbody'>
                                                        {

                                                            this.state.ByProdLineFilter.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='text-start'>{item.ProdLine}</td>
                                                                    <td className='text-center'>{item.TotalScanningProds}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PercentageScanning))} %</td>
                                                                    <td className='text-center'>{item.TotalManualProds}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PercentageManual))} %</td>
                                                                    <td className='text-center'>{item.TotalUPC}</td>
                                                                    <td className='text-center'>{item.Total}</td>

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="ScannByItemCode" role="tabpanel" aria-labelledby="ScannByItemCode">
                                        <div className='row pt-4'>
                                            <div className='col-12 pb-3'>
                                                <input onChange={this.searchScanning} className="form-control form-control-lg" placeholder='Search by ItemCode, Description, Product Line...' id='searchScannByItemCode' type="text" />
                                            </div>
                                            <div className='col-12 tableFixHead'>
                                                <table className='table align-middle table-hover'>
                                                    <thead className='thead'>
                                                        <tr className='text-light text-center'>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'ItemCode', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'ProdLine', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'HaveUPC', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Have UPC</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'Description', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'TotalScanningProds', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Times Scanning</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'PercentageScanning', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Scanning Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'TotalManualProds', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Times Manual</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'PercentageManual', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Manual Products</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByItemCodeFilter, 'Total', 'ByItemCodeFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbody'>
                                                        {

                                                            this.state.ByItemCodeFilter.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='text-start'>{item.ItemCode}</td>
                                                                    <td className='text-start'>{item.ProdLine}</td>
                                                                    <td className='text-center'>{item.HaveUPC}</td>
                                                                    <td className='text-start'>{item.Description}</td>
                                                                    <td className='text-center'>{item.TotalScanningProds}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PercentageScanning))} %</td>
                                                                    <td className='text-center'>{item.TotalManualProds}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PercentageManual))} %</td>
                                                                    <td className='text-center'>{item.Total}</td>

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="ScannByUser" role="tabpanel" aria-labelledby="ScannByUser">
                                        <div className='row pt-4'>

                                            <div className='col-12 pb-3'>
                                                <input onChange={this.searchScanning} className="form-control form-control-lg" placeholder='Search by User Name...' id='searchScannByUserName' type="text" />
                                            </div>
                                            <div className='col-12 pb-3'>
                                                <div className="form-check">
                                                    <input className="form-check-input" value={"All"} onChange={this.valueRadioButton} checked={this.state.reportPrint === "All"} type="radio" name="radioScanningReport" />
                                                    <label className="form-check-label" htmlFor="radioScanningReport">
                                                        All Information
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" value={"Sales Order"} onChange={this.valueRadioButton} checked={this.state.reportPrint === "Sales Order"} type="radio" name="radioScanningReport" />
                                                    <label className="form-check-label" htmlFor="radioScanningReport">
                                                        Sales Order
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" value={"Direct Invoice"} onChange={this.valueRadioButton} checked={this.state.reportPrint === "Direct Invoice"} type="radio" name="radioScanningReport" />
                                                    <label className="form-check-label" htmlFor="radioScanningReport">
                                                        Invoice
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" value={"Purchase Order"} onChange={this.valueRadioButton} checked={this.state.reportPrint === "Purchase Order"} type="radio" name="radioScanningReport" />
                                                    <label className="form-check-label" htmlFor="radioScanningReport">
                                                        Purchase Order
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-12 tableFixHead'>
                                                <table className='table align-middle table-hover'>
                                                    <thead className='thead'>
                                                        <tr className='text-light text-center'>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'DispatchUser', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>User Name</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'TotalEscaneos', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Scanning</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'PorcentajeEcaneos', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Scanning</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'TotalManuales', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Manual</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'PorcentajeManual', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Percentage Manual</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'Total', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total Items with UPC</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th onClick={() => this.OrderInformation(this.state.ByUsersFilter, 'Total', 'ByUsersFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                            <th className='bg-dark'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='tbody'>
                                                        {

                                                            this.state.ByUsersFilter.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className='text-start'>{item.DispatchUser}</td>

                                                                    <td className='text-center'>{item.TotalEscaneos}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PorcentajeEcaneos))} %</td>
                                                                    <td className='text-center'>{item.TotalManuales}</td>
                                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.PorcentajeManual))} %</td>
                                                                    <td className='text-center'>{item.TotalUPC}</td>
                                                                    <td className='text-center'>{item.Total}</td>
                                                                    <td className='text-center'>
                                                                        {MobileDisp() ?
                                                                            <OrderPDF
                                                                                id={"PrintScanningByUser_" + item.DispatchUser} disabled={false} colorButton="blackButton" title="Print Report"
                                                                                companyLogo={getValueCookie('DocumentsLogo')}
                                                                                OrderTitle="Scanning Report Per User"
                                                                                contactInfo={this.state.companyPrintHeader}
                                                                                OrderInfo1={["User Name: ", item.DispatchUser, "Printed by: ", getValueCookie('userName'), "Start Period: ", DateFormatMAS90(this.state.StartPeriod), "Printed Date: ", FormatQueryReturnDate(getActualDateUTC()), "End Period: ", DateFormatMAS90(this.state.EndPeriod), "", ""]}
                                                                                headerTable={["\nOrder\nNo", "\nCustomer\nName", "\nType", "\nDate", "Total\nScanning\nProducts", "Percentage\nScanning\nProducts", "Total\nManual\nProducts", "Percentage\nManual\nProducts", "Total\nProducts\nwith UPC", "\nTotal\nProducts"]}
                                                                                bodyTable={this.formatPDFScanningByUsers(item.Detail)}
                                                                                headerBodyTable={["OrderNo", "CustomerName", "Type", "Date", "TotalScanningProds", "PercentageScanning", "TotalManualProds", "PercentageManual", "TotalUPC", "Total"]}
                                                                                bottomInfo={["", "TOTALS:", "", "", item.TotalEscaneos, NumberFormat(pricesFormat(item.PorcentajeEcaneos)) + '%', item.TotalManuales, NumberFormat(pricesFormat(item.PorcentajeManual)) + '%', item.TotalUPC, item.Total]}
                                                                                styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoNormalLeft", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades"]}
                                                                                hidden={false}
                                                                                comment={''}
                                                                                breakpoint={32}
                                                                                keysDecimalFormat={[""]}
                                                                                titleButton='Print Report'
                                                                            />
                                                                            :
                                                                            <DownloadOrderPDF
                                                                                id={"PrintScanningByUser_" + item.DispatchUser} disabled={false} colorButton="blackButton" title="Print Report"
                                                                                companyLogo={getValueCookie('DocumentsLogo')}
                                                                                OrderTitle="Scanning Report Per User"
                                                                                contactInfo={this.state.companyPrintHeader}
                                                                                OrderInfo1={["User Name: ", item.DispatchUser, "Printed by: ", getValueCookie('userName'), "Start Period: ", DateFormatMAS90(this.state.StartPeriod), "Printed Date: ", FormatQueryReturnDate(getActualDateUTC()), "End Period: ", DateFormatMAS90(this.state.EndPeriod), "", ""]}
                                                                                headerTable={["\nOrder\nNo", "\nCustomer\nName", "\nType", "\nDate", "Total\nScanning\nProducts", "Percentage\nScanning\nProducts", "Total\nManual\nProducts", "Percentage\nManual\nProducts", "Total\nProducts\nwith UPC", "\nTotal\nProducts"]}
                                                                                bodyTable={this.formatPDFScanningByUsers(item.Detail)}
                                                                                headerBodyTable={["OrderNo", "CustomerName", "Type", "Date", "TotalScanningProds", "PercentageScanning", "TotalManualProds", "PercentageManual", "TotalUPC", "Total"]}
                                                                                bottomInfo={["", "TOTALS:", "", "", item.TotalEscaneos, NumberFormat(pricesFormat(item.PorcentajeEcaneos)) + '%', item.TotalManuales, NumberFormat(pricesFormat(item.PorcentajeManual)) + '%', item.TotalUPC, item.Total]}
                                                                                styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoNormalLeft", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades", "ColumnaCantidades"]}
                                                                                hidden={false}
                                                                                comment={''}
                                                                                breakpoint={32}
                                                                                keysDecimalFormat={[""]}
                                                                                titleButton='Print Report'
                                                                            />

                                                                        }

                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}
