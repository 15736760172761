import axios from "axios";
import server from "../services/Connection/backEndLink"
import { confirmCloseAlert } from "../functions/alerts"
import { status } from "../components/Status";
import { getValueCookie } from "./cookieService";
import { closeSession } from "../functions/closeSession";
const baseURLAuth = server.url


export const getInformationNoData = async (route) => {
    const URL = baseURLAuth + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    await axios.get(URL).then(
        response => {
            responseBack = response.data
        }
    ).catch(error => {
        responseBack = { status: status.ServerFail, response: [] };
        // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
    })

    return responseBack
}

export const GetWithInformation = async (route, data) => {
    const URL = baseURLAuth + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    await axios.get(URL, { data: data }).then(
        response => {
            return response.data
        }
    ).catch(error => {
        responseBack = { status: status.ServerFail, response: [] };
        // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
    })

    return responseBack
}


export const getInformationWithData = async (route, data) => {
    const URL = baseURLAuth + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    
    if (getValueCookie('userName')) {
        await axios.post(URL, { data: data }).then(
            response => {
                responseBack = response.data
            }
        ).catch(error => {
            // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
        })
    } else {
        closeSession()
        window.location.reload()
    }

    return responseBack
}
export const getDetailSO = async (route, data) => {
    const URL = baseURLAuth + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    
    if (getValueCookie('userName')) {
        try {
            const response = await axios.post(URL, {data: data}, { timeout: 90000})
            responseBack = response.data
        }
        catch (error) {
            if (error.code === 'ECONNABORTED'){
                console.error('Request timed out')
            } else {
                console.error('Request failed', error.message)
            }
    }
        // await axios.post(URL, { data: data }).then(
        //     response => {
        //         responseBack = response.data
        //     }
        // ).catch(error => {
        //     confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
        // })
    } else {
        window.location.reload()
    }

    return responseBack
}

export const saveJsonData = async (route, data) => {
    const URL = baseURLAuth + route
    await axios.post(URL, { data }).then().catch(error => {
        // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
    })
}

export const create_Delete_Update_Information = async (route, data) => {
    const URL = baseURLAuth + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    if (getValueCookie('userName')) {
        await axios.post(URL, { data: data }).then(
            response => {
                responseBack = response.data
            }
        ).catch(error => {
            // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
        })
    } else {
        window.location.reload()
    }


    return responseBack
}

export const uploadFile = async (route, file) => {
    const URL = baseURLAuth + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    
    if (getValueCookie('userName')) {
        await axios.post(URL, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(
            response => {
                responseBack = response.data
            }
        ).catch(error => {
            // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
        })
    } else {
        window.location.reload()
    }


    return responseBack
}


export const getInformationWithDataGlobalSales = async (route, data) => {
    const URL = 'https://apiglobalsales.mayaland.store' + route
    var responseBack = {
        data: [],
        status: status.Fail
    }
    if (getValueCookie('userName')) {
        await axios.post(URL, data).then(
            response => {
                responseBack = response.data
            }
        ).catch(error => {
            // confirmCloseAlert('incorrect', 'Error in the Server Response: ' + error)
        })
    } else {
        window.location.reload()
    }

    return responseBack
}

export default { getInformationNoData, create_Delete_Update_Information, getInformationWithData, getInformationWithDataGlobalSales }

