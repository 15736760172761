import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'

import { formatInputDate, getDateFromReports } from '../../functions/dateFormat';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { pricesFormat } from '../../functions/pricesFormat';
import { NumberFormat } from '../../functions/generalFunctions';
import { AiOutlineSearch } from "react-icons/ai"
import ExcelDocument from '../ExcelDocument'

import { getValueCookie } from '../../services/cookieService';
import { getDataSet } from '../../functions/generateDataSetExcel';
export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();


    }
    state = {
        Title: '',
        Data: [],
        useProps: true,
        showModal1: false,
        ExcessColor: 2.5,
        MissColor: 1.5,
        Order: true,
        filterText: ''
    }

    orderInfo(columName) {
        //console.log("colum:",columName)
        let order = this.state.Order
        //Revisar si el useProps es falso para utlizar el mismo estado
        const filtered = this.state.useProps ? this.props.data : this.state.Data;
        const temporal = filtered.sort((a, b) => {
            if (order) {
                return a[columName] > b[columName] ? 1 : a[columName] < b[columName] ? -1 : 0;
            } else {
                return a[columName] < b[columName] ? 1 : a[columName] > b[columName] ? -1 : 0;
            }
        });
        //console.log("temp",temporal)
        this.setState({ Data: temporal, Order: !order, useProps: false })
    }

    selectColor(Level) {
        if (Level < this.state.MissColor) {
            return "yellowTableCell"
        } else if (Level >= this.state.MissColor && Level <= this.state.ExcessColor) {
            return "greenTableCell"
        } else {
            return "redTableCell"
        }
    }

    FilterBySelectedOptions() {

        const checkRoute = document.getElementsByClassName('InventoryStatusCheck')

        let Items = []
        for (const option of checkRoute) {

            if (option.checked) {
                Items.push(option.name)
            }

        }


        let FilterItems = []
        for (const Item of Items) {
            if (Item === 'All') {
                FilterItems =  this.props.data
                break
            } else {
                for (const ItemCode of this.props.data) {
                    if (Item === "ShortageItems" && ItemCode.MOH < this.state.MissColor) {
                        FilterItems.push(ItemCode)
                    } else if (Item === "NormalItems" && ItemCode.MOH >= this.state.MissColor && ItemCode.MOH <= this.state.ExcessColor) {
                        FilterItems.push(ItemCode)
                    } else if (Item === "OverStockItems" && ItemCode.MOH > this.state.ExcessColor) {
                        FilterItems.push(ItemCode)
                    }
                }
            }
        }

        //console.log("la filter", FilterItems)
        this.setState({ Data: FilterItems, useProps: false })

    }

    checkFilterItems(id, Type) {
        const checkbox = document.getElementById(id);

        if (Type === 'All' && checkbox.checked) {
            const checkboxList = document.getElementsByClassName('InventoryStatusCheck')

            for (const checkbox of checkboxList) {
                checkbox.checked = true;
            }
        } else if (Type === 'All' && !checkbox.checked) {
            const checkboxList = document.getElementsByClassName('InventoryStatusCheck')
            for (const checkbox of checkboxList) {
                checkbox.checked = false;
            }
        } else {
            let cond = true
            const checkboxList = document.getElementsByClassName('InventoryStatusCheck')
            for (const checkbox of checkboxList) {
                if ((!checkbox.checked) && (checkbox.id !== 'CheckInvOverStockAll')) {
                    cond = false
                    break
                }
            }
            let all = document.getElementById('CheckInvOverStockAll')
            if (all) {
                all.checked = cond
            }


        }
        this.FilterBySelectedOptions()
    }

    /* onChange = (e) => {
        const id = e.target.id
        switch (id) {
            case "OverStockLevel":
                this.setState({ ExcessColor: Number(e.target.value) })
                break
            case "ShortageLevel":
                this.setState({ MissColor: Number(e.target.value) })
                break
            default:
                break
        }
        this.FilterBySelectedOptions()
    } */

    formatDatePickedUp(data) {
        let temporal = []
        let copy = JSON.parse(JSON.stringify(data))
        copy.forEach(element => {
            //'CustomerNo', 'CustomerName', 'Username', 'Note', 'Date'
            element.LastSoldDate = formatInputDate(element.LastSoldDate);
            temporal.push(element)

        });

        return temporal
    }

    handleFilterChange = (event) => {
        this.setState({
            filterText: event.target.value.toLowerCase(), // Convertir el texto del filtro a minúsculas
        });
    };

    render() {
        const { filterText, useProps, Data } = this.state;
        const filteredData = useProps ? this.props.data : Data;
        const lowerCaseFilterText = filterText.toLowerCase(); // Convertir el texto del filtro a minúsculas
        //console.log(filteredData)
        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='col-sm-12 text-center my-3'>
                        <h5 className='card-title'>{this.props.Title}</h5>
                    </div>
                    <div className='row '>
                        <div className='col-sm-12'>
                            <div className='row d-flex justify-content-center'>
                                <div className="form-check col-sm-12 col-lg-3 text-start fontLarger textRadioButtonSize ">
                                    <label className="form-check-label w-100" >
                                        All
                                        <input className="form-check-input InventoryStatusCheck" type="checkbox" name="All" defaultChecked onChange={() => this.checkFilterItems('CheckInvStatusAll', 'All')} id={'CheckInvStatusAll'} />
                                    </label>
                                </div>
                                <div className='form-check col-sm-12 col-lg-3 text-start fontLarger textRadioButtonSize'>
                                    <label className="form-check-label w-100" >
                                        <div className="color-box backLeyendSoftRed" />
                                        OverStock Items
                                        <input className="form-check-input InventoryStatusCheck" type="checkbox" name="OverStockItems" onChange={() => this.checkFilterItems('CheckInvStatusOverStockItm', 'OverStockItems')} defaultChecked id={'CheckInvStatusOverStockItm'} />
                                    </label>
                                </div>
                                <div className='form-check col-sm-12 col-lg-3 text-start fontLarger textRadioButtonSize'>
                                    <label className="form-check-label w-100" >
                                        <div className="color-box backLeyendSoftGreen" />
                                        Normal Items
                                        <input className="form-check-input InventoryStatusCheck" type="checkbox" name="NormalItems" onChange={() => this.checkFilterItems('CheckInvStatusNormalItm', 'NormalItems')} defaultChecked id={'CheckInvStatusNormalItm'} />
                                    </label>
                                </div>
                                <div className='form-check col-sm-12 col-lg-3 text-start fontLarger textRadioButtonSize'>
                                    <label className="form-check-label w-100" >
                                        <div className="color-box backLeyendSoftYellow" />
                                        Shortage Items
                                        <input className="form-check-input InventoryStatusCheck" type="checkbox" name="ShortageItems" onChange={() => this.checkFilterItems('CheckInvStatusShortageItm', 'ShortageItems')} defaultChecked id={'CheckInvStatusShortageItm'} />
                                    </label>
                                </div>
                            </div>
                            <div className='col-sm-12 my-4 d-flex justify-content-center'>
                                <ExcelDocument hidden={getValueCookie('TypeUser') !== '1'} data={getDataSet(filteredData, ['Item Code',  'Description', 'Product Line', 'Total','000 (ATL)','001 (FL)','002 (Freezer)','On Hand','MOH', 'MOH OverStock', 'Quantity OverStock'], ['ItemCode', 'Description', 'ProductLine', 'AVG', 'Warehouse000', 'Warehouse001', 'Warehouse002', "OnHand",'MOH','OverShort','QuantOverShort'])} sheetname={"Item Code"} archname={"Purchasing Inv Status report " + this.state.Title + ' ' + getValueCookie('Company') + " DATE " + getDateFromReports()} />
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 pb-3 pt-1'>
                                    <div className="input-group input-group-lg">
                                        <span className="input-group-text"><AiOutlineSearch /></span>
                                        <input type="text" autoComplete='off' className="form-control" placeholder='Search by ItemCode Description and ProductLine'  onChange={this.handleFilterChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle table-hover text-center'>
                                    <thead className='table-dark thead'>
                                        <tr>
                                            <th onClick={() => this.orderInfo("ItemCode")}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("Description")}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("ProductLine")}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("AVG")}><div className='row'><div className='col-12'>Sales Last 30 Days</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            {/* <th onClick={() => this.orderInfo("Total")}><div className='row'><div className='col-12'>General Average</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th> */}
                                            <th onClick={() => this.orderInfo("Warehouse000")} className='bg-secondary'><div className='row'><div className='col-12'>{"\n000\n(ATL)"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("Warehouse001")} className='bg-secondary'> <div className='row'><div className='col-12'>{"\n001\n(FL)"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("Warehouse002")} className='bg-secondary'> <div className='row'><div className='col-12'>{"\n002\n(Freezer)"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("OnHand")}> <div className='row'><div className='col-12'>On Hand</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("MOH")}><div className='row'><div className='col-12'>MOH</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("OverShort")}><div className='row'><div className='col-12'>{"MOH\nOverStock/\nShortage"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                            <th onClick={() => this.orderInfo("QuantOverShort")}> <div className='row'><div className='col-12'>{"Quantity\nOverStock/\nShortage"}</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData
                                            .filter(
                                                (element) =>
                                                    element.ItemCode.toLowerCase().includes(lowerCaseFilterText) ||
                                                    element.Description.toLowerCase().includes(lowerCaseFilterText) ||
                                                    element.ProductLine.toLowerCase().includes(lowerCaseFilterText)
                                            ).map((element, e) => (
                                                <tr key={e} className={this.selectColor(element.MOH)}>
                                                    <td>{element.ItemCode}</td>
                                                    <td className='text-start'>{element.Description}</td>
                                                    <td>{element.ProductLine}</td>
                                                    <td>{NumberFormat(element.AVG)}</td>
                                                    {/* <td>{NumberFormat(Number(element.Total))}</td> */}
                                                    <td>{NumberFormat(element.Warehouse000)}</td>
                                                    <td>{NumberFormat(element.Warehouse001)}</td>
                                                    <td>{NumberFormat(element.Warehouse002)}</td>
                                                    <td>{NumberFormat(element.OnHand)}</td>
                                                    <td>{NumberFormat(pricesFormat(element.MOH))}</td>
                                                    <td>{NumberFormat(pricesFormat(element.OverShort))}</td>
                                                    <td>{NumberFormat(Number(element.QuantOverShort))}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
