import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'

import SmallModal from '../SmallModal';
import { DateFormatMAS90,DeleteQueryFormat } from '../../functions/dateFormat';



export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title:'',
        Data:[],
        showModal1:false,
        OnHand:'',
        Descrip:''
    }

    getSpecificData(title,data,onHand,descrip){
        console.log(data)
        this.setState({
            Title:title,
            Data:data,
            OnHand:onHand,
            Descrip:descrip
        })
        this.handleModalOpen("showModal1")
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearState()
    }

    clearState(){
        this.setState({
            Title:'',
            Data:[]  
        })
    }



    render() {
        return (
            <>
                <div className='card'>
                    <div className='body-card'>
                        <div className='row '>
                            <div className='col-sm-12 text-center my-3'>
                                <h5 className='body-title'>{this.props.Title}</h5>
                            </div>
                            <div className='col-sm-12'>
                                <table className='table align-middle text-center table-hover'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Item Code</th>
                                            <th>Description</th>
                                            <th>Short</th>
                                            <th>On Hand</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.props.data).map((key, k) => (
                                            <tr onClick={()=>this.getSpecificData(key,this.props.data[key].detail,this.props.data[key].TotalQuantityOnHand,this.props.data[key].ItemCodeDesc)} key={k}>
                                                <td className='text-start'>{key}</td>
                                                <td className='text-start'>{this.props.data[key].ItemCodeDesc}</td>
                                                <td>{this.props.data[key].shorts.total}</td>
                                                <td>{this.props.data[key].TotalQuantityOnHand}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <SmallModal centered={true} size={'xl'} title={'ItemCode: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <h4> <span className='badge bg-secondary'>{"On Hand: "+this.state.OnHand}</span></h4>
                        <h4>{this.state.Descrip}</h4>
                        <div className='col-1' />
                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle text-center table-hover'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Invoice No</th>
                                            <th>Customer No</th>
                                            <th>Customer Name</th>
                                            <th>Invoce Date</th>
                                            <th>Date of Last Purchase Order</th>
                                            <th>Route</th>
                                            <th>Quantity Ordered</th>
                                            <th>Quantity Shipped</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Data.map((order, o) => (
                                            <tr key={o}>
                                                <td>{order.InvoiceNo}</td>
                                                <td>{order.CustomerNo}</td>
                                                <td className='text-left'>{order.BillToName}</td>
                                                <td>{DateFormatMAS90(order.InvoiceDate)}</td>
                                                <td>{DateFormatMAS90(DeleteQueryFormat(order.LastPODate))}</td>
                                                <td>{order.UDF_RUTA}</td>
                                                <td>{order.QuantityOrdered}</td>
                                                <td>{order.QuantityShipped}</td>
                                            </tr> 
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>
            </>
        )
    }
}
