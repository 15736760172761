import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
export default class LoadingWindow extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }

    state = {
        active: false
    }

    showState(status) {
        this.setState({ active: status });
    }

    render() {
        return (
            <>
                <Modal show={this.state.active}  >
                    <Modal.Body>
                        <div className='container text-center'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='display-3'>Loading data ...<br /></p>
                                </div>
                                <div className='col-12'>
                                    <img src={'./assets/loading_icon.gif'} width={200} alt="Engranaje gif" />
                                </div>
                                <div className='col-12'>
                                    <div className="btn-group btn-group-lg pt-2">
                                        <button type="button" onClick={() => this.showState(false)} className="btn redButton">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
