import React, { Component } from 'react'
import { getInformationWithData } from '../../../services/CABE';
import { AiOutlineReload } from "react-icons/ai";
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { v4 as uuidv4 } from 'uuid';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import LoadingWindow from '../../../components/LoadingWindow';
import { getIMGByItemCode, ItemDesc, NumberFormat, OrderArray, random_rgba } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { DateFormatMAS90, getDateFromReports } from '../../../functions/dateFormat';
import { Cost, Items } from './Class';
import { getIndexElement } from '../../../functions/searchInObject';
import ModalOrders from '../../../components/ModalComponent';
import ExcelDocument from '../../../components/ExcelDocument';
import { getValueCookie } from '../../../services/cookieService';
import { getDataSet } from '../../../functions/generateDataSetExcel';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

export default class VendorPriceChangeReport extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
        ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
            LinearScale,
            BarElement,
            Title, CategoryScale,
            LinearScale,
            PointElement,
            LineElement,

            Filler,
        );
    }
    state = {
        BodyFilter: [],
        flag: true,
        ItemVendorList: [],
        VendorItemList: [],
        AllInformation: [],
        mode: 'Item',
        ModalBody: [],
        ModalBodyFilter: [],
        ModalDetailBody: [],
        ModalFilterDetailBody: [],
        showModal1: false,
        order: true,
        currentItem: {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: '',
            Levels: [],
            KeysLevels: []
        },
        optionsLineGraph: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: `Costs in different months`,
                    font: {
                        size: 20, // Ajusta el tamaño del título aquí
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';
                            if (label) {
                                label += ': $';
                            }
                            label += NumberFormat(pricesFormat(context.parsed.y.toString().replace(/,/g, '.'))) 

                            return label;
                        },
                    },
                },
            },
            scales: {
                y: {
                    suggestedMin: 0,

                }
            }
        },
        LineGraphData: {
            labels: [],
            datasets: [
                {
                    fill: true,
                    label: 'Costs in dolars',
                    data: [],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    spanGaps: true
                },
            ],
        }
    }

    async getVendors() {
        this.ModalLoading.current.showState(true);
        const data = {
            start: document.getElementById('startVendorPriceChange').value,
            end: document.getElementById('endVendorPriceChange').value,
            ItemCode: 'ADRE1001',
            idCompany: Number(getValueCookie('CompanyId'))
        }


        const result = await getInformationWithData('/purchase/History/getDetialByItem', data)

        if (result.status.code === 1) {
            let processData = []
            for (const item of result.data) {
                const index = getIndexElement(processData, 'ItemCode', item.ItemCode)
                if (index === -1) {
                    let newItem = new Items(item.ItemCode, '', '')
                    newItem.Prices.unshift(new Cost(item.PurchaseOrderDate, item.UnitCost, item.VendorNo, item.PurchaseName, item.PriceLevels))
                    newItem.Dates.push({ Date: item.PurchaseOrderDate })
                    processData.push(newItem)
                } else {

                    if (processData[index].Prices.length > 0) {
                        if (processData[index].Prices[0].Cost !== item.UnitCost) {
                            processData[index].Prices.unshift(new Cost(item.PurchaseOrderDate, item.UnitCost, item.VendorNo, item.PurchaseName, item.PriceLevels))
                            const indexDate = getIndexElement(processData[index].Dates, 'Date', item.PurchaseOrderDate)
                            if (indexDate === -1) {
                                processData[index].Dates.push({ Date: item.PurchaseOrderDate })
                            }
                        }
                    }



                }
            }


            let onlyProductsPriceChange = []
            for (const item of processData) {
                if (item.Prices.length > 1) {
                    onlyProductsPriceChange.push(item)
                }
            }
            onlyProductsPriceChange = await ItemDesc(onlyProductsPriceChange, 'ItemCodeDesc', 'ItemCode', true)
            this.setState({ BodyFilter: onlyProductsPriceChange, ItemVendorList: onlyProductsPriceChange, AllInformation: onlyProductsPriceChange })

        }


        this.ModalLoading.current.showState(false);
    }

    valueRadioButton = async e => {
        let stat = e.target.value
        if (stat === 'Item') {
            this.setState({ BodyFilter: this.state.ItemVendorList, mode: stat })

        } else {
            this.setState({ BodyFilter: this.state.VendorItemList, mode: stat })
        }
    }

    async getVendorsList(index, it) {

        this.clearModal()
        await this.handleModalOpen("showModal1")
        this.ModalLoading.current.showState(true);
        let dataSet = []
        let dates = []
        let Levels = []
        let Keys = []
        for (const date of it.Dates) {
            dates.push(DateFormatMAS90(date.Date))
        }
        for (const Vendor of it.Prices) {

            const index = getIndexElement(dataSet, 'label', 'Costs')
            let l = 1
            for (const level of Vendor.PricesLevels) {
                Vendor['PriceLevel' + l] = level
                const indexL = getIndexElement(dataSet, 'label', 'Level Price ' + l)
                if (indexL === -1) {
                    Levels.push('Level Price ' + l)
                    Keys.push('PriceLevel' + l)
                    let color = random_rgba()
                    const vendordata = {
                        label: 'Level Price ' + l,
                        data: [],
                        borderColor: color,
                        backgroundColor: color,
                        spanGaps: true
                    }
                    for (const date of it.Dates) {

                        if (date.Date === Vendor.Date) {
                            vendordata.data.push(level)
                        } else {
                            vendordata.data.push(null)
                        }

                    }
                    dataSet.push(vendordata)
                } else {
                    let count = 0
                    for (const date of it.Dates) {
                        if (date.Date === Vendor.Date) {
                            dataSet[indexL].data[count] = level
                            break
                        }
                        count++
                    }
                }
                l++
            }


            if (index === -1) {
                let color = random_rgba()
                const vendordata = {
                    label: 'Costs',
                    data: [],
                    borderColor: color,
                    backgroundColor: color,
                    spanGaps: true
                }
                for (const date of it.Dates) {

                    if (date.Date === Vendor.Date) {
                        vendordata.data.push(Vendor.Cost)
                    } else {
                        vendordata.data.push(null)
                    }

                }
                dataSet.push(vendordata)
            } else {
                let count = 0
                for (const date of it.Dates) {
                    if (date.Date === Vendor.Date) {
                        dataSet[index].data[count] = Vendor.Cost
                        break
                    }
                    count++
                }
            }




        }

        const graphData = {
            labels: dates,
            datasets: dataSet
        }

        this.setState({ LineGraphData: graphData })
        const temporal = this.state.currentItem
        temporal.ItemCode = it.ItemCode
        temporal.ItemCodeDesc = it.ItemCodeDesc
        temporal.ProductLine = it.ProductLine
        temporal.itemURL = await getIMGByItemCode(it.ItemCode)
        temporal.Levels = Levels
        temporal.KeysLevels = Keys
        await this.setState({
            currentItem: temporal,
            ModalBody: it.Prices,
            ModalBodyFilter: it.Prices
        })

        this.ModalLoading.current.showState(false);

    }

    clearModal() {
        const temporal = {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: '',
            Levels: [],
            KeysLevels: []
        }


        this.setState({
            ModalBody: [],
            ModalBodyFilter: [],
            currentItem: temporal,
            ModalDetailBody: [],
            ModalFilterDetailBody: []
        })
    }



    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    OrderInformation(array, key, state, price) {
        if (price) {

        } else {
            const order = OrderArray(array, key, this.state.order)
            this.setState({ [state]: order, order: !this.state.order })
        }

    }
    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }


    searchVendor = e => {

        let search = e.target.value;

        let Vendor
        if (this.state.mode === 'Item') {
            Vendor = this.state.ItemVendorList.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLine, search) ||
                    this.contains(item.ItemCodeDesc, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });

        }

        this.setState({ BodyFilter: Vendor });
    }
    searchVendorHistory = e => {
        let search = e.target.value;

        let Vendor = this.state.ModalDetailBody.filter((vendor) => {
            if ((this.contains(vendor.VendorNo, search))
                || this.contains(vendor.PurchaseName, search)
                || this.contains(vendor.PurchaseOrderNo, search)) {
                return vendor
            } else {
                return null
            }
        })
        this.setState({ ModalFilterDetailBody: Vendor })
    }



    searchVendorModal = e => {

        let search = e.target.value;

        let Vendor = []
        if (this.state.mode === 'Item') {
            Vendor = this.state.ModalBody.filter((vendor) => {
                if (((
                    this.contains(vendor.VendorNo, search) ||
                    this.contains(vendor.VendorName, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });

        }

        this.setState({ ModalBodyFilter: Vendor });
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Vendors Price Change Report</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select an start and end date</p>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="startVendorPriceChange">Start</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startVendorPriceChange' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="endVendorPriceChange">End</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endVendorPriceChange' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 InputTextBolder text-center d-flex align-items-center'>
                                <button onClick={() => this.getVendors()} type="button" className="btn blueButton btn-lg w-100"><AiOutlineReload /> Load Data</button>
                            </div>
                        </div>
                        <div hidden={this.state.AllInformation.length === 0} className='row pt-3'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input type="text" autoComplete='off' className="form-control" id='searchPrincipalVendor' placeholder={this.state.mode === 'Item' ? 'Search Item by ItemCode, Line Product, Description...' : 'Search Vendor by VendorNo, Vendor Name...'} onChange={this.searchVendor} />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div hidden={this.state.BodyFilter.length === 0} className='col-10 tableFixHead'>
                        <table className='table align-middle table-hover'>
                            <thead className='thead'>
                                <tr className='text-light text-center'>
                                    <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCode', 'BodyFilter')} className='bg-dark'>Item Code <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                    <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ProductLine', 'BodyFilter')} className='bg-dark'>Product Line <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                    <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCodeDesc', 'BodyFilter')} className='bg-dark'>Description <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                {
                                    this.state.BodyFilter.map((item, i) => (
                                        <tr onClick={() => this.getVendorsList(i, item)} key={i}>
                                            <td className='text-start'>{item.ItemCode}</td>
                                            <td className='text-start'>{item.ProductLine}</td>
                                            <td className='text-start'>{item.ItemCodeDesc}</td>
                                        </tr>
                                    ))

                                }
                            </tbody>

                        </table>
                    </div>


                    <div className='col-1'></div>
                </div>

                <ModalOrders title={this.state.mode === 'Item' ? 'Vendors Detail' : 'Items Detail'} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>


                        <div className='col-10'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-12 titleTotal'>
                                            <p>Item Code:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ItemCode}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Description: </p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ItemCodeDesc}</p>
                                        </div>
                                        <div className='col-12 titleTotal'>
                                            <p>Product Line: </p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.state.currentItem.ProductLine}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-4'></div>
                                        <div className='col-4 pt-2 text-center'>
                                            <div className='ItemImg'>
                                                <img key={this.state.currentItem.itemURL} className='pb-5 pt-5' id='ShortsProductImg' src={this.state.currentItem.itemURL !== '' ? this.state.currentItem.itemURL : '/assets/notavailable.png'} alt='ProductImg' />
                                            </div>
                                        </div>
                                        <div className='col-4'></div>
                                    </div>
                                </div>

                                <div className='col-12 input-group input-group-lg pt-2 pb-3'>
                                    <input type="text" autoComplete='off' className="form-control" id='searchVendorVendorPriceChangeModal' placeholder={'Search Vendor by VendorNo, Vendor Name...'} onChange={this.searchVendorModal} />
                                </div>
                                <div className="d-grid gap-2 col-12 pt-4 pb-4">
                                    <ExcelDocument
                                        hidden={this.state.ModalBodyFilter.length === 0}
                                        archname={'Price Change Item ' + this.state.currentItem.ItemCode + getValueCookie('Company') + ' ' + getDateFromReports()}
                                        sheetname={this.state.currentItem.ItemCode}
                                        data={getDataSet(this.state.ModalBodyFilter, [].concat(['Vendor No', 'Vendor Name', 'Last Receipt Date', 'Last Unit Cost ($)'], this.state.currentItem.Levels), [].concat(['VendorNo', 'VendorName', 'Date', 'Cost'], this.state.currentItem.KeysLevels))}

                                    />
                                </div>
                                <div className='col-12 tableFixHead'>
                                    <table className='table align-middle table-hover'>
                                        <thead className='thead'>
                                            <tr className='text-light text-center'>
                                                <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorNo', 'ModalBodyFilter')} className='bg-dark'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            Vendor No
                                                        </div>
                                                        <div className='col-12'>
                                                            <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorName', 'ModalBodyFilter')} className='bg-dark'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            Vendor Name
                                                        </div>
                                                        <div className='col-12'>
                                                            <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'Date', 'ModalBodyFilter')} className='bg-dark'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            Last Receipt Date
                                                        </div>
                                                        <div className='col-12'>
                                                            <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='bg-dark'></th>
                                                <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'Cost', 'ModalBodyFilter')} className='bg-dark'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            Last Unit Cost
                                                        </div>
                                                        <div className='col-12'>
                                                            <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                        </div>
                                                    </div>
                                                </th>
                                                {this.state.currentItem.Levels.map((level, l) => (
                                                    <React.Fragment key={uuidv4()}>
                                                        <th className='bg-dark'></th>
                                                        <th className='bg-dark' onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'PriceLevel' + (l + 1), 'ModalBodyFilter')}>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    {level}
                                                                </div>
                                                                <div className='col-12'>
                                                                    <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </React.Fragment>
                                                ))}

                                            </tr>
                                        </thead>
                                        <tbody className='tbody'>
                                            {
                                                this.state.ModalBodyFilter.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className='text-start'>{item.VendorNo}</td>
                                                        <td className='text-start'>{item.VendorName}</td>
                                                        <td className='text-center'>{item.Date ? DateFormatMAS90(item.Date) : 'Not available'}</td>
                                                        <td className='text-end'>$</td>
                                                        <td className='text-end'>{item.Cost ? NumberFormat(pricesFormat(item.Cost)) : '0.00'}</td>
                                                        {
                                                            item.PricesLevels.map((element, e) => (
                                                                <React.Fragment key={uuidv4()}>
                                                                    <td className='text-end'>$</td>
                                                                    <td className='text-end'>{element ? NumberFormat(pricesFormat(element)) : '0.00'}</td>
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </tr>
                                                ))

                                            }
                                        </tbody>

                                    </table>
                                </div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-12 titleTotal'>
                                                <p>Item Code:</p>
                                            </div>
                                            <div className='col-12 TitleText'>
                                                <p>{this.state.currentItem.ItemCode}</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-12 titleTotal'>
                                                <p>Description: </p>
                                            </div>
                                            <div className='col-12 TitleText'>
                                                <p>{this.state.currentItem.ItemCodeDesc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 pb-3'>
                                    <Line options={this.state.optionsLineGraph} data={this.state.LineGraphData} />
                                </div>


                            </div>
                        </div>



                        <div className='col-1'></div>
                    </div>
                </ModalOrders>

            </div>
        )
    }
}
