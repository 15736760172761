import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsSearch, BsArrowRepeat } from "react-icons/bs";
import ModalOrders from './ModalComponent.js';
import "../css/table-responsive.css"

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        vendorList: [],
        vendorListShow: [],
        showModal: false
    }

    async obtenerCustomers(refresh) {
        await this.handleModalOpen()
        const data = {
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        }


        const buscador = document.getElementById('vendorList_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.vendorList.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ vendorList: [] });
            this.setState({ vendorListShow: [] });
            const respuesta = await getInformationWithData('/vendors/get/vendorsInformation', data);
        

            if (respuesta.status.code === 1) {

                this.setState({ vendorList: respuesta.data });
                this.setState({ vendorListShow: respuesta.data });
            }
            this.ModalLoading.current.showState(false);
        }
        this.setState({ vendorListShow: this.state.vendorList });
        buscador.value = '';
        setTimeout(() => { buscador.focus(); }, 300);

    
    }

    async getVendor(Vendor) {
        const Father=this.props.Padre
        if(this.props.nombrePadre==='PurchaseReturn'){
            await Father.setVendor(Vendor)
        }
        await this.handleModalClose()
    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }

    }

    searchVendor = e => {

        let busqueda = e.target.value;
        
        if (busqueda) {
            let customers = this.state.vendorList.filter((customer_) => {
                if (((
                    this.contiene(customer_.customerNo, busqueda) ||
                    this.contiene(customer_.name, busqueda)
                ))
                ) {
                    return customer_
                } else {
                    return null
                }
            });
            this.setState({ vendorListShow: customers });
        } else {
            let temporal = this.state.vendorList
            this.setState({ vendorListShow: temporal });
        }

    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} className="btn blueButton btn-lg w-100" onClick={() => this.obtenerCustomers(false)}><BsSearch /> Open Vendor List</button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Vendor List'} show={this.state.showModal} close={() => this.handleModalClose()}>

                    <div className='row form-group'>
                        <div className="input-group input-group-lg flex-nowrap col-sm-12 pb-4">
                            <input className='form-control w-75 display-inline' placeholder='Search by Name or Vendor No' id={'vendorList_busqueda_' + this.props.nombrePadre} onChange={this.searchVendor} />
                            <span className="input-group-text">
                                <button className='btn greenButton btn-lg' onClick={() => this.obtenerCustomers(true)}><BsArrowRepeat /></button>
                            </span>
                        </div>


                        <div className='col-sm-12 tableFixHead'>
                            <table className='table table-hover'>
                                <thead className='table-dark text-center thead'>
                                    <tr>
                                        <th>Vendor No</th>
                                        <th>Vendor Name</th>
                                        <th>Address Line</th>
                                        <th>City</th>
                                        <th>Country</th>
                                        <th>Telephone No</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {this.state.vendorListShow.map((vendor,v)=>(
                                    <tr onClick={()=>this.getVendor(vendor)} key={v}>
                                        <td className='text-center'>{vendor.customerNo}</td>
                                        <td className='text-start'>{vendor.name}</td>
                                        <td className='text-start'>{vendor.AddressLine1}</td>
                                        <td className='text-center'>{vendor.City}</td>
                                        <td className='text-center'>{vendor.country}</td>
                                        <td className='text-center'>{vendor.phone1}</td>

                                    </tr>
                                  ))} 
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </ModalOrders>

            </>
        )
    }
}
