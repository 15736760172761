import React, { Component } from 'react'
import { getInformationWithData, getInformationWithDataGlobalSales } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsSearch, BsArrowRepeat } from "react-icons/bs";
import { intervalTimeInDays } from '../functions/generalFunctions.js';
import { ActualDate, DateFormatMAS90 } from '../functions/dateFormat.js';
import ModalOrders from './ModalComponent.js';
import "../css/table-responsive.css"

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        customerList: [],
        customerListShow: [],
        customerShow: [],
        showModal: false,
        currentPage: 0,
        ProductsPerPage: 60,
        TotalPages: 0
    }

    async obtenerCustomers(refresh) {
        await this.handleModalOpen()
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        }

        const buscador = document.getElementById('customer_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.customerList.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ customerList: [] });
            this.setState({ customerListShow: [] });
            const respuesta = await getInformationWithData('/customer/getList', data);

            if (respuesta.status.code === 1) {
                this.setState({ customerList: respuesta.data, customerListShow: respuesta.data });
            }
            this.ModalLoading.current.showState(false);
        }
        await this.setState({ customerListShow: this.state.customerList });
        await this.ResetPagination()
        buscador.value = '';
        setTimeout(() => { buscador.focus(); }, 300);

    }

    async getCustomer(CustomerNo) {
        const Father = this.props.Padre
        if (this.props.nombrePadre === 'Accounts') {
            await Father.clearAll()
            if (Father.state.select.label === 'FREIGHT' || Father.state.select.label === 'CREDITCARD FEE' || getValueCookie('CompanyId') === '6') {
                await Father.getNewCorrelativeInvoiceNumber()
            }
        }
        if (this.props.nombrePadre !== 'Customer' && this.props.nombrePadre !== 'Promotions') {

            CustomerNo.SalespersonNo = getValueCookie('SalesPerson')

            const temporal = Father.state[this.props.nombrePadre]
            temporal.Warehouse = CustomerNo.PriceLevel === '4' ? '003' : CustomerNo.PriceLevel === '5' ? '004' : '000'
            temporal[this.props.headerName] = CustomerNo

            if (this.props.nombrePadre === 'Invoice') {
                temporal.Warehouse = CustomerNo.PriceLevel === '4' ? '003' : temporal.Warehouse = CustomerNo.PriceLevel === '5' ? '004' : '000'
            }


            Father.setState({ [this.props.nombrePadre]: temporal })

            if (this.props.nombrePadre === 'SalesOrder' || this.props.nombrePadre === 'Invoice') {
                const data = {
                    CustomerNo: CustomerNo.CustomerNo,
                    username: getValueCookie('userName'),
                    company: getValueCookie('Company'),
                    idCompany: Number(getValueCookie('CompanyId'))
                }
                this.ModalLoading.current.showState(true);
                const balance = await getInformationWithData('/customer/getBalance', data)
                if (balance.status.code === 1) {
                    balance.data.forEach(element => {
                        element.PaymentDelayDays = intervalTimeInDays(DateFormatMAS90(element.InvoiceDueDate), ActualDate())
                        element.InvoiceDate = DateFormatMAS90(element.InvoiceDate)
                        element.InvoiceDueDate = DateFormatMAS90(element.InvoiceDueDate)
                        element.CustomerName = CustomerNo.CustomerName
                    });

                    const temporalBalance = Father.state.Balance
                    temporalBalance.HeaderBalance = balance.data
                    temporalBalance.Detail = []
                    temporalBalance.TermsCode = CustomerNo.TermsCodeDesc
                    Father.setState({ Balance: temporalBalance })
                    Father.getTotalBalance()
                }
                if (this.props.nombrePadre === 'SalesOrder') {
                    Father.handleModalOpen("showModal1")
                } else if (this.props.nombrePadre === 'Invoice') {
                    if (Father.state.Invoice.InvoiceType === 'CM') {
                        Father.partialClear()
                    }
                    Father.handleModalOpen("showModal2")
                }
                this.ModalLoading.current.showState(false);
            }
        } else if (this.props.nombrePadre === 'Promotions') {
            CustomerNo.SalespersonNo = getValueCookie('SalesPerson')
            let temporal = Father.state.Customer
            temporal = CustomerNo
            await Father.setState({ Customer: temporal })
            await Father.getCustomerPromotions()
        } else {
            const resultUser = await getInformationWithDataGlobalSales('/user/hasUser', { CustomerNo: CustomerNo.CustomerNo })

            CustomerNo.OrderType = 'customerManager'
            CustomerNo.New = 0
            CustomerNo.Route = CustomerNo.UDF_RUTA
            CustomerNo.Country = CustomerNo.CountryCode
            CustomerNo.Taxable = CustomerNo.TaxSchedule
            CustomerNo.Customer = CustomerNo.CountryCode
            CustomerNo.Fax = CustomerNo.FaxNo
            let temporal2 = Father.state[this.props.nombrePadre]
            temporal2 = CustomerNo
            await Father.setState({ [this.props.nombrePadre]: temporal2, PlatformCheck: resultUser.Active })
            await Father.setValues()
        }

        await this.handleModalClose()
    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }

    }

    buscarCustomer = e => {

        let busqueda = e.target.value;

        if (busqueda) {
            let customers = this.state.customerList.filter((customer_) => {
                if (((
                    this.contiene(customer_.CustomerNo, busqueda) ||
                    this.contiene(customer_.CustomerName, busqueda)
                ))
                ) {
                    return customer_
                } else {
                    return null
                }
            });
            this.setState({ customerListShow: customers });
        } else {
            let temporal = this.state.customerList
            this.setState({ customerListShow: temporal });
        }
        this.ResetPagination()
    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }


    async ResetPagination() {
        let totalPages = Math.round(this.state.customerListShow.length / this.state.ProductsPerPage)
        let seeProds = this.state.customerListShow.slice(0, 0 + this.state.ProductsPerPage)
        await this.setState({
            customerShow: seeProds,
            currentPage: 0,
            ProductsPerPage: 30,
            TotalPages: totalPages
        })

    }

    async nextPage() {
        if ((this.state.currentPage + this.state.ProductsPerPage) <= this.state.customerListShow.length) {
            await this.setState({ currentPage: this.state.currentPage + this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async prevPage() {
        if (this.state.currentPage > 0) {
            await this.setState({ currentPage: this.state.currentPage - this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async updateSeeProducts() {
        let seeProds = this.state.customerListShow.slice(this.state.currentPage, this.state.currentPage + this.state.ProductsPerPage)
        await this.setState({
            customerShow: seeProds,
        })

    }
    backToTop() {

        const buscador = document.getElementById('customer_busqueda_' + this.props.nombrePadre);
        setTimeout(() => { buscador.focus(); }, 300);
    }



    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} className="btn btn-lg w-100 blackButton" onClick={() => this.obtenerCustomers(false)}> <BsSearch /> Customer List</button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Customer List'} show={this.state.showModal} close={() => this.handleModalClose()}>

                    <div className='row form-group'>
                        <div className="input-group input-group-lg flex-nowrap col-sm-12 pb-4">
                            <input className='form-control w-75 display-inline' placeholder='Search by CustomerNo or name' id={'customer_busqueda_' + this.props.nombrePadre} onChange={this.buscarCustomer} />
                            <span className="input-group-text">
                                <button className='btn greenButton btn-lg' onClick={() => this.obtenerCustomers(true)}><BsArrowRepeat /></button>
                            </span>
                        </div>


                        <div className='col-sm-12 tableFixHead'>
                            <table className='table table-hover'>
                                <thead className='table-dark text-center thead'>
                                    <tr>
                                        <th>Customer No</th>
                                        <th>Customer Name</th>
                                        <th>Address Line</th>
                                        <th>City</th>
                                        <th>Zip Code</th>
                                        <th>Telephone No</th>
                                        <th>Price Level</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.customerShow.map((Customer, i) => (
                                            <tr key={i} className='' onClick={() => this.getCustomer(Customer)}>
                                                <td className='text-center'>{Customer.CustomerNo}</td>
                                                <td className='text-start'>{Customer.CustomerName}</td>
                                                <td className='text-start'>{Customer.AddressLine1}</td>
                                                <td className='text-center'>{Customer.City}</td>
                                                <td className='text-center'>{Customer.ZipCode}</td>
                                                <td className='text-center'>{Customer.TelephoneNo}</td>
                                                <td className='text-center'>{Customer.PriceLevel}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className='text-center'>
                                        <td>
                                            <div className="btn-group btn-group-lg" role="group" aria-label="pagination">
                                                <button disabled={this.state.currentPage === 0} type="button" onClick={() => this.prevPage()} className="btn blueButton">Prev</button>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='text-center'>
                                            <div className="btn-group btn-group-lg" role="group" aria-label="pagination">
                                                <button disabled={(this.state.currentPage + this.state.ProductsPerPage) >= this.state.customerListShow.length} type="button" onClick={() => this.nextPage()} className="btn blueButton">Next</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </ModalOrders>

            </>
        )
    }
}
