import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { DateFormatMAS90, getDateFromReports, FormatQueryReturnDateForReport, DeleteQueryFormat } from '../../functions/dateFormat';
import { pricesFormat } from '../../functions/pricesFormat';
import { getValueCookie } from '../../services/cookieService';
import { getDataSet } from '../../functions/generateDataSetExcel';
import ExcelDocument from '../ExcelDocument';
import { create_Delete_Update_Information, getInformationWithData } from '../../services/CABE.js'
import { confirmCloseAlert } from '../../functions/alerts.js';
import SmallModal from '../SmallModal';
import { getActualDateUTC } from '../../functions/dateFormat.js'
import { OrderPDF } from '../OrderPDF.js'
import { DownloadOrderPDF } from '../DownloadOrderPDF'
import { MobileDisp } from '../../functions/generalFunctions'
import { getPrintHeaderCompany } from '../../functions/companyInformation'
import { AiOutlineSearch } from "react-icons/ai"
import { FaRegClock, FaCheckCircle, FaExclamationTriangle, FaTimesCircle } from "react-icons/fa";

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }

    state = {
        showModal1: false,
        Detail: [],
        Data: [],
        Title: '', // CustomerName
        Total: 0,
        TotalQuantity: 0,
        CustomerNo: '',
        Comment: '',
        NoteStatus: 0, // from petition status 0/1
        companyPrintHeader: getPrintHeaderCompany(),
        DaysOfLastFilter: [],
        ActualHeaderOrder: {
            Status: '',
            OrderNo: '',
            OrderName: '',
            OrderName2: '',
            OrderNameNo: '',
            OrderNameAddress: '',
            OrderNameCity: '',
            Date: '',
            SalesPerson: '',
            ZipCode: '',
            State: '',
            Order: null,
            Packing: ''
        },
        filterText: '',
        pageNumers: [],
        currentPage: 1,
        ProductsPerPage: 70,
        lastIndex: 70,
        firstIndex: 0,
        showRecentComments: false
    }


    async customerSetNotes() {
        const data = {
            "CustomerNo": this.state.CustomerNo, // state
            "CustomerName": this.state.Title, // state Title
            "User": getValueCookie('userName'),// Cookie
            "Comment": this.state.Comment, // state
            "Company": getValueCookie('CompanyId'), // cookie
            "DateActivity": getActualDateUTC() //system comment date
        }

        const respuesta = await create_Delete_Update_Information('/customer/set/Notes', data);
        if (respuesta.status.code === 1) {
            confirmCloseAlert('correct', "Note added successfully.")
            this.customerGetNotes(); // update information
        } else {
            confirmCloseAlert('incorrect', 'Whoops we have an error. Please try again!')
        }

    }

    async customerGetNotes() {
        const data = {
            "CustomerNo": this.state.CustomerNo,
            "Company": getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/customer/get/Notes', data)

        if (result.status.code === 1) {
            this.setState({ Data: result.data })
            if (this.state.Data.length > 0) {
                this.setState({ NoteStatus: 1 })
            }

        } else {
            confirmCloseAlert('incorrect', 'Whoops we have an error. Please try again!')
        }
    }

    SelectColor(Days) {
        if (Number(Days) <= 21) {
            return ""
        } else if (Number(Days) > 21 && Number(Days) <= 45) {
            return "softGreen"
        } else if (Number(Days) > 45 && Number(Days) <= 90) {
            return "softYellow"
        } else {
            return "softRed"
        }
    }

    handleModalOpen = (modal, Detail, Title, CustomerNo, DateActivity) => {
        this.setState(
            {
                [modal]: true, Detail: Detail, Title: Title, CustomerNo: CustomerNo, CustomerName: Title,
            }, () => {
                this.customerGetNotes(); // Llama a customerGetNotes después de que el estado se haya actualizado
            }
        );
    };

    handleModalClose = (modal) => {
        this.setState({ [modal]: false, Detail: [], Title: '' })
        this.resetStates();
    }

    handleData = () => () => {
        this.customerSetNotes()
        this.setState({ Comment: '' })
    };

    formatDatePickedUp() {
        let temporal = []
        let copy = JSON.parse(JSON.stringify(this.state.Data))
        copy.forEach(element => {
            //'CustomerNo', 'CustomerName', 'Username', 'Note', 'Date'
            element.Date = DateFormatMAS90(DeleteQueryFormat(element.Date));
            temporal.push(element)

        });

        return temporal
    }

    resetStates = () => {
        this.setState({
            showModal1: false,
            Detail: [],
            Data: [],
            Title: '',
            Total: 0,
            TotalQuantity: 0,
            CustomerNo: 0,
            CustomerName: '',
            User: '',
            Comment: '',
            Company: '',
            DateActivity: '',
            NoteStatus: 0
        });
    };

    handlerFilterChange = (e) => {
        this.setState({ filterText: e.target.value })
    }
    componentDidMount() {
        this.pagination();
        //console.log(this.props.data)
    }
    pagination() {
        const temp = []
        for (let i = 1; i <= Math.ceil(this.props.data.length / this.state.ProductsPerPage); i++) {
            temp.push(i)
        }
        this.setState({ pageNumers: temp })
    }
    async onPreviosPage() {
        await this.setState({ currentPage: this.state.currentPage - 1 })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
    }

    async onNextPage() {
        await this.setState({ currentPage: this.state.currentPage + 1 })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
    }

    async onSpecificPage(noPage) {
        await this.setState({ currentPage: noPage })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
    }

    checkFilterItems = () => {
        this.setState(prevState => ({
            showRecentComments: !prevState.showRecentComments,
        }));
    };
    generatePageNumbers() {
        const { currentPage, pageNumers } = this.state;
        const totalPages = pageNumers.length;
      
        if (totalPages <= 4) {
          return pageNumers;
        }
      
        if (currentPage <= 2) {
          return [...pageNumers.slice(0, 3), totalPages];
        }
      
        if (currentPage >= totalPages - 1) {
          return [1, ...pageNumers.slice(-3)];
        }
      
        return [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
      }
    render() {
        const { filterText } = this.state;
        const lowerCaseFilterText = filterText.toLowerCase();
        const filteredData = this.props.data
            .slice(this.state.firstIndex, this.state.lastIndex)
            .filter(order => {

                const matchesSearch = order.CustomerNo.includes(filterText) ||
                    order.CustomerName.toLowerCase().includes(lowerCaseFilterText);

           
                const matchesRecentComments = !this.state.showRecentComments || order.HaveNotes;

                return matchesSearch && matchesRecentComments;
            });


        return (
            <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row '>
                        <div className='col-sm-12 text-center'>
                            <h5 className='card-title'>{this.props.Title}</h5>
                        </div>
                        <div className='col-sm-12 backgroundWhite'>
                            <div className='col-12'>
                                <table className="table text-center">
                                    <thead>
                                        <tr className='border-bottom'>
                                            <th className='text-center py-3 text-muted' colSpan={3}>Orders / Percent</th>
                                        </tr>
                                        <tr>
                                            <th scope="col blackHeaderTableText"><h5><FaRegClock className="me-1" /> 0 - 21 Days</h5></th>
                                            <th className='greenHeaderTableText' scope="col"><h5><FaCheckCircle className="me-1" /> 22 - 45 Days</h5></th>
                                            <th className='yellowHeaderTableText' scope="col"> <h5><FaExclamationTriangle className="me-1" /> 46 - 90 Days</h5></th>
                                            <th className='redHeaderTableText' scope="col"> <h5><FaTimesCircle className="me-1" /> + 90 Days</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-bottom'>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                <span className='text-dark'>{this.props.Totals.Frequent}</span>
                                                <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.Frequent / this.props.Totals.Total) * 100) + " %"}</div>
                                                </div>
                                            </td>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                    <span className='text-dark'>{this.props.Totals.Normal}</span>
                                                    <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.Normal / this.props.Totals.Total) * 100) + " %"}</div>
                                                </div>
                                            </td>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                    <span className='text-dark'>{this.props.Totals.Warning}</span>
                                                    <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.Warning / this.props.Totals.Total) * 100) + " %"}</div>
                                                </div>
                                            </td>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                    <span className='text-dark'>{this.props.Totals.Risk}</span>
                                                    <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.Risk / this.props.Totals.Total) * 100) + " %"}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-12 my-3'>
                                <ExcelDocument hidden={getValueCookie('TypeUser') !== '1'} data={getDataSet(this.props.data, ['Customer No', 'Customer Name', 'Telephone Number', 'Address Line', 'City', 'State', 'Zip Code', 'Last Date of Sale', '# Days Of Last Sale'], ['CustomerNo', 'CustomerName', 'TelephoneNo', 'AddressLine1', 'City', 'State', 'ZipCode', 'DateLastActivity', 'NumberDays'])} sheetname={"Customers"} archname={"Days Of Last Sale COMPANY " + getValueCookie('Company') + " DATE " + getDateFromReports()} />
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 d-flex justify-content-center'>
                                    <div className="form-check">
                                        <label className="form-check-label w-100" >Recent comments</label>
                                        <input className="form-check-input HistoryCustomer" type="checkbox" name="recentComments" defaultChecked={false} onChange={() => this.checkFilterItems()} id={'RecentComents'} />
                                    </div>
                                </div>
                            </div>
                            <div className='row my-3'>
                                <div className='col-sm-12 pb-2'>
                                    <div className="input-group input-group-lg">
                                        <span className="input-group-text"><AiOutlineSearch /></span>
                                        <input type="text" autoComplete='offs' className="form-control" placeholder='Search by Customer No and customer Name'  onChange={this.handlerFilterChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="tableFixHeadSmall">
                                <table className='table align-middle table-hover text-center'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Customer No</th>
                                            <th>Customer Name</th>
                                            <th>Telephone Number</th>
                                            <th>Last Date of Sale</th>
                                            <th># Days Of Last Sale</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((order, o) => (
                                            <tr onClick={() => this.handleModalOpen('showModal1', order.Detail, order.CustomerName, order.CustomerNo)} className={this.SelectColor(order.NumberDays)} key={o}>
                                                <td>
                                                    <div className="container mt-1">
                                                        <div className="position-relative">
                                                            <i className="bi bi-bell text-primary"></i>
                                                            {order.HaveNotes && (
                                                                <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                                                                    {/* notificationCount */ "!"}
                                                                </span>
                                                            )}
                                                        </div>
                                                        {order.CustomerNo}
                                                    </div>
                                                </td>
                                                <td className='text-start'>{order.CustomerName}</td>
                                                <td>{order.TelephoneNo}</td>
                                                <td>{order.DateLastActivity ? DateFormatMAS90(order.DateLastActivity) : "Never"}</td>
                                                <td>{order.NumberDays}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-center">
                                <nav aria-label="...">
                                    <ul className="pagination">
                                        <li className={`page-item${this.state.currentPage === 1 ? ' disabled' : ''}`}>
                                            <button className="page-link" onClick={() => this.onPreviosPage()}>{"<<"}</button>
                                        </li>
                                        {this.generatePageNumbers().map((noPage, index) => (
                                            <li key={index} className={`page-item ${noPage === '...' ? 'disabled' : ''} ${noPage === this.state.currentPage ? 'active' : ''}`}>
                                                <button className="page-link" onClick={() => noPage !== '...' && this.onSpecificPage(noPage)}>
                                                    {noPage}
                                                </button>
                                            </li>
                                        ))}
                                        <li className={`page-item${this.state.currentPage === this.state.pageNumers.length ? ' disabled' : ''}`}>
                                            <button className="page-link" onClick={() => this.onNextPage()}>{">>"}</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div>
                    <SmallModal centered={true} size={'xl'} title={'Sales of Customer: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                        <div className='row'>
                            <div />
                            <div className='col-12'>
                                <div className="tableFixHeadSmall ">
                                    <div className="form-group separador-superior">
                                        <label>Comment</label>
                                        <textarea className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="3"
                                            value={this.state.Comment}
                                            onChange={(e) => this.setState({ Comment: e.target.value, User: getValueCookie('userName') })}
                                        ></textarea>
                                    </div>
                                    <div className='pt-3 pb-3'>
                                        <button type="button"
                                            className="btn blueButton btn-lg  col-12 AlignImgCenter " onClick={this.handleData()} disabled={!/\S/.test(this.state.Comment)}>Save</button>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className='row'>
                                            <div className='col-sm-6 pb-3'>
                                                <ExcelDocument /* disabled={true} */ hidden={getValueCookie('TypeUser') !== '1'} data={getDataSet(this.formatDatePickedUp(), ['Customer No', 'Customer Name', 'Username', 'Comment', 'Date', 'Hour'], ['CustomerNo', 'CustomerName', 'Username', 'Note', 'Date', "Hour"])} sheetname={"Customers"} archname={"Feedback report " + this.state.Title + ' ' + getValueCookie('Company') + " DATE " + getDateFromReports()} />
                                            </div>
                                            <div className='col-sm-6 pb-3'>
                                                {MobileDisp() ?
                                                    <OrderPDF
                                                        id={"PrintCommentCustomer"} disabled={this.state.NoteStatus === 0} colorButton="blackButton" title="Print Notes"
                                                        companyLogo={getValueCookie('DocumentsLogo')}
                                                        OrderTitle={"Notes List - " + this.state.Title}
                                                        contactInfo={this.state.companyPrintHeader}
                                                        OrderInfo1={["Customer No: ", this.state.CustomerNo, "Printed by: ", getValueCookie('userName'), "Cust. Name: ", this.state.Title, "Printed Date: ", FormatQueryReturnDateForReport(getActualDateUTC())]}
                                                        headerTable={["\n Username", "\nComment", "\nDate", "\nHour"]}
                                                        bodyTable={this.formatDatePickedUp()}
                                                        headerBodyTable={['Username', 'Note', 'Date', "Hour"]}
                                                        bottomInfo={[]}
                                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo4", "ColumnaTextoNormal", "ColumnaTextoNormal"]}
                                                        hidden={false}
                                                        comment={''}
                                                        breakpoint={30}
                                                        keysDecimalFormat={["TotalLineWeight"]}
                                                        titleButton='Print Notes'
                                                    />
                                                    :
                                                    <DownloadOrderPDF
                                                        id={"PrintCommentCustomer"} disabled={this.state.NoteStatus === 0} colorButton="blackButton" title="Print Notes"
                                                        companyLogo={getValueCookie('DocumentsLogo')}
                                                        OrderTitle={"Notes List - " + this.state.Title}
                                                        contactInfo={this.state.companyPrintHeader}
                                                        OrderInfo1={["Customer No: ", this.state.CustomerNo, "Printed by: ", getValueCookie('userName'), "Cust. Name: ", this.state.Title, "Printed Date: ", FormatQueryReturnDateForReport(getActualDateUTC())]}
                                                        headerTable={["\n Username", "\nComment", "\nDate", "\nHour"]}
                                                        bodyTable={this.formatDatePickedUp()}
                                                        headerBodyTable={['Username', 'Note', 'Date', 'Hour']}
                                                        bottomInfo={[]}
                                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo4", "ColumnaTextoNormal", "ColumnaTextoNormal"]}
                                                        hidden={false}
                                                        comment={''}
                                                        breakpoint={30}
                                                        keysDecimalFormat={["TotalLineWeight"]}
                                                        titleButton='Print Notes'
                                                        docname={"Custumer notes " + this.state.Title + ' ' + getValueCookie('Company') + " DATE " + getDateFromReports()}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div >
                                    <table className='table align-middle table-hover text-center'>
                                        <thead className='table-dark'>
                                            <tr>
                                                <th>Username</th>
                                                <th>Date</th>
                                                <th>Hour</th>
                                                <th>Comment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Data.map((order, o) => (
                                                <tr key={o}>
                                                    <td>{order.Username}</td>
                                                    <td>{DateFormatMAS90(DeleteQueryFormat(order.Date))}</td>
                                                    <td>{order.Hour}</td>
                                                    <td className='text-justify'>{order.Note}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div />
                        </div>
                    </SmallModal>
                </div>
            </>
        )
    }
}
