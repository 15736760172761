import React, { Component } from 'react'
import '../css/CartaProducto.css'


export default class CartaProducto extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="conteiner cartaHover font-family-arial" >
                {this.props.mode === 'Spoilage' ?

                    <div className="row">
                        <div className="col-6 imgC ">
                            <img key={this.props.itemURLImage} src={this.props.itemURLImage ? `${this.props.itemURLImage}` : "./assets/notavailable.png"} alt="Card  cap" className='imgC' />
                        </div>
                        <div className="col-6 descriptionMargin">
                            <div className='row'>
                                <h5>
                                    <span class="badge bg-primary me-3">&nbsp;{this.props.ItemCode}&nbsp;</span>
                                </h5>
                            </div>
                            <div className="row text-start">
                                <p className="card-text fs-6">{this.props.Description}</p>
                            </div>
                            {
                                this.props.needPrice ?
                                    <div className="row text-start" >
                                        <p className="card-text fs-4"><span class="badge bg-dark me-3">&nbsp;Price Sold: ${this.props.UnitPrice ? this.props.UnitPrice.toFixed(2) : this.props.UnitPrice}&nbsp;</span></p>
                                    </div>
                                :
                                    <div></div>
                            }
                            {
                                <div className="row text-start" >
                                    <p className="card-text fs-4"><span class="badge bg-dark me-3">&nbsp;Quantity Sold: {this.props.OnHand ? this.props.OnHand : 0}&nbsp;</span></p>
                                </div>
                                    
                            }


                        </div>
                    </div>
                    :
                    <div className="row item-card py-4">
                        <div className="col-6 imgC ">
                            <img key={this.props.itemURLImage} src={this.props.itemURLImage ? `${this.props.itemURLImage}` : "./assets/notavailable.png"} alt="Card  cap" className='imgC' />
                        </div>
                        <div className="col-6 descriptionMargin">
                            <div className='row'>
                                <h5>
                                    <span class="badge bg-primary me-3">&nbsp;{this.props.ItemCode}&nbsp;</span>
                                    <span class="badge bg-secondary">OnHand: {this.props.OnHand ? this.props.OnHand : 0}</span>
                                </h5>
                            </div>
                            <div className="text-start">
                                <p className="card-text fs-5">{this.props.Description}</p>
                            </div>

                            {
                                this.props.needPrice ?
                                    <div className="row text-start" >
                                        <p className="card-text fs-4"><span class="badge bg-dark me-3">&nbsp;Unit price: ${this.props.UnitPrice ? this.props.UnitPrice.toFixed(2) : this.props.UnitPrice}&nbsp;</span></p>
                                    </div>
                                    :
                                    <div></div>
                            }
                            {
                                this.props.needCost ?
                                    <div className="row text-start" >
                                        <p className="card-text fs-4"><span class="badge bg-dark me-3">&nbsp;Unit cost: ${this.props.LastTotalUnitCost ? this.props.LastTotalUnitCost.toFixed(2) : this.props.LastTotalUnitCost}&nbsp;</span></p>
                                    </div>
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>

                }

            </div>
        )
    }
}
