import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { DateFormatMAS90, formatInputDateQuery } from '../functions/dateFormat.js';
import { getIndexElement } from '../functions/searchInObject.js';

import ModalOrders from '../components/ModalComponent';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        OrderHeaderList: [],
        OrderHeaderListFilter: [],
        showModal: false,
        OrdersInvoiced: []
    }

    async getActualOrders() {
        await this.handleModalOpen()
        const data = {
            idcompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            SalespersonNo: getValueCookie('SalesPerson')
        }
        const data2 = {
            idcompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            SalespersonNo: '0000'
        }
        var proccessInformation = []


        const respuesta = await getInformationWithData('/sales/get/header', data);
        const completeList = await getInformationWithData('/sales/get/header/byDate', data2);
        const getOrdersInvoiced = await getInformationWithData('/invoice/header',{...data2, CustomerNo: '*'});
        let SOList = [];

        if(getOrdersInvoiced.status.code === 1){
            SOList = getOrdersInvoiced.data.map(order => order.SalesOrderNo)
        }

        if (respuesta.status.code === 1) {
            proccessInformation = respuesta.data.map((element) => {
                const pos=getIndexElement(completeList.data,'SalesOrderNo',element.SalesOrderNo)
                return {
                    Status: element.Status,
                    OrderNo: (element.Comment !== null && element.Comment !== 'undefined') ? element.SalesOrderNo + '-' + element.Comment : element.SalesOrderNo,
                    OrderName: element.BillToName,
                    Date: element.OrderDate,
                    SalesPerson: element.SalespersonNo,
                    OrderNameAddress: element.BillToAddress1,
                    OrderNameCity: element.BillToCity,
                    OrderNameNo: element.CustomerNo,
                    PosQue:pos!==-1?(completeList.data[pos].posicion?(completeList.data[pos].posicion):999):999
                }
            })
        }

        this.setState({ OrderHeaderList: proccessInformation, OrderHeaderListFilter: proccessInformation, OrdersInvoiced: SOList})

    }

    async SelectOrderDetail(OrderHeader) {
        await this.handleModalClose()
        let SalesOrderNo = ""
        let OrderInfo=""
        if (OrderHeader.OrderNo) {
            let SalesOrderProvisonalNo = OrderHeader.OrderNo.split('-')
            SalesOrderNo = SalesOrderProvisonalNo.length > 1 ? SalesOrderProvisonalNo[0] : OrderHeader.OrderNo
            
            let cont=0
            SalesOrderProvisonalNo.forEach(element => {
                if(cont>0&&cont<SalesOrderProvisonalNo.length-1){
                    OrderInfo+=element+"-"
                }
                if(cont===SalesOrderProvisonalNo.length-1){
                    OrderInfo+=element
                }
                cont++
            });
        }


        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            CustomerNo: OrderHeader.OrderNameNo,
            OrderNo: SalesOrderNo !== "" ? SalesOrderNo : OrderHeader.OrderNo,
            Status: -1,
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
        }
        this.ModalLoading.current.showState(true);
        const Father = this.props.Padre
        const result = await getInformationWithData('/sales/get/detail', data)
        const result2 = await getInformationWithData('/customer/getByCustomerNo', data)
        const invent = await getInformationWithData('/Items/get', data)

        if(this.state.OrdersInvoiced.includes(SalesOrderNo)){
            await Father.setState({ isAlreadyInvoiced: true })
        }
        
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const inventM = await getInformationWithData('/Items/get', data);
            invent.status.code = inventM.status.code
            for (const item of inventM.data) {
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(inventM.data)
        }
        if (result.status.code === 1 && result2.status.code === 1) {
            if (this.props.nombrePadre === 'SalesOrderListInvoice') {
                const temporalHeader = Father.state.Invoice
                temporalHeader.SalesOrderNo = OrderHeader.OrderNo
                temporalHeader.Customer = result2.data[0]
                temporalHeader.Comment=OrderInfo
                let table = []
               
                result.data.Tabla2.forEach(item => {
                    
                    const index = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                    let desc = ''
                    let comp = 1563
                    if (index !== -1) {
                        comp = invent.data[index].IdCompany ? invent.data[index].IdCompany : 1563
                        desc = (!(invent.data[index].completeDesc) || invent.data[index].completeDesc === "null") ? invent.data[index].abbreviatedDesc : invent.data[index].completeDesc
                    } else {
                        desc = item.ItemCodeDesc
                    }

                    const itemJson = {
                        itemCode: item.ItemCode,
                        abbreviatedDesc: desc,
                        upc: item.upc,
                        quantity: 0,
                        ShipWeight: Number(item.LineWeight),
                        StandardUnitCost: 0,
                        LastTotalUnitCost: 0,
                        UnitPrice: item.UnitPrice,
                        upc2: item.upc2,
                        caseupc: item.caseupc,
                        BIN: "",
                        totalWeight: Number(item.LineWeight) * Number(item.QuantityShipped),
                        Comment: "",
                        unitCost: Number(item.UnitCost),
                        quantityOrdered: item.QuantityOrdered,
                        quantityReceived: 0,
                        quantityShipped: item.QuantityShipped,
                        totalCost: Number(item.QuantityShipped) * Number(item.UnitPrice),
                        totalFreight: 0,
                        Visible: (item.QuantityOrdered !== 0 || item.QuantityShipped !== 0) ? 1 : 0,
                        BatchUnits: 0,
                        DiscountUnits: 0,
                        SuggestedDiscount: 0,
                        Pallet: item.Pallet,
                        IdCompany: comp
                    }
                    if (item.Pallet === -1) {
                        itemJson.Visible = 0
                    }

                    if(getValueCookie('CompanyId')==='3'&&data.OrderNo.startsWith("AL")){
                        if(comp===3){
                            table.push(itemJson)
                        }
                    }else{
                        table.push(itemJson)
                    }
                    
                });
               
             


                await Father.ChangeSalesOrder()
                await Father.setState({ Products: table, Invoice: temporalHeader })
                Father.refreshInputs()
                Father.calculateTotals()
            }


        }
        this.ModalLoading.current.showState(false);

    }


    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }

    }

    searchSalesOrder = e => {

        let busqueda = e.target.value;


        if(busqueda){
            let orders = this.state.OrderHeaderList.filter((order_) => {
                if (((
                    this.contiene(order_.OrderNo, busqueda)||
                    this.contiene(order_.OrderName, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
            this.setState({ OrderHeaderListFilter: orders });

        }else{
            let orders=this.state.OrderHeaderList
            this.setState({OrderHeaderListFilter:orders})
            
        }

        
    }

    stateClassColor(state) {
        if (state === 1) {
            return 'warning text-dark'
        } else if (state === 2) {
            return 'success'
        } else {
            return 'danger'
        }
    }
    stateInWords(state) {

        if (state === 1) {
            return 'In process'
        } else if (state === 2) {
            return 'Finished'
        } else {
            return 'Not started'
        }


    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className="btn purpleButton btn-lg w-100" onClick={() => this.getActualOrders()}><AiOutlineFileText /> Sales Order List</button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Sales Order List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className='row form-group'>
                        <div className='col-sm-12 pb-3'>
                            <div className="input-group input-group-lg flex-nowrap">
                                <input className='form-control w-75 display-inline' placeholder='Search by...' id={'salesorder_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchSalesOrder} />
                                <span className="input-group-text">
                                    <button className='btn greenButton btn-lg' onClick={() => this.getActualOrders(true)}><BsArrowRepeat /></button>
                                </span>
                            </div>
                        </div>
                        <div className='col-sm-12 tableFixHead tb-1 align-middle'>
                            <table className='table table-hover'>
                                <thead className='table-dark thead'>
                                    <tr className='text-center'>
                                        <th>Status</th>
                                        <th>Order</th>
                                        <th>Customer</th>
                                        <th>Created Date</th>
                                        <th>Sales Person</th>
                                        <th>Queue Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.OrderHeaderListFilter.map((order, i) => (
                                            <tr onClick={() => this.SelectOrderDetail(order)} key={i}>
                                                <td className='text-center'>
                                                    <span class={"badge bg-" + this.stateClassColor(order.Status)}> {this.stateInWords(order.Status)}&nbsp;</span>
                                                    {this.state.OrdersInvoiced.includes(order.OrderNo.split('-')[0])?
                                                    <span class="badge bg-primary">Invoiced</span>
                                                    : <span></span>
                                                    }
                                                </td>
                                                <td>{order.OrderNo}
                                                </td>
                                                <td>{order.OrderName}</td>
                                                <td className='text-center'>{formatInputDateQuery(order.Date) !== "" ? formatInputDateQuery(order.Date) : DateFormatMAS90(order.Date)}</td>
                                                <td className='text-center'>{order.SalesPerson}</td>
                                                <td className='text-center'>{order.PosQue===999?'Completed':order.PosQue}</td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>
                                </tfoot>

                            </table>
                        </div>


                    </div>
                    
                </ModalOrders>



            </>
        )
    }
}
