import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { filterAndSortData } from '../../functions/generalFunctions';
import ExcelDocument from '../ExcelDocument'
import { getDateFromReports, formatInputDate} from '../../functions/dateFormat';
import { getValueCookie } from '../../services/cookieService';
import { getDataSet } from '../../functions/generateDataSetExcel';
export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();


    }
    state = {
        Title: '',
        Data: [],
        useProps: true,
        showModal1: false
    }

    onChange = (e) => {
        if (this.state.useProps) {
            this.setState(prevState => ({
                useProps: !prevState.useProps
            }));
        }
        this.setState({ Data: filterAndSortData(this.props.data1, e) }, () => {
        });

    }

    formatDatePickedUp(data) {
        let temporal = []
        let copy = JSON.parse(JSON.stringify(data))
        copy.forEach(element => {
            //'CustomerNo', 'CustomerName', 'Username', 'Note', 'Date'
            element.LastSoldDate = formatInputDate( element.LastSoldDate);
            temporal.push(element)

        });

        return temporal
    }
    render() {
        const dataToDisplay = this.state.useProps ? this.props.data : this.state.Data;
        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='col-sm-12 text-center my-3'>
                        <h5>{this.props.Title}</h5>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-sm-12 col-md-6'>
                            <div className="input-group flex-nowrap" >
                                <input className="form-control  text-center" id='ShortageLevel' min="0" defaultValue={30} onChange={(e) => this.onChange(e.target.value)} type="number" />
                                <span className="input-group-text">days</span>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='col my-3'>
                                <ExcelDocument hidden={getValueCookie('TypeUser') !== '1'} data={getDataSet(this.formatDatePickedUp(dataToDisplay), ['Item Code', 'Product Line', 'Description', 'On Hand', 'Last Sold Date', '# Days of last sale'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'TotalQuantityOnHand', 'LastSoldDate', "NumberofDays"])} sheetname={"Customers"} archname={"Feedback report " + this.state.Title + ' ' + getValueCookie('Company') + " DATE " + getDateFromReports()} />
                            </div>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle table-hover text-center'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Item Code</th>
                                            <th>Product Line</th>
                                            <th>Description</th>
                                            <th>On Hand</th>
                                            <th>Last Sold Date</th>
                                            <th># Days Of Last Sale</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(dataToDisplay).map((key, k) => (
                                            <tr key={k}>
                                                <td>{dataToDisplay[key].ItemCode}</td>
                                                <td className='text-start'>{dataToDisplay[key].ProductLineDesc}</td>
                                                <td className='text-start'>{dataToDisplay[key].ItemCodeDesc}</td>
                                                <td>{dataToDisplay[key].TotalQuantityOnHand}</td>
                                                <td>{formatInputDate(dataToDisplay[key].LastSoldDate)}</td>
                                                <td>{dataToDisplay[key].NumberofDays}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
