import React from 'react'
import md5 from 'md5'
import { v4 as uuidv4 } from 'uuid';
import "../css/login-style.css"
import "../css/general-style.css"
import Footer from '../components/Footer'
import { 
  getValueCookie, 
  setNewCookie } from '../services/cookieService';
import { 
  OpenCompanyDashBoard, 
  OpenLobby, 
  OpenLogin } from '../functions/pagesFunction';
import { selectWithDataService } from '../services/auth/authservices';
import { automaticCloseAlert, confirmCloseAlert } from '../functions/alerts'
import { closeSession } from "../functions/closeSession"
import App from '../components/App';
import LoadingWindow from '../components/LoadingWindow';

export default class Login extends React.PureComponent {

  constructor(props) {
    super(props);
    this.ModalLoading = React.createRef();
  }

  state = {
    loginStorage: {
      userName: '',
      password: ''
    }
  }

  onChangeById = (e, type) => {
    const temporal = this.state.loginStorage
    const value = e.target.value
    switch (type) {
      case "UserName":
        temporal.userName = value
        break;
      case "Password":
        temporal.password = value
        break;
      default:
        break;
    }
    this.setState({ loginStorage: temporal })
  }

  onKeyDown = async e => {
    if (e.key === 'Enter') {
      await this.Login()
    }
  }

  componentDidMount() {
    if (getValueCookie('userName')) {
      if (getValueCookie('Company')) {
        OpenLobby()
      } else {
        OpenCompanyDashBoard()
      }
    } else {
      OpenLogin()
    }
  }

  async Login() {
    const temp = this.state.loginStorage
    const btn = document.getElementById('btn_signin');
    const btn_logo = btn.innerHTML;
    const datos = {
      userName: temp.userName,
      password: md5(temp.password),
    }
    btn.innerHTML = 'Loading...';
    btn.disabled = true;
    this.ModalLoading.current.showState(true);
    const response = await selectWithDataService(datos, "/login")
    if (response != null) {
      if (response.status.code === 1) {
        if (response.token !== 'invalid') {
          await automaticCloseAlert('correct', 'Welcome: ' + response.data.name + " " + response.data.surname)
          setNewCookie('sessionAuthToken', response.token, 50)
          setNewCookie('userName', response.data.userName, 50)
          setNewCookie('name', response.data.name, 50)
          setNewCookie('surname', response.data.surname, 50)
          window.localStorage.setItem('session', uuidv4())
          window.location.reload()
        } else {
          closeSession()
          confirmCloseAlert('incorrect', 'Your  Username or Password are incorrect. Please try again')
          const temporal = this.state.loginStorage
          temporal.password = ''
          this.setState({ loginStorage: temporal })
        }
      }
    }

    this.ModalLoading.current.showState(false);
    btn.innerHTML = btn_logo;
    btn.disabled = false;
  }


  render() {

    return (
      <React.Fragment>
        <LoadingWindow ref={this.ModalLoading} />
        <div id='login' className='page'>
          <div className='d-flex flex-column container-fluid fixContainer'>
            <main className='main-content'>
              <div className='row pt-2 d-flex justify-content-center'>
                <div className='col-sm-5 contLogin'>
                  <div className='row d-flex justify-content-center pt-5'>
                    <h3 className='text-center login-title'>Welcome back</h3>
                    <p className='text-center login-text'>Please enter your credentials to continue.</p>
                    <form className=''>
                      <div className='row d-flex justify-content-center pt-5 pb-2 px-5'>
                        <div className='col-sm-11'>
                          <label for="Login_userName" class="form-label login-input-label">Username</label>
                          <div className="input-group input-group-lg">
                            <input type="text" id='Login_userName' onChange={(e) => this.onChangeById(e, "UserName")} onKeyDown={this.onKeyDown} className="form-control login-input" />
                          </div>
                        </div>
                      </div>
                      <div className='row d-flex justify-content-center pt-4 pb-4 px-5'>
                        <div className='col-sm-11'>
                          <span for="Login_password" className="form-label login-input-label">Password</span>
                          <div className="input-group input-group-lg">
                            <input autoComplete='false' type="password" id='Login_password' onKeyDown={this.onKeyDown} onChange={(e) => this.onChangeById(e, "Password")} className="form-control login-input" />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className='row d-flex justify-content-center pt-4 pb-3 text-center'>
                      <div className='col-sm-7'>
                        <button id='btn_signin' type="button" className="btn signin-btn" onClick={() => this.Login()}>Sign in</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>

        <div id='App' className='page'>
          <App />
        </div>
      </React.Fragment>
    )
  }
}
