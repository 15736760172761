import React, { Component } from "react";
import "../../css/menu-style.css";
import "../../css/general-style.css";
import {
  AiOutlineUsergroupAdd,
  AiOutlineSetting,
  AiFillCreditCard,
  AiOutlineFileSearch,
} from "react-icons/ai";
import {
  AiFillCaretDown,
  AiOutlineReconciliation,
  AiFillTags,
  AiOutlineClockCircle,
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineLogout,
  AiFillCloseCircle,
  AiOutlineException,
  AiOutlineAreaChart,
  AiFillDropboxSquare,
  AiOutlineUserAdd,
  AiOutlineFileDone,
  AiOutlineSolution,
  AiOutlineAudit,
} from "react-icons/ai";
import { BiCartAlt, BiFileFind } from "react-icons/bi";
import { TbBuildingWarehouse } from "react-icons/tb";
import { FaClipboardList } from "react-icons/fa";
import { getValueCookie } from "../../services/cookieService";
import {
  OpenShortsReport,
  PriceMargin,
  CloseCompanySession,
  OpenCycleInventory,
  OpenInventory,
  OpenPikingSystem,
  OpenLobby,
  OpenPurchaseOrder,
  OpenSalesOrder,
  OpenNewCustomer,
  OpenInvoices,
  OpenAccountsReceivable,
  OpenMOHReport,
  OpenSalesReport,
  OpenVendorReport,
  OpenPurchaseProcessReport,
  OpenActualInventoryReport,
  OpenHistoryInventoryReport,
  OpenAutomaticPOPage,
  OpenNewProductsToInclude,
  OpenPriceMarginProdLine,
  OpenPaymentRecord,
  OpenWasteSystem,
  OpenScanningReport,
  OpenTrackingReport,
  OpenWeightPerTravelReport,
  OpenHistoryPurchaseOrdersReport,
  OpenReturnPurchaseOrder,
  OpenSalesOrdersReport,
  OpenVendorPriceChangeReport,
  OpenSpoilageReport,
  SalesTracker,
  OpenPortalSalesOrder,
  OpenRouteSales,
  OpenCalculateMargins,
  OpenGeneralMarginPrices,
  OpenGeneralPages,
  GetNumberOfOrders,
  OpenInventoryOverstock,
  OpenUsersManagement,
  OpenLogs,
  OpenPurchasing,
  OpenInventoryPromotionsView,
} from "../../functions/pagesFunction";
import { closeSession } from "../../functions/closeSession";
import { BiExit } from "react-icons/bi";
import { BsBoxSeam, BsTruck } from "react-icons/bs";
import { getInformationWithDataGlobalSales } from "../../services/CABE";
import { Users } from "../../pages/UsersManagement/Class";
import { ListModules } from "./Class";

export class Menu extends Component {

    state = {
        General: {
            directoryLogo: getValueCookie('CompanyLogo'),
            companyId: getValueCookie('SubCompanyId') ? getValueCookie('SubCompanyId') : getValueCookie('CompanyId'),
            typeUser: getValueCookie('TypeUser'),
            enableModules: [],
            needModlesCharge: true,
        },
        TotalSales: getValueCookie('TotalOrders') ? Number(getValueCookie('TotalOrders')) : 0,
        navbarColor: Number(getValueCookie('CompanyId')) === 6 ? '-altitude' : ''
    }

    async componentDidMount() {
    }

    async AssignStatesModules() {
        let temporal = this.state.General
        let Modules = new ListModules()
        const data = new Users(getValueCookie('userName'), "", "", "", "", getValueCookie('SubCompanyId') ? getValueCookie('SubCompanyId') : getValueCookie('CompanyId'))
        const result = await getInformationWithDataGlobalSales('/user/Permissions', data)
        if (result.data) {
            for (const module of result.data) {
                Modules.addModule(module.IdModule, module.name, module.Status)
            }
        }
        if (Number(getValueCookie('CompanyId')) === 3) {
            let total = await GetNumberOfOrders(getValueCookie('SalesPerson'))
            this.setState({ TotalSales: total })
        }
        temporal.enableModules = Modules.List
        this.setState({ General: temporal })

    }

    findStatus(IdModule) {
        if (this.state.General.enableModules.length > 0) {
            const Module = this.state.General.enableModules.find(objeto => objeto.Id === IdModule);

            return Module.Status
        }
    }

    findGeneral(IdModules) {
        if (this.state.General.enableModules.length > 0) {
            for (const IdModule of IdModules) {
                const Module = this.state.General.enableModules.find(objeto => objeto.Id === IdModule);
                if (!Module.Status) {
                    return false
                }
            }
            return true
        }
    }

    render() {
        return (
            <React.Fragment>
                <button id='updateOrdersGlobalSales' hidden onClick={() => this.getNumberOfSales()}></button>
                <nav className={`navbar justify-content-start navbar-dark text-light lateralMenu${this.state.navbarColor} fixed-top p-1`}>
                    <div className="container-fluid">
                        <div className='row align-items-start'>
                            <div className='col'>
                                <button onClick={() => this.AssignStatesModules()} className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className='col'>
                                <a className="navbar-brand" href='/#' onClick={() => OpenLobby()}>
                                    <img src={this.state.General.directoryLogo} alt='Company' />
                                </a>
                            </div>
                        </div>
                        <div className='row text-light'>
                            <div hidden={!(getValueCookie('CompanyId') === '3' && (getValueCookie('TypeUser') === '7' || getValueCookie('TypeUser') === '4' || getValueCookie('TypeUser') === '1'))} className='col-2'>
                                <div className="nav-item">
                                    <div>
                                        <button
                                            className='btn text-light pos position-relative'
                                            type="button"
                                            onClick={() => OpenPortalSalesOrder()}
                                        >
                                            <span hidden={this.state.TotalSales === 0} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {this.state.TotalSales}
                                                <span className="visually-hidden">Total Quantity</span>
                                            </span>
                                            <AiOutlineFileSearch className='h2' />
                                        </button></div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="nav-item dropdown dissapearObject">
                                    <a className="nav-link text-light" href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Hi {getValueCookie('name')} {getValueCookie('surname')} <AiFillCaretDown />
                                    </a>
                                    <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                        <li><a className="dropdown-item text-light h5" onClick={() => OpenLobby()} href="/#"><AiOutlineSetting /> User Data</a></li>
                                        <li><a className="dropdown-item text-light h5" onClick={() => closeSession()} href="/#"><AiOutlineLogout /> Logout</a></li>
                                    </ul>
                                </div>
                                <div className=''>
                                    <a className="nav-link text-light appearMovileObject" href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasUser2" aria-controls="offcanvasNavbar">
                                        <AiOutlineUser className='h4' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className={`offcanvas-header lateralMenu${this.state.navbarColor}`}>
                                <h4 className="offcanvas-title" id="offcanvasNavbarLabel">{getValueCookie('Company')}</h4>
                                <button type="button" className="text-reset especialButtonClose" data-bs-dismiss="offcanvas" aria-label="Close"><AiFillCloseCircle /></button>
                            </div>
                            <div className={`offcanvas-body lateralMenu${this.state.navbarColor}`}>
                                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                    <li className={`nav-item${this.state.navbarColor}`}>
                                        <a className="nav-link" aria-current="page" href="/#" data-bs-dismiss="offcanvas" onClick={() => OpenLobby()}><AiOutlineHome /> Home </a>
                                    </li>
                                    <li hidden={this.findGeneral([1, 2, 23, 22, 11, 27, 17, 3])} className={`nav-item${this.state.navbarColor} dropdown`} id='MenuInventoryManagement'>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <AiOutlineReconciliation /> Inventory Management  <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(1)} onClick={() => OpenInventory()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BiCartAlt /> Products Information </a></li>
                                            <li hidden={this.findStatus(2)} onClick={() => OpenCycleInventory()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiOutlineClockCircle /> Cyclical Inventory </a></li>
                                            <li hidden={this.findStatus(36)} onClick={() => OpenInventoryPromotionsView()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BiCartAlt /> Discounts Per Customer </a></li>
                                            <ul hidden={this.findGeneral([23, 22, 11, 27, 17, 3])} className='listDecoratorNone'>
                                                <li className={`nav-item${this.state.navbarColor}`}>
                                                    <a className="nav-link " onClick={(e) => e.stopPropagation()} href="/#" id="offcanvasSubNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <BsBoxSeam /> Reports  <AiFillCaretDown />
                                                    </a>
                                                    <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasSubNavbarDropdown">
                                                        <li hidden={this.findStatus(23)} onClick={() => OpenPriceMarginProdLine()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiOutlineClockCircle /> Price Margins by Product Line </a></li>
                                                        <li hidden={this.findStatus(22)} onClick={() => OpenNewProductsToInclude()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiOutlineClockCircle /> Products to Include </a></li>
                                                        <li hidden onClick={() => OpenGeneralPages("InventorySystem")}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiFillTags />Inventory System </a></li>
                                                        <li hidden={this.findStatus(11)} onClick={() => OpenShortsReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Shorts </a></li>
                                                        <li hidden={this.findStatus(27)} onClick={() => OpenInventoryOverstock()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Inventory Status </a></li>
                                                        <li hidden={this.findStatus(17)} onClick={() => OpenHistoryInventoryReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> History KPI Inventory </a></li>
                                                        <li hidden={this.findStatus(3)} onClick={() => OpenActualInventoryReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Actual KPI Inventory </a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </ul>
                                    </li>
                                    <li hidden={this.findGeneral([5, 20])} className={`nav-item${this.state.navbarColor} dropdown`}>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <TbBuildingWarehouse /> Warehouse Management  <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(5)} onClick={() => OpenPikingSystem()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><FaClipboardList /> Picking System </a></li>
                                            <li hidden onClick={() => OpenWasteSystem()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><FaClipboardList /> Spoilage System </a></li>
                                            <ul hidden={this.findGeneral([20])} className='listDecoratorNone'>
                                                <li className={`nav-item${this.state.navbarColor}`}>
                                                    <a className="nav-link " onClick={(e) => e.stopPropagation()} href="/#" id="offcanvasSubNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <BsBoxSeam /> Reports  <AiFillCaretDown />
                                                    </a>
                                                    <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasSubNavbarDropdown">
                                                        <li hidden={this.findStatus(20)} onClick={() => OpenScanningReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Scanning in Orders </a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </ul>
                                    </li>
                                    <li hidden={this.findGeneral([9, 10])} className={`nav-item${this.state.navbarColor} dropdown`}>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <AiOutlineSolution /> Invoicing  <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(9)} onClick={() => OpenInvoices()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiOutlineFileDone /> Invoices</a></li>
                                            <li hidden={this.findStatus(10)} onClick={() => OpenAccountsReceivable()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiOutlineException /> Accounts Receivable </a></li>
                                            {/* <li hidden={this.findStatus(25)} onClick={() => OpenInvoicesReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Report of Open Invoices </a></li> */}
                                        </ul>
                                    </li>
                                    <li hidden={this.findGeneral([7, 8, 29, 25, 31, 14, 28])} className={`nav-item${this.state.navbarColor} dropdown`}>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <AiFillDropboxSquare /> Sales  <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(7)} onClick={() => OpenSalesOrder()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Sales Order </a></li>
                                            <li hidden={this.findStatus(8)} onClick={() => OpenNewCustomer()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><AiOutlineUserAdd /> Customer Management </a></li>
                                            <li hidden={this.findStatus(29)} onClick={() => OpenPortalSalesOrder()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Portal Orders </a></li>
                                            <ul hidden={this.findGeneral([25, 31, 14, 28])} className='listDecoratorNone'>
                                                <li className={`nav-item${this.state.navbarColor}`}>
                                                    <a className="nav-link " onClick={(e) => e.stopPropagation()} href="/#" id="offcanvasSubNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Reports <BsBoxSeam /> <AiFillCaretDown />
                                                    </a>
                                                    <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasSubNavbarDropdown">
                                                        <li hidden={this.findStatus(25)} onClick={() => OpenSalesOrdersReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Sales Order </a></li>
                                                        <li hidden={this.findStatus(31)} onClick={() => OpenRouteSales()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Sales By Route </a></li>
                                                        <li hidden={this.findStatus(14)} onClick={() => OpenSalesReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Sales </a></li>
                                                        <li hidden={this.findStatus(28)} onClick={() => OpenSpoilageReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Spoilage </a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </ul>
                                    </li>
                                    <li hidden={this.findGeneral([6, 18, 32, 15, 16, 24, 26])} className={`nav-item${this.state.navbarColor} dropdown`}>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <AiOutlineAudit /> Purchase Order  <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(6)} onClick={() => OpenPurchaseOrder()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Purchase Order </a></li>
                                            <li hidden={this.findStatus(18)} onClick={() => OpenAutomaticPOPage()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Automatic Purchase Order </a></li>
                                            <li hidden={this.findStatus(32)} onClick={() => OpenReturnPurchaseOrder()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Return Purchase Order </a></li>
                                            <ul hidden={this.findGeneral([15, 16, 24, 26])} className='listDecoratorNone'>
                                                <li className={`nav-item${this.state.navbarColor}`}>
                                                    <a className="nav-link " onClick={(e) => e.stopPropagation()} href="/#" id="offcanvasSubNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <AiFillCaretDown /> Reports <BsBoxSeam /> 
                                                    </a>
                                                    <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasSubNavbarDropdown">
                                                        <li hidden={this.findStatus(15)} onClick={() => OpenVendorReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Vendor </a></li>
                                                        <li hidden={this.findStatus(16)} onClick={() => OpenPurchaseProcessReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> In Transit Purchase Order </a></li>
                                                        <li hidden={this.findStatus(24)} onClick={() => OpenHistoryPurchaseOrdersReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> History Purchase Orders </a></li>
                                                        <li hidden={this.findStatus(26)} onClick={() => OpenVendorPriceChangeReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Vendor Price Change </a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </ul>
                                    </li>
                                    <li hidden={getValueCookie('CompanyId') !== '6'} className={`nav-item${this.state.navbarColor} dropdown`}>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Payment Management <AiOutlineFileDone /> <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(35)} onClick={() => OpenPaymentRecord()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas">Payment Record <AiFillCreditCard /></a></li>
                                        </ul>
                                    </li>
                                    <li hidden={this.findGeneral([12, 13, 19, 21, 33, 34])} className={`nav-item${this.state.navbarColor} dropdown`}>
                                        <a className="nav-link " href="/#" id="offcanvasNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <AiOutlineAreaChart /> Pricing  <AiFillCaretDown />
                                        </a>
                                        <ul className={`dropdown-menu dropDownMenu${this.state.navbarColor}`} aria-labelledby="offcanvasNavbarDropdown">
                                            <li hidden={this.findStatus(12)} onClick={() => PriceMargin()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Price Margins </a></li>
                                            <li hidden={this.findStatus(13)} onClick={() => OpenMOHReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Month On Hand </a></li>
                                            <li hidden={this.findStatus(19)} onClick={() => OpenTrackingReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Tracking Number </a></li>
                                            <li hidden={this.findStatus(21)} onClick={() => OpenWeightPerTravelReport()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsTruck /> Weight Per Travel </a></li>
                                            <li hidden={this.findStatus(33)} onClick={() => OpenGeneralMarginPrices()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> General Margin Prices </a></li>
                                            <li hidden={this.findStatus(34)} onClick={() => OpenCalculateMargins()}><a className="dropdown-item text-light" href="/#" data-bs-dismiss="offcanvas"><BsBoxSeam /> Calculate Price Margins </a></li>
                                        </ul>
                                    </li>
                                    <li hidden={getValueCookie('TypeUser') !== '1'} className={`nav-item${this.state.navbarColor}`}>
                                        <a className="nav-link" aria-current="page" href="/#" data-bs-dismiss="offcanvas" onClick={() => OpenPurchasing()}><BiCartAlt /> Purchasing   </a>
                                    </li>
                                    <li hidden={getValueCookie('TypeUser') !== '1'} className={`nav-item${this.state.navbarColor}`}>
                                        <a className="nav-link" aria-current="page" href="/#" data-bs-dismiss="offcanvas" onClick={() => OpenLogs()}><BiFileFind /> Logs   </a>
                                    </li>
                                    <li hidden={getValueCookie('TypeUser') !== '1'} className={`nav-item${this.state.navbarColor}`}>
                                        <a className="nav-link" aria-current="page" href="/#" data-bs-dismiss="offcanvas" onClick={() => OpenUsersManagement()}><AiOutlineUsergroupAdd /> Users Management </a>
                                    </li>
                                    <li className={`nav-item${this.state.navbarColor}`}>
                                        <a className="nav-link" aria-current="page" href="/#" data-bs-dismiss="offcanvas" onClick={() => CloseCompanySession()}><BiExit /> Close Company Session </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="offcanvas offcanvas-end" tabIndex="1" id="offcanvasUser2" aria-labelledby="offcanvasNavbarLabel">
                            <div className={`offcanvas-header lateralMenu${this.state.navbarColor}`}>
                                <h4 className="offcanvas-title" id="offcanvasNavbarLabel">Hi {getValueCookie('name')} {getValueCookie('surname')}</h4>
                                <button type="button" className="text-reset especialButtonClose" data-bs-dismiss="offcanvas" aria-label="Close"><AiFillCloseCircle /></button>
                            </div>
                            <div className={`offcanvas-body lateralMenu${this.state.navbarColor}`}>
                                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                    <li className={`nav-item${this.state.navbarColor}`}>
                                        <a className="nav-link" aria-current="page" href="/#" onClick={() => OpenLobby()}><AiOutlineSetting /> User Data</a>
                                        <a className="nav-link" aria-current="page" href="/#" onClick={() => closeSession()}><AiOutlineLogout /> Logout</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </nav>
                <div className='filling'></div>
            </React.Fragment>
        )
    }
}