//Invoices and Spoilage
import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import LoadingWindow from '../../components/LoadingWindow';
import VendorList from '../../components/VendorList';
import SearchUPC from '../../components/SearchUPC';
import Catalogue2 from '../../components/Catalogue2.0/Catalogue2.0';
import { getValueCookie } from '../../services/cookieService';
import PurchaseOrderVendorNo from '../../components/PurchaseOrderVendorNo';
import { AiOutlineCloseCircle, AiFillPrinter } from "react-icons/ai";
import { create_Delete_Update_Information, getInformationWithData } from '../../services/CABE';
import Swal from "sweetalert2";
import { Item } from './Class';
import { ItemDesc, NumberFormat,DeleteCharacter } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { DateFormatMAS90, getDateYearMonthDayDash } from '../../functions/dateFormat';
import { InvoicePDF } from '../../components/InvoicePDF';
import { InvoicePDF2 } from '../../components/InvoicePDF2';
import { confirmCloseAlert } from '../../functions/alerts';
import { getIndexElement } from '../../functions/searchInObject';


export default class ReturnPurchaseOrderView extends Component {
    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }


    state = {
        Return: {
            ReturnNo: '',
            ReturnDate: '',
            PurchaseOrderNo: '',
            InvoiceNo: '',
            Comment: '',
            Vendor: {
                VendorNo: '',
                VendorName: '',
                City: '',
                Country: '',
                AddressLine: '',
                Phone: '',
                Email: ''
            }
        },
        Products: [],
        Products2: [],

        Totals: {
            TotalQuantityReceived: 0,
            TotalQuantityReturned: 0,
            TotalWeight: 0,
            TotalCost: 0
        }

    }
    async getNewCorrelativeReturnPO() {

        const data = {
            type: 'PurchaseOrderReturn',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = this.state.Return
            temporal.ReturnNo = 'R' + result.data[0].correlative
            this.setState({ Return: temporal })
        }
    }

    onChange = (e, item) => {
        const temporal = this.state.Products
        const index = temporal.indexOf(item)
        const ident = e.target.id.split('_')
        if (index !== -1) {
            if (ident[0] === "quantityReturnedPurchaseReturn") {
                temporal[index].QuantityReturned = Number(e.target.value)
                temporal[index].TotalCost = (Number(e.target.value) * Number(temporal[index].UnitCost))
                temporal[index].TotalWeight = (Number(e.target.value) * Number(temporal[index].UnitWeight))
                this.setState({ Products: temporal })
                this.calculateTotals()
            } else if (ident[0] === "unitCostReturnedPurchaseReturn") {
                temporal[index].UnitCost = Number(e.target.value)
                temporal[index].TotalCost = (Number(e.target.value) * Number(temporal[index].QuantityReturned))
                this.setState({ Products: temporal })
                this.calculateTotals()
            }


        }
    }
    print(){
        
    }

    calculateTotals() {
        const temporal = this.state.Totals
        let totalQuantityReturned = 0
        let totalWeight = 0
        let totalCost = 0
        let totalReceived = 0
        for (const item of this.state.Products) {
            totalQuantityReturned += Number(item.QuantityReturned)
            totalWeight += Number(item.QuantityReturned) * Number(item.UnitWeight)
            totalCost += Number(item.QuantityReturned) * Number(pricesFormat(item.UnitCost))
            totalReceived += Number(item.QuantityReceived)
        }
        temporal.TotalQuantityReceived = totalReceived
        temporal.TotalCost = totalCost
        temporal.TotalWeight = totalWeight
        temporal.TotalQuantityReturned = totalQuantityReturned
        this.setState({ Totals: temporal })
    }

    async setVendor(Vendor) {
        await this.partialClear()
        const temporal = this.state.Return
        temporal.Vendor.VendorNo = Vendor.customerNo
        temporal.Vendor.VendorName = Vendor.name
        temporal.Vendor.City = Vendor.City
        temporal.Vendor.Country = Vendor.country
        temporal.Vendor.AddressLine = Vendor.AddressLine1 ? (Vendor.AddressLine1 + (Vendor.AddressLine2 ? (' ' + Vendor.AddressLine2) : '')) : null
        temporal.Vendor.Phone = Vendor.phone1
        temporal.Vendor.Email = Vendor.email
        await this.setState({ Return: temporal })
    }

    async addItem(Items) {
        const temporal=this.state.Products
        const index=getIndexElement(temporal,'itemCode',Items.itemCode)
        
        if(index!==-1){
            temporal[index].QuantityReturned=Items.QuantityReturned
            temporal[index].Visible=Items.QuantityReturned>0?true:false
            temporal[index].calculateTotals()
            await this.setState({Products:temporal})
            await this.calculateTotals()
            await this.refreshInputs()
        }else{
            let prod=new Item(Items.itemCode, Items.abbreviatedDesc,0, Items.QuantityReturned, Items.LastTotalUnitCost, Items.ShipWeight,"")           
            prod.Visible=prod.QuantityReturned>0?true:false
            prod.calculateTotals()
            await temporal.unshift(prod)
            await this.setState({Products:temporal})
            await this.calculateTotals()
            await this.refreshInputs()
        }
        
    }

    async addItem2(Items){
        
        const temporal=this.state.Products
        const index=getIndexElement(temporal,'itemCode',Items.itemCode)
        
        if(index!==-1){
            temporal[index].QuantityReturned+=1
            temporal[index].Visible=temporal[index].QuantityReturned>0?true:false
            temporal[index].calculateTotals()
            await this.setState({Products:temporal})
            await this.calculateTotals()
            await this.refreshInputs()
        }else{
            let prod=new Item(Items.itemCode, Items.abbreviatedDesc,0, 1, Items.LastTotalUnitCost, Items.ShipWeight,"")           
            prod.Visible=prod.QuantityReturned>0?true:false
            prod.calculateTotals()
            await temporal.unshift(prod)
            await this.setState({Products:temporal})
            await this.calculateTotals()
            await this.refreshInputs()
        }
        
    }

    async setInvoiceDetail(InvoiceHeader, InvoiceDetail) {
        console.log(InvoiceHeader)
        await this.getNewCorrelativeReturnPO()
        const temporal = this.state.Return
        let Invoice="CM"+DeleteCharacter(InvoiceHeader.LastInvoiceNo,"PM")
        temporal.InvoiceNo = Invoice
        temporal.PurchaseOrderNo = InvoiceHeader.PurchaseOrderNo
        temporal.ReturnDate = getDateYearMonthDayDash()
        this.setState({ Return: temporal })
        let temporalProds = []
        Swal.fire({
            title: 'Do you want return the complete order?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
            allowOutsideClick: false
        }).then(async (result) => {
            this.ModalLoading.current.showState(true);
            if (result.isConfirmed) {
                for (const item of InvoiceDetail) {
                    temporalProds.push(new Item(item.ItemCode, item.ItemCodeDesc, item.QuantityReceived, item.QuantityReceived, item.UnitCost, item.Weight, ""))
                }
                temporalProds = await ItemDesc(temporalProds, "Description", "itemCode")
                await this.setState({ Products: temporalProds })
                await this.calculateTotals()
                await this.refreshInputs()

            } else {
                for (const item of InvoiceDetail) {
                    temporalProds.push(new Item(item.ItemCode, item.ItemCodeDesc, item.QuantityReceived, 0, item.UnitCost, item.Weight, ""))
                }
                temporalProds = await ItemDesc(temporalProds, "Description", "itemCode")
                
                await this.setState({ Products: temporalProds })
                await this.calculateTotals()
                await this.refreshInputs()

            }
            this.ModalLoading.current.showState(false);
        })




    }



    async removeProduct(item) {
        const temporal = this.state.Products
        const index = temporal.indexOf(item)
        if (index !== -1) {
            temporal[index].QuantityReturned = 0
            temporal[index].TotalCost = 0
            temporal[index].Visible = false
            temporal[index].TotalWeight = 0
            await this.setState({ Products: temporal })
            await this.calculateTotals()
            await this.refreshInputs()
        }
    }

    refreshInputs() {
        let a = 0
        for (const item of this.state.Products) {
            document.getElementById("quantityReturnedPurchaseReturn_" + a).value = item.QuantityReturned
            document.getElementById("unitCostReturnedPurchaseReturn_" + a).value = item.UnitCost
            a++
        }

    }

    partialClear() {
        const temporal = this.state.Return
        temporal.InvoiceNo = ''
        temporal.PurchaseOrderNo = ''
        temporal.ReturnNo = ''
        temporal.ReturnDate = ''

        this.setState({
            Return: temporal,
            Products: [],
            Products2:[],
            Totals: {
                TotalQuantityReceived: 0,
                TotalQuantityReturned: 0,
                TotalWeight: 0,
                TotalCost: 0
            }

        })
        document.getElementById('CommentReturnPO').value=''
    }

    printDetail(type) {
        let detail = []

        this.state.Products.forEach(element => {

            if (element.Visible && element.QuantityReturned !== 0) {
                detail.push(element)
            }
        });
        const tamBreak = getValueCookie("CompanyId") === '3' ? 55 : 45
        const res = detail.length % tamBreak
        for (let a = 0; a < tamBreak - res; a++) {
            let temporal=new Item('','',0,0,0,0,'')
            temporal.QuantityReturned=" "
            temporal.UnitCost=" "
            temporal.TotalCost=" "
            detail.push(temporal)

        }






        return detail
    }

    realLongDetail() {
        let cont = 0
        this.state.Products.forEach(element => {
            if (element.Visible && element.QuantityReturned > 0) {
                cont++
            }
        });
        return cont
    }

    AsignLineSequence() {
        const temporal = this.state.Products
        let count = 1
        let Prod=[]
        for (const item of temporal) {
            
            if(item.QuantityReturned>0){
                item.LineKey = count
                count++
                Prod.push(item)
            }
            
            
        }
        this.setState({ Products: temporal,Products2:Prod })
    }

    async SaveReturn() {
        await this.AsignLineSequence()
        await this.refreshInputs()
        const data = {
            Return: this.state.Return,
            Products: this.state.Products2,
            Totals: this.state.Totals,
            Comment: document.getElementById('CommentReturnPO').value,
            OrderType: "ReturnPO",
            idCompany: Number(getValueCookie('CompanyId')),
            username: getValueCookie('userName'),
            company: getValueCookie('Company')
        }
        
        const result = await create_Delete_Update_Information('/purchase/return/import', data)
       
        if(result.status.code===1){
            confirmCloseAlert('correct', 'The Return Purchase Order was saved successfully!')
            await this.OpenPrint()
        }else{

        }
        console.log(result)
    }
    OpenPrint(){
        if(document.getElementById('ReturnPurchaseOrderPrint')){
            let button=document.getElementById('ReturnPurchaseOrderPrint')
            button.click()
        }
    }

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Purchase Return</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please click on Vendor List button.</p>
                </div>
                <div className='row pb-2'>
                    <div className='col-sm-1' />
                    <div className='col-sm-10'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 pb-2'>
                                <div className='row'>
                                    <div className='col-12 d-grid gap-2'>
                                        <VendorList nombrePadre="PurchaseReturn" ident="VendorList" Padre={this} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 pb-2'>
                                <div className='row'>
                                    <div className='col-12 d-grid gap-2'>
                                        <PurchaseOrderVendorNo hidden={false} disabled={this.state.Return.Vendor.VendorNo === ''} VendorNo={this.state.Return.Vendor.VendorNo} headerName='' buttonTitle="Open Purchase Orders" nombrePadre="PurchaseOrderReturn" Padre={this} />
                                    </div>
                                </div>
                            </div>
                            <div hidden={this.state.Return.Vendor.VendorNo === ''} className='col-sm-12 grayBackground mt-3 row'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h1 className='card-title text-center text-primary my-5'>
                                            {this.state.Return.Vendor.VendorName}
                                        </h1>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-6'>
                                                <div className='row'>
                                                    <div className='col-6 pt-3 InputTextBolder'>
                                                        <p>Vendor No:</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputText text-start'>
                                                        <p>{this.state.Return.Vendor.VendorNo}</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputTextBolder'>
                                                        <p>City:</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputText text-start'>
                                                        <p>{this.state.Return.Vendor.City}</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputTextBolder'>
                                                        <p>Country:</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputText text-start'>
                                                        <p>{this.state.Return.Vendor.Country}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-6'>
                                                <div className='row'>
                                                    <div className='col-6 pt-3 InputTextBolder'>
                                                        <p>Address Line:</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputText text-start'>
                                                        <p>{this.state.Return.Vendor.AddressLine}</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputTextBolder'>
                                                        <p>Phone:</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputText text-start'>
                                                        <p>{this.state.Return.Vendor.Phone}</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputTextBolder'>
                                                        <p>Email:</p>
                                                    </div>
                                                    <div className='col-6 pt-3 InputText text-start'>
                                                        <p>{this.state.Return.Vendor.Email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div hidden={this.state.Return.PurchaseOrderNo === ''} className="row mt-3">
                                    <div className='col-sm-12 col-md-6 d-flex-justify-content-center mt-3'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='row pt-3 '>
                                                    <div className='col-6 InputTextBolder text-end'>
                                                        <p>Return No:</p>
                                                    </div>
                                                    <div className='col-6 InputText text-start'>
                                                        <p>{this.state.Return.ReturnNo}</p>
                                                    </div>
                                                </div>
                                                <div className='row pt-3'>
                                                    <div className='col-6 InputTextBolder text-end'>
                                                        <p>Date :</p>
                                                    </div>
                                                    <div className='col-6 InputText text-start'>
                                                        <p>{DateFormatMAS90(this.state.Return.ReturnDate)}</p>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div> 
                                    <div className='col-sm-12 col-md-6 d-flex-justify-content-center mt-3'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='row pt-3 '>
                                                    <div className='col-6 InputTextBolder text-end'>
                                                        <p>Apply to Purchase Order No:</p>
                                                    </div>
                                                    <div className='col-6 InputText text-start'>
                                                        <p>{this.state.Return.PurchaseOrderNo}</p>
                                                    </div>
                                                </div>
                                                <div className='row pt-3 '>
                                                    <div className='col-6 InputTextBolder text-end'>
                                                        <p>Invoice No:</p>
                                                    </div>
                                                    <div className='col-6 InputText text-start'>
                                                        <p>{this.state.Return.InvoiceNo}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={this.state.Return.PurchaseOrderNo === ''} className='row pt-4'>
                            <div className='col-sm-12 pt-2 pb-4'>
                                <div className='row'>
                                    <div className='col-12 InputText'>
                                        <p>Comment:</p>
                                    </div>
                                    <div className='col-12 pb-1'>
                                        <div className="input-group input-group-lg pb-4 textAreaGeneral">
                                            <textarea disabled={this.state.Return.ReturnNo===''} className="form-control" id="CommentReturnPO" placeholder='Insert comment...' rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 pt-2 pb-4'>
                                <Catalogue2 id='Catalogue2' colorButton='purpleButton' titleButton='Open Catalogue' PriceLevel={null} Array={'Products'} disabled={this.state.Return.PurchaseOrderNo===''} nombrePadre={"ReturnPO"} Padre={this} needPrice={false} needCost={true} />
                            </div>
                            <div className="col-sm-12 input-group input-group-lg">
                                <SearchUPC disabled={this.state.Return.PurchaseOrderNo ===''} ComponentName={'ReturnPO'} Padre={this} priceLevel={null} />
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={this.state.Return.PurchaseOrderNo === ''} className='row pb-3'>
                    <div className='col-sm-12 tableFixHead'>
                        <table className='table align-middle table-hover'>
                            <thead className='table-dark thead'>
                                <tr className='text-white'>
                                    <th className='text-center'>Item Code</th>
                                    <th className='text-center'>Description</th>
                                    <th className='text-center'>Quantity Received</th>
                                    <th className='text-center'>Quantity Returned</th>
                                    <th className='text-center'>Total Weight (lbs)</th>
                                    <th className='text-center'>Unit Price</th>
                                    <th className='text-center'></th>
                                    <th className='text-center'>Total</th>
                                    <th className='text-center'></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.Products.map((item, i) => (
                                        <tr hidden={!item.Visible} key={i}>
                                            <td className='textTableSize'>{item.itemCode}</td>
                                            <td className='textTableSize'>{item.Description}</td>
                                            <td className='text-center textTableSize'>{NumberFormat(item.QuantityReceived)}</td>
                                            <td className=''>
                                                <div className="input-group  input-group-lg">
                                                    <input onChange={(e) => this.onChange(e, item)} type="number" id={'quantityReturnedPurchaseReturn_' + i} min={0} defaultValue={item.QuantityReturned} className="form-control text-center inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-center textTableSize'>{NumberFormat(pricesFormat(item.TotalWeight))}</td>
                                            <td className=''>
                                                <div className="input-group input-group-lg flex-nowrap">
                                                    <span className="input-group-text">$</span>
                                                    <input onChange={(e) => this.onChange(e, item)} type="number" id={'unitCostReturnedPurchaseReturn_' + i} min={0} step={0.01} pattern={/([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]/} defaultValue={pricesFormat((item.UnitCost))} className="form-control text-end text-success inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-end textTableSize'>$</td>
                                            <td className='text-end textTableSize'>{NumberFormat(pricesFormat(item.TotalCost))}</td>
                                            <td className=''>
                                                <div className="d-grid gap-2">
                                                    <button type="button" onClick={() => this.removeProduct(item)} className="btn redButton btn-lg"><AiOutlineCloseCircle /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                            <tfoot className='tfoot'>
                                <tr className='bg-dark text-white'>
                                    <td className=''></td>
                                    <td className='text-center textTableSize'>TOTALS</td>
                                    <td className='text-center textTableSize'>{NumberFormat(this.state.Totals.TotalQuantityReceived)}</td>
                                    <td className='text-center textTableSize'>{NumberFormat(this.state.Totals.TotalQuantityReturned)}</td>
                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(this.state.Totals.TotalWeight))}</td>
                                    <td className='text-center'></td>
                                    <td className='text-end textTableSize'>$</td>
                                    <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.state.Totals.TotalCost))}</td>
                                    <td className=''></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div hidden={this.state.Return.PurchaseOrderNo === ''} className='row pb-2'>
                    <div className='col-sm-12 col-md-6 pb-3'>
                        <button hidden={false} type="button" className="btn greenButton btn-lg w-100" onClick={() => this.SaveReturn()}><AiFillPrinter /> {"Return Save and Print"}</button>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        {
                            getValueCookie('CompanyId') !== '6' ?
                            <InvoicePDF
                                id={'ReturnPurchaseOrderPrint'}
                                hidden={false}
                                colorButton='blackButton'
                                titleButton='Invoice Print'
                                title='Invoice Print'
                                CompanyTitle='Mayaland'
                                InvoiceType={'Return Purchase Order'}
                                CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(678) 213-1226']}
                                InvoiceHeader={['Return Number: ', this.state.Return.ReturnNo, 'Date: ', DateFormatMAS90(this.state.Return.ReturnDate), 'Apply To PO: ', this.state.Return.PurchaseOrderNo, 'Vendor No: ', this.state.Return.Vendor.VendorNo, 'Invoice No: ', this.state.Return.InvoiceNo]}
                                Box1={[this.state.Return.Vendor.VendorName, this.state.Return.Vendor.AddressLine?this.state.Return.Vendor.AddressLine:"", this.state.Return.Vendor.City?this.state.Return.Vendor.City+" ,":"" + this.state.Return.Vendor.Country?this.state.Return.Vendor.Country:""]}
                                Box2={[this.state.Return.Vendor.VendorName, this.state.Return.Vendor.AddressLine?this.state.Return.Vendor.AddressLine:"", this.state.Return.Vendor.City?this.state.Return.Vendor.City+" ,":"" + this.state.Return.Vendor.Country?this.state.Return.Vendor.Country:""]}
                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " "]}
                                DescriptionKeys={["QuantityReturned", "itemCode", "SKU", "Description", "$", "UnitCost", "$", "TotalCost"]}
                                HeaderDescritpion={["Qty", "Code", "SKU", "Description", "", "Price", "", "Amount"]}
                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormalCenter", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                Detail={this.printDetail()}
                                breakpoint={55}
                                quantSignDolar={this.realLongDetail()}
                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                bottomInformation={[
                                    NumberFormat(this.state.Totals.TotalQuantityReturned), "", "", "", "", "", "", "" , "Net Return: ", "$", NumberFormat(pricesFormat(this.state.Totals.TotalCost)),
                                    "", "", "", "", "", "", "", "", "", "", "",
                                    "", "", "", "", "", "", "", "", "Return Total: ", "$", NumberFormat(pricesFormat(this.state.Totals.TotalCost))
                                ]}
                                bottomStyle={[
                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                                ]}
                                bottomMessage={""}
                            />
                            :
                            <InvoicePDF2
                                id={'ReturnPurchaseOrderPrint'}
                                hidden={true}
                                colorButton='blackButton'
                                titleButton='Invoice Print'
                                title='Invoice Print'
                                companyLogo={getValueCookie('DocumentsLogo')}
                                InvoiceType={'Return Purchase Order'}
                                CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(770) 871-5807']}
                                InvoiceHeader={['Return Number:', this.state.Return.ReturnNo, 'Date:', DateFormatMAS90(this.state.Return.ReturnDate), 'Apply To PO:', this.state.Return.PurchaseOrderNo, 'Client No:', this.state.Return.Vendor.VendorNo, 'Invoice No:', this.state.Return.InvoiceNo]}
                                Box1={[this.state.Return.Vendor.VendorName, this.state.Return.Vendor.AddressLine, this.state.Return.Vendor.City + ", " + this.state.Return.Vendor.Country]}
                                Box2={[this.state.Return.Vendor.VendorName, this.state.Return.Vendor.AddressLine, this.state.Return.Vendor.City + ", " + this.state.Return.Vendor.Country]}
                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", ""]}
                                DescriptionKeys={["QuantityReturned", "itemCode", "Description", "$", "UnitCost", "$", "TotalCost"]}
                                HeaderDescritpion={["Quantity", "Code", "Description", "", "    Price", "", "Amount"]}
                                StyleDetail={["ColumnaPequenaCenter", "ColumnaNormal", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                Detail={this.printDetail()}
                                breakpoint={48}
                                quantSignDolar={this.realLongDetail()}
                                keysDecimalFormat={["UnitCost", "TotalCost"]}
                                bottomInformation={[
                                    NumberFormat(this.state.Totals.TotalQuantityReturned), "", "", "", "", "", "", "     Weight: " + NumberFormat(pricesFormat(this.state.Totals.TotalWeight)), "Net Return: ", "$", NumberFormat(pricesFormat(this.state.Totals.TotalCost)),
                                    "", "", "", "", "", "", "", "", "", "", "",
                                    "", "", "", "", "", "", "", "", "Return Total: ", "$", NumberFormat(pricesFormat(this.state.Totals.TotalCost))
                                ]}
                                bottomStyle={[
                                    "NormalButtom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom", "DolarButtomLeft", "DolarButtomLeft", "LargeButtom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                                ]}
                                bottomMessage={""}
                            />
                        }
                    </div>
                </div>

            </div>
        )
    }
}