import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';

import SmallModal from '../SmallModal';
import { DateFormatMAS90 } from '../../functions/dateFormat';





export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        showModal1: false,
        Detail: [],
        Title: '',
        Total: 0,
        TotalQuantity: 0
    }

    handleModalOpen = (modal, Detail, Title, Sales, Total) => {

        this.setState({ [modal]: true, Detail: Detail, Title: Title, Total: Sales, TotalQuantity: Total })
    }

    handleModalClose = (modal) => {

        this.setState({ [modal]: false, Detail: [], Title: '', Total: 0 })

    }

    render() {
        return (
            <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row '>
                        <div className='col-sm-12 text-center'>
                            <h5 className='card-title'>{this.props.Title}</h5>
                        </div>
                        <div className='col-sm-12 backgroundWhite'>
                            <div className='pb-3' />
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle text-center table-hover'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Route</th>
                                            <th>Customer No</th>
                                            <th>Customer Name</th>
                                            <th># Of Sales</th>
                                            <th></th>
                                            <th>Total Of Sales</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.data.map((order, o) => (
                                            <tr onClick={() => this.handleModalOpen('showModal1', order.Detail, order.CustomerName, order.Total, order.TotalNo)} key={o}>
                                                <td>{order.Route}</td>
                                                <td>{order.CustomerNo}</td>
                                                <td className='text-start'>{order.CustomerName}</td>
                                                <td>{order.TotalNo}</td>
                                                <td className='text-end'>{"$"}</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.Total))}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SmallModal centered={true} size={'xl'} title={'Customer: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                <div className='row'>
                    <div className='col-1' />
                    <div className='col-10'>
                        <div className="tableFixHeadSmall ">
                            <table className='table align-middle text-center table-hover'>
                                <thead className='table-dark'>
                                    <tr>
                                        <th>Invoice No</th>
                                        <th>Route</th>
                                        <th>Date</th>
                                        <th></th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.Detail.map((order, o) => (
                                        <tr key={o}>
                                            <td>{order.InvoiceNo}</td>
                                            <td>{order.UDF_RUTA}</td>
                                            <td>{DateFormatMAS90(order.InvoiceDate)}</td>
                                            <td className='text-end'>$</td>
                                            <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableSalesAmt))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr className='table-dark'>
                                        <td></td>
                                        <td className='textTableSize'>Totals</td>
                                        <td className='textTableSize'>{this.state.TotalQuantity}</td>
                                        <td className='textTableSize text-end'>$</td>
                                        <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className='col-1' />
                </div>
            </SmallModal>
            </>
        )
    }
}
