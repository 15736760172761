import React, { Component } from 'react'
import { getInformationWithData, getDetailSO } from '../../../services/CABE';
import { AiOutlineReload } from "react-icons/ai";
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import LoadingWindow from '../../../components/LoadingWindow';
import { getIMGByItemCode, ItemDesc, NumberFormat, OrderArray, random_rgba } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { DateFormatMAS90, getDateFromReports, OrderArrayByDateGenericLinesDesc } from '../../../functions/dateFormat';
import { ItemPerVendor, ItemsVendor, VendorDetail, Vendors } from './Class';
import { getIndexElement } from '../../../functions/searchInObject';
import ModalOrders from '../../../components/ModalComponent';
import ExcelDocument from '../../../components/ExcelDocument';
import { getValueCookie } from '../../../services/cookieService';
import { getDataSet } from '../../../functions/generateDataSetExcel';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, Filler } from 'chart.js';


export default class SalesOrderReport extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
        ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
            LinearScale,
            BarElement,
            Title, CategoryScale,
            LinearScale,
            PointElement,
            LineElement,

            Filler,
        );
    }
    state = {
        BodyFilter: [],
        flag: true,
        ItemVendorList: [],
        VendorItemList: [],
        AllInformation: [],
        mode: 'Item',
        ModalBody: [],
        ModalBodyFilter: [],
        ModalDetailBody: [],
        ModalFilterDetailBody: [],
        showModal1: false,
        order: true,
        currentItem: {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: '',
            TotalQuant: 0,
            TotalAmt: 0
        },
        ExcelInfo:[]
    }

    sumatoria(arreglo, llave = null) {
        let suma = 0
        for (const temp of arreglo) {
            if (llave != null) {
                suma = suma + temp[llave]
            } else {
                suma = suma + temp
            }
        }
        return suma ? suma.toFixed(2) : suma
    }

    async fetchWithSimpleRetry(data, retries = 3) {
        for (let i = 0; i < retries; i++) {
          try {
            // Try to obtain the response
            const detail = await getDetailSO('/sales/get/detail', data);
            return detail;
            
          } catch (error) {
            console.log(`Error in try ${i + 1}: ${error.message}`);
            
            if (error.message.includes('504')) {
              console.log(`Retrying... (${i + 1}/${retries})`);
            } else {
              throw error;
            }
      
            // If the error persist throw an error
            if (i === retries - 1) {
              throw new Error('Max retries reached. No se pudo obtener los datos.');
            }
          }
        }
      }

    async getVendors() {
        this.ModalLoading.current.showState(true);
        let ItemVendorList = []
        let VendorItemList = []
        let salesPerson = getValueCookie('SalesPerson')
        if(salesPerson === 'RR')
            salesPerson = '0000'
        const data = {
            idcompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            SalespersonNo: salesPerson
        }

        let result = await getInformationWithData('/sales/get/header', data)
        let SalesOrder = await getInformationWithData('/sales/get/header', data)
        const quoteOrders = SalesOrder.data.filter(order => order.OrderType === 'Q').map(order => order.SalesOrderNo); ;

        if (result.status.code === 1) {
            const d1 = {
                OrderNo: '*',
                idCompany: Number(getValueCookie('CompanyId')),
            }
            let detail = []
            
            try {
                detail = await this.fetchWithSimpleRetry(d1);
                if (detail && detail.data && detail.data.Tabla1) {
                    let noQuotes = detail.data.Tabla1.filter(order => !quoteOrders.includes(order.SalesOrderNo))
                    noQuotes = await ItemDesc(noQuotes, 'ItemCodeDesc', 'ItemCode', true)
                    result.data = result.data.filter(order => order.OrderType === 'S')
                    for (const info of noQuotes) {
                        const VendorIndex = getIndexElement(result.data, 'SalesOrderNo', info.SalesOrderNo)
        
                        if (VendorIndex !== -1) {
        
                            const index = getIndexElement(ItemVendorList, 'ItemCode', info.ItemCode)
                            const index2 = getIndexElement(VendorItemList, 'VendorNo', result.data[VendorIndex].CustomerNo)
        
        
                            if (index === -1) {
                                let it = new ItemsVendor(info.ItemCode, info.ItemCodeDesc, info.ProductLine)
                                it.addVendor(new Vendors(result.data[VendorIndex].CustomerNo, result.data[VendorIndex].BillToName, info.QuantityOrdered, info.UnitPrice, result.data[VendorIndex].OrderDate, info.SalesOrderNo))
                                ItemVendorList.push(it)
                            } else {
                                ItemVendorList[index].addVendor(new Vendors(result.data[VendorIndex].CustomerNo, result.data[VendorIndex].BillToName, info.QuantityOrdered, info.UnitPrice, result.data[VendorIndex].OrderDate, info.SalesOrderNo))
                                ItemVendorList[index].VendorList = OrderArrayByDateGenericLinesDesc(ItemVendorList[index].VendorList, 'LastReceiptDate')
                            }
        
                            if (index2 === -1) {
                                let vend = new Vendors(result.data[VendorIndex].CustomerNo, result.data[VendorIndex].BillToName, info.QuantityOrdered, info.UnitPrice, result.data[VendorIndex].OrderDate, info.SalesOrderNo)
                                vend.addItem(new ItemPerVendor(info.QuantityOrdered, info.UnitPrice, result.data[VendorIndex].OrderDate, info.ItemCode, info.ItemCodeDesc, info.ProductLine, info.SalesOrderNo))
                                VendorItemList.push(vend)
                            } else {
                                VendorItemList[index2].addItem(new ItemPerVendor(info.QuantityOrdered, info.UnitPrice, result.data[VendorIndex].OrderDate, info.ItemCode, info.ItemCodeDesc, info.ProductLine, info.SalesOrderNo))
                                VendorItemList[index2].ItemList = OrderArrayByDateGenericLinesDesc(VendorItemList[index2].ItemList, 'LastReceiptDate')
                            }
        
                        }
                        for (const item of ItemVendorList) {
                            item.calculateTotals()
                        }
                    }
                    this.setState({ 
                        ItemVendorList: ItemVendorList, 
                        VendorItemList: SalesOrder.data, 
                        BodyFilter: ItemVendorList, 
                        mode: 'Item', 
                        AllInformation: result.data,
                        ExcelInfo: noQuotes
                    })
                } else {
                    throw new Error('La respuesta no tiene los datos esperados');
                }
            } catch (error) {
                console.error('Error al obtener o procesar los detalles:', error.message);
            }
            
        }

        this.ModalLoading.current.showState(false);

        document.getElementById("excelContainerSalesOrdersReport").classList.remove("d-none");
        document.getElementById("loadDataContainerSalesOrdersReport").classList.replace("col-12", "col-6");
    }

    valueRadioButton = async e => {
        let stat = e.target.value
        if (stat === 'Item') {
            this.setState({ BodyFilter: this.state.ItemVendorList, mode: stat })

        } else {
            this.setState({ BodyFilter: this.state.VendorItemList, mode: stat })
        }
    }

    async getVendorsList(index, it) {
        this.clearModal()
        await this.handleModalOpen("showModal1")
        this.ModalLoading.current.showState(true);

        if (this.state.mode === 'Item') {
            const data = {
                ItemCode: it.ItemCode,
                idCompany: Number(getValueCookie('CompanyId')),
            }
            const result = await getInformationWithData('/purchase/History/getDetialByItem', data)
            if (result.status.code === 1) {
                let allData = []
                let dates = []
                let date2 = []
                for (const row of result.data) {

                    const index = getIndexElement(allData, 'VendorNo', row.VendorNo)
                    const index2 = getIndexElement(dates, 'OrderDate', DateFormatMAS90(row.PurchaseOrderDate))

                    if (index === -1) {
                        let newVendorDetail = new VendorDetail(row.VendorNo, row.PurchaseName)
                        newVendorDetail.addDetailVendor(row.PurchaseOrderNo, DateFormatMAS90(row.PurchaseOrderDate), row.QuantityInvoiced, row.UnitCost)
                        allData.push(newVendorDetail)
                    } else {
                        allData[index].addDetailVendor(row.PurchaseOrderNo, DateFormatMAS90(row.PurchaseOrderDate), row.QuantityInvoiced, row.UnitCost)
                        allData[index].VendorName = row.PurchaseName
                    }

                    if (index2 === -1) {
                        dates.push({ OrderDate: DateFormatMAS90(row.PurchaseOrderDate) })
                        date2.push(DateFormatMAS90(row.PurchaseOrderDate))
                    }
                }

                let dataSet = []

                for (const Vendor of allData) {
                    let color = random_rgba()
                    const vendordata = {

                        label: Vendor.VendorName,
                        data: [],
                        borderColor: color,
                        backgroundColor: color,
                        spanGaps: true
                    }
                    for (const date of dates) {

                        const index = getIndexElement(Vendor.Detail, 'OrderDate', date.OrderDate)
                        if (index !== -1) {
                            vendordata.data.push(Vendor.Detail[index].UnitCost)
                        } else {
                            vendordata.data.push(null)
                        }
                    }
                    dataSet.push(vendordata)
                }

                const graphData = {
                    labels: date2,
                    datasets: dataSet
                }
                this.setState({ LineGraphData: graphData })
            }


            const temporal = this.state.currentItem
            temporal.ItemCode = it.ItemCode
            temporal.ItemCodeDesc = it.ItemCodeDesc
            temporal.ProductLine = it.ProductLine
            temporal.TotalAmt = it.TotalAmt
            temporal.TotalQuant = it.TotalQuantity
            temporal.itemURL = await getIMGByItemCode(it.ItemCode)
            const index2 = getIndexElement(this.state.ItemVendorList, 'ItemCode', it.ItemCode)
            const item = this.state.ItemVendorList[index2]

            await this.setState({
                ModalBody: item.VendorList,
                ModalBodyFilter: item.VendorList,
                currentItem: temporal,
                ModalDetailBody: result.data,
                ModalFilterDetailBody: result.data
            })

        } else {
            const data = {
                idCompany: Number(getValueCookie('CompanyId')),
                OrderNo: it.SalesOrderNo,
                Mode: true
            }
            const result = await getDetailSO('/sales/get/detail', data)

            if (result.status.code === 1) {
                const temporal = this.state.currentItem
                temporal.ItemCodeDesc = it.BillToName
                temporal.ItemCode = it.CustomerNo
                temporal.ProductLine = it.SalesOrderNo
                temporal.itemURL = it.OrderDate
                result.data.Tabla1 = await ItemDesc(result.data.Tabla1, 'ItemCodeDesc', 'ItemCode', true)
                this.setState({
                    ModalBody: result.data.Tabla1,
                    ModalBodyFilter: result.data.Tabla1,
                    currentItem: temporal
                })
            }

        }


        this.ModalLoading.current.showState(false);

    }

    clearModal() {
        const temporal = {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: ''
        }


        this.setState({
            ModalBody: [],
            ModalBodyFilter: [],
            currentItem: temporal,
            ModalDetailBody: [],
            ModalFilterDetailBody: []
        })
    }


    async getItemList(index, vend) {
        const index2 = getIndexElement(this.state.VendorItemList, 'VendorNo', vend.VendorNo)
        const vendor = this.state.VendorItemList[index2]
        const temporal = this.state.currentItem
        temporal.ItemCode = vend.VendorNo
        temporal.ItemCodeDesc = vend.VendorName
        temporal.TotalQuant = vend.TotalQuantity
        temporal.TotalAmt = vend.TotalPrice

        await this.setState({ ModalBody: vendor.ItemList, ModalBodyFilter: vendor.ItemList, currentItem: temporal })
        this.handleModalOpen("showModal1")


    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    OrderInformation(array, key, state) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [state]: order, order: !this.state.order })
    }
    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }


    searchVendor = e => {

        let search = e.target.value;

        let Vendor
        if (this.state.mode === 'Item') {
            Vendor = this.state.ItemVendorList.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLine, search) ||
                    this.contains(item.ItemCodeDesc, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });

        } else {
            Vendor = this.state.VendorItemList.filter((vendor) => {
                if (((
                    this.contains(vendor.SalesOrderNo, search) ||
                    this.contains(vendor.CustomerNo, search) ||
                    this.contains(vendor.BillToName, search) ||
                    this.contains(vendor.SalespersonNo, search) ||
                    this.contains(vendor.UDF_RUTA, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });

        }

        this.setState({ BodyFilter: Vendor });
    }
    searchVendorHistory = e => {
        let search = e.target.value;

        let Vendor = this.state.ModalDetailBody.filter((vendor) => {
            if ((this.contains(vendor.VendorNo, search))
                || this.contains(vendor.PurchaseName, search)
                || this.contains(vendor.PurchaseOrderNo, search)) {
                return vendor
            } else {
                return null
            }
        })
        this.setState({ ModalFilterDetailBody: Vendor })
    }



    searchVendorModal = e => {

        let search = e.target.value;

        let Vendor = []
        if (this.state.mode === 'Item') {
            Vendor = this.state.ModalBody.filter((vendor) => {
                if (((
                    this.contains(vendor.VendorNo, search) ||
                    this.contains(vendor.VendorName, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });


        } else {
            Vendor = this.state.ModalBody.filter((item) => {
                if (((

                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLine, search) ||
                    this.contains(item.ItemCodeDesc, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });
        }

        this.setState({ ModalBodyFilter: Vendor });
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Sales Order Report</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please click on Load Data button.</p>
                </div>
                <div className='row pt-2'>
                    <div className='col-12'>
                        <div className='row d-flex justify-content-center'>
                            <div id="loadDataContainerSalesOrdersReport" className='col-sm-12 col-md-6 pb-4 InputTextBolder text-center'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getVendors()} type="button" className="btn blueButton btn-lg"><AiOutlineReload /> Load Data</button>
                                </div>
                            </div>
                            <div id="excelContainerSalesOrdersReport" className='col-sm-12 col-md-6 d-none'>
                                {
                                    this.state.mode === 'Item' ?
                                        <div className="d-grid gap-2">
                                            <ExcelDocument
                                                hidden={this.state.AllInformation.length === 0}
                                                archname={'Items in Sales Orders ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Items ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.BodyFilter, ['Item Code', 'Product Line', 'Description', 'Total Ordered', 'Total Amt($)'], ['ItemCode', 'ProductLine', 'ItemCodeDesc', 'TotalQuantity', 'TotalAmt'])}
                                                sheetname2={['All Detail']}
                                                data2={[
                                                    getDataSet(this.state.ExcelInfo,['Sales Order','Item Code','Description','Quantity Ordered','Unit Price ($)','Total Amt ($)'],['SalesOrderNo','ItemCode','ItemCodeDesc','QuantityOrdered','UnitPrice','ExtensionAmt'])
                                                ]}
                                            />
                                        </div>
                                    :
                                        <div className="d-grid gap-2">
                                            <ExcelDocument
                                                hidden={this.state.BodyFilter.length === 0}
                                                archname={'Sales Orders ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Sales Orders ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.VendorItemList, ['Sales Order No', 'Customer No', 'Bill to Name', 'Order Date', 'Sales Person No', 'Route', 'Total Amt($)'], ['SalesOrderNo', 'CustomerNo', 'BillToName', 'OrderDate', 'SalespersonNo', 'UDF_RUTA', 'NonTaxableAmt'])}
                                                sheetname2={['All Detail']}
                                                data2={[
                                                    getDataSet(this.state.ExcelInfo,['Sales Order','Item Code','Description','Quantity Ordered','Unit Price ($)','Total Amt ($)'],['SalesOrderNo','ItemCode','ItemCodeDesc','QuantityOrdered','UnitPrice','ExtensionAmt'])
                                                ]}
                                            />
                                        </div>
                                }
                            </div>
                            <div hidden={this.state.BodyFilter.length === 0} className='row TitleText'>
                                <div className='col-12 pt-3 d-flex justify-content-center'>
                                    <div className="form-check me-5">
                                        <input className="form-check-input textRadioButtonSize" id='typeSalesOrdReportItem' value={'Item'} onChange={this.valueRadioButton} checked={this.state.mode === 'Item'} type="radio" name="typeSalesOrdReportItem" />
                                        <label htmlFor='typeSalesOrdReportItem' className="form-check-label textRadioButtonSize" >
                                            Item List
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input textRadioButtonSize" id='typeSalesOrdReportOrder' value={'Vendor'} onChange={this.valueRadioButton} checked={this.state.mode === 'Vendor'} type="radio" name="typeSalesOrdReportOrder" />
                                        <label className="form-check-label textRadioButtonSize" htmlFor='typeSalesOrdReportOrder' >
                                            Order List
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={this.state.AllInformation.length === 0} className='row pt-3'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input type="text" autoComplete='off' className="form-control" id='opopopop' placeholder={this.state.mode === 'Item' ? 'Search Item by ItemCode, Line Product, Description...' : 'Search Order by OrderNo, Customer Name, Sales Person...'} onChange={this.searchVendor} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.mode === 'Item' ?
                            <div hidden={this.state.BodyFilter.length === 0} className='col-12 tableFixHead'>
                                <table className='table align-middle table-hover'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCode', 'BodyFilter')} className='bg-dark'>
                                                <div className='row'>
                                                    <div className='col-12'>Item Code</div>
                                                </div>
                                                <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ProductLine', 'BodyFilter')} className='bg-dark'>
                                                <div className='row'>
                                                    <div className='col-12'>Product Line</div>
                                                    <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                </div>
                                            </th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCodeDesc', 'BodyFilter')} className='bg-dark'>
                                                <div className='row'>
                                                    <div className='col-12'>Description</div>
                                                    <div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div>
                                                </div>
                                            </th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'TotalQuantity', 'BodyFilter')} className='bg-dark'>
                                                <div className='row'>
                                                    <div className='col-12'>Total Ordered </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark'> </th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'TotalAmt', 'BodyFilter')} className='bg-dark'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Total Amt
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.BodyFilter.map((item, i) => (
                                                <tr onClick={() => this.getVendorsList(i, item)} key={i}>
                                                    <td className='text-center'>{item.ItemCode}</td>
                                                    <td className='text-center'>{item.ProductLine}</td>
                                                    <td className='text-start'>{item.ItemCodeDesc}</td>
                                                    <td className='text-center'>{NumberFormat(pricesFormat(item.TotalQuantity))}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(item.TotalAmt))}</td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>
                                    <tfoot className='bg-dark text-light '>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-center'>{NumberFormat(pricesFormat(this.sumatoria(this.state.BodyFilter, 'TotalQuantity')))}</td>
                                            <td className='text-end'>$</td>
                                            <td className='text-end'>{NumberFormat(pricesFormat(this.sumatoria(this.state.BodyFilter, 'TotalAmt')))}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        :
                            <div hidden={this.state.BodyFilter.length === 0} className='col-12 tableFixHead'>
                                <table className='table align-middle table-hover'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'SalesOrderNo', 'VendorItemList')} >
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Sales Order No
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'CustomerNo', 'VendorItemList')}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Customer No
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'BillToName', 'VendorItemList')}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Customer Name
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'OrderDate', 'VendorItemList')}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Order Date
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'SalespersonNo', 'VendorItemList')}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Sales Person
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'UDF_RUTA', 'VendorItemList')}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Route
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='bg-dark'></th>
                                            <th className='bg-dark' onClick={() => this.OrderInformation(this.state.VendorItemList, 'NonTaxableAmt', 'VendorItemList')}>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        Total Amt
                                                    </div>
                                                    <div className='col-12'>
                                                        <BsFileArrowUpFill /><BsFileArrowDownFill />
                                                    </div>
                                                </div>
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.BodyFilter.map((item, i) => (
                                                <tr key={i} onClick={() => this.getVendorsList(i, item)}>
                                                    <td className='text-center'>{item.SalesOrderNo}</td>
                                                    <td className='text-center'>{item.CustomerNo}</td>
                                                    <td className='text-start'>{item.BillToName}</td>
                                                    <td className='text-center'>{DateFormatMAS90(item.OrderDate)}</td>
                                                    <td className='text-center'>{item.SalespersonNo}</td>
                                                    <td className='text-start'>{item.UDF_RUTA}</td>
                                                    <td className='text-end'>$</td>
                                                    <td className='text-end'>{NumberFormat(pricesFormat(item.NonTaxableAmt))}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot className='bg-dark text-light '>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='text-end'>$</td>
                                            <td className='text-end'>{NumberFormat(pricesFormat(this.sumatoria(this.state.VendorItemList, 'NonTaxableAmt')))}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                    }

                </div>

                <ModalOrders title={this.state.mode === 'Item' ? 'Item in Orders' : 'Order Detail'} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        {
                            this.state.mode === 'Item' ?
                                <div className='col-10'>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-sm-12 col-md-3 col-lg-6'>
                                            <div className='row d-flex justify-content-center'>
                                                <div className='col-12 pt-2 text-center d-lg-flex justify-content-lg-end d-sm-block text-sm-center align-items-center'>
                                                    <div className='ItemImg'>
                                                        <img key={this.state.currentItem.itemURL} className='pb-5 pt-5' id='ShortsProductImg' src={this.state.currentItem.itemURL !== '' ? this.state.currentItem.itemURL : '/assets/notavailable.png'} alt='ProductImg' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-6'>
                                            <div className='row'>
                                                <div className='col-12 TitleText mt-4 mb-2'>
                                                    <span className='badge bg-primary me-4'>{this.state.currentItem.ItemCode}</span>
                                                    <span className='badge bg-secondary'>{this.state.currentItem.ProductLine}</span>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCodeDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 py-4">
                                            <ExcelDocument
                                                hidden={false}
                                                archname={'Order List per Item ' + this.state.currentItem.ItemCode + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Order List ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.ModalBody, ['Order No', 'Customer No', 'Customer Name','Order Date','Quantity Ordered','Unit Price ($)', 'Total Amt($)'], ['OrderNo', 'VendorNo', 'VendorName','LastReceiptDate', 'LastReceiptQuantity', 'LastUnitCost','TotalPrice'])}

                                            />
                                        </div>
                                        <div className='col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchVendorVendorModal' placeholder={'Search by OrderNo, CustomerNo, Customer Name...'} onChange={this.searchVendorModal} />
                                        </div>
                                        <div className='col-12 tableFixHead table-hover'>
                                            <table className='table align-middle table-hover'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'OrderNo', 'ModalBodyFilter')} className='bg-dark'>Order No<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorNo', 'ModalBodyFilter')} className='bg-dark'>Customer No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorName', 'ModalBodyFilter')} className='bg-dark'>Customer Name <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptDate', 'ModalBodyFilter')} className='bg-dark'>Order Date <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptQuantity', 'ModalBodyFilter')} className='bg-dark'>Quantity Ordered<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastUnitCost', 'ModalBodyFilter')} className='bg-dark'>Unit Price <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'TotalPrice', 'ModalBodyFilter')} className='bg-dark'>Total Amt<BsFileArrowUpFill /><BsFileArrowDownFill /></th>

                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {
                                                        this.state.ModalBodyFilter.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-center'>{item.OrderNo}</td>
                                                                <td className='text-center'>{item.VendorNo}</td>
                                                                <td className='text-start'>{item.VendorName}</td>
                                                                <td className='text-center'>{item.LastReceiptDate ? DateFormatMAS90(item.LastReceiptDate) : 'Not available'}</td>
                                                                <td className='text-center'>{item.LastReceiptQuantity ? NumberFormat(pricesFormat(item.LastReceiptQuantity)) : 0}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.LastUnitCost ? NumberFormat(pricesFormat(item.LastUnitCost)) : '0.00'}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.TotalPrice ? NumberFormat(pricesFormat(item.TotalPrice)) : '0.00'}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>
                                                <tfoot className='tfoot'>
                                                    <tr className='bg-dark text-white'>
                                                        <td></td>
                                                        <td className='text-center textTableSize'>Totals</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.state.currentItem.TotalQuant))}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>$</td>
                                                        <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.state.currentItem.TotalAmt))}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='col-10 text-center'>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-sm-6 '>
                                            <div className='row'>
                                                <div className='col-12 titleTotal'>
                                                    <p>Order Date: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{DateFormatMAS90(this.state.currentItem.itemURL)}</p>
                                                </div>
                                                <div className='col-12 titleTotal'>
                                                    <p>Order No:</p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ProductLine}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='row'>
                                                <div className='col-12 titleTotal'>
                                                    <p>Customer No:</p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCode}</p>
                                                </div>
                                                <div className='col-12 titleTotal'>
                                                    <p>Customer Name: </p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCodeDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 py4">
                                            <ExcelDocument
                                                hidden={false}
                                                archname={'Order: ' + this.state.currentItem.ProductLine + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Item List Order '+this.state.currentItem.ProductLine}
                                                data={getDataSet(this.state.ModalBodyFilter, ['Item Code', 'Product Line', 'Description', 'Quantity Ordered', 'Unit Price($)', 'Total Amt($)'], ['ItemCode', 'ProductLine', 'ItemCodeDesc', 'QuantityOrdered', 'UnitPrice', 'ExtensionAmt'])}
                                            />
                                        </div>
                                        <div className='col-12 col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchItemVendorModal' placeholder={'Search Item by OrderNo, ItemCode, Product Line, Description...'} onChange={this.searchVendorModal} />
                                        </div>
                                        <div className='col-12 tableFixHead'>
                                            <table className='table align-middle table-hover'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCode', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ProductLine', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCodeDesc', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'QuantityOrdered', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Quantity Ordered</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'UnitPrice', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Unit Price</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ExtensionAmt', 'ModalBodyFilter')} className='bg-dark'><div className='row'><div className='col-12'>Total</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {

                                                        this.state.ModalBodyFilter.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-center'>{item.ItemCode}</td>
                                                                <td className='text-center'>{item.ProductLine}</td>
                                                                <td className='text-start'>{item.ItemCodeDesc}</td>
                                                                <td className='text-center'>{item.QuantityOrdered ? NumberFormat(pricesFormat(item.QuantityOrdered)) : 0}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.UnitPrice ? NumberFormat(pricesFormat(item.UnitPrice)) : '0.00'}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.ExtensionAmt ? NumberFormat(pricesFormat(item.ExtensionAmt)) : '0.00'}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot className='tfoot'>
                                                    <tr className='bg-dark text-white'>
                                                        <td></td>
                                                        <td className='text-center textTableSize'>Totals</td>
                                                        <td></td>
                                                        <td className='text-center textTableSize'>{NumberFormat(pricesFormat(this.sumatoria(this.state.ModalBodyFilter, 'QuantityOrdered')))}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>$</td>
                                                        <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.sumatoria(this.state.ModalBodyFilter, 'ExtensionAmt')))}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div></div>
                        }
                        <div className='col-1'></div>
                    </div>
                </ModalOrders>

            </div>
        )
    }
}
