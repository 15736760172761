import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { ActualDate, DateFormatMAS90 } from '../functions/dateFormat.js';
import { getPriceMargin, getPurePriceMargin, intervalTimeInDays, NumberFormat } from '../functions/generalFunctions.js';
import { confirmCloseAlert } from '../functions/alerts.js';
import { getIndexElement } from '../functions/searchInObject.js';
import { pricesFormat } from '../functions/pricesFormat.js';
import ModalOrders from './ModalComponent.js';
import "../css/table-responsive.css"

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        salesOrderList: [],
        salesOrderListFilter: []
    }

    async getSalesOrderHeader(refresh) {
        await this.handleModalOpen()
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
        }
        const data2 = {
            idcompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            SalespersonNo: '0000'
        }


        const buscador = document.getElementById('salesorder_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.salesOrderList.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ salesOrderList: [], salesOrderListFilter: [] });

            const respuesta = await getInformationWithData('/sales/get/header', data);

            const completeList = await getInformationWithData('/sales/get/header/byDate', data2);

            if (respuesta.status.code === 1) {
                let result = respuesta.data
                for (const sale of result) {
                    const pos = getIndexElement(completeList.data, 'SalesOrderNo', sale.SalesOrderNo)
                    sale.PosQue = pos !== -1 ? (completeList.data[pos].posicion ? (completeList.data[pos].posicion) : 999) : 999
                    if (sale.OrderType === 'S') {
                        sale.TypeinLetters = 'Standard'
                    } else {
                        sale.TypeinLetters = 'Quote'
                    }
                }

                await this.setState({ salesOrderList: result, salesOrderListFilter: result });

            }
            this.ModalLoading.current.showState(false);
        }
        await this.setState({ salesOrderListFilter: this.state.salesOrderList });
        buscador.value = '';
        setTimeout(() => { buscador.focus(); }, 300);

    }

    async getSalesOrder(SalesOrderHeader) {
        //Aqui asignamos el producto al estado del padre
        
        await this.handleModalClose()
        let state = 0
        if (SalesOrderHeader.OrderType === 'S') {
            state = 'S'
        } else {
            state = 'Q'
        }
        if (!(SalesOrderHeader.Status)) {
            SalesOrderHeader.Status = 0
        }

        let enableEdit = false
        
        if (getValueCookie('CompanyId') === '6' && SalesOrderHeader.Status !== 0) {
            
            enableEdit = true
        }

        if ((this.props.nombrePadre === 'SalesOrderListSO')) {
            SalesOrderHeader.Status = -1
        }
        let TemporalSalesOrderNo = ""
        if (SalesOrderHeader.SalesOrderNo) {
            let SalesOrderProvisonalNo = SalesOrderHeader.SalesOrderNo.split('-')
            TemporalSalesOrderNo = SalesOrderProvisonalNo.length > 1 ? SalesOrderProvisonalNo[0] : SalesOrderHeader.SalesOrderNo
        }


        const data = {
            SalesOrderNo: TemporalSalesOrderNo !== "" ? TemporalSalesOrderNo : SalesOrderHeader.SalesOrderNo,
            OrderNo: SalesOrderHeader.SalesOrderNo,
            CustomerNo: SalesOrderHeader.CustomerNo,
            username: getValueCookie('userName'),
            company: getValueCookie('Company'),
            idCompany: Number(getValueCookie('CompanyId')),
            idcompany: getValueCookie('CompanyId'),
            Mode: true,
            InvoiceType: 1,
            Status: SalesOrderHeader.Status
        }



        this.ModalLoading.current.showState(true);
        const result1 = await getInformationWithData('/sales/get/detail', data)
        const result2 = await getInformationWithData('/customer/getByCustomerNo', data)
        const balance = await getInformationWithData('/customer/getBalance', data)

        const invent = await getInformationWithData('/Items/get', data)
        for (const item of invent.data) {
            item.IdCompany = 6
        }
        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const inventM = await getInformationWithData('/Items/get', data);
            invent.status.code = inventM.status.code
            for (const item of inventM.data) {
                item.IdCompany = 3
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(inventM.data)
        }


        if (result1.status.code === 1 && result2.status.code === 1 && balance.status.code === 1) {
            const Father = this.props.Padre
            if (this.props.nombrePadre === 'SalesOrderListSO') {
                const CustomerResult = result2.data[0]
                const Packing = SalesOrderHeader.Packing ? SalesOrderHeader.Packing.split(';') : null
                const SalesOrder = {

                    Customer: {
                        AddressLine1: CustomerResult.AddressLine1,
                        City: CustomerResult.City,
                        CustomerName: CustomerResult.CustomerName,
                        CustomerNo: CustomerResult.CustomerNo,
                        EmailAddress: CustomerResult.EmailAddress,
                        PriceLevel: CustomerResult.PriceLevel,
                        SalespersonNo: CustomerResult.SalespersonNo,
                        State: CustomerResult.State,
                        TelephoneNo: CustomerResult.TelephoneNo,
                        ZipCode: CustomerResult.ZipCode,
                    },
                    NoOrder: SalesOrderHeader.SalesOrderNo,
                    Type: state,
                    TotalQuantityOrdered: 0,
                    TotalCost: 0,
                    Date: SalesOrderHeader.OrderDate,
                    Warehouse: SalesOrderHeader.WarehouseCode,
                    Packing: Packing ? (Packing.length === 2 ? Packing[0] : null) : null,
                    WeightOption: Packing ? (Packing.length === 2 ? Packing[1] : null) : null,
                    MAS90Status: SalesOrderHeader.MAS90Status,
                    Duplicate:false
                }
                let Detail = []
                result1.data.Tabla1.forEach(element => {

                    if (getValueCookie('CompanyId') === '6') {
                        const index = getIndexElement(invent.data, 'itemCode', element.ItemCode)
                        if (index !== -1) {
                            element.UnitCost = invent.data[index].LastTotalUnitCost
                        }
                    }

                    const index2 = getIndexElement(invent.data, 'itemCode', element.ItemCode)
                    let desc = ''
                    let LastTotalUnitCost = 0
                    let comp = 1563
                    if (index2 !== -1) {

                        desc = (!(invent.data[index2].completeDesc) || invent.data[index2].completeDesc === "null") ? invent.data[index2].abbreviatedDesc : invent.data[index2].completeDesc
                        comp = invent.data[index2].IdCompany ? invent.data[index2].IdCompany : 1563

                        LastTotalUnitCost = invent.data[index2].LastTotalUnitCost
                    } else {
                        desc = element.ItemCodeDesc
                    }

                    const indDupl = getIndexElement(Detail, 'itemCode', element.ItemCode)
                    if(indDupl!==-1&&!SalesOrder.Duplicate){
                        SalesOrder.Duplicate=true
                    }
                    const itemTemporal = {
                        BIN: "",
                        Comment: getValueCookie('CompanyId') === '6' ? "BOX: " + getPriceMargin(element.UnitPrice, element.UnitCost, SalesOrderHeader.PriceLevel) : element.CommentText,
                        PureMargin: getValueCookie('CompanyId') === '6' ? getPurePriceMargin(element.UnitPrice, element.UnitCost) : 0,
                        LastTotalUnitCost: LastTotalUnitCost,
                        ShipWeight: element.LineWeight,
                        StandardUnitCost: element.UnitCost,
                        UnitPrice: Number(element.UnitPrice).toFixed(2),
                        Visible: (element.QuantityOrdered !== 0 || element.QuantityShipped !== 0) ? 1 : 0,
                        abbreviatedDesc: desc,
                        caseupc: "",
                        itemCode: element.ItemCode,
                        quantity: 0,
                        quantityOrdered: element.QuantityOrdered,
                        LineSequence: element.LineSeqNo,
                        quantityShipped: element.QuantityShipped,
                        quantityReceived: 0,
                        totalCost: ((Number(element.QuantityOrdered) * Number(element.UnitPrice)).toFixed(2)),
                        totalFreight: 0,
                        unitCost: element.UnitCost,
                        upc: "",
                        upc2: "",
                        IdCompany: comp,
                        duplicate: indDupl !== -1 ? 'bg-danger' : ''
                    }
                    Detail.push(itemTemporal)
                });

                balance.data.forEach(element => {
                    element.PaymentDelayDays = intervalTimeInDays(DateFormatMAS90(element.InvoiceDueDate), ActualDate())
                    element.InvoiceDate = DateFormatMAS90(element.InvoiceDate)
                    element.InvoiceDueDate = DateFormatMAS90(element.InvoiceDueDate)
                    element.CustomerName = CustomerResult.CustomerName
                });

                const temporalBalance = Father.state.Balance
                temporalBalance.HeaderBalance = balance.data
                temporalBalance.TermsCode = CustomerResult.TermsCodeDesc


                Father.setState({Products: Detail, SalesOrder: SalesOrder, Balance: temporalBalance, ConfirmSave: Detail.length,enableEdit:enableEdit })
                Father.refreshInputs()
                Father.calculateTotals()
                Father.getTotalBalance()
                Father.handleModalOpen("showModal1")

                
                if (SalesOrder.Customer.CustomerNo === '0016027' && getValueCookie('CompanyId') !== '6'){
                    Father.setState({isMayalandOrder: false})
                } else {
                    Father.setState({isMayalandOrder: true})
                }
            }

        } else {
            confirmCloseAlert('incorrect', 'Whoops something was wrong try again please!')
        }
        this.ModalLoading.current.showState(false);

    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }

    }

    searchSalesOrder = e => {

        let busqueda = e.target.value;

        if (busqueda) {
            let orders = this.state.salesOrderList.filter((order_) => {
                if (((
                    this.contiene(order_.BillToAddress1, busqueda) ||
                    this.contiene(order_.BillToName, busqueda) ||
                    this.contiene(order_.SalespersonNo, busqueda) ||
                    this.contiene(order_.SalesOrderNo, busqueda) ||
                    this.contiene(order_.TermsCode, busqueda) ||
                    this.contiene(order_.CustomerNo, busqueda) ||
                    this.contiene(order_.TypeinLetters, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
            this.setState({ salesOrderListFilter: orders });
        } else {
            let temporal = this.state.salesOrderList
            this.setState({ salesOrderListFilter: temporal })
        }
    }

    stateInWords(state) {
        if (state === 1) {
            return 'In process'
        } else if (state === 2) {
            return 'Finished'
        } else {
            return 'Not started'
        }
    }
    Mas90stateInWords(state) {
        if (state === 0) {
            return 'Not Charged'
        } else if (state === 1) {
            return 'Charged'
        }
        return ''
    }

    stateColor(state) {
        if (state === 1) {
            return 'text-dark yellow'
        } else if (state === 2) {
            return 'text-light green'
        } else {
            return 'text-light red'
        }
    }
    MAS90stateColor(state) {
        if (state === 0) {
            return 'text-danger'
        } else if (state === 1) {
            return 'text-success'
        }
        return ''

    }


    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className="btn purpleButton btn-lg w-100" onClick={() => this.getSalesOrderHeader(true)}><AiOutlineFileText /> SO List</button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Sales Order List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className='row form-group'>
                        <div className="input-group input-group-lg flex-nowrap col-sm-12 pb-3">
                            <input className='form-control w-75 display-inline' placeholder='Search by...' id={'salesorder_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchSalesOrder} />
                            <span className="input-group-text">
                                <button className='btn greenButton btn-lg' onClick={() => this.getSalesOrderHeader(true)}><BsArrowRepeat /></button>
                            </span>
                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table align-middle table-hover'>
                                <thead className='table-dark text-center thead'>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        {/* <th>MAS90 Status</th>*/}
                                        <th>Customer No</th>
                                        <th>Customer Name</th>
                                        <th>Sales Person</th>
                                        <th>Terms Code</th>
                                        <th>Date</th>
                                        <th></th>
                                        <th>Total Amount</th>
                                        <th>Queue Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.salesOrderListFilter.map((element, e) => (
                                            <tr key={e} onClick={() => this.getSalesOrder(element)}>
                                                <td className='text-center'>{element.SalesOrderNo}</td>
                                                <td className='text-center'>{element.TypeinLetters}</td>
                                                <td className='text-center'>
                                                    <span className={'badge ' + this.stateColor(element.Status) + "Background"}>{this.stateInWords(element.Status)}</span>
                                                    </td>
                                                {/*<td className={'text-center '+this.MAS90stateColor(element.MAS90Status)}>{this.Mas90stateInWords(element.MAS90Status)}</td>*/}
                                                <td className='text-center'>{element.CustomerNo}</td>
                                                <td className='text-start'>{element.BillToName}</td>
                                                <td className='text-center'>{element.SalespersonNo}</td>
                                                <td className='text-center'>{element.TermsCode}</td>
                                                <td className='text-center'>{DateFormatMAS90(element.OrderDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.NonTaxableAmt))}</td>
                                                <td className='text-center'>{element.PosQue === 999 ? 'Completed' : element.PosQue}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalOrders>
            </>
        )
    }
}
