import React, { Component } from 'react'
import { getInformationWithData } from '../../services/CABE.js'
import { getValueCookie } from '../../services/cookieService';
import LoadingWindow from '../LoadingWindow';
import { getIndexElement } from '../../functions/searchInObject';
import { BsArrowRepeat, BsJournals, BsList } from "react-icons/bs";
import ModalOrders from '../ModalComponent';
import "../../css/general-style.css"
import "../../css/scrollBar.css"
import { Item, productLine } from './Class';

import { pricesFormat } from '../../functions/pricesFormat.js';


import ItemCardNoPrices from './ItemCardNoPrices.js';
//BsJournals

export default class Catalogue2NoPrices extends Component {

    constructor(props) {
        super(props);
        this.retornarProducto = React.createRef();
        this.ModalLoading = React.createRef();
    }

    state = {
        Catalogo: [],
        ProductsInSearch: [],
        ProductosMostrados: [],
        ProductLine: [],
        ProductLineMostrados: [],
        oldPriceLevel: '',
        showModal: false,
        SelectedProdLine: 'All',
        currentPage: 0,
        ProductsPerPage: 60,
        TotalPages: 0,
        oldCustomerNo: '',
        selectedItem: {}
    }

    clearAll() {
        this.setState({
            Catalogo: [],
            ProductosMostrados: [],
            ProductLine: [],
            ProductLineMostrados: [],
            oldPriceLevel: '',
            SelectedProdLine: 'All',

        })
    }

    handleModalOpen = () => {
        this.setState({ showModal: true })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }

    getPriceLevel() {
        if (this.props.PriceLevel) {
            this.setState({ oldPriceLevel: this.props.PriceLevel })
            return this.props.PriceLevel
        } else {
            this.setState({ oldPriceLevel: this.props.PriceLevel })
            return undefined
        }
    }



    buscarProductos = async e => {
        let search = e.target.value
        if (search.length >= 3 || search.length === 0) {
            if (this.state.SelectedProdLine !== 'All') {
                if (search !== '') {
                    let Products = this.state.Catalogo.filter((item) => {
                        if (
                            (this.contiene(item.itemCode, search) ||
                                this.contiene(item.abbreviatedDesc, search)) && (this.contiene(item.ProductLineDesc, this.state.SelectedProdLine))

                        ) {
                            return item
                        } else {
                            return null
                        }
                    })
                    await this.setState({ ProductsInSearch: Products })
                } else {
                    let Products = this.state.Catalogo.filter((item) => {
                        if (this.contiene(item.ProductLineDesc, this.state.SelectedProdLine)) {
                            return item
                        } else {
                            return null
                        }
                    })
                    await this.setState({ ProductsInSearch: Products })
                }


            } else {
                if (search !== '') {
                    let Products = this.state.Catalogo.filter((item) => {
                        if (
                            this.contiene(item.itemCode, search) ||
                            this.contiene(item.abbreviatedDesc, search)


                        ) {
                            return item
                        } else {
                            return null
                        }
                    })
                    await this.setState({ ProductsInSearch: Products })
                } else {
                    await this.setState({ ProductsInSearch: this.state.Catalogo })
                }

            }
        }
        await this.ResetPagination()



    }

    SearchLineProduct = e => {
        let search = e.target.value
        if (search !== '') {
            let ProductLines = this.state.ProductLine.filter((prodLine) => {
                if (this.contiene(prodLine.ProductLineName, search)) {
                    return prodLine
                } else {
                    return null
                }
            })

            this.setState({ ProductLineMostrados: ProductLines })
        } else {
            this.setState({ ProductLineMostrados: this.state.ProductLine })
        }

    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        } else {
            return false
        }

    }
    getCustomerNo(){
        
        if(this.props.CustomerNo){
            this.setState({oldCustomerNo:this.props.CustomerNo})
        }else{
            this.setState({oldCustomerNo:''})
        }
        
    }


    async SelectProductLine(prodLine) {
        if (prodLine.ProductLineName !== 'All') {
            const catalogo = this.state.Catalogo
            let catalogoProductLine = []
            for (const item of catalogo) {

                if (item.ProductLineDesc === prodLine.ProductLineName) {
                    catalogoProductLine.push(item)
                }
            }

            await this.setState({ ProductsInSearch: catalogoProductLine, SelectedProdLine: prodLine.ProductLineName })
        } else {
            const catalogo = this.state.Catalogo
            await this.setState({ ProductsInSearch: catalogo, SelectedProdLine: 'All' })
        }
        await this.ResetPagination()
    }

    setAltPrice(item){
        let altitudePrice = item.LastTotalUnitCost / 0.95;
        altitudePrice = altitudePrice / (1 - (item.MarkUp / 100));
        return altitudePrice;
    }

    async getProductsWithProductLine(refresh) {

        await this.handleModalOpen()
        
        this.ModalLoading.current.showState(true);
        if (refresh || this.state.Catalogo.length <= 0 || this.state.oldPriceLevel !== this.props.PriceLevel||this.props.CustomerNo!==this.state.oldCustomerNo) {
            await this.getCustomerNo()
            this.clearAll()
            const data = {
                company: getValueCookie('Company'),
                idcompany: getValueCookie('CompanyId'),
                priceLevel: this.getPriceLevel(),
                Component: this.props.Component ? this.props.Component : null,
                CustomerNo: this.props.CustomerNo?this.props.CustomerNo:null
            }
            const respuesta = await getInformationWithData('/Items/get', data);
            
            if (getValueCookie('CompanyId') === '6') {
                data.company = 'Mayaland Atlanta'
                data.idcompany = '3'
                const respuesta2 = await getInformationWithData('/Items/get', data);

                // if (getValueCookie('CompanyId') === '6') {
                    for (const item of respuesta2.data) {
                       
                        if (item.UnitPriceALT) {
                            const calculateFinalPrice = this.setAltPrice(item)
                            item.UnitPrice = Number(pricesFormat(calculateFinalPrice))
                        } else {
                            //ELIMINAR DEL CATALOGO
                            item.UnitPrice = 0
                        }
                    }

                // }
                respuesta.status.code = respuesta2.status.code

                //respuesta.data = respuesta.data.concat(respuesta2.data)
                let prodMayalandToInclude = []
                for (const item of respuesta2.data) {
                    item.IdCompany = 3
                    const index = getIndexElement(respuesta.data, 'itemCode', item.itemCode)
                    if (index !== -1) {

                        await respuesta.data.splice(index, 1)
                        await prodMayalandToInclude.push(item)
                    }
                }
                let acceptProductLines = [{ ProdLine: '2012' }, { ProdLine: '2013' }, { ProdLine: '2014' }]

                for (let m = respuesta.data.length - 1; m >= 0; m--) {

                    const item = respuesta.data[m]

                    item.IdCompany = 6
                    const prodIndex = getIndexElement(acceptProductLines, 'ProdLine', item.ProductLine)
                    if (prodIndex !== -1) {
                        if (item.UnitPriceALT || item.UnitPriceALT !== 0) {
                            item.UnitPrice = Number(pricesFormat(item.UnitPriceALT))
                        }
                    } else {
                        respuesta.data.splice(m, 1)
                    }

                }
                if (data.priceLevel !== '2') {
                    respuesta.data = respuesta.data.concat(prodMayalandToInclude)
                } else {
                    respuesta.data = prodMayalandToInclude
                }
            }
            if (respuesta.status.code === 1) {
                const Padre = this.props.Padre
                const Products = Padre.state[this.props.Array];

                let allProducts = []
                let allProductLine = []
                allProductLine.push(new productLine('All'))

                for (const item of respuesta.data) {
                    item.ProductLineDesc=item.ProductLineDesc!=='TALPA'?item.ProductLineDesc:'TLP'
                    const index1 = getIndexElement(allProductLine, 'ProductLineName', item.ProductLineDesc!=='TALPA'?item.ProductLineDesc:'TLP')
                    const index2 = getIndexElement(allProducts, 'itemCode', item.itemCode)
                    const index3 = getIndexElement(Products, 'itemCode', item.itemCode)

                    if (index1 === -1) {
                       
                            allProductLine.push(new productLine(item.ProductLineDesc!=='TALPA'?item.ProductLineDesc:'TLP'))
                        
                        
                    }
                    if (index2 === -1) {
                        let inputvalue = 0
                        if (index3 !== -1) {
                            if (this.props.nombrePadre === 'SalesOrder') {
                                inputvalue = Products[index3].quantityOrdered
                            } else if (this.props.nombrePadre === 'Invoice') {
                                inputvalue = Products[index3].quantityShipped
                            } else if (this.props.nombrePadre === 'ReturnPO') {
                                inputvalue = Products[index3].QuantityReturned
                            } else if (this.props.nombrePadre === 'PurchaseOrder') {
                                if (this.props.State === '' || this.props.State === '1') {
                                    inputvalue = Products[index3].quantityOrdered
                                } else {

                                    inputvalue = Products[index3].quantityReceived ? Products[index3].quantityReceived : 0
                                }

                            } else if (this.props.nombrePadre === 'PurchaseReturn') {

                            }
                        }
                        let description = item.completeDesc && item.completeDesc !== "null" ? item.completeDesc : item.abbreviatedDesc
                        allProducts.push(new Item(item.itemCode, description, item.upc, item.quantity, item.ShipWeight, item.StandardUnitCost, item.LastTotalUnitCost, item.UnitPrice, item.ProductLine, item.ProductLineDesc, item.upc2, item.caseupc, item.itemURLImage, inputvalue, item.IdCompany))
                    }
                }
                await this.setState({
                    Catalogo: allProducts,
                    ProductsInSearch: allProducts,
                    ProductLine: allProductLine,
                    ProductLineMostrados: allProductLine,

                })
                await this.ResetPagination()

            }
        } else {
            const Padre = this.props.Padre
            const Products = Padre.state[this.props.Array];
            const temporal = this.state.Catalogo

            for (const item of temporal) {
                const index = getIndexElement(Products, 'itemCode', item.itemCode)

                let inputvalue = 0
                if (index !== -1) {
                    if (this.props.nombrePadre === 'SalesOrder') {
                        inputvalue = Products[index].quantityOrdered
                    } else if (this.props.nombrePadre === 'Invoice') {
                        inputvalue = Products[index].quantityShipped
                    } else if (this.props.nombrePadre === 'ReturnPO') {
                        inputvalue = Products[index].QuantityReturned
                    } else if (this.props.nombrePadre === 'PurchaseOrder') {
                        if (this.props.State === '' || this.props.State === '1') {
                            inputvalue = Products[index].quantityOrdered
                        } else {

                            inputvalue = Products[index].quantityReceived ? Products[index].quantityReceived : 0
                        }

                    } else if (this.props.nombrePadre === 'PurchaseReturn') {

                    }

                }
                item.InputValue = inputvalue
            }


            this.setState({
                Catalogo: temporal,
                ProductsInSearch: temporal,
                ProductLineMostrados: this.state.ProductLine,

            })
            await this.ResetPagination()

        }

        await this.ModalLoading.current.showState(false)
    }

    async ResetPagination() {
        let totalPages = Math.round(this.state.ProductsInSearch.length / this.state.ProductsPerPage)
        let seeProds = this.state.ProductsInSearch.slice(0, 0 + this.state.ProductsPerPage)
        await this.setState({
            ProductosMostrados: seeProds,
            currentPage: 0,
            ProductsPerPage: 60,
            TotalPages: totalPages
        })
        await this.updateInputsCard()
    }

    async nextPage() {
        if ((this.state.currentPage + this.state.ProductsPerPage) <= this.state.ProductsInSearch.length) {
            await this.setState({ currentPage: this.state.currentPage + this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async prevPage() {
        if (this.state.currentPage > 0) {
            await this.setState({ currentPage: this.state.currentPage - this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async updateSeeProducts() {
        let seeProds = this.state.ProductsInSearch.slice(this.state.currentPage, this.state.currentPage + this.state.ProductsPerPage)


        await this.setState({
            ProductosMostrados: seeProds,
        })
        await this.updateInputsCard()
    }

    updateInputsCard() {
        for (const item of this.state.ProductosMostrados) {
            if (document.getElementById('QuantityAdded_' + item.itemCode)) {
                document.getElementById('QuantityAdded_' + item.itemCode).value = item.InputValue
            }
        }
    }

    backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    selectItem(item){
        const inventoryProduct = this.props.Padre.state.Product;
        const commonKeys = Object.keys(inventoryProduct).filter(key => item.hasOwnProperty(key));

        // Crear un nuevo objeto con las claves comunes
        const commonObj = commonKeys.reduce((acc, key) => {
        acc[key] = item[key];
        return acc;
        }, {});
        this.props.Padre.state.Product = { ...commonObj, quantity: item.quantity}
        this.handleModalClose()
    }



    render() {

        return (
            <React.Fragment>
                <LoadingWindow ref={this.ModalLoading} />
                <div className="d-grid gap-2">
                    <button hidden={this.props.hidden} id={this.props.id} disabled={this.props.disabled} type="button" onClick={() => this.getProductsWithProductLine(false)} className="btn purpleButton btn-lg"><BsJournals /> {this.props.titleButton}</button>
                </div>

                <ModalOrders title={"Inventory Catalogue"} show={this.state.showModal} close={() => this.handleModalClose()}>

                    <div className='row'>

                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="canvasCategory" aria-labelledby="canvasCategorysDet">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasExampleLabel">Product Lines</h5>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body row">
                                <div className='col-sm-12 FixedTitle'>
                                    <div className='row'>
                                        <div className='InputTextBolder PrincipalHead col-sm-12 text-center '>

                                            <div className="input-group input-group-lg flex-nowrap pt-2">
                                                <input className='form-control w-75 display-inline' placeholder='Search by Product Line...' id={'productLineSearch' + this.props.nombrePadre} onKeyUp={this.SearchLineProduct} autoComplete={"off"} />
                                                <span className="input-group-addon">
                                                    <button className='btn greenButton btn-lg' onClick={() => this.getProductsWithProductLine(true)}><BsArrowRepeat /></button>
                                                </span>
                                            </div>
                                        </div>
                                        {this.state.ProductLineMostrados.map((element, pL) => (
                                            <div data-bs-dismiss="offcanvas" key={pL} onClick={() => this.SelectProductLine(element)} className={'col-sm-12 InputTextBolder2 ' + (element.ProductLineName === this.state.SelectedProdLine ? 'backgroundSky2' : '')}>
                                                <p>{element.ProductLineName}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12  FixedTitle2 '>
                            <div className='row'>
                                <div className='col-12 PrincipalHead2'>
                                    <div className="input-group input-group-lg flex-nowrap pt-2">
                                        <span className="input-group-addon">
                                            <button className="btn blueButton btn-lg" type="button" data-bs-toggle="offcanvas" data-bs-target="#canvasCategory" aria-controls="canvasCategorys"><BsList /></button>
                                        </span>
                                        <input className='form-control w-75 display-inline' placeholder='Search by ItemCode, Description...' id={'catalogo_busqueda' + this.props.nombrePadre} onKeyUp={this.buscarProductos} autoComplete={"off"} />
                                    </div>
                                </div>

                                {

                                    this.state.ProductosMostrados.map((producto, i) => (
                                        <div key={i} className='col-sm-12 col-md-6 col-lg-4' data-bs-dismiss="modal" onClick={() => this.selectItem(producto)}>
                                            <ItemCardNoPrices State={this.props.State} PriceLevel={this.props.PriceLevel} Padre={this.props.Padre} Array={this.props.Array} nombrePadre={this.props.nombrePadre} objectProd={producto} itemURLImage={producto.itemURLImage} ItemCode={producto.itemCode} Description={producto.abbreviatedDesc} OnHand={producto.quantity} UPC={producto.upc} LastTotalUnitCost={producto.LastTotalUnitCost} UnitPrice={producto.UnitPrice} needPrice={this.props.needPrice} needCost={this.props.needCost} />
                                        </div>

                                    ))
                                }

                            </div>
                            <div className='row'>
                                <div className='col-sm-12 text-center'>
                                    <div className="btn-group btn-group-lg pt-4" role="group" aria-label="pagination">
                                        <button type="button" onClick={() => this.prevPage()} className="btn blueButton">Prev</button>
                                        <button type="button" onClick={() => this.nextPage()} className="btn blueButton">Next</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </ModalOrders>
            </React.Fragment>
        )
    }
}