import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { pricesFormat } from '../../functions/pricesFormat';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineSearch } from 'react-icons/ai'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";

import { NumberFormat } from '../../functions/generalFunctions';


import { getValueCookie } from '../../services/cookieService';


export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title: '',
        showModal1: false,
        Detail: [],
        Total: 0,
        Data: [],
        filterText1: '',
        useProps: true,

        pageNumers: [],
        currentPage: 1,
        ProductsPerPage: 70,
        lastIndex: 70,
        firstIndex: 0,
        Order:true,
        DataFilter:[]
    }
    clearStates() {
        this.setState({
            Detail: [],
            Title: '',
            Total: 0
        })
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearStates()
    }

    getDetail(detail, title, total) {
        this.setState({ Detail: detail.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt), Title: title, Total: total })
        this.handleModalOpen("showModal1")
    }

    addMonth() {
        if (getValueCookie('TypeUser') === '1') {
            const date = this.props.Date
            const firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().substring(0, 10);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0).toISOString().substring(0, 10);

            this.props.Father.changeMonth({ IdCompany: 3, Start: firstDay, End: lastDay }, (this.props.Date.setMonth(this.props.Date.getMonth() + 1)))
        }

    }
    restMonth() {
        if (getValueCookie('TypeUser') === '1') {
            const date = this.props.Date
            const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString().substring(0, 10);
            const lastDay = new Date(date.getFullYear(), date.getMonth(), 0).toISOString().substring(0, 10);

            this.props.Father.changeMonth({ IdCompany: 3, Start: firstDay, End: lastDay }, (this.props.Date.setMonth(this.props.Date.getMonth() - 1)))
        }
    }


    componentDidMount() {
        this.pagination();
        //console.log(this.props.data)
    }
    pagination() {
        const temp = []
        for (let i = 1; i <= Math.ceil(this.props.data.length / this.state.ProductsPerPage); i++) {
            temp.push(i)
        }
        this.setState({ pageNumers: temp })
    }

    handleFilterChange = (event) => {
        this.setState({
            filterText1: event.target.value.toLowerCase(), // Convertir el texto del filtro a minúsculas
        });
    };

    generatePageNumbers() {
        //console.log("generate pagenumber",this.props.pageNumers)
        const pageNumers = this.props.pageNumers
        const { currentPage } = this.state;
        const totalPages = pageNumers.length;

        if (totalPages <= 4) {
            return pageNumers;
        }

        if (currentPage <= 2) {
            return [...pageNumers.slice(0, 2), '...', totalPages];
        }

        if (currentPage >= totalPages - 1) {
            return [...pageNumers.slice(-4)];
        }

        return [currentPage - 1, currentPage, "...", totalPages];
    }

    async onPreviosPage() {
        await this.setState({ currentPage: this.state.currentPage - 1 ,useProps: true })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
    }

    async onNextPage() {
        await this.setState({ currentPage: this.state.currentPage + 1 ,useProps: true })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
    }

    async onSpecificPage(noPage) {
        await this.setState({ currentPage: noPage,useProps: true  })
        await this.setState({ lastIndex: this.state.currentPage * this.state.ProductsPerPage })
        await this.setState({ firstIndex: this.state.lastIndex - this.state.ProductsPerPage })
    }

    orderInfo(columName) {
        //console.log("colum:",columName)
        let order = this.state.Order
        //Revisar si el useProps es falso para utlizar el mismo estado
        const filtered = this.props.data.slice(this.state.firstIndex, this.state.lastIndex)
        const temporal = filtered.sort((a, b) => {
            if (order) {
                return a[columName] > b[columName] ? 1 : a[columName] < b[columName] ? -1 : 0;
            } else {
                return a[columName] < b[columName] ? 1 : a[columName] > b[columName] ? -1 : 0;
            }
        });
        console.log("temp",temporal)
        this.setState({ Data: temporal, Order: !order, useProps: false })
    }
    render() {
        const { filterText1, useProps, Data } = this.state;
        const filteredData = useProps ? this.props.data.slice(this.state.firstIndex, this.state.lastIndex) : Data;
        //console.log("data", filteredData)
        //console.log("useProps",useProps)
        const lowerCaseFilterText = filterText1.toLowerCase(); // Convertir el texto del filtro a minúsculas
        return (
            <>
                <div className='row'>
                    {/*  <div className='col-sm-12'>
                        <h5>{this.props.Title}</h5>
                    </div> */}
                    <div className='col-sm-12 pb-3 pt-1'>
                        <div className="input-group input-group-lg">
                            <span className="input-group-text"><AiOutlineSearch /></span>
                            <input type="text" autoComplete='off' className="form-control" placeholder='Search by ItemCode and Description' onChange={this.handleFilterChange} />
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='tableFixHeadSmall'>
                            <table className='table align-middle table-hover text-center'>
                                <thead className='table-dark thead'>
                                    <tr>
                                        <th className='bg-secondary' colSpan={9}>
                                            <div className='row'>
                                                <div className='col-2' onClick={() => this.restMonth()}><AiFillCaretLeft /></div>
                                                <div className='col-8'>{this.props.Month}</div>
                                                <div className='col-2' onClick={() => this.addMonth()}><AiFillCaretRight /></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th onClick={() => this.orderInfo("ItemCode")}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("Description")}><div className='row'><div className='col-12'>Description</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("TotalOnHand")}><div className='row'><div className='col-12'>On Hand</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("TotalQuantityShipped")}><div className='row'><div className='col-12'>Total Quantity</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th></th>
                                        <th onClick={() => this.orderInfo("TotalInv")}><div className='row'><div className='col-12'>Total Inventory</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th></th>
                                        <th onClick={() => this.orderInfo("TotalSales")}><div className='row'><div className='col-12'>Total Sales</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                        <th onClick={() => this.orderInfo("Rotation")}><div className='row'><div className='col-12'>Rotation</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {
                                        filteredData.filter(
                                            (element) =>
                                                element.ItemCode.toLowerCase().includes(lowerCaseFilterText) ||
                                                element.Description.toLowerCase().includes(lowerCaseFilterText)
                                        ).map((element, e) => (
                                            <tr /* onClick={() => this.getDetail(element.Detail, element.VendorName, element.Total)} */ key={e}>
                                                <td>{element.ItemCode}</td>
                                                <td className='text-start' >{element.Description}</td>
                                                <td>{element.TotalOnHand}</td>
                                                <td>{element.TotalQuantityShipped}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end' >{NumberFormat(pricesFormat(element.TotalInv))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(element.TotalSales))}</td>
                                                <td className=''>{pricesFormat(element.Rotation)}</td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                                {/* <tfoot className='tfoot bg-light'>
                                    <tr className=''>
                                        <td className='text-center '>TOTAL</td>
                                        <td className=' text-end'></td>
                                        <td className=' text-end'>$</td>
                                        <td className=' text-end'>{NumberFormat(pricesFormat(this.props.data.Total))}</td>
                                        <td className=' text-center'>{"100%"}</td>
                                    </tr>
                                </tfoot> */}
                            </table>
                        </div>

                        <div className="d-flex justify-content-center">
                            <nav aria-label="...">
                                <ul className="pagination ">
                                    <li className={`page-item${this.state.currentPage === 1 ? ' disabled' : ''}`}>
                                        <button className="page-link" onClick={() => this.onPreviosPage()}>{"<<"}</button>
                                    </li>

                                    {this.generatePageNumbers().map((noPage, index) => (
                                        <li key={index} className={`page-item ${noPage === '...' ? 'disabled' : ''} ${noPage === this.state.currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => noPage !== '...' && this.onSpecificPage(noPage)}>
                                                {noPage}
                                            </button>
                                        </li>
                                    ))}

                                    <li className={`page-item${this.state.currentPage === this.props.pageNumers.length ? ' disabled' : ''}`}>
                                        <button className="page-link" onClick={() => this.onNextPage()}>{">>"}</button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
