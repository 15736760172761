
import React, { Component } from "react";
import "../../css/general-style.css"
import LoadingWindow from '../../components/LoadingWindow';
import { BsFillFileEarmarkMedicalFill } from "react-icons/bs";
import ModalOrders from "../ModalComponent";
import { ActualDate, DateFormatMAS90, getDateFromReports } from "../../functions/dateFormat";
import { getInformationWithData } from "../../services/CABE";
import { getValueCookie } from "../../services/cookieService";
import { intervalTimeInDays, NumberFormat } from "../../functions/generalFunctions";
import { pricesFormat } from "../../functions/pricesFormat";
import { getIndexElement } from "../../functions/searchInObject";



export default class InvoicePerCustomer extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }

    state = {
        showModal: false,
        Data: [],
        FilterData: [],
        SelectAllInvoices: false
    }


    handleModalOpen = () => {
        this.setState({ showModal: true })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }

    async getCustomerCurrentInvoices(CustomerNo) {
        await this.clearAll()
        await this.handleModalOpen()
        await this.ModalLoading.current.showState(true);
        const data = {
            CustomerNo: CustomerNo,
            username: getValueCookie('userName'),
            company: getValueCookie('Company'),
            idCompany: Number(getValueCookie('CompanyId'))
        }
        const result = await getInformationWithData('/customer/getBalance', data);

        const Father=this.props.Father
        const Detail=Father.state[this.props.FatherName][this.props.Detail]

        if (result.status.code === 1) {
            result.data.forEach(element => {
                element.PaymentDelayDays = intervalTimeInDays(DateFormatMAS90(element.InvoiceDueDate), ActualDate())
                element.InvoiceDate = DateFormatMAS90(element.InvoiceDate)
                element.InvoiceDueDate = DateFormatMAS90(element.InvoiceDueDate)
                element.CustomerName = CustomerNo.CustomerName
                element.Check = getIndexElement(Detail,'InvoiceNo',element.InvoiceNo)!==-1?true:false
                element.Amount=0.00
            });



            this.setState({ Data: result.data, FilterData: result.data })
        }
        this.updateChecks()
        this.ModalLoading.current.showState(false);
    }

    checkAllInv = async (e, actualStat) => {
        const temporal = this.state.Data
        const temporalF = this.state.FilterData
        if (!actualStat) {
            temporal.forEach(element => {
                element.Check = true
            })
            temporalF.forEach(element => {
                element.Check = true
            })
            const Father=this.props.Father
            const FatherTemporal=Father.state[this.props.FatherName]
            FatherTemporal[this.props.Detail]=temporal
            Father.setState({[this.props.FatherName]:FatherTemporal})
            Father.AsignAutomaticAumount()
        } else {
            temporal.forEach(element => {
                element.Check = false
            })
            temporalF.forEach(element => {
                element.Check = false
            })
            const Father=this.props.Father
            const FatherTemporal=Father.state[this.props.FatherName]
            FatherTemporal[this.props.Detail]=[]
            Father.setState({[this.props.FatherName]:FatherTemporal})
            Father.AsignAutomaticAumount()
        }
        await this.setState({ SelectAllInvoices: !actualStat, Data: temporal, FilterData: temporalF })
    }

    checkSpecificInvoice = async (e, actualStat, InvoiceNo) => {
        this.specificSelectInvoice(!actualStat, InvoiceNo)
        this.updateChecks()
    }

    async specificSelectInvoice(stat, InvoiceNo) {
        const index1 = getIndexElement(this.state.Data, 'InvoiceNo', InvoiceNo)
        const index2 = getIndexElement(this.state.FilterData, 'InvoiceNo', InvoiceNo)

        if (index1 !== -1 && index2 !== -1) {
            const temporal = this.state.Data
            const temporalF = this.state.FilterData
            temporal[index1].Check = stat
            temporalF[index2].Check = stat
            
            const Father=this.props.Father
            let FatherTemporal=Father.state[this.props.FatherName]
            
            if(stat){
                FatherTemporal[this.props.Detail].push(temporal[index1])
            }else{
                let tDetail=FatherTemporal[this.props.Detail]
                const index=getIndexElement(tDetail,'InvoiceNo',InvoiceNo)
                if(index!==-1){    
                    tDetail.splice(index,1)
                    FatherTemporal[this.props.Detail]=tDetail
                }
                
            }
            
            await Father.setState({[this.props.FatherName]:FatherTemporal})
            await this.setState({ Data: temporal, FilterData: temporalF })
            Father.AsignAutomaticAumount()
        }
    }

    updateChecks() {
        let all = true
        for (const inv of this.state.Data) {
            if (!inv.Check) {
                all = false
                break
            }
        }

        this.setState({ SelectAllInvoices: all })
    }

    clearAll() {
        this.setState({
            Data: [],
            FilterData: [],
            SelectAllInvoices: false
        })
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    searchInv = e => {
        const searchValue = e.target.value
        let Filter = []
        if (searchValue === '') {
            Filter = this.state.Data
        } else {
            Filter = this.state.Data.filter((detail) => {
                if (this.Contains(detail.InvoiceNo, searchValue)) {
                    return detail
                } else {
                    return null
                }
            })
        }
        this.setState({ FilterData: Filter })
    }


    render() {
        return (
            <>
                <button type="button" onClick={() => this.getCustomerCurrentInvoices(this.props.CustomerNo)} className={"btn btn-lg " + this.props.buttonProperties} disabled={this.props.disabled} hidden={this.props.hidden}>{this.props.buttonText} <BsFillFileEarmarkMedicalFill /></button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={"Current Invoices Date: " + getDateFromReports()} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10">
                            <div className="row">
                                <div className="col-6 pb-5">
                                    <div className="row">
                                        <div className='col-12 titleTotal'>
                                            <p>Customer No:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.props.CustomerNo}</p>
                                        </div>
                                    </div>
                                    <div disabled={this.state.Data.length === 0} className="row">
                                        <div className="col-xs-2">
                                            <div className="checkbox-inline">
                                                <label className="checkbox-inline TitleText">
                                                    <input onChange={(e) => this.checkAllInv(e, this.state.SelectAllInvoices)} className="form-check-input" type="checkbox" checked={this.state.SelectAllInvoices} value="" id="checkAllInvoicePerCustomer" />
                                                    Select All Invoices
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 pb-5">
                                    <div className="row">
                                        <div className='col-12 titleTotal'>
                                            <p>Customer Name:</p>
                                        </div>
                                        <div className='col-12 TitleText'>
                                            <p>{this.props.CustomerName}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pb-3">
                                    <input onChange={this.searchInv} className="form-control form-control-lg" placeholder='Search by InvoiceNo' id='searchInvoiceNoPerCust' type="text" />
                                </div>
                                <div className="col-12 tableFixHead">
                                    <table className='table align-middle'>
                                        <thead className='thead'>

                                            <tr className='bg-dark text-white'>
                                                <th className='text-center bg-dark'>Invoice No</th>
                                                <th className='text-center bg-dark'>Date</th>
                                                <th className='text-center bg-dark'>Due Date</th>
                                                <th className='text-center bg-dark'>Payment Delay Days</th>
                                                <th className='text-center bg-dark'>Customer Name</th>
                                                <th className='text-center bg-dark'>Sales Person</th>
                                                <th className='text-center bg-dark'></th>
                                                <th className='text-center bg-dark'>Balance</th>
                                                <th className='text-center bg-dark'>Select</th>
                                            </tr>

                                        </thead>
                                        <tbody className='tbody'>
                                            {
                                                this.state.FilterData.map((element, e) => (
                                                    <tr key={e}>

                                                        <td className='text-start'>{element.InvoiceNo}</td>
                                                        <td className='text-center'>{element.InvoiceDate}</td>
                                                        <td className='text-center'>{element.InvoiceDueDate}</td>
                                                        <td className='text-center'>{element.PaymentDelayDays}</td>
                                                        <td className='text-center'>{this.props.CustomerName}</td>
                                                        <td className='text-center'>{element.SalespersonNo}</td>
                                                        <td>$</td>
                                                        <td className='text-end'>{NumberFormat(pricesFormat(element.Balance))}</td>
                                                        <td className='text-center'>
                                                            <div className="row">
                                                                <div className="col-xs-2">
                                                                    <div className="checkbox-inline">
                                                                        <input className="form-check-input" type="checkbox" checked={element.Check} onChange={(e) => this.checkSpecificInvoice(e, element.Check, element.InvoiceNo)} value="" id={element.InvoiceNo} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </ModalOrders>
            </>


        )
    }
}