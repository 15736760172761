import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import PieGraph from '../PieGraph';
import SmallModal from '../SmallModal';

import { DateFormatMAS90 } from '../../functions/dateFormat';
import { getValueCookie } from '../../services/cookieService';


export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title: '',
        showModal1: false,
        Detail: [],
        Total: 0,


    }
    clearStates() {
        this.setState({
            Detail: [],
            Title: '',
            Total: 0
        })
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearStates()
    }

    getDetail(detail, title, total) {
        this.setState({ Detail: detail.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt), Title: title, Total: total })
        this.handleModalOpen("showModal1")
    }

    addMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData((this.props.Date.setMonth(this.props.Date.getMonth() + 1)))
        }

    }
    restMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData((this.props.Date.setMonth(this.props.Date.getMonth() - 1)))
        }
    }

    render() {
        return (
            <>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 text-center my-3'>
                                <h5 className='card-title'>{this.props.Title}</h5>
                            </div>
                            <div className='col-sm-12'>
                                <div className='tableFixHeadSmall'>
                                    <table className='table align-middle table-hover text-center'>
                                        <thead className='table-dark thead'>
                                            <tr className='bg-secondary'>
                                                <th colSpan={5}>
                                                    <div className='row'>
                                                        <div className='col-2' onClick={() => this.restMonth()}><AiFillCaretLeft /></div>
                                                        <div className='col-8'>{this.props.Month}</div>
                                                        <div className='col-2' onClick={() => this.addMonth()}><AiFillCaretRight /></div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Vendor No</th>
                                                <th>Vendor Name</th>
                                                <th></th>
                                                <th>Total</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody bg-light'>
                                            {
                                                this.props.data.Detail.map((element, e) => (
                                                    <tr onClick={() => this.getDetail(element.Detail, element.VendorName, element.Total)} key={e}>
                                                        <td>{element.VendorNo}</td>
                                                        <td className=' text-start'>{element.VendorName}</td>
                                                        <td className=' text-end'>$</td>
                                                        <td className=' text-end'>{NumberFormat(pricesFormat(element.Total))}</td>
                                                        <td>{(pricesFormat(element.Percentage)) + "%"}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot className='tfoot'>
                                            <tr>
                                                <td>TOTAL</td>
                                                <td className=' text-end'></td>
                                                <td className=' text-end'>$</td>
                                                <td className=' text-end'>{NumberFormat(pricesFormat(this.props.data.Total))}</td>
                                                <td>{"100%"}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className='col-sm-12 pb-2 text-end'>
                                <p className='InputTextBolderSimple text-muted'>**The total sales does not include late invoices, spoilage, discounts and credit notes</p>
                            </div>
                            <div className='col-sm-2 pt-3'></div>
                            <div className='col-sm-8 pt-3'>
                                <PieGraph options={this.props.data.Options} data={this.props.data.DataSet} />
                            </div>
                            <div className='col-sm-2 pt-3'></div>
                        </div>
                    </div>
                </div>
                <SmallModal centered={true} size={'xl'} title={'Purchases: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1' />
                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle table-hover text-center'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Purchase Order No</th>
                                            <th>Receipt Date</th>
                                            <th>Vendor No</th>
                                            <th>Vendor Name</th>
                                            <th>Order Date</th>
                                            <th>Warehouse</th>
                                            <th>Comment</th>
                                            <th>Invoice Date</th>
                                            <th></th>
                                            <th>Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Detail.map((order, o) => (
                                            <tr key={o}>
                                                <td>{order.PurchaseOrderNo}</td>
                                                <td>{DateFormatMAS90(order.ReceiptDate)}</td>
                                                <td>{order.VendorNo}</td>
                                                <td className='text-left'>{order.PurchaseName}</td>
                                                <td>{DateFormatMAS90(order.OrderDate)}</td>
                                                <td>{order.WarehouseCode}</td>
                                                <td className='text-left'>{order.Comment}</td>
                                                <td>{DateFormatMAS90(order.InvoiceDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableAmt))}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className='table-dark'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='textTableSize'>Totals</td>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize text-end'>$</td>
                                            <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>
                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>

            </>
        )
    }
}
