import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import PieGraph from '../PieGraph';
import SmallModal from '../SmallModal';
import { getValueCookie } from '../../services/cookieService';


export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {
        Title: '',
        showModal1: false,
        Detail: [],
        Total: 0

    }
    clearStates() {
        this.setState({
            Detail: [],
            Title: '',
            Total: 0
        })
    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
        this.clearStates()
    }
    getTotals() {
        let total = 0
        for (const row of this.state.Detail) {
            total += row.NonTaxableSalesAmt
        }
        this.setState({ Total: total })
    }

    async getDetail(detail, title) {
        await this.setState({ Detail: detail.sort((a, b) => b.NonTaxableSalesAmt - a.NonTaxableSalesAmt), Title: title })
        await this.getTotals()
        await this.handleModalOpen("showModal1")
    }
    addMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData(this.props.SalesPerson, (this.props.Date.setMonth(this.props.Date.getMonth() + 1)))
        }

    }
    restMonth() {
        if (getValueCookie('TypeUser') === '1') {
            this.props.Father.changeAllMonthData(this.props.SalesPerson, (this.props.Date.setMonth(this.props.Date.getMonth() - 1)))
        }
    }


    render() {
        return (
            <>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 text-center my-3'>
                                <h5 className='card-title'>{this.props.Title}</h5>
                            </div>
                            <div className='col-sm-12 pb-2 backgroundWhite'>
                                <div className='pb-3' />
                                {getValueCookie('TypeUser') !== '1' ?
                                    <div className='tableFixHeadSmall'>
                                        <table className='table align-middle text-center table-hover'>
                                            <thead className='thead'>
                                                <tr className='text-light'>
                                                    <th className='bg-secondary' colSpan={7}>{this.props.Month}</th>
                                                </tr>
                                                <tr className='table-dark'>
                                                    <th>Route Type</th>
                                                    <th></th>
                                                    <th>Total Sales </th>
                                                    <th>Percentage</th>
                                                    <th></th>
                                                    <th>{"Goal"}</th>
                                                    <th>Complete Goal Percentage</th>
                                                    <th hidden className='bg-dark '>Total of Sales</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.props.data.Information.map((element, e) => (
                                                        <tr onClick={() => this.getDetail(element.Detail, element.Name)} key={e} className={element.Color}>
                                                            <td>{element.Name}</td>
                                                            {
                                                                element.Data.map((amount, a) => (
                                                                    <React.Fragment key={a + 585 + 5}>
                                                                        <td className=' text-end'>$</td>
                                                                        <td className=' text-end'>{NumberFormat(pricesFormat(amount.Total))}</td>
                                                                        <td>{(pricesFormat(amount.Percentage)) + "%"}</td>
                                                                        <td className=' text-end'>$</td>
                                                                        <td className=' text-end'>{NumberFormat(pricesFormat(amount.TotalMeta))}</td>
                                                                        <td>{(pricesFormat(amount.PercentageComplete)) + "%"}</td>

                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                            <td hidden >{element.Detail.length}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot className='tfoot'>
                                                <tr className=''>
                                                    <td className='text-center '>TOTAL</td>
                                                    <React.Fragment>
                                                        {this.props.data.Months.map((month, m) => (
                                                            <React.Fragment key={m}>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(month.Total))}</td>
                                                                <td className=' text-center'>100%</td>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(month.TotalMeta))}</td>
                                                                <td className=' text-center'>{month.TotalMeta > 0 ? NumberFormat(pricesFormat((month.Total / month.TotalMeta) * 100)) + "%" : "0.00%"}</td>

                                                            </React.Fragment>
                                                        ))}
                                                    </React.Fragment>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    :
                                    <div className='tableFixHeadSmall'>
                                        <table className='table align-middle text-center table-hover'>
                                            <thead className='thead'>
                                                {
                                                    this.props.noMonths === false ?
                                                        <tr>
                                                            <th className='table-secondary' colSpan={7}>
                                                                <div className='row'>
                                                                    <div className='col-2' onClick={() => this.restMonth()}><AiFillCaretLeft /></div>
                                                                    <div className='col-8'>{this.props.Month}</div>
                                                                    <div className='col-2' onClick={() => this.addMonth()}><AiFillCaretRight /></div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    : <></>
                                                }
                                                <tr className='table-dark'>
                                                    <th>Route Type</th>
                                                    <th></th>
                                                    <th>Total Sales </th>
                                                    <th>Percentage</th>
                                                    <th></th>
                                                    <th>{"Goal"}</th>
                                                    <th>Complete Goal Percentage</th>
                                                    <th hidden>Total of Customer</th>
                                                </tr>
                                            </thead>
                                            <tbody className='tbody'>
                                                {
                                                    this.props.data.Information.map((element, e) => (
                                                        <tr onClick={() => this.getDetail(element.Detail, element.Name)} key={e} className={element.Color}>
                                                            <td>{element.Name}</td>
                                                            {
                                                                element.Data.map((amount, a) => (
                                                                    <React.Fragment key={a + 585 + 5}>
                                                                        <td className=' text-end'>$</td>
                                                                        <td className=' text-end'>{NumberFormat(pricesFormat(amount.Total))}</td>
                                                                        <td>{(pricesFormat(amount.Percentage)) + "%"}</td>
                                                                        <td className=' text-end'>$</td>
                                                                        <td className=' text-end'>{NumberFormat(pricesFormat(amount.TotalMeta))}</td>
                                                                        <td>{(pricesFormat(amount.PercentageComplete)) + "%"}</td>

                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                            <td hidden className='text-start'>{element.Detail.length}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot className='tfoot'>
                                                <tr className='table-dark'>
                                                    <td>TOTAL</td>
                                                    <React.Fragment>
                                                        {this.props.data.Months.map((month, m) => (
                                                            <React.Fragment key={m}>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(month.Total))}</td>
                                                                <td >100%</td>
                                                                <td className=' text-end'>$</td>
                                                                <td className=' text-end'>{NumberFormat(pricesFormat(month.TotalMeta))}</td>
                                                                <td >{month.TotalMeta > 0 ? NumberFormat(pricesFormat((month.Total / month.TotalMeta) * 100)) + "%" : "0.00%"}</td>

                                                            </React.Fragment>
                                                        ))}
                                                    </React.Fragment>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                }
                            </div>
                            <div className='col-sm-12 pb-2 backgroundWhite text-end' hidden={this.props.noGraph}>
                                <p className='InputTextBolderSimple text-muted'>**The total sales does not include late invoices, spoilage, discounts and credit notes</p>
                            </div>
                            {

                                this.props.data.Months.map((element, e) => (
                                    <React.Fragment key={e}>
                                        <div className='col-sm-2 pt-3 backgroundWhite'></div>
                                        <div className='col-sm-8 pt-3 backgroundWhite'  hidden={this.props.noGraph}>
                                            <PieGraph options={element.Options} data={element.DataSet}/>
                                        </div>
                                        <div className='col-sm-2 pt-3 backgroundWhite'></div>
                                    </React.Fragment>
                                ))
                            }
                        </div>

                    </div>
                </div>
                <SmallModal centered={true} size={'xl'} title={'Sales: ' + this.state.Title} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)} hidden={this.props.noGraph}>>
                    <div className='row'>
                        <div className='col-1' />
                        <div className='col-12'>
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle text-center table-hover'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Invoice No</th>
                                            <th>Invoice Type</th>
                                            <th>Customer No</th>
                                            <th>Customer Name</th>
                                            <th>Route</th>
                                            <th></th>
                                            <th>Total</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Detail.map((order, o) => (
                                            <tr key={o}>
                                                <td>{order.InvoiceNo}</td>
                                                <td>{order.InvoiceType}</td>
                                                <td>{order.CustomerNo}</td>
                                                <td className='text-left'>{order.BillToName}</td>
                                                <td className='text-left'>{order.UDF_RUTA}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.NonTaxableSalesAmt))}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot className='tfoot'>
                                        <tr className='table-dark'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='textTableSize'>Totals</td>
                                            <td className='textTableSize'></td>
                                            <td className='textTableSize text-end'>$</td>
                                            <td className='textTableSize text-end'>{NumberFormat(pricesFormat(this.state.Total))}</td>

                                        </tr>
                                    </tfoot>

                                </table>
                            </div>
                        </div>
                        <div className='col-1' />
                    </div>
                </SmallModal>

            </>
        )
    }
}
