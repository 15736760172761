import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';



export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {

    }


    render() {
        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='row '>
                        <div className='col-sm-12 text-center'>
                            <h5 className='card-title'>{this.props.Title}</h5>
                        </div>
                        <div className='col-sm-12 backgroundWhite'>

                            <div className='pb-3' />
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle text-center table-hover'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th >Customer No</th>
                                            <th >Customer Name</th>
                                            <th ></th>
                                            <th>Credit Limit</th>
                                            <th ></th>
                                            <th >Current Balance</th>
                                            <th ></th>
                                            <th >Excess</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.data.map((order, o) => (
                                            <tr key={o}>
                                                <td>{order.CustomerNo}</td>
                                                <td className='text-start'>{order.CustomerName}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.CreditLimit))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.CurrentBalance))}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.Exceso))}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}
