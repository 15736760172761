import React, { Component } from 'react'
import Select from 'react-select';
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import "../../../css/inventory-style.css"
import { getValueCookie } from '../../../services/cookieService';
import { getInformationWithData, getInformationWithDataGlobalSales } from '../../../services/CABE';
import { login, getUsersByType } from '../../../services/apiv2';
import { DeleteCharactersNotAccepted, ItemDesc, MonthInLetters, NumberFormat, OrderArray } from '../../../functions/generalFunctions';
import { getCurrentBalance, getCustomerExcess, getCustomerHistory, getSalesByCustomer, getSalesOrdersPerUser, getSalesPerMonth, getSalesPerRoute, getSalesPerRouteAllYear, getSalesPerSalesPerson, getShorts } from "../../InitialDashBoard/Functions";
import { getIndexElement } from '../../../functions/searchInObject'
import TableofCurrentBalanceView from "../../../components/TableofCurrentBalance/TableofCurrentBalanceView";
import TableSalesTypeView from "../../../components/TableofSalesType/TableSalesTypeView";
import { pricesFormat } from '../../../functions/pricesFormat';
import { v4 as uuidv4 } from 'uuid';
import LoadingWindow from '../../../components/LoadingWindow';
import ExcelDocument from '../../../components/ExcelDocument';
import { getDataSet, getDataSetSales, getDataSetSales2 } from '../../../functions/generateDataSetExcel';
import ModalOrders from '../../../components/ModalComponent';
import { DateFormatMAS90, getDateFromReports, OrderArrayByDateGenericLines } from '../../../functions/dateFormat';
import { confirmCloseAlert } from '../../../functions/alerts';



export default class SalesTracker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            selectOptions: [],
            sellerList: [],
            loadedSellerList: [],
            activeSP: null
        }

    }

    // fetchSellers = async () => {
    //     // Get the Sellers list
    //     const company = getValueCookie('CompanyId');
    //     const userType = [4,7]
    //     let allData = []

    //     for (const type of userType) {
    //         const sellerList = await getUsersByType(company, type)
    //         allData.push(...sellerList)
    //     }

    //     allData = allData.filter(item => item.status === 1);

    //     const cleanedSellerList = allData.map(item => ({
    //         value: item.sales_person,
    //         label: this.formatUsername(item.username)
    //     }))

    //     this.setState({selectOptions: cleanedSellerList})
    // }

    // componentDidMount(){
    //     if(getValueCookie('CompanyId'))
    //         this.fetchSellers();
    // }

    formatUsername = (username) => {

        if(username === '0010'){
            return 'Corporativo';
        }
        const replaced = username.replace(".", " ");

        const capitalized = replaced
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

        return capitalized
    }

    getSpecificInfo = async (SalesPerson) => {
        const actualSellerList = this.state.sellerList;
        const searchSalesPerson = actualSellerList.findIndex(item => item.SalesPerson === SalesPerson)

        if (searchSalesPerson === -1) {
            const SalesPerRoute = await getSalesPerRoute(SalesPerson)
            const PendingSalesOrder = await getSalesOrdersPerUser(SalesPerson)
            const CustomerExcess = await getCustomerExcess(SalesPerson)
            const CurrentBalance = await getCurrentBalance(SalesPerson)
            const CustomerHistory = await getCustomerHistory(SalesPerson)
            const SalesByCustomer = await getSalesByCustomer(SalesPerson)
            const SalesPerMonth = await getSalesPerMonth(SalesPerson)
            const SalesPerSalesPersons = await getSalesPerSalesPerson(SalesPerson)
            const Shorts = await getShorts(SalesPerson)
            let RouteYearSales = []
            if (SalesPerson === 'TOT') {
                RouteYearSales = await getSalesPerRouteAllYear()
            }
            let TotalCustomer = {
                Normal: 0,
                Frequent: 0,
                Waring: 0,
                Risk: 0,
                Total: 0
            }
            let TotalPendingInvoices = {
                TotalDueInv: 0,
                Range1: 0,
                Range2: 0,
                Range3: 0,
                TotalR1: 0,
                TotalR2: 0,
                TotalR3: 0
            }
            let TotSales = {
                NoInvoices: 0,
                Total: 0
            }
            let NotStarted = 0
            let InProcess = 0
            let Finished = 0
            for (const Invoice of CurrentBalance) {
                if (Invoice.Balance > 0) {
                    TotalPendingInvoices.TotalDueInv += Invoice.Balance
                }
                if (Number(Invoice.DaysDlq) <= 14) {
                    TotalPendingInvoices.Range1 += 1
                    TotalPendingInvoices.TotalR1 += Invoice.Balance > 0 ? Invoice.Balance : 0
                } else if (Number(Invoice.DaysDlq) >= 15 && Number(Invoice.DaysDlq) <= 29) {
                    TotalPendingInvoices.Range2 += 1
                    TotalPendingInvoices.TotalR2 += Invoice.Balance > 0 ? Invoice.Balance : 0
                } else {
                    TotalPendingInvoices.Range3 += 1
                    TotalPendingInvoices.TotalR3 += Invoice.Balance > 0 ? Invoice.Balance : 0
                }
            }
            for (const SalesOrder of PendingSalesOrder) {

                SalesOrder.Status = SalesOrder.Status ? SalesOrder.Status : 0
                switch (SalesOrder.Status) {
                    case 0:
                        NotStarted += SalesOrder.NonTaxableAmt
                        break;
                    case 1:
                        InProcess += SalesOrder.NonTaxableAmt
                        break;
                    case 2:
                        Finished += SalesOrder.NonTaxableAmt
                        break;
                    default:
                        break;
                }
            }
            for (const Customer of CustomerHistory) {
                if (Number(Customer.NumberDays) <= 21) {
                    TotalCustomer.Frequent += 1
                } else if (Number(Customer.NumberDays) > 21 && Number(Customer.NumberDays) <= 45) {
                    TotalCustomer.Normal += 1
                } else if (Number(Customer.NumberDays) > 45 && Number(Customer.NumberDays) <= 90) {
                    TotalCustomer.Waring += 1
                } else {
                    TotalCustomer.Risk += 1
                }
                TotalCustomer.Total += 1
            }
            for (const Sale of SalesPerMonth) {
                if (Sale.Week) {

                    TotSales.NoInvoices += Number(Sale.totalsales)
                    TotSales.Total += Number(Sale.total)
                }
            }

            let temporal = {
                Load: false,
                SalesPerRouteDate: new Date(),
                SalesPerSalesPersonDate: new Date(),
                SalesPerRoute: SalesPerRoute,
                SalesPerSalesPersons: SalesPerSalesPersons,
                CustomerHistory: CustomerHistory,
                PendingSalesOrders: PendingSalesOrder,
                CustomerExcess: CustomerExcess,
                CurrentBalance: CurrentBalance,
                SalesPerson: SalesPerson,
                SalesByCustomer: SalesByCustomer,
                SalesPerMonth: SalesPerMonth,
                Shorts: Shorts,
                RouteYearSales: RouteYearSales,
                Totals: {
                    TotalPendingInvoices: TotalPendingInvoices,
                    SalesOrder: {
                        NotStarted: NotStarted,
                        InProcess: InProcess,
                        Finished: Finished
                    },
                    Customer: TotalCustomer,
                    Sales: TotSales
                }
            }
            this.setState({ data: temporal });
            this.asignTotalsCalendar(SalesPerMonth, SalesPerson);
            const loadNewSeller = [...this.state.loadedSellerList, temporal];
            this.setState({loadedSellerList: loadNewSeller});
        } else {
            this.setState({data: searchSalesPerson})
        }
    }

    asignTotalsCalendar(Data, SalesPerson) {
      for (const week of Data) {
        if (week.Week) {
          let m = document.getElementById('quant_' + week.Week + "_" + SalesPerson)
          let n = document.getElementById('sales_' + week.Week + "_" + SalesPerson)
          if (m) {
            m.innerHTML = NumberFormat(Number(Number(week.totalsales).toFixed(0)))
          }
          if (n) {
            n.innerHTML = "$" + NumberFormat(Number(Number(week.total).toFixed(0)))    
          }
        }
      }
    }

    onChangeSelects = async (e, id) => {
        if (id === 'SelectSeller') {
            this.setState({ activeSP: e.value})
            this.getSpecificInfo(e.value)
        }
    }



    render() {
        const {selectOptions, activeSP, loadedSellerList} = this.state;
        const selectedOption = selectOptions.find(option => option.value === String(activeSP))

        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Sales by Route Report</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select a seller.</p>
                </div>
                <div className='row pb-2'>
                    <div className='col-12'>
                        <div className='row d-flex justify-content-center'>
                          <div className='col-sm-12 col-md-6'>
                            <label for="SelectSellerInput" className="form-label">Seller Name</label>
                            <Select value={selectedOption}  options={selectOptions} className='fs-4 text-start' placeholder="Select Seller" onChange={(e) => this.onChangeSelects(e, "SelectSeller")}/>
                          </div>
                          <div>
                            <div className='row d-flex justify-content-center mt-4'>
                              {loadedSellerList.length === 0 ?
                              <></>
                              : <>
                                    <div className='col-sm-12 col-md-6'>
                                        <TableofCurrentBalanceView Title={"Past Due Invoices"} Totals={this.state.data.Totals.TotalPendingInvoices} data={this.state.data.CurrentBalance} />
                                    </div>
                                    <div className='col-sm-12 col-md-6'>
                                        <TableSalesTypeView SalesPerson={this.state.data.SalesPerson} Father={this.state.data} Date={this.state.data.SalesPerRouteDate} Month={MonthInLetters(this.state.data.SalesPerRouteDate.getMonth() + 1) + " " + this.state.data.SalesPerRouteDate.getFullYear()} Title={"Sales by Distribution Channel"} data={this.state.data.SalesPerRoute} noMonths noGraph/>
                                    </div>
                                </>
                            }
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}