import React, { Component } from 'react'
import { getValueCookie } from '../../services/cookieService';
import { getInformationWithData } from '../../services/CABE';
import "../../css/inventory-style.css"
import "../../css/general-style.css"
import '../../css/table-responsive.css'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import LoadingWindow from '../../components/LoadingWindow';
import { confirmCloseAlert } from '../../functions/alerts';
import { NumberFormat, OrderArray } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { getDateFromReports } from '../../functions/dateFormat';
import ExcelDocument from '../../components/ExcelDocument';
import { getDataSet } from '../../functions/generateDataSetExcel';

export default class MOH extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {
        Body: [],
        BodyFilter: [],
        flag: true
    }

    async getMOH() {
        this.ModalLoading.current.showState(true);
        const start = document.getElementById('startMOHDate').value
        const end = document.getElementById('endMOHDate').value

        if (start !== '' && end !== '') {
            const data = {
                idCompany: Number(getValueCookie('CompanyId')),
                Start: start,
                End: end
            }

            const result = await getInformationWithData('/invoice/report/MOH', data)

            if (result.status.code === 1) {
                result.data.forEach(item => {
                    var InvCont = Number(item.InventarioContable)
                    var Sales = Number(item.TotalSales)
                    var res = 0

                    if (Sales !== 0 && InvCont && Sales) {
                        res = InvCont / Sales
                    }

                    item.MOH = res
                    item.Validation = res < 0.5 ? 'Scare' : (res > 1 ? 'Excess' : 'Ok')
                });

                this.setState({ Body: result.data, BodyFilter: result.data })
            } else {
                this.setState({ Body: [], BodyFilter: [] })
            }

        } else {
            this.ModalLoading.current.showState(false);
            confirmCloseAlert('incorrect', 'Two dates are required for the information search')
        }
        this.ModalLoading.current.showState(false);
    }



    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }

    OrderMOH(option) {
        var temporal = this.state.BodyFilter
        switch (option) {
            case "ItemCode":
                temporal = OrderArray(temporal, "ItemCode", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            case "ProductLineDesc":
                temporal = OrderArray(temporal, "ProductLineDesc", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            case "MOH":
                temporal = OrderArray(temporal, "MOH", this.state.flag)
                this.setState({ BodyFilter: temporal, flag: !this.state.flag })
                break;
            default:
                break;
        }
    }

    searchMOH = e => {

        let search = e.target.value;

        let Products = this.state.Body.filter((item) => {
            if (((
                this.contains(item.ItemCode, search) ||
                this.contains(item.ProductLineDesc, search) ||
                this.contains(item.ItemCodeDesc, search)
            ))
            ) {
                return item
            } else {
                return null
            }
        });
        this.setState({ BodyFilter: Products });
    }



    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Month On Hand Report</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select a start and end date.</p>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="startMOHDate">Start</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='startMOHDate' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4'>
                                <div className='row TitleText'>
                                    <div className='col-12'>
                                        <label className='form-label' for="endMOHDate">End</label>
                                        <div className="input-group input-group-lg pb-4" >
                                            <input className="form-control form-control-lg" id='endMOHDate' type="date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 InputTextBolder text-center d-flex align-items-center'>
                                <button onClick={() => this.getMOH()} type="button" className="btn blueButton btn-lg w-100"><AiOutlineReload /> Load Data</button>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-12 col-md-4 mx-auto mb-5">
                                <ExcelDocument hidden={this.state.Body.length === 0} archname={'MOH ' + getValueCookie('Company') + ' ' + getDateFromReports()} sheetname={'MOH ' + getValueCookie('Company')} data={getDataSet(this.state.Body,['Item Code','Product Line','Description','MOH','Validation'],['ItemCode','ProductLineDesc','ItemCodeDesc','MOH','Validation'])} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className="input-group input-group-lg pb-4" >
                                    <input hidden={this.state.Body.length === 0} type="text" autoComplete='off' className="form-control" id='searchMOHItem' placeholder='Search Item by ItemCode, Line Product, Description...' onChange={this.searchMOH} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 tableFixHead'>
                        <table hidden={this.state.Body.length === 0} className='table align-middl table-hover'>
                            <thead className='thead'>
                                <tr className='text-light text-center'>
                                    <th className='bg-dark' onClick={() => this.OrderMOH("ItemCode")}><div className='row'><div className='col-12'>Item Code</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th className='bg-dark' onClick={() => this.OrderMOH("ProductLineDesc")}><div className='row'><div className='col-12'>Product Line</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th className='bg-dark'>Description</th>
                                    <th className='bg-dark'>Inventory Value</th>
                                    <th className='bg-dark'>Month Sales</th>
                                    <th className='bg-dark' onClick={() => this.OrderMOH("MOH")}><div className='row'><div className='col-12'>MOH</div><div className='col-12'><BsFileArrowUpFill /><BsFileArrowDownFill /></div></div></th>
                                    <th className='bg-dark'>Validation</th>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                {this.state.BodyFilter.map((element, e) => (
                                    <tr key={e}>
                                        <td className='text-start'>{element.ItemCode}</td>
                                        <td className='text-start'>{element.ProductLineDesc}</td>
                                        <td className='text-start'>{element.ItemCodeDesc}</td>
                                        <td className='text-start'>{element.InventarioContable}</td>
                                        <td className='text-start'>{element.TotalSales}</td>
                                        <td className='text-center'>{NumberFormat(pricesFormat(element.MOH))}</td>
                                        <td className='text-center'>{element.Validation}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
