import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { DateFormatMAS90 } from '../../functions/dateFormat';
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import {FaCheckCircle, FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';



export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {

    }

    selectColor(number) {

        if (number >= 0 && number <= 14) {
            return "greenTableCell"
        } else if (number >= 15 && number <= 29) {
            return "yellowTableCell"
        } else if (number >= 3) {
            return "redTableCell"
        }
                
        return ""

}


    render() {
        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='row '>
                        <div className='col-sm-6'>
                            <h4>{this.props.Title}</h4>
                        </div>
                        <div className='col-sm-6 text-end text-danger'>
                            <h3><span className='badge bg-danger'>{"Total: $ " + NumberFormat(pricesFormat(this.props.Totals.TotalDueInv))}</span></h3>
                        </div>
                        <div className='col-sm-12 backgroundWhite'>
                            <div className='col-12'>
                                <table className="table text-center">
                                    <thead>
                                        <tr className='border-bottom'>
                                            <th className='text-center py-3 text-muted' colSpan={3}>Orders / Total Amount</th>
                                        </tr>
                                        <tr className='border-bottom'>
                                            <th className='greenHeaderTableText' scope="col"> <h5><FaCheckCircle className="me-1" />0 - 14 Days</h5></th>
                                            <th className='yellowHeaderTableText' scope="col"><h5><FaExclamationTriangle className="me-1" />15 - 29 Days</h5></th>
                                            <th className='redHeaderTableText' scope="col"><h5><FaTimesCircle className="me-1" />+ 30 Days</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-bottom'>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                    <span className='text-dark'>{NumberFormat(this.props.Totals.Range1)+" / $ "}{NumberFormat(pricesFormat(this.props.Totals.TotalR1))}</span>
                                                    <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.TotalR1/this.props.Totals.TotalDueInv)*100)} %</div>
                                                </div>
                                                </td>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                    <span className='text-dark'>{NumberFormat(this.props.Totals.Range2)+" / $ "}{NumberFormat(pricesFormat(this.props.Totals.TotalR2))}</span>
                                                    <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.TotalR2/this.props.Totals.TotalDueInv)*100)} %</div>
                                                </div>
                                                </td>
                                            <td className='p-3'>
                                                <div className='fw-bold fs-5'>
                                                    <span className='text-dark'>{NumberFormat(this.props.Totals.Range3)+" / $ "}{NumberFormat(pricesFormat(this.props.Totals.TotalR3))}</span>
                                                    <div className='fs-6 text-muted'>{pricesFormat((this.props.Totals.TotalR3/this.props.Totals.TotalDueInv)*100)} %</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='pb-3' />
                            <div className="tableFixHeadSmall ">
                                <table className='table align-middle table-hover text-center'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Invoice No</th>
                                            <th>Customer No</th>
                                            <th>Customer Name</th>
                                            <th>Invoice Due Date</th>
                                            <th>Days Dlq</th>
                                            <th></th>
                                            <th>Current Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.data.map((order, o) => (
                                            <tr className={this.selectColor(Number(order.DaysDlq))} key={o}>
                                                <td>{order.InvoiceNo}</td>
                                                <td>{order.CustomerNo}</td>
                                                <td className='text-start'>{order.CustomerName}</td>
                                                <td>{DateFormatMAS90(order.InvoiceDueDate)}</td>
                                                <td>{order.DaysDlq}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{NumberFormat(pricesFormat(order.Balance))}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}
