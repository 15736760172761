import React, { Component } from 'react'
import "../../css/general-style.css"
import '../../css/small-table-responsive.css'
import { NumberFormat } from '../../functions/generalFunctions';
import { pricesFormat } from '../../functions/pricesFormat';
import { FaCheckCircle, FaHourglassHalf, FaTimesCircle } from "react-icons/fa";




export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }
    state = {

    }

    print() {
        console.log(this.props.data.Months)
    }


    render() {
        return (
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-sm-12 text-center my-3'>
                            <h5 className='card-title'>{this.props.Title}</h5>
                        </div>
                        <div className='backgroundWhite col-sm-12 '>
                            <div className='col-12'>
                                <table className="table text-center">
                                    <thead className=''>
                                        <tr>
                                            <th className='redHeaderTableText' scope="col"><h5><FaTimesCircle className='me-1' />{"Not Started"}</h5></th>
                                            <th className='yellowHeaderTableText' scope="col"><h5><FaHourglassHalf className='me-1' />{"In Process"}</h5></th>
                                            <th className='greenHeaderTableText' scope="col"><h5><FaCheckCircle className='me-1' />{"Finished"}</h5></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-dark'><h5>{"$ " + NumberFormat(pricesFormat(this.props.Totals.NotStarted))}</h5></td>
                                            <td className='text-dark'><h5>{"$ " + NumberFormat(pricesFormat(this.props.Totals.InProcess))}</h5></td>
                                            <td className='text-dark'><h5>{"$ " + NumberFormat(pricesFormat(this.props.Totals.Finished))}</h5></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='pb-3' />
                            <div className="tableFixHeadSmall">
                                <table className='table align-middle text-center table-hover'>
                                    <thead className='table-dark'>
                                        <tr>
                                            <th>Order No</th>
                                            <th>Type</th>
                                            <th>Customer No</th>
                                            <th>Customer Name</th>
                                            <th>Date</th>
                                            <th>Days in System</th>
                                            <th>Picking Status</th>
                                            <th>Sales Person</th>
                                            <th>Have Invoice?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.data.map((order, o) => (
                                            <tr className={order.ColorRow} key={o}>
                                                <td className='text-start'>{order.SalesOrderNo}</td>
                                                <td className='text-start'>{order.TypeinLetters}</td>
                                                <td>{order.CustomerNo}</td>
                                                <td className='text-start'>{order.BillToName}</td>
                                                <td>{order.OrderDate}</td>
                                                <td>{order.DaysSystem}</td>
                                                <td className={order.StatusColor}>{order.StatusText}</td>
                                                <td>{order.SalespersonNo}</td>
                                                <td>{order.ExistInv}</td>

                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
