import React, { Component } from 'react'
import "../css/general-style.css"
import '../css/table-responsive.css'
import { AiOutlineCloseCircle, AiFillPrinter, AiFillCopy, AiOutlineDownload, AiOutlineCloudUpload } from "react-icons/ai"
import { FiPlusCircle, FiArchive, FiSave, FiUpload} from "react-icons/fi";
import Catalogue from '../components/Catalogue';
import { OrderPDF } from '../components/OrderPDF';

import Swal from "sweetalert2";
import { getValueCookie } from '../services/cookieService';
import { create_Delete_Update_Information, getInformationWithData } from '../services/CABE';
import { getIndexElement } from '../functions/searchInObject';
import { confirmCloseAlert } from '../functions/alerts';
import ModalOrders from '../components/ModalComponent';
import { formatInputDate, formatInputDateQuery, formatInputDateQuerytoInput, getDateFromReports } from '../functions/dateFormat';
import Select from 'react-select';
import LoadingWindow from '../components/LoadingWindow';
import { getPrintHeaderCompany } from '../functions/companyInformation';
import { pricesFormat } from '../functions/pricesFormat';
import { DeleteCharacter, ItemDesc, MobileDisp, NumberFormat } from '../functions/generalFunctions';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse'
import Catalogue2 from '../components/Catalogue2.0/Catalogue2.0';
import { DownloadOrderPDF } from '../components/DownloadOrderPDF';
export default class PurchaseOrder extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
    }
    state = {

        purchaseOrderHeader: {
            NoOrder: '',
            InvoiceNo: '',
            Carrier: '',
            orderDate: '',
            completionDate: '',
            realCompletionDate: '',
            creationDate: '',
            vendorNo: '',
            vendorName: '',
            comment: '',
            createBy: '',
            State: '',
            totalFreight: 0
        },
        companyPrintHeader: getPrintHeaderCompany(),
        products: [],
        badProductsCSV: [],

        totals: {
            finalquantityOrderedTotal: 0,
            finalquantityOrderedReceived: 0,
            finalTotalCost: 0
        },
        disableHeader: true,
        secureTransaction: false,
        modalPurchase: false,
        oldPurchaseOrderHeader: [],
        oldPurchaseOrderHeaderFilter: [],
        oldPurchaseOrderHeaderShow: [],
        vendorSelectInformation: [],
        selectOptions: [{ value: 'All', label: 'All' }],
        HistoryOptionSelected: 'All',
        acceptFile: false,
        currentPage: 0,
        ProductsPerPage: 60,
        TotalPages: 0
    }

    disableTransactions() {
        this.setState({ secureTransaction: true })
    }

    enableTransactions() {
        this.setState({ secureTransaction: false })
    }

    updateInputs() {
        let num = 0
        for (const item of this.state.products) {
            document.getElementById('quantityOrderPurchase_' + num).value = item.quantityOrdered
            document.getElementById('quantityReceivedPurchase_' + num).value = item.quantityReceived ? item.quantityReceived : 0
            document.getElementById('standarCostPurchase_' + num).value = pricesFormat(item.unitCost)
            document.getElementById('unitFreightPurchase_' + num).value = pricesFormat(item.totalFreight)
            num++
        }
    }


    onTarget = async (e, item) => {
        const temporal = this.state.products
        const ident = e.target.id.split('_')
        const index = temporal.indexOf(item)
        if (index !== -1) {
            if (ident[0] === "BINPurchase") {
                temporal[index].BIN = e.target.value
                this.setState({ products: temporal })
            } else if (ident[0] === "quantityOrderPurchase") {
                temporal[index].quantityOrdered = Number(e.target.value)
                if (this.state.purchaseOrderHeader.State === "1" || this.state.purchaseOrderHeader.State === '') {
                    temporal[index].totalCost = ((Number(e.target.value) * temporal[index].unitCost).toFixed(2))
                    temporal[index].totalCost = (Number(temporal[index].totalCost) + Number(temporal[index].totalFreight)).toFixed(2)
                }

                this.setState({ products: temporal })
                this.calculateTotals()
            } else if (ident[0] === "quantityReceivedPurchase") {
                temporal[index].quantityReceived = Number(e.target.value)
                if (this.state.purchaseOrderHeader.State !== "1") {
                    temporal[index].totalCost = ((Number(e.target.value) * temporal[index].unitCost).toFixed(2))
                    temporal[index].totalCost = (Number(temporal[index].totalCost) + Number(temporal[index].totalFreight)).toFixed(2)
                }

                this.setState({ products: temporal })
                this.calculateFreightPerItem()
            } else if (ident[0] === "DueDate") {
                console.log(temporal[index])
                let date = new Date(e.target.value)
                temporal[index].DueDate = new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
            } else if (ident[0] === "standarCostPurchase") {
                temporal[index].unitCost = Number(e.target.value)

                if (this.state.purchaseOrderHeader.State !== "1" && this.state.purchaseOrderHeader.State !== '') {
                    temporal[index].totalCost = ((Number(e.target.value) * temporal[index].quantityReceived).toFixed(2))
                    temporal[index].totalCost = (Number(temporal[index].totalCost) + Number(temporal[index].totalFreight)).toFixed(2)
                } else {

                    temporal[index].totalCost = ((Number(e.target.value) * temporal[index].quantityOrdered).toFixed(2))
                    temporal[index].totalCost = (Number(temporal[index].totalCost) + Number(temporal[index].totalFreight)).toFixed(2)
                }

                this.setState({ products: temporal })
                this.calculateTotals()
            } else if (ident[0] === "unitFreightPurchase") {
                temporal[index].totalFreight = Number(e.target.value)

                if (this.state.purchaseOrderHeader.State !== "1" && this.state.purchaseOrderHeader.State !== '') {
                    temporal[index].totalCost = ((Number(temporal[index].unitCost) * Number(temporal[index].quantityReceived)).toFixed(2))
                    temporal[index].totalCost = (Number(temporal[index].totalCost) + Number(temporal[index].totalFreight)).toFixed(2)
                } else {

                    temporal[index].totalCost = Number(temporal[index].unitCost) * Number(temporal[index].quantityOrdered)
                    temporal[index].totalCost = (Number(temporal[index].totalCost) + Number(temporal[index].totalFreight)).toFixed(2)
                }

                this.setState({ products: temporal })
                this.calculateTotals()
            }

        }

    }
    onTargerHeader = async (e) => {
        const varChange = e.target.id
        const temporal = this.state.purchaseOrderHeader
        if (varChange === "purchaseOrderNo") {
            await this.getPurchaseOrderHeader()
            const index = getIndexElement(this.state.oldPurchaseOrderHeader, "OrderNo", e.target.value)
            if (index === -1) {
                temporal.NoOrder = e.target.value
                this.setState({ purchaseOrderHeader: temporal })
            } else {
                confirmCloseAlert('incorrect', "The order number is already registered, select a different number or use the autogenerated one")
                await this.createNewPurchaseOrder()
            }
        } else if (varChange === "purchaseCarrier") {
            temporal.Carrier = e.target.value
            this.setState({ purchaseOrderHeader: temporal })
        } else if (varChange === "purchaseOrderDate") {
            temporal.orderDate = e.target.value
            this.setState({ purchaseOrderHeader: temporal })
        } else if (varChange === "purchaseRealCompletionDate") {
            temporal.realCompletionDate = e.target.value
            this.setState({ purchaseOrderHeader: temporal })
        } else if (varChange === "purchaseInvoiceNo") {
            temporal.InvoiceNo = e.target.value
            this.setState({ purchaseOrderHeader: temporal })
        } else if (varChange === "purchaseComment") {
            let comment = DeleteCharacter(e.target.value, '\n')
            temporal.comment = comment
            this.setState({ purchaseOrderHeader: temporal })
        } else if (varChange === "purchaseCompletionDate") {
            temporal.completionDate = e.target.value
            this.setState({ purchaseOrderHeader: temporal })
        } else if (varChange === "purchaseTotalFreight") {

            temporal.totalFreight = Number(e.target.value)
            this.setState({ purchaseOrderHeader: temporal })
            this.calculateFreightPerItem()
            //FUNCION QUE ACTUALICE TODOS LOS FREIGHT
        }



    }

    async createNewPurchaseOrder() {
        await this.clearDashBoardPurchaseOrder()
        await this.disableTransactions()

        const data = {
            type: 'PurchaseOrder',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)

        if (result.status.code === 1) {
            var orderNumber = "PO"
            for (let a = result.data[0].correlative.toString().length; a < 5; a++) {
                orderNumber += "0"
            }

            orderNumber += result.data[0].correlative.toString()

            const temporal = this.state.purchaseOrderHeader

            temporal.NoOrder = orderNumber
            temporal.createBy = getValueCookie('userName')
            this.setState({ purchaseOrderHeader: temporal, disableHeader: false })
            document.getElementById('purchaseOrderNo').value = this.state.purchaseOrderHeader.NoOrder
        }


        this.enableTransactions()
    }

    async getPurchaseOrderHeader() {
        const data = {
            idcompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/purchase/get/purchaseOrderHeader', data)

        if (result.status.code === 1) {

            let elements = [{ value: 'All', label: 'All' }]
            for (const purchase of result.data) {
                const index = getIndexElement(elements, 'label', this.stateInWords(purchase.Status))
                if (index === -1) {
                    let data2 = {
                        value: purchase.Status,
                        label: this.stateInWords(purchase.Status)
                    }
                    elements.push(data2)
                }
            }

            this.setState({ oldPurchaseOrderHeader: result.data, selectOptions: elements, oldPurchaseOrderHeaderFilter: result.data, HistoryOptionSelected: 'All' })

        }
        this.ResetPagination()
    }

    async clearDashBoardPurchaseOrder() {

        var header = {
            NoOrder: '',
            InvoiceNo: '',
            Carrier: '',
            orderDate: '',
            completionDate: '',
            realCompletionDate: '',
            creationDate: '',
            vendorNo: '',
            vendorName: '',
            comment: '',
            createBy: '',
            State: '',
            totalFreight: 0
        }
        var tot = {
            finalquantityOrderedTotal: 0,
            finalquantityOrderedReceived: 0,
            finalTotalCost: 0
        }
        await this.setState({ purchaseOrderHeader: header, products: [], totals: tot, disableHeader: true, acceptFile: false, badProductsCSV: [] })
        this.defineHeader()
    }

    cancelPurchaseOrder() {
        this.disableTransactions()
        Swal.fire({
            title: 'Do you want to discard the actual Purchase Order?',
            backdrop: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result !== null && result !== undefined) {
                if (result.isConfirmed) {
                    await this.clearDashBoardPurchaseOrder()
                    Swal.fire('The Purchase Order was canceled!', '', 'info')
                }
            }

        })
        this.enableTransactions()
    }
    stateInWords(state) {

        if (state === '') {
            return 'Creating Order'
        } else if (state === '1') {
            return 'Created'
        } else if (state === '2') {
            return 'Received'
        } else if (state === '3') {
            return 'Completed and Received'
        }

    }
    colorStateText(state) {
        if (state === '') {
            return 'secondary'
        } else if (state === '1') {
            return 'primary'
        } else if (state === '2') {
            return 'warning'
        } else if (state === '3') {
            return 'success'
        }
    }

    colorOrderState(state) {
        if (state === '') {
            return 'gray'
        } else if (state === '1') {
            return 'blue'
        } else if (state === '2') {
            return 'yellow'
        } else if (state === '3') {
            return 'green'
        }
    }

    async createPurchaseOrder() {
        this.disableTransactions()
        const temporal = this.state.purchaseOrderHeader
        temporal.State = "1"

        this.setState({ purchaseOrderHeader: temporal })
        const data = {
            header: this.state.purchaseOrderHeader,
            products: this.state.products,
            idcompany: getValueCookie('CompanyId'),
            username: getValueCookie('userName'),
            cost: this.state.totals.finalTotalCost,
            totalquant: this.state.totals.finalquantityOrderedTotal,
            Freight: document.getElementById('purchaseTotalFreight').value
        }



        const result = await create_Delete_Update_Information('/purchase/create/purchaseOrder', data)
      
        if (result.status.code === 1) {
            Swal.fire({
                title: 'Do you want to print the actual Purchase Order?',
                backdrop: true,
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result !== null && result !== undefined) {
                    if (result.isConfirmed) {
                        this.selectTypeOfPrint()
                    } else {
                        this.clearDashBoardPurchaseOrder()
                    }
                }

            })

        } else {
            confirmCloseAlert('incorrect', 'Whoops we have an error. Please try again!')
            temporal.State = ""
            this.setState({ purchaseOrderHeader: temporal })
        }


        this.enableTransactions()
    }

    async updatePurchaseOrder(save) {
        this.disableTransactions()
        const temporal = this.state.purchaseOrderHeader
        if (temporal.State === "2" && save) {
            temporal.State = "3"
        }

        this.setState({ purchaseOrderHeader: temporal })
        const data = {
            header: this.state.purchaseOrderHeader,
            products: this.state.products,
            idcompany: getValueCookie('CompanyId'),
            idCompany: Number(getValueCookie('CompanyId')),
            username: getValueCookie('userName'),
            cost: this.state.totals.finalTotalCost,
            totalquant: this.state.totals.finalquantityOrderedTotal,
            OrderType: 'PurchaseOrder',
            Freight: document.getElementById('purchaseTotalFreight').value,
            InvoiceNo: this.state.purchaseOrderHeader.InvoiceNo
        }
        let result
        if (save && getValueCookie('CompanyId') === '6') {
            await create_Delete_Update_Information('/purchase/update/purchaseOrder', data)
            result = await create_Delete_Update_Information('/purchase/ImportMAS90Manual', data)

        } else {
            result = await create_Delete_Update_Information('/purchase/update/purchaseOrder', data)
        }

        if (result.status.code === 1) {
            Swal.fire({
                title: 'Do you want to print the actual Purchase Order?',
                backdrop: true,
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
                allowOutsideClick: () => !Swal.isLoading()
            }).then(async (result) => {
                if (result !== null && result !== undefined) {
                    if (result.isConfirmed) {
                        this.selectTypeOfPrint()
                    } else {
                        this.clearDashBoardPurchaseOrder()
                    }
                }

            })

        } else {
            confirmCloseAlert('incorrect', 'Whoops we have an error. Please try again!')
            temporal.State = "2"
            this.setState({ purchaseOrderHeader: temporal })
        }
        this.enableTransactions()
    }

    calculateTotals() {
        var totalquantOrdered = 0
        var totalquantReceived = 0
        var totalcost = 0
        const temporal = this.state.products

        for (const item of temporal) {
            totalquantOrdered += Number(item.quantityOrdered)
            totalquantReceived += Number(item.quantityReceived)
            totalcost += Number(item.totalCost)
        }
        const temporal2 = this.state.totals
        temporal2.finalquantityOrderedTotal = totalquantOrdered
        temporal2.finalquantityOrderedReceived = totalquantReceived
        temporal2.finalTotalCost = totalcost.toFixed(2)


        this.setState({ totals: temporal2 })
    }

    handleModalOpen = async () => {
        this.getPurchaseOrderHeader()
        await this.setState({ modalPurchase: true })
    }

    handleModalClose = () => {

        this.setState({ modalPurchase: false })
    }

    async removeProduct(item) {
        var temporal = this.state.products
        const index = temporal.indexOf(item)

        if (index !== -1) {
            temporal.splice(index, 1)

            for (let a = 0; a < temporal.length; a++) {
                document.getElementById("BINPurchase_" + a).value = temporal[a].BIN
                document.getElementById("quantityOrderPurchase_" + a).value = temporal[a].quantityOrdered
                if (this.state.purchaseOrderHeader.State !== '' && this.state.purchaseOrderHeader.State !== '1') {
                    document.getElementById("quantityReceivedPurchase_" + a).value = temporal[a].quantityReceived
                }
                document.getElementById("standarCostPurchase_" + a).value = (Number(temporal[a].unitCost)).toFixed(2)
                document.getAnimations("unitFreightPurchase_" + a).value = (Number(temporal[a].Freight)).toFixed(2)
            }


            if (this.state.purchaseOrderHeader.State !== '') {
                const data = {
                    OrderNo: this.state.purchaseOrderHeader.NoOrder,
                    ItemCode: item.itemCode
                }
                await create_Delete_Update_Information('/purchase/delete/purchaseOrderDetail', data)

            }
            /*
            quantityOrdered: item.QuantityOrdered,
                    quantityReceived: item.QuantityReceived,
                    totalCost: (item.QuantityReceived * item.UnitCost).toFixed(2),
            */

            await this.setState({ products: temporal })
            this.calculateTotals()
        }

    }

    async SelectOldPurchaseOrder(order) {
        await this.handleModalClose()
        await this.ModalLoading.current.showState(true);
        const data = {
            NoOrder: order.OrderNo,
            idcompany: getValueCookie('CompanyId')
        }

        const temporal = this.state.purchaseOrderHeader
        temporal.Carrier = order.Carrier
        temporal.NoOrder = order.OrderNo
        temporal.completionDate = formatInputDateQuerytoInput(order.completionDate)
        temporal.orderDate = formatInputDateQuerytoInput(order.OrderDate)
        temporal.realCompletionDate = formatInputDateQuerytoInput(order.realCompletionDate)
        temporal.creationDate = formatInputDateQuerytoInput(order.CreationDate)
        temporal.comment = order.Comment
        temporal.State = order.Status
        temporal.createBy = order.createBy
        temporal.vendorNo = ''
        temporal.totalFreight = order.Freight
        temporal.InvoiceNo = order.InvoiceNo
        var sizeVendor = ('' + order.VendorNo).length
        if (sizeVendor !== 7) {
            for (let a = sizeVendor; a < 7; a++) {
                temporal.vendorNo += '0'
            }
        }
        temporal.vendorNo += order.VendorNo
        temporal.vendorName = order.VendorName

        this.defineHeader()
        const detailData = await getInformationWithData('/purchase/get/purchaseOrderDetail', data)

        if (detailData.status.code === 1) {
            this.setState({ purchaseOrderHeader: temporal, disableHeader: false })
            var temporalproducts = []

            for (const item of detailData.data) {
                let DueDate = null
                if (item.DueDate) {
                    let date = new Date(item.DueDate)
                    DueDate = new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
                }
                const prod = {
                    LineKey: item.id,
                    BIN: null,
                    abbreviatedDesc: item.completeDesc && item.completeDesc !== "null" ? item.completeDesc : item.abbreviatedDesc,
                    completeDesc: item.completeDesc && item.completeDesc !== "null" ? item.completeDesc : item.abbreviatedDesc,
                    createBy: "",
                    creationDate: "",
                    height: "0",
                    itemCode: item.ItemCode,
                    itemStatus: 1,
                    itemURLImage: "",
                    lastUpdateDate: "",
                    length: "",
                    HaveUPC: Number(item.HaveUPC) ? true : false,
                    RegisterType: item.RegisterType,
                    quantityOrdered: item.QuantityOrdered,
                    quantityReceived: item.QuantityReceived,
                    totalCost: (item.QuantityReceived * item.UnitCost).toFixed(2),
                    unitPrice: 0,
                    unitCost: item.UnitCost,
                    originalUnitCost: item.OriginalUnitCost,
                    LastTotalUnitCost: item.OriginalUnitCost,
                    upc: "",
                    updateBy: "",
                    width: "",
                    totalFreight: item.Freight,
                    DueDate: DueDate
                }
                if (this.state.purchaseOrderHeader.State === "1") {
                    prod.totalCost = (prod.quantityOrdered * prod.unitCost).toFixed(2)
                }


                temporalproducts.push(prod)

            }
            temporalproducts = await ItemDesc(temporalproducts, 'abbreviatedDesc', 'itemCode')
            this.setState({ products: temporalproducts })
            this.calculateTotals()
            this.updateInputs()
            this.ModalLoading.current.showState(false);
        }



    }
    defineHeader() {
        const temporal = this.state.purchaseOrderHeader
        document.getElementById('purchaseOrderNo').value = temporal.NoOrder
        document.getElementById('purchaseCarrier').value = temporal.Carrier
        document.getElementById('purchaseOrderDate').value = temporal.orderDate
        document.getElementById('purchaseCompletionDate').value = temporal.completionDate
        document.getElementById('purchaseRealCompletionDate').value = temporal.realCompletionDate
        document.getElementById('purchaseComment').value = temporal.comment
        document.getElementById('purchaseTotalFreight').value = temporal.totalFreight
        document.getElementById('purchaseInvoiceNo').value = temporal.InvoiceNo
    }


    async getVendors() {
        const data = {
            idCompany: Number(getValueCookie('CompanyId'))
        }
        const result = await getInformationWithData('/vendors/get/vendorsInformation', data)
        if (result.status.code === 1) {
            var arr = []
            for (const vendor of result.data) {
                vendor.name = DeleteCharacter(vendor.name, '\'')
                const ven = {
                    value: vendor.customerNo + '{' + vendor.name,
                    label: vendor.customerNo + " " + vendor.name
                }
                arr.push(ven)
            }
            this.setState({ vendorSelectInformation: arr })

        }
    }

    handleChange(e) {
        const change = e.value.split('{')
        if (change.length === 2) {
            const temporal = this.state.purchaseOrderHeader
            temporal.vendorNo = change[0]
            temporal.vendorName = change[1]
            this.setState({ purchaseOrderHeader: temporal })
        }
    }

    onChangeSelectPurchase = async (e) => {

        if (e.value !== 'All') {
            var filter = this.state.oldPurchaseOrderHeader.filter((order) => {
                if (order.Status.toString() === e.value.toString()) {
                    return order
                } else {
                    return null
                }
            })
           await  this.setState({ oldPurchaseOrderHeaderFilter: filter, HistoryOptionSelected: e.value })
        } else {
            const temporal = this.state.oldPurchaseOrderHeader
         await   this.setState({ oldPurchaseOrderHeaderFilter: temporal, HistoryOptionSelected: 'All' })
        }
        await this.ResetPagination()

    }
    searchOldPurchaseOrder = async e => {
        const searchValue = e.target.value
        let Filter = []
        if (this.state.HistoryOptionSelected === 'All') {
            if (searchValue !== '') {
                Filter = this.state.oldPurchaseOrderHeader.filter((order) => {
                    if ((this.Contains(order.OrderNo, searchValue) || this.Contains(order.VendorNo, searchValue) || this.Contains(order.VendorName, searchValue))) {
                        return order
                    } else {
                        return null
                    }
                })
            } else {
                Filter = this.state.oldPurchaseOrderHeader
            }


        } else {
            if (searchValue !== '') {
                Filter = this.state.oldPurchaseOrderHeader.filter((order) => {
                    if ((this.Contains(order.OrderNo, searchValue) || this.Contains(order.VendorNo, searchValue) || this.Contains(order.VendorName, searchValue)) && (this.Contains(order.Status, this.state.HistoryOptionSelected))) {
                        return order
                    } else {
                        return null
                    }
                })

            } else {
                Filter = this.state.oldPurchaseOrderHeader.filter((order) => {
                    if ((this.Contains(order.Status, this.state.HistoryOptionSelected))) {
                        return order
                    } else {
                        return null
                    }
                })

            }
        }
        await this.setState({ oldPurchaseOrderHeaderFilter: Filter })
        await this.ResetPagination()
    }
    Contains(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false
    }

    selectTypeOfPrint() {
        Swal.fire({
            title: 'Select the type of Print:',
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            denyButtonText: 'Print with prices',
            confirmButtonText: 'Print without prices',
            confirmButtonColor: '#26117A',
            denyButtonColor: '#D05416',

        }).then(async (result) => {
            if (result.isConfirmed) {
                var button1 = document.getElementById('PurchaseNotPrices')
                if (button1) {
                    await button1.click()

                }
            } else if (result.isDenied) {
                var button2 = document.getElementById('PurchasePrices')
                if (button2) {
                    await button2.click()


                }

            }
        })
    }
    acceptFiles() {
        this.setState({ acceptFile: true })
    }
    uploadFile() {
        let button = document.getElementById('uploadTemplatePurchaseOrder')
        if (button) {
            button.click()
        }
    }

    calculateFreightPerItem() {
        this.calculateTotals()
        const totalFreight = this.state.purchaseOrderHeader.totalFreight
        const totalAllItemsReceived = this.state.totals.finalquantityOrderedReceived
        const temporal = this.state.products
        for (let a = 0; a < temporal.length; a++) {
            if (temporal[a].quantityReceived > 0) {
                let unitFreight = ((totalFreight / totalAllItemsReceived) * temporal[a].quantityReceived).toFixed(2)
                document.getElementById("unitFreightPurchase_" + a).value = unitFreight
                temporal[a].totalFreight = unitFreight
                if (this.state.purchaseOrderHeader.State === "1" || this.state.purchaseOrderHeader.State === '') {
                    temporal[a].totalCost = ((Number(temporal[a].quantityOrdered) * Number(temporal[a].unitCost)).toFixed(2))
                    temporal[a].totalCost = (Number(temporal[a].totalCost) + Number(unitFreight)).toFixed(2)
                } else {
                    temporal[a].totalCost = ((Number(temporal[a].quantityReceived) * Number(temporal[a].unitCost)).toFixed(2))
                    temporal[a].totalCost = (Number(temporal[a].totalCost) + Number(unitFreight)).toFixed(2)
                }
            } else {
                temporal[a].totalFreight = 0
                document.getElementById("unitFreightPurchase_" + a).value = 0
                if (this.state.purchaseOrderHeader.State === "1" || this.state.purchaseOrderHeader.State === '') {
                    temporal[a].totalCost = ((Number(temporal[a].quantityOrdered) * Number(temporal[a].unitCost)).toFixed(2))

                } else {
                    temporal[a].totalCost = ((Number(temporal[a].quantityReceived) * Number(temporal[a].unitCost)).toFixed(2))

                }
            }
        }
        this.setState({ products: temporal })
        this.calculateTotals()

    }

    copyOldPurchaseOrder() {
        Swal.fire({
            title: 'Do you want to copy this old Purchase Order and start a new one?',
            backdrop: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
            allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.disableTransactions()
                const data = {
                    type: 'PurchaseOrder',
                    idCompany: getValueCookie('CompanyId')
                }

                const noOrder = await getInformationWithData('/sales/get/correlative', data)

                if (noOrder.status.code === 1) {
                    var orderNumber = "PO"
                    for (let a = noOrder.data[0].correlative.toString().length; a < 5; a++) {
                        orderNumber += "0"
                    }

                    orderNumber += noOrder.data[0].correlative.toString()

                    const temporal = this.state.purchaseOrderHeader
                    temporal.NoOrder = orderNumber
                    temporal.createBy = getValueCookie('userName')
                    temporal.creationDate = ''
                    temporal.completionDate = ''
                    temporal.realCompletionDate = ''
                    temporal.orderDate = ''
                    temporal.State = ''
                    temporal.InvoiceNo = ''
                    await this.setState({ purchaseOrderHeader: temporal, disableHeader: false })
                    this.defineHeader()
                }
                this.enableTransactions()
            }


        })
    }

    fillVendorNoZeros(vendorNo) {
        var newVendor = ''
        var sizeVendor = ('' + vendorNo).length
        if (sizeVendor !== 7) {
            for (let a = sizeVendor; a < 7; a++) {
                newVendor += '0'
            }
        }
        newVendor += vendorNo
        return newVendor
    }


    onTargetFile = async e => {

        if (e.target.files.length > 0) {

            if (e.target.files[0].type === 'text/csv') {

                this.ModalLoading.current.showState(true);
                let results = await new Promise((resolve, reject) => {
                    try {
                        Papa.parse(e.target.files[0], {
                            header: true,
                            skipEmptyLines: true,
                            complete: function (res) {
                                resolve(res.data)
                            }
                        })
                    } catch (error) {
                        reject(error)
                    }
                })

                await this.chargeCSVtoDetail(results)
                await this.calculateTotals()
                await this.updateInputs()
                document.getElementById('uploadTemplatePurchaseOrder').value = ""
                this.ModalLoading.current.showState(false);

            } else {
                confirmCloseAlert('incorrect', 'The File is not a CSV please upload a correct File!')

            }
        }
    }


    async chargeCSVtoDetail(csv) {

        this.setState({ products: [] })
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
            priceLevel: undefined,
            Component: 'PurchaseOrder'
        }
        const allProducts = await getInformationWithData('/Items/get', data);
        let temporalProducts = []
        let errorProducts = []
        for (const item of csv) {
            const index = getIndexElement(allProducts.data, 'itemCode', item[Object.keys(item)[0]])
            const index2 = getIndexElement(temporalProducts, 'itemCode', item[Object.keys(item)[0]])
            if (index !== -1 && index2 === -1) {
                const prodFound = allProducts.data[index]
                let unitCostVer = Number(item[Object.keys(item)[3]])

                const prodtemporal = {
                    itemCode: prodFound.itemCode,
                    abbreviatedDesc: prodFound.abbreviatedDesc,
                    upc: prodFound.upc,
                    quantity: prodFound.quantity,
                    ShipWeight: prodFound.ShipWeight,
                    StandardUnitCost: prodFound.StandardUnitCost,
                    LastTotalUnitCost: prodFound.LastTotalUnitCost,
                    UnitPrice: prodFound.UnitPrice,
                    upc2: prodFound.upc2,
                    caseupc: prodFound.caseupc,
                    itemURLImage: prodFound.itemURLImage,
                    BIN: "",
                    Comment: "",
                    unitCost: isNaN(unitCostVer) ? prodFound.LastTotalUnitCost : (unitCostVer === 0 ? prodFound.LastTotalUnitCost : unitCostVer),//(Number(item[Object.keys(item)[3]])) === 0 ? prodFound.LastTotalUnitCost : Number(item.RealPrice),
                    quantityOrdered: isNaN(Number(item[Object.keys(item)[2]])) ? 0 : Number(item[Object.keys(item)[2]]),
                    quantityReceived: 0,
                    totalCost: 0,//Number(item.Quantity) * (Number(item.RealPrice) === 0 ? prodFound.LastTotalUnitCost : Number(item.RealPrice)),
                    totalFreight: 0
                }
                prodtemporal.totalCost = prodtemporal.quantityOrdered * prodtemporal.unitCost

                temporalProducts.push(prodtemporal)
            } else {
                errorProducts.push(item)
            }
        }

        if (errorProducts.length === 0) {
            this.setState({ products: temporalProducts })
            confirmCloseAlert('correct', 'All products were successfully uploaded to the Purchase Order')
        } else {

            this.setState({ badProductsCSV: errorProducts })
            await Swal.fire({
                title: 'Whoops we have an error\nAttention Please!',
                icon: 'error',
                text: `${temporalProducts.length} products were identified, but ${errorProducts.length} have problems. 
                Please check the imported file and correct the errors to re-upload the file. 
                Make sure there are no duplicate or inactive products`,
                showDenyButton: true,
                denyButtonColor: '#166a1b',
                confirmButtonText: 'Ok',
                denyButtonText: `Download CSV with items who have problems`,
            }).then((result) => {

                if (result.isDenied) {
                    let button = document.getElementById('badProductsCSVPurchaseOrder')
                    button.click()
                }
            })
        }
    }

    formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    async ResetPagination() {
        let totalPages = Math.round(this.state.oldPurchaseOrderHeaderFilter.length / this.state.ProductsPerPage)
        let seeProds = this.state.oldPurchaseOrderHeaderFilter.slice(0, 0 + this.state.ProductsPerPage)
        await this.setState({
            oldPurchaseOrderHeaderShow: seeProds,
            currentPage: 0,
            ProductsPerPage: 30,
            TotalPages: totalPages
        })

    }

    async nextPage() {
        if ((this.state.currentPage + this.state.ProductsPerPage) <= this.state.oldPurchaseOrderHeaderFilter.length) {
            await this.setState({ currentPage: this.state.currentPage + this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async prevPage() {
        if (this.state.currentPage > 0) {
            await this.setState({ currentPage: this.state.currentPage - this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async updateSeeProducts() {
        let seeProds = this.state.oldPurchaseOrderHeaderFilter.slice(this.state.currentPage, this.state.currentPage + this.state.ProductsPerPage)
        await this.setState({
            oldPurchaseOrderHeaderShow: seeProds,
        })

    }
    backToTop() {
        const buscador = document.getElementById('searchPOHistory');
        setTimeout(() => { buscador.focus(); }, 300);
    }

    render() {
        return (
            <div className='purchaseOrderContainer'>
                <CSVLink id='badProductsCSVPurchaseOrder' hidden data={this.state.badProductsCSV} filename={getValueCookie('Company') + ' Purchase Order ' + this.state.purchaseOrderHeader.NoOrder + ' Bad Items ' + getDateFromReports() + '.csv'}></CSVLink>
                <LoadingWindow ref={this.ModalLoading} />
                <button hidden id='vendorsFromPurchaseOrder' onClick={() => this.getVendors()}></button>
                <button hidden onClick={() => this.print()}>PRINT</button>
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Purchase Order</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please select an order or create a new one</p>
                </div>
                <div>
                    <div className='row pb-2 text-center d-flex justify-content-center'>
                        <div className='col-sm-5 pb-2'>
                            <div className="d-grid gap-2">
                                <button type="button" className="btn blueButton btn-lg" disabled={this.state.secureTransaction} onClick={() => this.createNewPurchaseOrder()}><FiPlusCircle /> New Purchase Order</button>
                                <button type="button" hidden={this.state.purchaseOrderHeader.State !== '3'} onClick={() => this.copyOldPurchaseOrder()} className="btn blueButton btn-lg w-100" ><AiFillCopy /> Copy Old Purchase Order</button>
                            </div>
                        </div>
                        <div className='col-sm-5 pb-2'>
                            <div className="d-grid gap-2">
                                <button type="button" className="btn purpleButton btn-lg" disabled={this.state.secureTransaction} onClick={() => this.handleModalOpen()}><FiArchive /> History</button>
                            </div>
                        </div>
                    </div>
                    <div className='row pb-2 text-center d-flex justify-content-center'>
                        <div className='col-sm-10'>
                            <div hidden={this.state.purchaseOrderHeader.NoOrder === ''} className='col-sm-12'>
                                <div className={'col-sm-12 d-flex justify-content-center my-4 alert alert alert-' + this.colorStateText(this.state.purchaseOrderHeader.State)}>
                                    <div className='text-start pText'><h3>Order Status: <span className={'fw-bold text-' + this.colorStateText(this.state.purchaseOrderHeader.State)}>{this.stateInWords(this.state.purchaseOrderHeader.State)}</span></h3></div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-6'>
                                        <div className='row pb-4'>
                                            <div className='col-12 text-start pText'><p>Order No:</p></div>
                                            <div className='col-12'><input className="form-control form-control-lg" disabled id='purchaseOrderNo' type="text" onChange={this.onTargerHeader} /></div>
                                        </div>
                                        <div className='row pb-4' hidden={(((getValueCookie('CompanyId') !== '6')) || (this.state.purchaseOrderHeader.State !== '2'))}>
                                            <div className='col-12 text-start pText'><p>Invoice No:</p></div>
                                            <div className='col-12'><input disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control form-control-lg" id='purchaseInvoiceNo' onChange={this.onTargerHeader} type="text" /></div>
                                        </div>
                                        <div className='row pb-4'>
                                            <div className='col-12 text-start pText'><p>Carrier:</p></div>
                                            <div className='col-12'><input disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control form-control-lg" id='purchaseCarrier' type="text" onChange={this.onTargerHeader} /></div>
                                        </div>
                                        <div className='row pb-3'>
                                            <div className='col-12 text-start pText'><p>Comment:</p></div>
                                            <div className='col-12 textAreaGeneral'>
                                                <textarea disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control" id='purchaseComment' onChange={this.onTargerHeader} rows="4"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-6'>
                                        <div className='row pb-2'>
                                            <div className='col-12 text-start pText'><p>Vendor:</p></div>
                                            <div className='col-12'>
                                                <Select isDisabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} value={{ label: this.state.purchaseOrderHeader.vendorNo + ' ' + this.state.purchaseOrderHeader.vendorName, value: this.state.purchaseOrderHeader.vendorNo + '{' + this.state.purchaseOrderHeader.vendorName }} options={this.state.vendorSelectInformation} className='fs-4 text-start' placeholder="Select Vendor" onChange={this.handleChange.bind(this)} />
                                            </div>
                                        </div>
                                        <div className='row pb-4'>
                                            <div className='col-12 text-start pText'><p>Date:</p></div>
                                            <div className='col-12'><input disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control form-control-lg" id='purchaseOrderDate' onChange={this.onTargerHeader} type="date" /></div>
                                        </div>
                                        <div className='row pb-4'>
                                            <div className='col-12 text-start pText'><p> Estimated completion date:</p></div>
                                            <div className='col-12'><input disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control form-control-lg" id='purchaseCompletionDate' onChange={this.onTargerHeader} type="date" /></div>
                                        </div>
                                        <div className='row pb-4' hidden={this.state.purchaseOrderHeader.State === '1' || this.state.purchaseOrderHeader.State === ''}>
                                            <div className='col-12 text-start pText'><p> Real completion date:</p></div>
                                            <div className='col-12'><input disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control form-control-lg" id='purchaseRealCompletionDate' onChange={this.onTargerHeader} type="date" /></div>
                                        </div>
                                        <div className='row pb-4'>
                                            <div className='col-12 text-start pText'><p>Total Freight:</p></div>
                                            <div className='col-12'>

                                                <div className="input-group input-group-lg flex-nowrap">
                                                    <span className="input-group-text">$</span>
                                                    <input disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} className="form-control form-control-lg text-center" id='purchaseTotalFreight' type="number" min={0.00} defaultValue={0.00} step={0.01} onChange={this.onTargerHeader} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pb-2 pt-3 text-center d-flex justify-content-center'>
                        <div hidden={this.state.purchaseOrderHeader.NoOrder === ''} className='row col-sm-10'>
                            <div className='col-sm-12 col-md-6 pb-3 text-start display-6'>
                                <div className='row pb-2'>
                                    <div className="col-12">
                                        <div className='card'>
                                            <div className='card-body'>
                                                <h5 className='card-title text-center'>Add products from file</h5>
                                                <h6 className='card-title text-center text-muted mb-4'>Download, complete and upload the file</h6>
                                                <CSVLink disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} hidden={this.state.acceptFile} className='nonTextDecoration' filename={getValueCookie('Company') + ' Purchase Order Template ' + getDateFromReports() + '.csv'} data={[{ ItemCode: '', Description: '', Quantity: '', RealPrice: '' }]}><div className="col-12 pt-1 d-grid gap-2"> <button disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} hidden={this.state.acceptFile} onClick={() => this.acceptFiles()} type="button" className="btn greenButton btn-lg"><AiOutlineDownload /> Download CSV Template</button></div></CSVLink>
                                                <div className="col-12 pt-1 d-grid gap-2"> <button disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'} hidden={!this.state.acceptFile} onClick={() => this.uploadFile()} type="button" className="btn blueButton btn-lg"><AiOutlineCloudUpload /> Upload CSV Template </button></div>
                                                <input id='uploadTemplatePurchaseOrder' hidden type='file' accept='.csv' className='form-control ' onChange={this.onTargetFile} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 pb-3 text-start display-6'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <h5 className='card-title text-center'>Add products by catalogue</h5>
                                        <h6 class="card-title text-center text-muted mb-4">Select a product from the list</h6>
                                        {
                                            getValueCookie('CompanyId') === '6' ?
                                                <Catalogue2  hidden={false} id='Catalogue2' PriceLevel={null} State={this.state.purchaseOrderHeader.State} Array={'products'} nombrePadre={"PurchaseOrder"} colorButton='blueButton' Padre={this} disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'||this.state.purchaseOrderHeader.vendorNo===''} titleButton='Open Catalogue' needPrice={false} needCost={true} />
                                            :
                                                <Catalogue  Array={'products'} disabled={this.state.purchaseOrderHeader.NoOrder === '' || this.state.purchaseOrderHeader.State === '3'||this.state.purchaseOrderHeader.vendorNo===''} nombrePadre={"PurchaseOrder"} Padre={this} needCost={true} Component={'PurchaseOrder'} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={this.state.purchaseOrderHeader.NoOrder === ''} className='col-sm-12 tb-5 tableFixHead'>
                            <table className='table align-middle table-hover'>
                                <thead className='table-dark thead'>
                                    <tr className='text-center'>
                                        <th>Item Code</th>
                                        <th>Description</th>
                                        <th hidden>BIN</th>
                                        <th>{(this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3") ? "Quantity Ordered" : "Quantity"}</th>
                                        <th hidden={!(this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3")}>Quantity Received</th>
                                        <th hidden={!(this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3")}>Due Date</th>
                                        <th>Real Price</th>
                                        <th>Unit Freight</th>
                                        <th></th>
                                        <th>Last Unit Price</th>
                                        <th></th>
                                        <th>Total Cost</th>
                                        <th></th>


                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {this.state.products.map((product, i) => (
                                        <tr className='text-center' key={i}>
                                            <td className='text-center'>{product.itemCode}</td>
                                            <td className='text-start'>{product.abbreviatedDesc}</td>
                                            <td hidden>
                                                <div className="input-group input-group-lg">
                                                    <input type="text" id={'BINPurchase_' + i} defaultValue={product.BIN} className="form-control text-center inputResize" onChange={(e) => this.onTarget(e, product)} />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="input-group input-group-lg">
                                                    <input disabled={this.state.purchaseOrderHeader.State === '3'} type="number" id={'quantityOrderPurchase_' + i} min={0} step={1} defaultValue={product.quantityOrdered} onChange={(e) => this.onTarget(e, product)} className="form-control text-center inputResize" />
                                                </div>
                                            </td>
                                            <td hidden={!(this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3")}>
                                                <div className="input-group input-group-lg">
                                                    <input disabled={this.state.purchaseOrderHeader.State === '3'} type="number" id={'quantityReceivedPurchase_' + i} min={0} step={1} defaultValue={product.quantityReceived} onChange={(e) => this.onTarget(e, product)} className="form-control text-center inputResize" />
                                                </div>
                                            </td>
                                            <td hidden={!(this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3")}>
                                                <div className="input-group input-group-lg">
                                                    <input disabled={this.state.purchaseOrderHeader.State === '3'} type="date" id={'DueDate_' + i} min={0} step={1} defaultValue={(product.DueDate) ? this.formatDate(product.DueDate) : null} onChange={(e) => this.onTarget(e, product)} className="form-control text-center inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-end'>
                                                <div className="input-group input-group-lg flex-nowrap">
                                                    <span className="input-group-text">$</span>
                                                    <input disabled={this.state.purchaseOrderHeader.State === '3'} type="number" id={'standarCostPurchase_' + i} min={0} step={0.01} defaultValue={pricesFormat(product.unitCost)} onChange={(e) => this.onTarget(e, product)} className="form-control text-end inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-end'>
                                                <div className="input-group input-group-lg flex-nowrap">
                                                    <span className="input-group-text">$</span>
                                                    <input disabled={this.state.purchaseOrderHeader.State === '3'} type="number" id={'unitFreightPurchase_' + i} min={0} step={0.01} defaultValue={pricesFormat(product.totalFreight)} onChange={(e) => this.onTarget(e, product)} className="form-control text-end inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-end textTableSize'>$</td>
                                            <td className='text-end textTableSize'>{NumberFormat(pricesFormat(product.LastTotalUnitCost))}</td>
                                            <td className='text-end textTableSize'>$</td>
                                            <td className='text-end textTableSize'>{NumberFormat(pricesFormat(product.totalCost))}</td>
                                            <td><button disabled={this.state.purchaseOrderHeader.State === '3'} onClick={() => this.removeProduct(product)} className='btn btn-danger'><AiOutlineCloseCircle /></button></td>
                                        </tr>

                                    ))}
                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-dark text-light'>
                                        <td hidden></td>
                                        <td></td>
                                        <td className='textTableSize'>TOTAL:</td>
                                        <td className='text-center textTableSize'>{NumberFormat(this.state.totals.finalquantityOrderedTotal)}</td>
                                        <td hidden={!(this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3")} className='text-center textTableSize'>{NumberFormat(this.state.totals.finalquantityOrderedReceived)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='text-end textTableSize'>$</td>
                                        <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.state.totals.finalTotalCost))}</td>
                                        <td></td>
                                        
                                    </tr>
                                </tfoot>

                            </table>
                        </div>

                    </div>
                </div>

                <div className='row pb-4 text-center d-flex justify-content-center'>
                    <div hidden={this.state.purchaseOrderHeader.NoOrder === ''}  className='col-sm-5 pb-2'>
                        <div className="d-grid gap-2">
                            <button type="button" hidden={this.state.purchaseOrderHeader.State !== ''} disabled={this.state.disableHeader || this.state.secureTransaction} className="btn greenButton btn-lg" onClick={() => this.createPurchaseOrder()}><FiSave /> Save Purchase Order</button>
                            <button type="button" hidden={this.state.purchaseOrderHeader.State === ''} disabled={this.state.disableHeader || this.state.secureTransaction || this.state.purchaseOrderHeader.State === '3'} className="btn greenButton btn-lg" onClick={() => this.updatePurchaseOrder(false)}><FiSave /> Update Order</button>
                        </div>
                    </div>
                    <div hidden={this.state.purchaseOrderHeader.NoOrder === ''}  className='col-sm-5'>
                        <div className="d-grid gap-1">
                            <button type="button" className="btn blackButton btn-lg" disabled={this.state.disableHeader || this.state.secureTransaction} onClick={() => this.selectTypeOfPrint()}><AiFillPrinter /> Print Order</button>
                            {this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3" ?
                                (MobileDisp() ?
                                    <OrderPDF id={"PurchasePrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Purchase Order"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                        headerTable={["\n Item Code", "\n Description", "\n Quantity \n Ordered", "\n Quantity \n Received", "", "\n Real\nPrice", "", "Last\n Unit \nPrice\n", "", "\n Total \n Cost\n"]}
                                        bodyTable={this.state.products}
                                        headerBodyTable={["itemCode", "abbreviatedDesc", "quantityOrdered", "quantityReceived", "dolar", "unitCost", "dolar", "LastTotalUnitCost", "dolar", "totalCost"]}
                                        bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal), "\n" + NumberFormat(this.state.totals.finalquantityOrderedReceived), "", "", "", "", "\n$", "\n" + NumberFormat(pricesFormat(this.state.totals.finalTotalCost))]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo2", "ColumnaCantidades", "ColumnaCantidades", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios"]}
                                        hidden={true}
                                        comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                        breakpoint={56}
                                        keysDecimalFormat={["unitCost", "LastTotalUnitCost", "totalCost"]}
                                        titleButton='Print Order'
                                    />
                                    :
                                    <DownloadOrderPDF id={"PurchasePrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Purchase Order"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                        headerTable={["\n Item Code", "\n Description", "\n Quantity \n Ordered", "\n Quantity \n Received", "", "\n Real\nPrice", "", "Last\n Unit \nPrice\n", "", "\n Total \n Cost\n"]}
                                        bodyTable={this.state.products}
                                        headerBodyTable={["itemCode", "abbreviatedDesc", "quantityOrdered", "quantityReceived", "dolar", "unitCost", "dolar", "LastTotalUnitCost", "dolar", "totalCost"]}
                                        bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal), "\n" + NumberFormat(this.state.totals.finalquantityOrderedReceived), "", "", "", "", "\n$", "\n" + NumberFormat(pricesFormat(this.state.totals.finalTotalCost))]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo2", "ColumnaCantidades", "ColumnaCantidades", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios"]}
                                        hidden={true}
                                        comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                        breakpoint={56}
                                        keysDecimalFormat={["unitCost", "LastTotalUnitCost", "totalCost"]}
                                        titleButton='Print Order'
                                    />
                                )
                                :
                                (MobileDisp() ?
                                    <OrderPDF id={"PurchasePrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Purchase Order"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                        headerTable={["\n Item Code", "\n Description", "\n Quantity\n Ordered", "", "\n Real\nPrice", "", "Last\n Unit \nPrice\n", "", "\n Total \n Cost\n"]}
                                        bodyTable={this.state.products}
                                        headerBodyTable={["itemCode", "abbreviatedDesc", "quantityOrdered", "dolar", "unitCost", "dolar", "LastTotalUnitCost", "dolar", "totalCost"]}
                                        bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal), "", "", "", "", "\n$", "\n" + NumberFormat(pricesFormat(this.state.totals.finalTotalCost))]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo3", "ColumnaCantidades", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios"]}
                                        hidden={true}
                                        comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                        keysDecimalFormat={["unitCost", "LastTotalUnitCost", "totalCost"]}
                                        breakpoint={56}
                                        titleButton='Print Order'
                                    />
                                    :
                                    <DownloadOrderPDF id={"PurchasePrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Purchase Order"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                        headerTable={["\n Item Code", "\n Description", "\n Quantity\n Ordered", "", "\n Real\nPrice", "", "Last\n Unit \nPrice\n", "", "\n Total \n Cost\n"]}
                                        bodyTable={this.state.products}
                                        headerBodyTable={["itemCode", "abbreviatedDesc", "quantityOrdered", "dolar", "unitCost", "dolar", "LastTotalUnitCost", "dolar", "totalCost"]}
                                        bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal), "", "", "", "", "\n$", "\n" + NumberFormat(pricesFormat(this.state.totals.finalTotalCost))]}
                                        styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo3", "ColumnaCantidades", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios", "Dolar", "ColumnaPrecios"]}
                                        hidden={true}
                                        comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                        keysDecimalFormat={["unitCost", "LastTotalUnitCost", "totalCost"]}
                                        breakpoint={56}
                                        titleButton='Print Order'
                                    />

                                )
                            }

                            {this.state.purchaseOrderHeader.State === "2" || this.state.purchaseOrderHeader.State === "3" ?
                                (
                                    MobileDisp() ?
                                        <OrderPDF id={"PurchaseNotPrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            OrderTitle="Purchase Order"
                                            contactInfo={this.state.companyPrintHeader}
                                            OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                            headerTable={["\n Item Code", "\n Description", "", "\n Quantity \n Ordered", "\n Quantity \n Received"]}
                                            bodyTable={this.state.products}
                                            headerBodyTable={["itemCode", "abbreviatedDesc", "", "quantityOrdered", "quantityReceived"]}
                                            bottomInfo={["", "\nTOTALS", "", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal), "\n" + NumberFormat(this.state.totals.finalquantityOrderedReceived)]}
                                            styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo3", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoNormal"]}
                                            hidden={true}
                                            comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                            keysDecimalFormat={[]}
                                            breakpoint={56}
                                            titleButton='Print Order'
                                        />
                                        :
                                        <DownloadOrderPDF id={"PurchaseNotPrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                            companyLogo={getValueCookie('DocumentsLogo')}
                                            OrderTitle="Purchase Order"
                                            contactInfo={this.state.companyPrintHeader}
                                            OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                            headerTable={["\n Item Code", "\n Description", "", "\n Quantity \n Ordered", "\n Quantity \n Received"]}
                                            bodyTable={this.state.products}
                                            headerBodyTable={["itemCode", "abbreviatedDesc", "", "quantityOrdered", "quantityReceived"]}
                                            bottomInfo={["", "\nTOTALS", "", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal), "\n" + NumberFormat(this.state.totals.finalquantityOrderedReceived)]}
                                            styleColumns={["ColumnaTextoNormalLeft", "ColumnaTextoLargo3", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoNormal"]}
                                            hidden={true}
                                            comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                            keysDecimalFormat={[]}
                                            breakpoint={56}
                                            titleButton='Print Order'
                                        />
                                )
                                :
                                (MobileDisp() ?
                                    <OrderPDF id={"PurchaseNotPrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Purchase Order"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                        headerTable={["\n Item Code", "\n Description", "\n Quantity\n Ordered"]}
                                        bodyTable={this.state.products}
                                        headerBodyTable={["itemCode", "abbreviatedDesc", "quantityOrdered"]}
                                        bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal)]}
                                        styleColumns={["ColumnaTextoNormal2Left", "ColumnaTextoLargo3", "ColumnaTextoNormal2"]}
                                        hidden={true}
                                        comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                        breakpoint={56}
                                        keysDecimalFormat={[]}
                                        titleButton='Print Order'
                                    />
                                    :
                                    <DownloadOrderPDF id={"PurchaseNotPrices"} disabled={this.state.disableHeader || this.state.secureTransaction} colorButton="orangeButton" title="Purchase Order Print"
                                        companyLogo={getValueCookie('DocumentsLogo')}
                                        OrderTitle="Purchase Order"
                                        contactInfo={this.state.companyPrintHeader}
                                        OrderInfo1={["Order No: ", this.state.purchaseOrderHeader.NoOrder, "Order State: ", this.stateInWords(this.state.purchaseOrderHeader.State), "No Vendor: ", this.state.purchaseOrderHeader.vendorNo, "Order by: ", this.state.purchaseOrderHeader.createBy, "Vendor: ", this.state.purchaseOrderHeader.vendorName, "Printed by: ", getValueCookie('userName'), "Carrier: ", this.state.purchaseOrderHeader.Carrier, "Date: ", formatInputDate(this.state.purchaseOrderHeader.orderDate)]}
                                        headerTable={["\n Item Code", "\n Description", "\n Quantity\n Ordered"]}
                                        bodyTable={this.state.products}
                                        headerBodyTable={["itemCode", "abbreviatedDesc", "quantityOrdered"]}
                                        bottomInfo={["", "\nTOTALS", "\n" + NumberFormat(this.state.totals.finalquantityOrderedTotal)]}
                                        styleColumns={["ColumnaTextoNormal2Left", "ColumnaTextoLargo3", "ColumnaTextoNormal2"]}
                                        hidden={true}
                                        comment={"Comment: " + this.state.purchaseOrderHeader.comment}
                                        breakpoint={56}
                                        keysDecimalFormat={[]}
                                        titleButton='Print Order'
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div hidden={this.state.purchaseOrderHeader.NoOrder === ''}  className='col-sm-5 pb-2'>
                        <div className="d-grid gap-2">
                            <button type="button" hidden={this.state.purchaseOrderHeader.State !== '2'} disabled={this.state.disableHeader || this.state.secureTransaction || this.state.purchaseOrderHeader.State === '3'} className="btn orangeButton btn-lg" onClick={() => this.updatePurchaseOrder(true)}><FiUpload /> Upload to MAS90</button>
                        </div>
                    </div>
                </div>
                <ModalOrders title={'Purchase Orders'} show={this.state.modalPurchase} close={() => this.handleModalClose()}>
                    <div className='row pt-2'>
                        <div className='col-sm-12'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-9 pb-3'>
                                    <input onChange={this.searchOldPurchaseOrder} className="form-control form-control-lg" placeholder='Search PO by Order No, Vendor No, Vendor Name' id='searchPOHistory' type="text" />
                                </div>
                                <div className='col-sm-12 col-md-3 text-start'>
                                    <Select className='fs-4 text-start' defaultValue={this.state.selectOptions[0]} options={this.state.selectOptions} onChange={this.onChangeSelectPurchase} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-3'>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table table-hover'>
                                <thead className='thead table-dark'>
                                    <tr className='text-light text-center'>
                                        <th>Order No</th>
                                        <th>Vendor No</th>
                                        <th>Vendor Name</th>
                                        <th>Order Date</th>
                                        <th>Comment</th>
                                        <th>Carrier</th>
                                        <th>Total Quantity</th>
                                        <th>Total Cost</th>
                                        <th>Status</th>
                                        <th>Create by</th>

                                    </tr>
                                </thead>
                                <tbody className='tbody'>

                                    {this.state.oldPurchaseOrderHeaderShow.map((item, i) => (
                                        <tr onClick={() => this.SelectOldPurchaseOrder(item)} className='text-center' key={i}>

                                            <td className='text-center'>{item.OrderNo}</td>
                                            <td>{this.fillVendorNoZeros(item.VendorNo)}</td>
                                            <td className='text-start'>{item.VendorName}</td>
                                            <td>{formatInputDateQuery(item.OrderDate)}</td>
                                            <td className='text-start'>{item.Comment}</td>
                                            <td>{item.Carrier}</td>
                                            <td className='text-center'>{NumberFormat(item.TotalQuantity)}</td>
                                            <td className='text-end'><div className='row'><div className='col-1'></div> <div className='col-1 text-end'>$</div><div className='col-7'>{NumberFormat(pricesFormat(item.TotalCost))} </div><div className='col-1'></div></div></td>
                                            <td className='text-center'>
                                                <span class={"badge "+ this.colorOrderState(item.Status) +"Background" }>{this.stateInWords(item.Status)}</span>
                                                </td>
                                            <td>{item.createBy}</td>
                                        </tr>
                                    ))}

                                </tbody>
                                <tfoot className='tfoot'>

                                    <tr className='bg-secondary text-light'>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-1' />
                        <div className='col-sm-5 text-center pt-1'>
                            <div className="btn-group btn-group-lg" role="group" aria-label="pagination">
                                <button disabled={this.state.currentPage === 0} type="button" onClick={() => this.prevPage()} className="btn blueButton">Prev</button>
                            </div>
                        </div>
                        <div className='col-sm-5 text-center pt-1'>
                            <div className="btn-group btn-group-lg" role="group" aria-label="pagination">
                                <button disabled={(this.state.currentPage + this.state.ProductsPerPage) >= this.state.oldPurchaseOrderHeaderFilter.length} type="button" onClick={() => this.nextPage()} className="btn blueButton">Next</button>
                            </div>
                        </div>
                        <div className='col-sm-1' />
                    </div>
                </ModalOrders>

            </div>
        )
    }
}