import React, { Component } from 'react';
import { fetchData, sendOrderData } from '../../services/apiService';
import { AiOutlineSearch } from "react-icons/ai"
import LoadingWindow from '../../components/LoadingWindow';
import "../../css/general-style.css"
import '../../css/table-responsive.css'

export default class LogsPage extends Component {

    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
        this.state = {
            data: null,
            error: null,
            loading: false,
            searchQuery: '',
            initialLoad: true,
        }
    }

    componentDidMount() {
        // this.getData('');
    }

    async getData(searchQuery) {
        this.setState({ loading: true });
        try {
            const { data } = await fetchData(searchQuery);
            this.setState({ data: data, loading: false, initialLoad: false });
        } catch (error) {
            this.setState({ error, loading: false, initialLoad: false });
        }
    }

    handleInputChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    handleSearch = () => {
        this.getData(this.state.searchQuery);
    }

   handleButtonClick = async (object) => {
    object.replace(/(\r\n|\n|\r)/gm, '').replace(/\s{2,}/g, ' ')
    const response = await sendOrderData({ data: object })
    console.log(response)
    }

    handleCopyButtonClick = async (object) => {
        navigator.clipboard.writeText(object)
        .then(() => {
        })
        .catch(err => {
            console.error('Error al copiar el texto: ', err);
            alert('Error al copiar el texto.');
        });
    }

    render() {
        const { data, error, loading, searchQuery, initialLoad } = this.state;

        if (loading) {
            return <LoadingWindow ref={this.ModalLoading} />
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        const noDataMessage = !initialLoad && (!data || data.length === 0) ? (
            <p>No data available</p>
        ) : null;

        const getSalesOrder = (object) => {
            let parsedObject = JSON.parse(object)
            return parsedObject.NoOrder || parsedObject.Invoice.InvoiceNo
        }
        return (
            <div className="container mt-4">
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Log History</h2>
                </div>
                <div className="mb-3 row">
                    <div className="input-group input-group-lg">
                        <span className="input-group-text search-input"><AiOutlineSearch /></span>
                        <input
                            type="text"
                            className="form-control"
                            value={searchQuery}
                            onChange={this.handleInputChange}
                            placeholder="Search order number"
                        />
                        <button className="btn blueButton" onClick={this.handleSearch}>Search</button>
                    </div>
                </div>

                {noDataMessage}

                <div className='col-sm-12 tableFixHead'>
                    {data && data.length > 0 && (
                        <table className="table table-striped table-hover align-middle">
                            <thead className="table-dark">
                                <tr>
                                    <th className='text-center'>User Name</th>
                                    <th className='text-center'>Type Object</th>
                                    <th className='text-center'>Created At</th>
                                    <th className='text-center'>Invoice/Sales Order</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item) => (
                                    <tr key={item.id}>
                                        <td className='text-center'>{item.user_name}</td>
                                        <td className='text-center'><span className={"badge " + (item.type_object === 'Invoice'? 'bg-primary' : 'bg-secondary')}>{item.type_object}</span></td>
                                        <td className='text-center'>{new Date(item.created_at).toLocaleString()}</td>
                                        <td className='text-center'>{getSalesOrder(item.object)}</td>
                                        <td className='gap-2 text-center'>
                                            <button
                                                className="btn blueButton me-2 mb-2"
                                                onClick={() => this.handleButtonClick(item.object)}
                                            >
                                                Load Order
                                            </button>
                                            <button
                                                className="btn blackButton"
                                                onClick={() => this.handleCopyButtonClick(item.object)}
                                            >
                                                Copy Json
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>)
    }
}
