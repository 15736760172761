import React, { Component } from 'react'
import "../../css/general-style.css"
import { AiFillPrinter } from "react-icons/ai"
import ModalOrders from '../ModalComponent'
import styles from '../../pdfConfig/pdfInvoiceZebra'
import { Document, PDFViewer, View, Page, Text, Image } from '@react-pdf/renderer'
import { NumberFormat } from '../../functions/generalFunctions'
import { v4 as uuidv4 } from 'uuid';

export class InvoiceZebra extends Component {
    // eslint-disable-next-line
    constructor(props) {

        super(props)

    }

    state = {
        showModal: false,
        lastRow: 0,


    }

    handleModalOpen = () => {
        this.setState({ showModal: true })
    }

    handleModalClose = () => {
        this.setState({ showModal: false })
    }




    calculateNewSheet() {
        let arrTam = this.props.bodyTable.length

        while (true) {
            arrTam = arrTam - 27
            if (arrTam <= 27) {
                break
            }
        }
        if (arrTam > 20) {
            return true
        }

        return false
    }

    truncDecimal(n) {
        let t = n.toString();
        let regex = /(\d*.\d{0,1})/;
        return t.match(regex)[0];
    }
    EncabezadoOrder(data, num) {
        let format = []
        let temporal = []
        let cont = 1

        for (const item of data) {
            if (cont === num) {
                temporal.push(item)
                format.push(temporal)
                temporal = []
                cont = 1

            } else {
                cont++
                temporal.push(item)
            }
        }

        return format
    }
    EncabezadoInfo(Llaves, State, Confirm) {

        let resultado = []
        Llaves.forEach(llave => {
            if (llave !== '$') {
                if (this.props.keysDecimalFormat.includes(llave) && State[llave] !== ' ') {
                    resultado.push(NumberFormat(Number(State[llave]).toFixed(2)))
                } else {
                    resultado.push(State[llave])
                }
            } else {
                if (Confirm) {
                    resultado.push(llave)
                } else {
                    resultado.push(' ')
                }



            }


        });


        return resultado
    }


    render() {
        return (
            <React.Fragment>

                <div className="d-grid gap-2">
                    <button hidden={this.props.hidden} id={this.props.id} disabled={this.props.disabled} type="button" onClick={() => this.handleModalOpen()} className={"btn " + this.props.colorButton + " btn-lg"}><AiFillPrinter /> {this.props.titleButton}</button>
                </div>
                <ModalOrders title={this.props.title} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <PDFViewer className="w-100" style={{ minHeight: "90vh" }}>
                        <Document >
                            <Page size={{ width: 104.12, height: "auto" }} wrap>
                                <View>
                                    <View style={styles.MarginBottom} />
                                    <View style={styles.blockHeaderStyle}>
                                        <Image style={styles.BusinessLogo} src={this.props.companyLogo} />
                                    </View>

                                    {this.props.CompanyContactInfo.map((element) => (
                                        <View key={uuidv4()} style={styles.blockHeader}>
                                            <Text style={styles.CompanyTitleStyle}>{element}</Text>
                                        </View>
                                    ))}
                                    <View style={styles.MarginBottom} />
                                    <View  style={styles.blockHeader}>
                                        <Text  style={styles.CompanyTitleStyle}>{this.props.InvoiceType}</Text>
                                    </View>
                                    <View style={styles.MarginBottom} />
                                    {this.props.InvoiceHeader.map((element) => (
                                        <View key={uuidv4()} style={styles.generalHeaderStyle}>
                                            {element.map((title, t) => (
                                                <Text key={uuidv4()} style={t === 0 ? styles.generalBolderTextLeftStyle : styles.generalTextLeftStyle}>{title}</Text>
                                            ))}
                                        </View>
                                    ))}
                                    <View style={styles.MarginBottom} />

                                    <View style={styles.generalHeaderStyle}>
                                        {this.props.HeaderDescritpion.map((element, e) => (
                                            <Text key={uuidv4()} style={styles[this.props.StyleDetail[e] + 'Header']}>{element}</Text>
                                        ))}
                                    </View>
                                    {
                                        this.props.Detail.map((item, i) => (
                                            <View key={uuidv4()} style={styles.generalHeaderStyle}>
                                                {
                                                    this.EncabezadoInfo(this.props.DescriptionKeys, item, (i + 1) <= this.props.quantSignDolar).map((fila, e2) => (

                                                        <Text style={styles[this.props.StyleDetail[e2]]} key={uuidv4()}>{fila}</Text>

                                                    ))
                                                }


                                            </View>
                                        ))
                                    }
                                    <View style={styles.MarginBottom} />
                                    {
                                        this.props.bottomInformation.map((element) => (
                                            <View key={uuidv4()} style={styles.generalHeaderStyle}>
                                                {element.map((item, i) => (
                                                    <Text style={styles[this.props.StyleDetail[i] + 'Bottom']} key={uuidv4()}>{item}</Text>
                                                ))}
                                            </View>

                                        ))

                                    }
                                    <View style={styles.MarginBottom} />
                                    <View style={styles.generalHeaderStyle}>
                                        <Text style={styles.CommentStyle}>{this.props.bottomMessage}</Text>
                                    </View>
                                    <View style={styles.MarginBottom} />
                                    <View style={styles.MarginBottom} />
                                </View>

                            </Page>
                        </Document>
                    </PDFViewer>
                </ModalOrders>
            </React.Fragment>
        )
    }
}

