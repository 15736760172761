import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { DateFormatMAS90, getDateYearMonthDayDash } from '../functions/dateFormat.js';
import "../css/general-style.css"
import '../css/table-responsive.css'
import { pricesFormat } from '../functions/pricesFormat.js';
import { ItemDesc, NumberFormat } from '../functions/generalFunctions.js';
import ModalOrders from './ModalComponent.js';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        InvoiceListCustomer: [],
        invoiceCustomerListFilter: [],
        ProductsPerPage: 20,
        showModal:false
    }

    async getInvoicesHeader(refresh) {
        await this.handleModalOpen()
        const data = {
            CustomerNo: this.props.CustomerNo,
            idCompany: Number(getValueCookie('CompanyId'))
        }


        const buscador = document.getElementById('InvoiceCust_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.InvoiceListCustomer.length <= 0) {
            this.ModalLoading.current.showState(true);
            this.setState({ InvoiceListCustomer: [], invoiceCustomerListFilter: [] });

            const respuesta = await getInformationWithData('/invoice/history/header', data);

            if (respuesta.status.code === 1) {
                respuesta.data = respuesta.data.slice(0,100)
                console.log(respuesta.data)
                this.setState({ InvoiceListCustomer: respuesta.data, invoiceCustomerListFilter: respuesta.data });

            }
            this.ModalLoading.current.showState(false);
        }

        setTimeout(() => { buscador.focus(); }, 300);

    }

    async getInvoiceDetail(InvoiceHeader) {
        //Aqui asignamos el producto al estado del padre

        const Father = this.props.Padre
        this.ModalLoading.current.showState(true);
        if (this.props.nombrePadre === 'InvoiceListCustomer') {
            const temporal = Father.state.Invoice
            temporal.InvoiceNo = ''
            temporal.InvoiceDate = ''
            temporal.SalespersonNo = getValueCookie('SalesPerson')
            temporal.Comment = ''
            temporal.FreightAmt = 0
            temporal.ApplyToInvoiceNo = InvoiceHeader.InvoiceNo
            Father.setState({ Invoice: temporal, oldInvoice: true })
            /*
             InvoiceNo: '',
            InvoiceDate: '',
            BatchNo: '',
            InvoiceType: 'IN',
            SalespersonNo: '',
            Comment: '',
            FreightAmt: 0,

            Customer: {
                AddressLine1: '',
                City: '',
                CountryCode: '',
                CreditLimit: 0,
                CustomerName: '',
                CustomerNo: '',
                EmailAddress: '',
                FaxNo: '',
                PriceLevel: '',
                SalespersonNo: '',
                State: '',
                TaxSchedule: '',
                TelephoneNo: '',
                TermsCode: '',
                UDF_RUTA: '',
                ZipCode: '',
            },
            TotalQuantityOrdered: 0,
            TotalCost: 0
            */

            await Father.BatchNumberModal('New')
        } else if (this.props.nombrePadre === 'AccountsListCustomer') {
            const data = {
                idCompany: Number(getValueCookie('CompanyId')),
                InvoiceNo: InvoiceHeader.InvoiceNo,
                HeaderSeqNo: InvoiceHeader.HeaderSeqNo
            }
            const result = await getInformationWithData('/invoice/history/detail', data)

            if (result.status.code === 1) {
                const temporal = Father.state.Accounts
                temporal.InvoiceDate = getDateYearMonthDayDash()
                temporal.SalespersonNo = getValueCookie('SalesPerson')
                temporal.Comment = ''
                temporal.FreightAmt = 0
                temporal.ApplyToInvoiceNo = InvoiceHeader.InvoiceNo
                let prod = []
                result.data.forEach(item => {

                    const itemJson = {
                        itemCode: item.ItemCode,
                        abbreviatedDesc: item.ItemCodeDesc,
                        upc: item.AliasItemNo,
                        quantity: 0,
                        ShipWeight: item.LineWeight,
                        StandardUnitCost: item.UnitCost,
                        LastTotalUnitCost: item.UnitCost,
                        UnitPrice: item.UnitPrice,
                        upc2: 0,
                        caseupc: 0,
                        BIN: "",
                        totalWeight: Number(item.LineWeight) * Number(item.QuantityShipped),
                        Comment: "",
                        unitCost: Number(item.UnitCost),
                        quantityOrdered: item.QuantityOrdered,
                        quantityReceived: 0,
                        quantityShipped: item.QuantityShipped,
                        totalCost: Number(item.QuantityShipped) * Number(item.UnitPrice),
                        totalFreight: 0,
                        Visible: 1,
                        BatchUnits: 0,
                        DiscountUnits: 0,
                        SuggestedDiscount: 0
                    }
                    prod.push(itemJson)
                });
                prod = await ItemDesc(prod, 'abbreviatedDesc', 'itemCode')
                Father.setState({ Accounts: temporal, Products: prod, FilterProducts: prod })
                await Father.getNewCorrelativeInvoiceNumber()
            }




        }
        this.ModalLoading.current.showState(false);
        await this.handleModalClose()
    }

    contiene(parametro, busqueda) {
        if (parametro && busqueda) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        }
        return false

    }

    searchInvoice = e => {

        let busqueda = e.target.value;
        let orders = []
        if (busqueda) {
            orders = this.state.InvoiceListCustomer.filter((order_) => {

                if (((
                    this.contiene(order_.InvoiceNo, busqueda) ||
                    this.contiene(order_.BillToName, busqueda) ||
                    this.contiene(order_.CustomerNo, busqueda)
                ))
                ) {
                    return order_
                } else {
                    return null
                }
            });
        } else {
            orders = this.state.InvoiceListCustomer
        }

        this.setState({ invoiceCustomerListFilter: orders });
    }

    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {

        this.setState({ showModal: false })
    }

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className="btn blueButton btn-lg w-100" onClick={() => this.getInvoicesHeader(true)}><AiOutlineFileText /> {this.props.buttonTitle}</button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Invoice List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="modal-body">
                        <div className='row form-group pt-4'>

                            <div className='col-sm-12 pb-4'>
                                <div className="input-group input-group-lg flex-nowrap">
                                    <input className='form-control w-75 display-inline' placeholder='Search by...' id={'InvoiceCust_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchInvoice} />
                                    <span className="input-group-text">
                                        <button className='btn greenButton btn-lg' onClick={() => this.getInvoicesHeader(true)}><BsArrowRepeat /></button>
                                    </span>
                                </div>
                            </div>
                            <div className='col-sm-12 tableFixHead'>
                                <table className='table align-middle table-hover'>
                                    <thead className='table-dark thead'>
                                        <tr className='text-center'>
                                            <th>Invoice</th>
                                            <th>Sales Person</th>
                                            <th>Date</th>
                                            <th>Customer No</th>
                                            <th>Bill To Name</th>
                                            <th></th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.invoiceCustomerListFilter.map((element, e) => {
                                                if (element && element.InvoiceNo) {
                                                    return (
                                                        <tr key={e} onClick={() => this.getInvoiceDetail(element)}>
                                                            <td className='text-center'>{element.InvoiceNo}</td>
                                                            <td className='text-center'>{element.SalespersonNo}</td>
                                                            <td className='text-center'>{DateFormatMAS90(element.InvoiceDate)}</td>
                                                            <td className='text-center'>{element.CustomerNo}</td>
                                                            <td className='text-start'>{element.BillToName}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(element.NonTaxableSalesAmt))}</td>
                                                        </tr>
                                                    )
                                                }
                                                return null;
                                            })
                                        }
                                    </tbody>

                                </table>
                            </div>

                        </div>
                        
                    </div>
                </ModalOrders>

            </>
        )
    }
}
