import React, { Component } from 'react'
import '../../css/CartaProducto.css'
import '../../css/scrollBar.css'
import { getPriceMargin, getPurePriceMargin } from '../../functions/generalFunctions'
import { getIndexElement } from '../../functions/searchInObject'
import { getValueCookie } from '../../services/cookieService'


export default class ItemCard extends Component {
    addQuantity(data) {
        data.InputValue += 1
        if (this.props.nombrePadre === 'Invoice') {
            data.Visible = 1
            data.quantityShipped = data.InputValue
            this.UpdatePrincipalItemList(data)
        } else if (this.props.nombrePadre === 'SalesOrder') {
            data.Visible = 1
            data.quantityOrdered = data.InputValue
            this.UpdatePrincipalItemList(data)
        } else if (this.props.nombrePadre === 'ReturnPO') {
            data.QuantityReturned = data.InputValue
            this.UpdatePrincipalItemList(data)

        } else if (this.props.nombrePadre === 'PurchaseOrder') {
            if (this.props.State === '1' || this.props.State === '') {
                data.quantityOrdered = data.InputValue
                this.UpdatePrincipalItemList(data)
            } else {
                data.quantityReceived = data.InputValue
                this.UpdatePrincipalItemList(data)
            }

        }
        document.getElementById('QuantityAdded_' + data.itemCode).value = data.InputValue

    }

    removeQuantity(data) {
        let res = data.InputValue - 1
        
        if (this.props.nombrePadre === 'SalesOrder' || this.props.nombrePadre === 'Invoice') {
            if (res > 0) {
                if (this.props.nombrePadre === 'Invoice') {
                    data.quantityShipped = res
                } else if (this.props.nombrePadre === 'SalesOrder') {
                    data.quantityOrdered = res
                }
                data.InputValue = res
                document.getElementById('QuantityAdded_' + data.itemCode).value = data.InputValue
                this.UpdatePrincipalItemList(data)
            } else if (res === 0) {
                data.Visible = 0
                data.quantityShipped = 0
                data.quantityOrdered = 0

                data.InputValue = res
                document.getElementById('QuantityAdded_' + data.itemCode).value = data.InputValue
                this.UpdatePrincipalItemList(data)
            }
        } else if (this.props.nombrePadre === 'ReturnPO') {
            if (res < 0) {
                res = 0
            }
            data.QuantityReturned = res
            data.InputValue = res

            this.UpdatePrincipalItemList(data)
            document.getElementById('QuantityAdded_' + data.itemCode).value = res
        } else {
            if (this.props.nombrePadre === 'PurchaseOrder') {
                if (res > 0) {
                    if (this.props.State === '1' || this.props.State === '') {
                        data.quantityOrdered = res
                    } else {
                        data.quantityReceived = res
                    }

                    data.InputValue = res
                    document.getElementById('QuantityAdded_' + data.itemCode).value = data.InputValue
                    this.UpdatePrincipalItemList(data)
                } else if (res === 0) {
                    if (this.props.State === '1' || this.props.State === '') {
                        data.quantityOrdered = res
                    } else {
                        data.quantityReceived = res
                    }
                    data.InputValue = res
                    document.getElementById('QuantityAdded_' + data.itemCode).value = data.InputValue
                    this.UpdatePrincipalItemList(data)
                }
            }
        }
    }

    async UpdatePrincipalItemList(product) {
        product.HaveUPC=((product.upc)||(product.upc2)||(product.caseupc))?true:false
        
        const Padre = this.props.Padre
        if (this.props.nombrePadre !== 'ReturnPO') {
            const Products = Padre.state[this.props.Array];
            const index = getIndexElement(Products, 'itemCode', product.itemCode)

            if (index !== -1) {
                if (this.props.nombrePadre === 'SalesOrder' || this.props.nombrePadre === 'Invoice') {
                    if (Products[index].Visible === 0) {
                        if (this.props.nombrePadre === 'SalesOrder') {
                            Products[index].quantityOrdered = product.InputValue
                            Products[index].totalCost = Number(Products[index].quantityOrdered) * Number(Products[index].UnitPrice)
                            product.quantityOrdered === 0 ? Products[index].Visible = 0 : Products[index].Visible = 1
                        } else if (this.props.nombrePadre === 'Invoice') {
                            Products[index].RegisterType='m'
                            Products[index].Pallet=1
                            Products[index].quantityShipped = product.InputValue
                            Products[index].totalCost = Number(Products[index].quantityShipped) * Number(Products[index].UnitPrice)
                            Products[index].totalWeight = Number(Products[index].quantityShipped) * Number(Products[index].ShipWeight)
                            product.quantityShipped === 0 ? Products[index].Visible = 0 : Products[index].Visible = 1
                        }

                    } else {
                        if (this.props.nombrePadre === 'SalesOrder') {
                            Products[index].quantityOrdered = product.InputValue
                            Products[index].totalCost = Number(Products[index].quantityOrdered) * Number(Products[index].UnitPrice)
                            product.quantityOrdered === 0 ? Products[index].Visible = 0 : Products[index].Visible = 1
                        } else if (this.props.nombrePadre === 'Invoice') {
                            Products[index].RegisterType=Products[index].RegisterType!=='s'?'m':Products[index].RegisterType
                            Products[index].quantityShipped = product.InputValue
                            Products[index].Pallet=1
                            Products[index].totalCost = Number(Products[index].quantityShipped) * Number(Products[index].UnitPrice)
                            Products[index].totalWeight = Number(Products[index].quantityShipped) * Number(Products[index].ShipWeight)
                            product.quantityShipped === 0 ? Products[index].Visible = 0 : Products[index].Visible = 1
                        }
                    }

                    if (getValueCookie('CompanyId') === '6' && Products[index].Visible === 1 && this.props.nombrePadre === 'SalesOrder') {

                        Products[index].Comment = "BOX:" + getPriceMargin(Products[index].UnitPrice, Products[index].unitCost, this.props.PriceLevel)
                        Products[index].PureMargin=getPurePriceMargin(Products[index].UnitPrice, Products[index].unitCost)
                    }
                    const startOfTheList = Products[index]
                    await Products.splice(index, 1)
                    await Products.unshift(startOfTheList)
                    await Padre.setState({ Products: Products });
                    await Padre.calculateTotals()
                    await Padre.refreshInputs()
                } else {
                    if (this.props.nombrePadre === 'PurchaseOrder') {
                        if (this.props.State === '1' || this.props.State === '') {
                            Products[index].quantityOrdered = product.InputValue
                            Products[index].totalCost = Number(Products[index].quantityOrdered) * Number(Products[index].unitCost)
                        } else {
                            Products[index].quantityReceived = product.InputValue
                            Products[index].totalCost = Number(Products[index].quantityReceived) * Number(Products[index].unitCost)

                        }
                        await Padre.setState({ products: Products });
                        await Padre.calculateTotals()
                        await Padre.updateInputs()
                    }
                }



            } else {
                if (this.props.nombrePadre === 'SalesOrder' || this.props.nombrePadre === 'Invoice') {
                    if (this.props.nombrePadre === 'Invoice') {
                        product.preparItemInvoice(product.InputValue, this.props.PriceLevel)
                        product.RegisterType='m'
                    } else if (this.props.nombrePadre === 'SalesOrder') {
                        product.preparItemSalesOrder(product.InputValue, this.props.PriceLevel)
                    }
                    await Products.unshift(product);
                    await Padre.setState({ Products: Products });
                    await Padre.calculateTotals()
                    await Padre.refreshInputs()
                } else {
                    if (this.props.nombrePadre === 'PurchaseOrder') {
                        await product.preparItemPurchase(product.InputValue, this.props.State)
                        await Products.push(product);
                        await Padre.setState({ products: Products });
                        await Padre.calculateTotals()
                        await Padre.updateInputs()
                    }
                }


                //

            }
            if (this.props.nombrePadre === 'SalesOrder' || this.props.nombrePadre === 'Invoice') {
                await Padre.AutoSave()
            }
        } else {
            if (this.props.nombrePadre === 'ReturnPO') {
                Padre.addItem(product)
            }
        }


    }

    onChangeQuantity = async (e, item) => {



        const value = Number(e.target.value)
        item.InputValue = value

        if (this.props.nombrePadre === 'Invoice') {
            item.quantityShipped = value
            item.Visible = value >= 0 ? 0 : 1
        } else if (this.props.nombrePadre === 'SalesOrder') {
            item.quantityOrdered = value
            item.Visible = value >= 0 ? 0 : 1
        } else if (this.props.nombrePadre === 'ReturnPO') {
            item.QuantityReturned = value

        } else if (this.props.nombrePadre === 'PurchaseOrder') {
            if (this.props.State === '1' || this.props.State === '') {
                item.quantityOrdered = value
            } else {
                item.quantityReceived = value
            }
        }
        await this.UpdatePrincipalItemList(item)
    }



    render() {
        return (
            <div className="conteiner cartaHover font-family-arial h-100 w-100" >
                <div className='row item-card py-4'>
                    <div className="col-4 imgC ">
                        <img key={this.props.itemURLImage} src={this.props.itemURLImage ? `${this.props.itemURLImage}` : "./assets/notavailable.png"} alt="Card  cap" className='imgC' />
                    </div>
                    <div className="col-8 descriptionMargin">
                        <div className="row">
                            <h5>
                                <span class="badge bg-primary me-3">&nbsp;{this.props.ItemCode}&nbsp;</span>
                                <span class="badge bg-secondary">On Hand: {this.props.OnHand ? this.props.OnHand : 0}</span>
                            </h5>
                        </div>
                        <div className="text-start my-2">
                            <p className="card-text fs-5">{this.props.Description}</p>
                        </div>

                        {
                            this.props.needPrice ?
                                <div className="row text-start mb-2" >
                                    <p className="card-text fs-5"><span class="badge bg-dark me-3">&nbsp;Unit price: ${this.props.UnitPrice ? this.props.UnitPrice.toFixed(2) : this.props.UnitPrice}&nbsp;</span></p>
                                </div>
                                :
                                <div></div>
                        }
                        {
                            this.props.needCost ?
                                <div className="row text-start mb-2" >
                                    <p className="card-text fs-5"><span class="badge bg-dark me-3">&nbsp;Unit cost: ${this.props.LastTotalUnitCost ? this.props.LastTotalUnitCost.toFixed(2) : this.props.LastTotalUnitCost}&nbsp;</span></p>
                                </div>
                                :
                                <div></div>
                        }

                        <div className='row me-2'>
                            <div className="input-group input-group-lg flex-nowrap pt-2">
                                <span className="input-group-addon controlZIndex">
                                    <button onClick={() => this.removeQuantity(this.props.objectProd)} className='btn grayButton btn-lg'>-</button>
                                </span>
                                <input defaultValue={this.props.objectProd.InputValue} onChange={(e) => this.onChangeQuantity(e, this.props.objectProd)} key={this.props.ItemCode} type={'number'} id={'QuantityAdded_' + this.props.ItemCode} min={'0'} className='form-control text-center w-10 display-inline' />
                                <span className="input-group-addon controlZIndex">
                                    <button onClick={() => this.addQuantity(this.props.objectProd)} className='btn blueButton btn-lg'>+</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


