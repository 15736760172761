import React, { Component } from 'react'
import LoadingWindow from '../../components/LoadingWindow';
import TableListofUnsoldProducts from '../../components/TableListofUnsoldProducts/TableListofUnsoldProducts';
import { getValueCookie } from '../../services/cookieService';
import { getInformationWithData, getInformationWithDataGlobalSales } from '../../services/CABE'
import { confirmCloseAlert } from '../../functions/alerts';
import { MonthInLetters, filterAndSortData } from '../../functions/generalFunctions';
import TableInventoryStatus from '../../components/TableInventoryStatus/TableInventoryStatus'
import { getPurchaseDetail } from './Functions';
import TableofPurchaseVendor from '../../components/TableofPurchaseVendor/TableofPurchaseVendor';
import { PurchaseByVendors } from './Class';
import TableInventoryRotation1 from '../../components/TableInvetoryRotation1/TableInventoryRotation1'
import TableInventoryRotation2 from '../../components/TableInventoryRotation2/TableInventoryRotation2'
export default class Purcharsing extends Component {
    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }

    state = {
        Data: [],
        Shortage: [],
        Rotation: [],
        RotationProdLine: [],
        PurchaseByVendor: new PurchaseByVendors(),
        Date: new Date(),
        Date2: new Date(),
        Date3: new Date(),
        pageNumers: [],
    }
    async ItemsGetLastSaleList() {
        this.ModalLoading.current.showState(true);

        const data = {
            IdCompany: 3,
            Start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substring(0, 10),
            End: new Date().toISOString().substring(0, 10),
        }
        let flag = true;

        const result = await getInformationWithData('/Items/Get/LastSaleList', { idCompany: 3 });
        result.status.code === 1 ? this.setState({ Data: result.result }) : flag = false;

        const result2 = await getInformationWithDataGlobalSales('/invoice/Shortage/Dashboard', { idCompany: 3 });
        result2.status.code === 1 ? this.setState({ Shortage: result2.data }) : flag = false;

        const result3 = await getInformationWithDataGlobalSales('/Invoice/InvRotation', data);
        result3.status.code === 1 ? this.setState({ Rotation: result3.data }) : flag = false;
        console.log("result3", result3.data)

        const result4 = await getInformationWithDataGlobalSales('/Invoice/InvRotationByProdLine', data);
        result4.status.code === 1 ? this.setState({ RotationProdLine: result4.data }) : flag = false;
        console.log("result4", result4.data)

        //console.log("result 4", result4)

        if (!flag) {
            this.ModalLoading.current.showState(false);
            confirmCloseAlert('incorrect', 'Whoops we have an error. Please try again!')
        }

        const PurchaseByVendor = await getPurchaseDetail(null)
        this.setState({ PurchaseByVendor: PurchaseByVendor })
        this.ModalLoading.current.showState(false);
        this.pagination()
    }
    async changeAllMonthData(DateC) {
        this.ModalLoading.current.showState(true);
        const data = await getPurchaseDetail(new Date(DateC))

        await this.setState({ PurchaseByVendor: data, Date: new Date(DateC) })
        this.ModalLoading.current.showState(false);
    }

    async changeMonth(data, date) {
        this.ModalLoading.current.showState(true);
        await this.setState({ Date2: new Date(date) })

        if (new Date().toISOString().substring(0, 10) === this.state.Date2.toISOString().substring(0, 10)) {
            data = {
                IdCompany: 3,
                Start: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substring(0, 10),
                End: new Date().toISOString().substring(0, 10),
            }
        }
        const result1 = await getInformationWithDataGlobalSales('/Invoice/InvRotation', data);
        const result2 = await getInformationWithDataGlobalSales('/Invoice/InvRotationByProdLine', data);
        this.setState({ Rotation: result1.data, RotationProdLine: result2.data })
        this.ModalLoading.current.showState(false);
    }

    pagination() {

        const temp = []
        for (let i = 1; i <= Math.ceil(this.state.Rotation.length / 70); i++) {
            temp.push(i)
        }
        //console.log("aber el temp",temp)
        this.setState({ pageNumers: temp })
    }

    render() {
        return (
            <div>
                <LoadingWindow ref={this.ModalLoading} />
                <button hidden id='actionatorPurchasing' onClick={() => this.state.Shortage.length !== 0 ? null : this.ItemsGetLastSaleList()}>button...</button>
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Purchasing</h2>
                </div>
                <div className='row pb-4 d-flex justify-content-center'>
                    <div className={'col-sm-6 ' + (getValueCookie('CompanyId') === '6' ? 'd-none' : '')}>
                        <div hidden={getValueCookie('CompanyId') === '6'} className='col-12 pt-2 pb-3'>
                            <TableListofUnsoldProducts /* SalesPerson={getValueCookie('SalesPerson')}*/ data1={this.state.Data} data={filterAndSortData(this.state.Data, 30)} Title={"List of Unsold Products"} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="col-12 pt-2 pb-3">
                            <TableofPurchaseVendor Father={this} Date={this.state.Date} Month={MonthInLetters(this.state.Date.getMonth() + 1) + " " + this.state.Date.getFullYear()} data={this.state.PurchaseByVendor} Title={"Purchase By Vendor"} />
                        </div>
                    </div>
                </div>
                <div className='row pb-4 justify-content-between'>
                    <div className="">
                        <div hidden={getValueCookie('CompanyId') === '6'} className="col-12 pt-2 pb-3">
                            <TableInventoryStatus /* SalesPerson={getValueCookie('SalesPerson')}*/ data={this.state.Shortage} Title={"Inventory Status"} />
                        </div>
                    </div>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        <div className='row pb-4 d-flex justify-content-between'>
                            <div className='col-sm-12 text-center'>
                                <h5 className='card-title'>Inventory Rotation</h5>
                            </div>
                            <div className="col-sm-6">
                                <div className="col-12 pt-2 pb-3">
                                    <TableInventoryRotation1 Father={this} Date={this.state.Date2} Month={MonthInLetters(this.state.Date2.getMonth() + 1) + " " + this.state.Date2.getFullYear()} data={this.state.Rotation} pageNumers={this.state.pageNumers} Title={"Table 1"} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="col-12 pt-2 pb-3">
                                    <TableInventoryRotation2 Father={this} Date={this.state.Date2} Month={MonthInLetters(this.state.Date2.getMonth() + 1) + " " + this.state.Date.getFullYear()} data={this.state.RotationProdLine} Title={"Table 2"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}