
import React, { Component } from "react";
import '../../css/table-responsive.css'
import "../../css/general-style.css"
import { AuthContext } from "../../services/auth/authContext";
import { getValueCookie } from "../../services/cookieService";
import TableSalesTypeView from "../../components/TableofSalesType/TableSalesTypeView";
import { getCurrentBalance, getCustomerExcess, getCustomerHistory, getSalesByCustomer, getSalesOrdersPerUser, getSalesPerMonth, getSalesPerRoute, getSalesPerRouteAllYear, getSalesPerSalesPerson, getShorts } from "./Functions";
import TableofPendingSalesView from "../../components/TableofPendingSales/TableofPendingSalesView";
import TableofExcessCustomerView from "../../components/TableofExcessCustomer/TableofExcessCustomerView";
import TableofCurrentBalanceView from "../../components/TableofCurrentBalance/TableofCurrentBalanceView";
import { MonthInLetters, NameSalesPerson, NumberFormat } from "../../functions/generalFunctions";
import CalendarOfSales from "../../components/CalendarOfSales/CalendarOfSales";
import TableHistoryCustomerView from "../../components/TableHistoryCustomer/TableHistoryCustomerView";
import TableDetailSalesPerRouteView from "../../components/TableDetailSalesPerRoute/TableDetailSalesPerRouteView";
import TableShortView from "../../components/TableShort/TableShortView";
import { getIndexElement } from "../../functions/searchInObject";
import LoadingWindow from '../../components/LoadingWindow';
import TableSalesPerSalesPersonView from "../../components/TableofSalesPerSalesPerson/TableSalesPerSalesPersonView";
import BarSalesDasboard from "../../components/BarSalesDashboard/BarSalesDasboard";




export default class AdminLobby extends Component {
    static contextType = AuthContext
    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();

    }


    state = {
        data: []

    }

    getInfo(SalesPerson) {
        let button = document.getElementById('LobbyInfoCharge' + SalesPerson)
        if (button) {
            button.click()
        }
    }

    async getMultipleInfo() {

        let data = []
        let flag = true
        for (const SalesPerson of this.props.SalesPersonList) {
            if (flag) {
                const SalesPerRoute = await getSalesPerRoute(SalesPerson)
                const PendingSalesOrder = await getSalesOrdersPerUser(SalesPerson)
                const CustomerExcess = await getCustomerExcess(SalesPerson)
                const CurrentBalance = await getCurrentBalance(SalesPerson)
                const CustomerHistory = await getCustomerHistory(SalesPerson)
                const SalesByCustomer = await getSalesByCustomer(SalesPerson)
                const SalesPerMonth = await getSalesPerMonth(SalesPerson)
                const SalesPerSalesPersons = await getSalesPerSalesPerson(SalesPerson)
                const Shorts = await getShorts(SalesPerson)
                let RouteYearSales = []
                if (SalesPerson === 'TOT') {
                    RouteYearSales = await getSalesPerRouteAllYear()
                }


                let TotSales = {
                    NoInvoices: 0,
                    Total: 0
                }
                let TotalCustomer = {
                    Normal: 0,
                    Frequent: 0,
                    Waring: 0,
                    Risk: 0,
                    Total: 0
                }
                let TotalPendingInvoices = {
                    TotalDueInv: 0,
                    Range1: 0,
                    Range2: 0,
                    Range3: 0,
                    TotalR1: 0,
                    TotalR2: 0,
                    TotalR3: 0
                }
                let NotStarted = 0
                let InProcess = 0
                let Finished = 0
                for (const Invoice of CurrentBalance) {
                    if (Invoice.Balance > 0) {
                        TotalPendingInvoices.TotalDueInv += Invoice.Balance
                    }
                    if (Number(Invoice.DaysDlq) <= 14) {
                        TotalPendingInvoices.Range1 += 1
                        TotalPendingInvoices.TotalR1 += Invoice.Balance > 0 ? Invoice.Balance : 0
                    } else if (Number(Invoice.DaysDlq) >= 15 && Number(Invoice.DaysDlq) <= 29) {
                        TotalPendingInvoices.Range2 += 1
                        TotalPendingInvoices.TotalR2 += Invoice.Balance > 0 ? Invoice.Balance : 0
                    } else {
                        TotalPendingInvoices.Range3 += 1
                        TotalPendingInvoices.TotalR3 += Invoice.Balance > 0 ? Invoice.Balance : 0
                    }
                }
                for (const SalesOrder of PendingSalesOrder) {

                    SalesOrder.Status = SalesOrder.Status ? SalesOrder.Status : 0
                    switch (SalesOrder.Status) {
                        case 0:
                            NotStarted += SalesOrder.NonTaxableAmt
                            break;
                        case 1:
                            InProcess += SalesOrder.NonTaxableAmt
                            break;
                        case 2:
                            Finished += SalesOrder.NonTaxableAmt
                            break;
                        default:
                            break;
                    }
                }
                for (const Customer of CustomerHistory) {
                    if (Number(Customer.NumberDays) <= 21) {
                        TotalCustomer.Frequent += 1
                    } else if (Number(Customer.NumberDays) > 21 && Number(Customer.NumberDays) <= 45) {
                        TotalCustomer.Normal += 1
                    } else if (Number(Customer.NumberDays) > 45 && Number(Customer.NumberDays) <= 90) {
                        TotalCustomer.Waring += 1
                    } else {
                        TotalCustomer.Risk += 1
                    }
                    TotalCustomer.Total += 1
                }
                for (const Sale of SalesPerMonth) {
                    if (Sale.Week) {
                        TotSales.NoInvoices += Number(Sale.totalsales)
                        TotSales.Total += Number(Sale.total)
                    }
                }
                let temporal = {
                    Load: false,
                    SalesPerRouteDate: new Date(),
                    SalesPerSalesPersonDate: new Date(),
                    SalesPerRoute: SalesPerRoute,
                    SalesPerSalesPersons: SalesPerSalesPersons,
                    CustomerHistory: CustomerHistory,
                    PendingSalesOrders: PendingSalesOrder,
                    CustomerExcess: CustomerExcess,
                    CurrentBalance: CurrentBalance,
                    SalesPerson: SalesPerson,
                    SalesByCustomer: SalesByCustomer,
                    SalesPerMonth: SalesPerMonth,
                    Shorts: Shorts,
                    RouteYearSales: RouteYearSales,
                    Totals: {
                        TotalPendingInvoices: TotalPendingInvoices,
                        SalesOrder: {
                            NotStarted: NotStarted,
                            InProcess: InProcess,
                            Finished: Finished
                        },
                        Customer: TotalCustomer,
                        Sales: TotSales
                    }
                }
                data.push(temporal)
            } else {
                let temporal = {
                    Load: true,
                    SalesPerRouteDate: new Date(),
                    SalesPerSalesPersonDate: new Date(),
                    SalesPerRoute: { Information: [], Months: [] },
                    SalesPerSalesPersons: { Information: [], Months: [] },
                    CustomerHistory: [],
                    PendingSalesOrders: [],
                    CustomerExcess: [],
                    CurrentBalance: [],
                    SalesPerson: SalesPerson,
                    SalesByCustomer: [],
                    SalesPerMonth: [],
                    RouteYearSales: [],
                    Shorts: {},
                    Totals: {
                        TotalPendingInvoices: {
                            TotalDueInv: 0,
                            Range1: 0,
                            Range2: 0,
                            Range3: 0,
                            TotalR1: 0,
                            TotalR2: 0,
                            TotalR3: 0
                        },
                        SalesOrder: {
                            NotStarted: 0,
                            InProcess: 0,
                            Finished: 0
                        },
                        Customer: {
                            Normal: 0,
                            Frequent: 0,
                            Waring: 0,
                            Risk: 0,
                            Total: 0
                        },
                        Sales: {
                            NoInvoices: 0,
                            Total: 0
                        }
                    }
                }
                data.push(temporal)
            }
            flag = false


        }

        this.setState({ data: data })

    }

    async getSpecificInfo(flag, SalesPerson) {
        if (flag) {
            this.ModalLoading.current.showState(true);
            const SalesPerRoute = await getSalesPerRoute(SalesPerson)
            const PendingSalesOrder = await getSalesOrdersPerUser(SalesPerson)
            const CustomerExcess = await getCustomerExcess(SalesPerson)
            const CurrentBalance = await getCurrentBalance(SalesPerson)
            const CustomerHistory = await getCustomerHistory(SalesPerson)
            const SalesByCustomer = await getSalesByCustomer(SalesPerson)
            const SalesPerMonth = await getSalesPerMonth(SalesPerson)
            const SalesPerSalesPersons = await getSalesPerSalesPerson(SalesPerson)
            const Shorts = await getShorts(SalesPerson)
            let RouteYearSales = []
            if (SalesPerson === 'TOT') {
                RouteYearSales = await getSalesPerRouteAllYear()
            }
            let TotalCustomer = {
                Normal: 0,
                Frequent: 0,
                Waring: 0,
                Risk: 0,
                Total: 0
            }
            let TotalPendingInvoices = {
                TotalDueInv: 0,
                Range1: 0,
                Range2: 0,
                Range3: 0,
                TotalR1: 0,
                TotalR2: 0,
                TotalR3: 0
            }
            let TotSales = {
                NoInvoices: 0,
                Total: 0
            }
            let NotStarted = 0
            let InProcess = 0
            let Finished = 0
            for (const Invoice of CurrentBalance) {
                if (Invoice.Balance > 0) {
                    TotalPendingInvoices.TotalDueInv += Invoice.Balance
                }
                if (Number(Invoice.DaysDlq) <= 14) {
                    TotalPendingInvoices.Range1 += 1
                    TotalPendingInvoices.TotalR1 += Invoice.Balance > 0 ? Invoice.Balance : 0
                } else if (Number(Invoice.DaysDlq) >= 15 && Number(Invoice.DaysDlq) <= 29) {
                    TotalPendingInvoices.Range2 += 1
                    TotalPendingInvoices.TotalR2 += Invoice.Balance > 0 ? Invoice.Balance : 0
                } else {
                    TotalPendingInvoices.Range3 += 1
                    TotalPendingInvoices.TotalR3 += Invoice.Balance > 0 ? Invoice.Balance : 0
                }
            }
            for (const SalesOrder of PendingSalesOrder) {

                SalesOrder.Status = SalesOrder.Status ? SalesOrder.Status : 0
                switch (SalesOrder.Status) {
                    case 0:
                        NotStarted += SalesOrder.NonTaxableAmt
                        break;
                    case 1:
                        InProcess += SalesOrder.NonTaxableAmt
                        break;
                    case 2:
                        Finished += SalesOrder.NonTaxableAmt
                        break;
                    default:
                        break;
                }
            }
            for (const Customer of CustomerHistory) {
                if (Number(Customer.NumberDays) <= 21) {
                    TotalCustomer.Frequent += 1
                } else if (Number(Customer.NumberDays) > 21 && Number(Customer.NumberDays) <= 45) {
                    TotalCustomer.Normal += 1
                } else if (Number(Customer.NumberDays) > 45 && Number(Customer.NumberDays) <= 90) {
                    TotalCustomer.Waring += 1
                } else {
                    TotalCustomer.Risk += 1
                }
                TotalCustomer.Total += 1
            }
            for (const Sale of SalesPerMonth) {
                if (Sale.Week) {

                    TotSales.NoInvoices += Number(Sale.totalsales)
                    TotSales.Total += Number(Sale.total)
                }
            }

            let temporal = {
                Load: false,
                SalesPerRouteDate: new Date(),
                SalesPerSalesPersonDate: new Date(),
                SalesPerRoute: SalesPerRoute,
                SalesPerSalesPersons: SalesPerSalesPersons,
                CustomerHistory: CustomerHistory,
                PendingSalesOrders: PendingSalesOrder,
                CustomerExcess: CustomerExcess,
                CurrentBalance: CurrentBalance,
                SalesPerson: SalesPerson,
                SalesByCustomer: SalesByCustomer,
                SalesPerMonth: SalesPerMonth,
                Shorts: Shorts,
                RouteYearSales: RouteYearSales,
                Totals: {
                    TotalPendingInvoices: TotalPendingInvoices,
                    SalesOrder: {
                        NotStarted: NotStarted,
                        InProcess: InProcess,
                        Finished: Finished
                    },
                    Customer: TotalCustomer,
                    Sales: TotSales
                }
            }
            const index = getIndexElement(this.state.data, 'SalesPerson', SalesPerson)
            if (index !== -1) {
                let info = this.state.data
                info[index] = temporal
                await this.setState({ data: info })
                await this.asignTotalsCalendar(SalesPerMonth, SalesPerson)
            }
            this.ModalLoading.current.showState(false);
        }
    }


    async changeMonthSalesPerRoute(SalesPerson, DateC) {
        this.ModalLoading.current.showState(true);
        const temporal = this.state.data
        const index = await getIndexElement(this.state.data, 'SalesPerson', SalesPerson)
        const SalesPerRoute = await getSalesPerRoute(SalesPerson, new Date(DateC))
        const SalesByCustomer = await getSalesByCustomer(SalesPerson, new Date(DateC))
        if (index !== -1) {
            temporal[index].SalesPerRouteMonth = DateC
            temporal[index].SalesPerRoute = SalesPerRoute
            temporal[index].SalesByCustomer = SalesByCustomer
            this.setState({ data: temporal })
        }
        this.ModalLoading.current.showState(false);


    }
    async changeMonthSalesPerSalesPerson(SalesPerson, DateC) {
        this.ModalLoading.current.showState(true);
        const temporal = this.state.data
        const index = await getIndexElement(this.state.data, 'SalesPerson', SalesPerson)
        const SalesPerSalesPersons = await getSalesPerSalesPerson(SalesPerson, new Date(DateC))

        if (index !== -1) {
            temporal[index].SalesPerRouteMonth = DateC
            temporal[index].SalesPerSalesPersons = SalesPerSalesPersons

            this.setState({ data: temporal })
        }
        this.ModalLoading.current.showState(false);


    }

    async changeDateSalesPerMonth(SalesPerson, DateC) {
        this.ModalLoading.current.showState(true);

        const temporal = this.state.data
        let TotSales = {
            NoInvoices: 0,
            Total: 0
        }
        const index = await getIndexElement(this.state.data, 'SalesPerson', SalesPerson)
        const SalesPerMonth = await getSalesPerMonth(SalesPerson, new Date(DateC))
        if (index !== -1) {
            temporal[index].SalesPerRouteDate = new Date(DateC)
            temporal[index].SalesPerMonth = SalesPerMonth
            for (const Sale of SalesPerMonth) {
                if (Sale.Week) {
                    TotSales.NoInvoices += Number(Sale.totalsales)
                    TotSales.Total += Number(Sale.total)
                }
            }
            temporal[index].Totals.Sales = TotSales
            await this.setState({ data: temporal })
            await this.asignTotalsCalendar(SalesPerMonth, SalesPerson)
        }
        this.ModalLoading.current.showState(false);

    }

    async changeAllMonthData(SalesPerson, DateC) {
        await this.changeMonthSalesPerRoute(SalesPerson, DateC)
        await this.changeDateSalesPerMonth(SalesPerson, DateC)
        if (SalesPerson === 'TOT') {
            await this.changeMonthSalesPerSalesPerson(SalesPerson, DateC)
        }
    }


    asignTotalsCalendar(Data, SalesPerson) {
        for (const week of Data) {

            if (week.Week) {

                let m = document.getElementById('quant_' + week.Week + "_" + SalesPerson)
                let n = document.getElementById('sales_' + week.Week + "_" + SalesPerson)
                if (m) {
                    m.innerHTML = NumberFormat(Number(Number(week.totalsales).toFixed(0)))
                }
                if (n) {
                    n.innerHTML = "$" + NumberFormat(Number(Number(week.total).toFixed(0)))

                }
            }
        }
    }


    render() {
        return (
            <div >
                <button hidden id={"LobbyInfoCharge"} onClick={() => this.getMultipleInfo()}></button>
                <LoadingWindow ref={this.ModalLoading} />
                {getValueCookie('TypeUser') === '1'||getValueCookie('TypeUser') === '12' ?
                    <div className='col-sm-12'>
                        <ul className="nav nav-tabs" id="ScanningControls" role="tablist">
                            {this.state.data.map((element, e) => (
                                <li key={e} hidden={false} className="nav-item" role="presentation">
                                    <button onClick={() => this.getSpecificInfo(element.Load, element.SalesPerson)} id={'DashBoardFromSP' + element.SalesPerson} className={e === 0 ? "nav-link active" : "nav-link"} data-bs-toggle="tab" data-bs-target={"#DashSP" + element.SalesPerson} type="button" role="tab" aria-controls="DashBoardSP" aria-selected="true">{NameSalesPerson(element.SalesPerson)}</button>
                                </li>
                            ))}


                        </ul>
                        <div className="tab-content" id="allDashActive">
                            {this.state.data.map((element, e) => (
                                <div key={e} className={e === 0 ? "tab-pane fade show active" : "tab-pane fade show"} id={"DashSP" + element.SalesPerson} role="tabpanel" aria-labelledby="">
                                    <div className="row pb-4 justify-content-between backgroundGrayLow">
                                        {
                                            element.SalesPerson === 'TOT' ?
                                                <React.Fragment>
                                                    <div className="col-sm-6">
                                                        <div  className="col-12 pt-2 pb-3">
                                                            <TableSalesTypeView SalesPerson={element.SalesPerson} Father={this} Date={element.SalesPerRouteDate} Month={MonthInLetters(element.SalesPerRouteDate.getMonth() + 1) + " " + element.SalesPerRouteDate.getFullYear()} Title={"Sales by Distribution Channel"} data={element.SalesPerRoute} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div hidden={element.SalesPerson !== "TOT"} className="col-12 pt-2 pb-3">
                                                            <TableSalesPerSalesPersonView SalesPerson={element.SalesPerson} Father={this} Date={element.SalesPerRouteDate} Month={MonthInLetters(element.SalesPerRouteDate.getMonth() + 1) + " " + element.SalesPerRouteDate.getFullYear()} Title={"Sales by Sales Person"} data={element.SalesPerSalesPersons} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <BarSalesDasboard Title="Graphs of Sales" Data={element.RouteYearSales} />
                                                    </div>
                                                    <div className="col-sm-6">

                                                        <div  className="col-12 pt-2 pb-3">
                                                            <TableDetailSalesPerRouteView Title={"Sales by Customer"} data={element.SalesByCustomer} />
                                                        </div>
                                                        <div  className="col-12 pt-2 pb-3">
                                                            <CalendarOfSales Father={this} Totals={element.Totals.Sales} Date={element.SalesPerRouteDate} SalesPerson={element.SalesPerson} data={element.SalesPerMonth} Title={"Sales Per Day"} />
                                                        </div>
                                                        {element.SalesPerson !== "TOT" ?
                                                            <div  className="col-12 pt-2 pb-3">
                                                                <TableShortView SalesPerson={element.SalesPerson} data={element.Shorts} Title={"Shorts of Last 15 Days"} />
                                                            </div>
                                                            :
                                                            <></>}

                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row">

                                                            <div  className="col-sm-12">

                                                                <div className="row">
                                                                    <div className="col-12 pt-2 pb-3">
                                                                        <TableofCurrentBalanceView Title={"Past Due Invoices"} Totals={element.Totals.TotalPendingInvoices} data={element.CurrentBalance} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div  className="col-sm-12">

                                                                <div className="row">
                                                                    <div className="col-12 pt-2 pb-3">
                                                                        <TableofExcessCustomerView Title={"Customers with Exceeded Credit Limit"} data={element.CustomerExcess} />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            {element.SalesPerson !== "TOT" ?
                                                                <div className="col-sm-12">

                                                                    <div className="row">
                                                                        <div className="col-12 pt-2 pb-3">
                                                                            <TableofPendingSalesView Title={"Actual Sales Order"} Totals={element.Totals.SalesOrder} data={element.PendingSalesOrders} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <></>}

                                                            <div className="col-sm-12">

                                                                <div className="row">
                                                                    <div className="col-12 pt-2 pb-3">
                                                                        <TableHistoryCustomerView Title={"# Days Of Last Sale"} Totals={element.Totals.Customer} data={element.CustomerHistory} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="col-sm-6">
                                                        <div  className="col-12 pt-2 pb-3">
                                                            <TableSalesTypeView SalesPerson={element.SalesPerson} Father={this} Date={element.SalesPerRouteDate} Month={MonthInLetters(element.SalesPerRouteDate.getMonth() + 1) + " " + element.SalesPerRouteDate.getFullYear()} Title={"Sales by Distribution Channel"} data={element.SalesPerRoute} />
                                                        </div>
                                                        <div  className="col-12 pt-2 pb-3">
                                                            <TableDetailSalesPerRouteView Title={"Sales by Customer"} data={element.SalesByCustomer} />
                                                        </div>
                                                        <div  className="col-12 pt-2 pb-3">
                                                            <CalendarOfSales Father={this} Totals={element.Totals.Sales} Date={element.SalesPerRouteDate} SalesPerson={element.SalesPerson} data={element.SalesPerMonth} Title={"Sales Per Day"} />
                                                        </div>
                                                        {element.SalesPerson !== "TOT" ?
                                                            <div  className="col-12 pt-2 pb-3">
                                                                <TableShortView SalesPerson={element.SalesPerson} data={element.Shorts} Title={"Shorts of Last 15 Days"} />
                                                            </div>
                                                            :
                                                            <></>}

                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="row">
                                                            <div hidden={element.SalesPerson !== "TOT"} className="col-12 pt-2 pb-3">
                                                                <TableSalesPerSalesPersonView SalesPerson={element.SalesPerson} Father={this} Date={element.SalesPerRouteDate} Month={MonthInLetters(element.SalesPerRouteDate.getMonth() + 1) + " " + element.SalesPerRouteDate.getFullYear()} Title={"Sales by Sales Person"} data={element.SalesPerSalesPersons} />
                                                            </div>
                                                            <div  className="col-sm-12">

                                                                <div className="row">
                                                                    <div className="col-12 pt-2 pb-3">
                                                                        <TableofCurrentBalanceView Title={"Past Due Invoices"} Totals={element.Totals.TotalPendingInvoices} data={element.CurrentBalance} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div  className="col-sm-12">

                                                                <div className="row">
                                                                    <div className="col-12 pt-2 pb-3">
                                                                        <TableofExcessCustomerView Title={"Customers with Exceeded Credit Limit"} data={element.CustomerExcess} />
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            {element.SalesPerson !== "TOT" ?
                                                                <div className="col-sm-12">

                                                                    <div className="row">
                                                                        <div className="col-12 pt-2 pb-3">
                                                                            <TableofPendingSalesView Title={"Actual Sales Order Totals"} Totals={element.Totals.SalesOrder} data={element.PendingSalesOrders} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <></>}

                                                            <div className="col-sm-12">

                                                                <div className="row">
                                                                    <div className="col-12 pt-2 pb-3">
                                                                        <TableHistoryCustomerView Title={"# Days Of Last Sale"} Totals={element.Totals.Customer} data={element.CustomerHistory} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        }




                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                    : <></>}



            </div>


        )
    }
}