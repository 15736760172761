import React, { Component } from 'react'
import { getInformationWithData } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import { BsFileArrowUpFill, BsFileArrowDownFill } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import LoadingWindow from '../../../components/LoadingWindow';
import { getIMGByItemCode, ItemDesc, NumberFormat, OrderArray, random_rgba } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { DateFormatMAS90, getDateFromReports, OrderArrayByDateGenericLinesDesc } from '../../../functions/dateFormat';
import { ItemPerVendor, ItemsVendor, VendorDetail, Vendors } from './Class';
import { getIndexElement } from '../../../functions/searchInObject';
import ModalOrders from '../../../components/ModalComponent';
import ExcelDocument from '../../../components/ExcelDocument';
import { getValueCookie } from '../../../services/cookieService';
import { getDataSet } from '../../../functions/generateDataSetExcel';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';

export default class VendorReport extends Component {
    constructor(props) {
        super(props)
        this.ModalLoading = React.createRef();
        ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
            LinearScale,
            BarElement,
            Title, CategoryScale,
            LinearScale,
            PointElement,
            LineElement,

            Filler,
        );
    }
    state = {
        BodyFilter: [],
        flag: true,
        ItemVendorList: [],
        VendorItemList: [],
        AllInformation: [],
        mode: 'Item',
        ModalBody: [],
        ModalBodyFilter: [],
        ModalDetailBody: [],
        ModalFilterDetailBody: [],
        showModal1: false,
        order: true,
        currentItem: {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: ''
        },
        optionsLineGraph: {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'Costs in different months',
                },
            },
            scales: {
                y: {
                    suggestedMin: 0,
                    suggestedMax: 100
                }
            }
        },
        LineGraphData: {
            labels: [],
            datasets: [
                {
                    fill: true,
                    label: 'Costs in dolars',
                    data: [],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    spanGaps: true
                },
            ],
        }
    }

    async getVendors() {
        this.ModalLoading.current.showState(true);
        const result = await getInformationWithData('/Items/Vendors',{IdCompany:Number(getValueCookie('CompanyId'))})
        result.data=await ItemDesc(result.data,'ItemCodeDesc','ItemCode')
        let ItemVendorList = []
        let VendorItemList = []
        if (result.status.code === 1) {
            for (const item of result.data) {
                const index = getIndexElement(ItemVendorList, 'ItemCode', item.ItemCode)
                const index2 = getIndexElement(VendorItemList, 'VendorNo', item.VendorNo)
                if (index === -1) {
                    let it = new ItemsVendor(item.ItemCode, item.ItemCodeDesc, item.ProductLineDesc)
                    it.addVendor(new Vendors(item.VendorNo, item.VendorName, item.LastReceiptQuantity, item.LastUnitCost, item.LastReceiptDate))
                    ItemVendorList.push(it)
                } else {
                    ItemVendorList[index].addVendor(new Vendors(item.VendorNo, item.VendorName, item.LastReceiptQuantity, item.LastUnitCost, item.LastReceiptDate))
                    ItemVendorList[index].VendorList = OrderArrayByDateGenericLinesDesc(ItemVendorList[index].VendorList, 'LastReceiptDate')
                }

                if (index2 === -1) {
                    let vend = new Vendors(item.VendorNo, item.VendorName, null, null, null)
                    vend.addItem(new ItemPerVendor(item.LastReceiptQuantity, item.LastUnitCost, item.LastReceiptDate, item.ItemCode, item.ItemCodeDesc, item.ProductLineDesc))
                    VendorItemList.push(vend)
                } else {
                    VendorItemList[index2].addItem(new ItemPerVendor(item.LastReceiptQuantity, item.LastUnitCost, item.LastReceiptDate, item.ItemCode, item.ItemCodeDesc, item.ProductLineDesc))
                    VendorItemList[index2].ItemList = OrderArrayByDateGenericLinesDesc(VendorItemList[index2].ItemList, 'LastReceiptDate')
                }
            }
        }
        
        this.setState({ ItemVendorList: ItemVendorList, VendorItemList: VendorItemList, BodyFilter: ItemVendorList, mode: 'Item', AllInformation: result.data })
        this.ModalLoading.current.showState(false);
        
        document.getElementById("excelContainerVendors").classList.remove("d-none");
        document.getElementById("loadDataContainerVendors").classList.replace("col-12", "col-6");
    }

    valueRadioButton = async e => {
        let stat = e.target.value
        if (stat === 'Item') {
            this.setState({ BodyFilter: this.state.ItemVendorList, mode: stat })

        } else {
            this.setState({ BodyFilter: this.state.VendorItemList, mode: stat })
        }
    }

    async getVendorsList(index, it) {
        this.clearModal()
        await this.handleModalOpen("showModal1")
        this.ModalLoading.current.showState(true);
        const data = {
            ItemCode: it.ItemCode,
            idCompany: Number(getValueCookie('CompanyId'))
        }

        const result = await getInformationWithData('/purchase/History/getDetialByItem', data)

        if (result.status.code === 1) {
            let allData = []
            let dates = []
            let date2 = []
            for (const row of result.data) {

                const index = getIndexElement(allData, 'VendorNo', row.VendorNo)
                const index2 = getIndexElement(dates, 'OrderDate', DateFormatMAS90(row.PurchaseOrderDate))

                if (index === -1) {
                    let newVendorDetail = new VendorDetail(row.VendorNo, row.PurchaseName)
                    newVendorDetail.addDetailVendor(row.PurchaseOrderNo, DateFormatMAS90(row.PurchaseOrderDate), row.QuantityInvoiced, row.UnitCost)
                    allData.push(newVendorDetail)
                } else {
                    allData[index].addDetailVendor(row.PurchaseOrderNo, DateFormatMAS90(row.PurchaseOrderDate), row.QuantityInvoiced, row.UnitCost)
                    allData[index].VendorName = row.PurchaseName
                }

                if (index2 === -1) {
                    dates.push({ OrderDate: DateFormatMAS90(row.PurchaseOrderDate) })
                    date2.push(DateFormatMAS90(row.PurchaseOrderDate))
                }
            }

            let dataSet = []

            for (const Vendor of allData) {
                let color = random_rgba()
                const vendordata = {

                    label: Vendor.VendorName,
                    data: [],
                    borderColor: color,
                    backgroundColor: color,
                    spanGaps: true
                }
                for (const date of dates) {

                    const index = getIndexElement(Vendor.Detail, 'OrderDate', date.OrderDate)
                    if (index !== -1) {
                        vendordata.data.push(Vendor.Detail[index].UnitCost)
                    } else {
                        vendordata.data.push(null)
                    }
                }
                dataSet.push(vendordata)
            }

            const graphData = {
                labels: date2,
                datasets: dataSet
            }
            this.setState({ LineGraphData: graphData })
        }


        const temporal = this.state.currentItem
        temporal.ItemCode = it.ItemCode
        temporal.ItemCodeDesc = it.ItemCodeDesc
        temporal.ProductLine = it.ProductLineDesc
        temporal.itemURL = await getIMGByItemCode(it.ItemCode)
        const index2 = getIndexElement(this.state.ItemVendorList, 'ItemCode', it.ItemCode)
        const item = this.state.ItemVendorList[index2]
        
        await this.setState({
            ModalBody: item.VendorList,
            ModalBodyFilter: item.VendorList,
            currentItem: temporal,
            ModalDetailBody: result.data,
            ModalFilterDetailBody: result.data
        })
        this.ModalLoading.current.showState(false);

    }

    clearModal() {
        const temporal = {
            ItemCode: '',
            ItemCodeDesc: '',
            ProductLine: '',
            itemURL: ''
        }


        this.setState({
            ModalBody: [],
            ModalBodyFilter: [],
            currentItem: temporal,
            ModalDetailBody: [],
            ModalFilterDetailBody: []
        })
    }


    async getItemList(index, vend) {
        const index2 = getIndexElement(this.state.VendorItemList, 'VendorNo', vend.VendorNo)
        const vendor = this.state.VendorItemList[index2]
        const temporal = this.state.currentItem
        temporal.ItemCode = vend.VendorNo
        temporal.ItemCodeDesc = vend.VendorName

        await this.setState({ ModalBody: vendor.ItemList, ModalBodyFilter: vendor.ItemList, currentItem: temporal })
        this.handleModalOpen("showModal1")


    }

    handleModalClose = (modal) => {
        this.setState({ [modal]: false })
    }

    handleModalOpen = (modal) => {
        this.setState({ [modal]: true })
    }

    OrderInformation(array, key, state) {
        const order = OrderArray(array, key, this.state.order)
        this.setState({ [state]: order, order: !this.state.order })
    }
    contains(parametro, busqueda) {
        return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
    }


    searchVendor = e => {

        let search = e.target.value;

        let Vendor
        if (this.state.mode === 'Item') {
            Vendor = this.state.ItemVendorList.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLineDesc, search) ||
                    this.contains(item.ItemCodeDesc, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });

        } else {
            Vendor = this.state.VendorItemList.filter((vendor) => {
                if (((
                    this.contains(vendor.VendorNo, search) ||
                    this.contains(vendor.VendorName, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });

        }

        this.setState({ BodyFilter: Vendor });
    }
    searchVendorHistory = e => {
        let search = e.target.value;
        
        let Vendor=this.state.ModalDetailBody.filter((vendor)=>{
            if ((this.contains(vendor.VendorNo, search))
            ||this.contains(vendor.PurchaseName,search)
            ||this.contains(vendor.PurchaseOrderNo,search)){
                return vendor
            } else {
                return null
            }
        })
        this.setState({ModalFilterDetailBody:Vendor})
    }



    searchVendorModal = e => {

        let search = e.target.value;

        let Vendor = []
        if (this.state.mode === 'Item') {
            Vendor = this.state.ModalBody.filter((vendor) => {
                if (((
                    this.contains(vendor.VendorNo, search) ||
                    this.contains(vendor.VendorName, search)
                ))
                ) {
                    return vendor
                } else {
                    return null
                }
            });

        } else {
            Vendor = this.state.ModalBody.filter((item) => {
                if (((
                    this.contains(item.ItemCode, search) ||
                    this.contains(item.ProductLineDesc, search) ||
                    this.contains(item.ItemCodeDesc, search)
                ))
                ) {
                    return item
                } else {
                    return null
                }
            });
        }

        this.setState({ ModalBodyFilter: Vendor });
    }


    render() {
        return (
            <div className='conteiner'>
                <LoadingWindow ref={this.ModalLoading} />
                <div className='text-center my-4'>
                    <h2 className='display-4 fw-bold inventory-title'>Vendor Report</h2>
                    <p className='lead text-muted inventory-subtitle'>To start please click on Load Data button.</p>
                </div>
                <div className='row pt-2'>
                    <div className='col-12'>
                        <div className='row d-flex justify-content-center'>
                            <div id="loadDataContainerVendors" className='col-sm-12 col-md-6 pb-4 InputTextBolder text-center'>
                                <div className="d-grid gap-2">
                                    <button onClick={() => this.getVendors()} type="button" className="btn blueButton btn-lg"><AiOutlineReload /> Load Data</button>
                                </div>
                            </div>
                            <div id="excelContainerVendors" className='col-sm-12 col-md-6 d-none'>
                                <div className="d-grid gap-2">
                                    <ExcelDocument
                                        hidden={this.state.AllInformation.length === 0}
                                        archname={'Vendors ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                                        sheetname={'Items Vendor Report ' + getValueCookie('Company')}
                                        data={getDataSet(this.state.ItemVendorList, ['Item Code', 'Product Line', 'Description'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc'])}
                                        sheetname2={['Vendors List ' + getValueCookie('Company'), 'All Information Vendors Report ' + getValueCookie('Company')]}
                                        data2={[
                                            getDataSet(this.state.VendorItemList, ['Vendor No', 'Vendor Name'], ['VendorNo', 'VendorName']),
                                            getDataSet(this.state.AllInformation, ['Vendor No', 'Vendor Name', 'Item Code', 'Product Line', 'Description', 'Last Receipt Date', 'Last Unit Cost ($)', 'Last Receipt Quantity'], ['VendorNo', 'VendorName', 'ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'LastReceiptDate', 'LastUnitCost', 'LastReceiptQuantity'])
                                        ]}
                                    />
                                </div>
                            </div>
                            <div hidden={this.state.BodyFilter.length === 0} className='row TitleText'>
                                <div className='col-12 pt-3 d-flex justify-content-center'>
                                    <div className="form-check me-5">
                                        <input className="form-check-input textRadioButtonSize" value={'Item'} onChange={this.valueRadioButton} checked={this.state.mode === 'Item'} type="radio" id='typeItemVendorReport' name="typeVendorReport" />
                                        <label className="form-check-label textRadioButtonSize" htmlFor='typeItemVendorReport' >
                                            Item List
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input textRadioButtonSize" value={'Vendor'} onChange={this.valueRadioButton} checked={this.state.mode === 'Vendor'} type="radio" id='typeVendorVendorReport' name="typeVendorReport" />
                                        <label className="form-check-label textRadioButtonSize" htmlFor='typeVendorVendorReport' >
                                            Vendor List
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div hidden={this.state.AllInformation.length === 0} className='row pt-3'>
                                <div className='col-12'>
                                    <div className="input-group input-group-lg pb-4" >
                                        <input type="text" autoComplete='off' className="form-control" id='searchPrincipalVendor2' placeholder={this.state.mode === 'Item' ? 'Search Item by ItemCode, Line Product, Description...' : 'Search Vendor by VendorNo, Vendor Name...'} onChange={this.searchVendor} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        this.state.mode === 'Item' ?

                            <div hidden={this.state.BodyFilter.length === 0} className='col-12 tableFixHead'>
                                <table className='table align-middle table-hover'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCode', 'BodyFilter')} className='bg-dark'>Item Code <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ProductLineDesc', 'BodyFilter')} className='bg-dark'>Product Line <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'ItemCodeDesc', 'BodyFilter')} className='bg-dark'>Description <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.BodyFilter.map((item, i) => (
                                                <tr onClick={() => this.getVendorsList(i, item)} key={i}>
                                                    <td className='text-center'>{item.ItemCode}</td>
                                                    <td className='text-center'>{item.ProductLineDesc}</td>
                                                    <td className='text-center'>{item.ItemCodeDesc}</td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>

                                </table>
                            </div>
                            :
                            <div hidden={this.state.BodyFilter.length === 0} className='col-12 tableFixHead'>
                                <table className='table align-middle table-hover'>
                                    <thead className='thead'>
                                        <tr className='text-light text-center'>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'VendorNo', 'BodyFilter')} className='bg-dark'>Vendor No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                            <th onClick={() => this.OrderInformation(this.state.BodyFilter, 'VendorName', 'BodyFilter')} className='bg-dark'>Vendor Name <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            this.state.BodyFilter.map((vendor, v) => (
                                                <tr onClick={() => this.getItemList(v, vendor)} key={v}>
                                                    <td className='text-center'>{vendor.VendorNo}</td>
                                                    <td className='text-center'>{vendor.VendorName}</td>
                                                </tr>
                                            ))

                                        }
                                    </tbody>

                                </table>
                            </div>
                    }
                    <div className='col-1'></div>
                </div>

                <ModalOrders title={this.state.mode === 'Item' ? 'Vendors Detail' : 'Items Detail'} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className='col-1'></div>
                        {
                            this.state.mode === 'Item' ?
                                <div className='col-10'>
                                    <div className='row'>
                                        <div className='col-sm-6 col-md-3 col-lg-6'>
                                            <div className='row'>
                                                <div className='col-12 pt-2 text-center d-lg-flex justify-content-lg-end d-sm-block text-sm-center align-items-center'>
                                                    <img key={this.state.currentItem.itemURL} className='pb-5 pt-5' id='ShortsProductImg' src={this.state.currentItem.itemURL !== '' ? this.state.currentItem.itemURL : '/assets/notavailable.png'} alt='ProductImg' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-6'>
                                            <div className='row'>
                                                <div className='col-12 TitleText mt-4 mb-2'>
                                                    <span className='badge bg-primary me-4'>{this.state.currentItem.ItemCode}</span>
                                                    <span className='badge bg-secondary'>{this.state.currentItem.ProductLine}</span>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCodeDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 col-12 pt-4 pb-4">
                                            <ExcelDocument
                                                hidden={this.state.ModalBody.length === 0}
                                                archname={'Vendor List per Item ' + this.state.currentItem.ItemCode + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Vendor List ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.ModalBody, ['Vendor No', 'Vendor Name', 'Last Receipt Date', 'Last Receipt Quantity', 'Last Unit Cost($)'], ['VendorNo', 'VendorName', 'LastReceiptDate', 'LastReceiptQuantity', 'LastUnitCost'])}
                                                buttonTitle="Download Vendor List "

                                            />
                                        </div>
                                        <div className='col-12 input-group input-group-lg pt-3 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchVendorVendorModal' placeholder={'Search Vendor by VendorNo, Vendor Name...'} onChange={this.searchVendorModal} />
                                        </div>
                                        <div className='col-12 tableFixHead'>
                                            <table className='table align-middle table-hover'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorNo', 'ModalBodyFilter')} className='bg-dark'>Vendor No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'VendorName', 'ModalBodyFilter')} className='bg-dark'>Vendor Name <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptDate', 'ModalBodyFilter')} className='bg-dark'>Last Receipt Date <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptQuantity', 'ModalBodyFilter')} className='bg-dark'>Last Receipt Quantity <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastUnitCost', 'ModalBodyFilter')} className='bg-dark'>Last Unit Cost <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {
                                                        this.state.ModalBodyFilter.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-center'>{item.VendorNo}</td>
                                                                <td className='text-center'>{item.VendorName}</td>
                                                                <td className='text-center'>{item.LastReceiptDate ? DateFormatMAS90(item.LastReceiptDate) : 'Not available'}</td>
                                                                <td className='text-center'>{item.LastReceiptQuantity ? NumberFormat(pricesFormat(item.LastReceiptQuantity)) : 0}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.LastUnitCost ? NumberFormat(pricesFormat(item.LastUnitCost)) : '0.00'}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                        <div className='col-12 pb-3'>
                                            <Line options={this.state.optionsLineGraph} data={this.state.LineGraphData} />
                                        </div>
                                        <div className="d-grid gap-2 col-12 pt-4 pb-4">
                                            <ExcelDocument
                                                hidden={this.state.ModalFilterDetailBody.length === 0}
                                                archname={'PO History Item ' + this.state.currentItem.ItemCode + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Vendor List ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.ModalFilterDetailBody, ['Vendor No', 'Vendor Name', 'Purchase Order No', 'Purchase Order Date', 'Quantity Invoiced','Unit Cost'], ['VendorNo', 'PurchaseName', 'PurchaseOrderNo', 'PurchaseOrderDate', 'QuantityInvoiced','UnitCost'])}
                                                buttonTitle="Download PO History Item"
                                            />
                                        </div>
                                        <div className='col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchVendorModalHistory' placeholder={'Search Vendor by VendorNo, Vendor Name, Purchase Order No...'} onChange={this.searchVendorHistory} />
                                        </div>
                                        <div className='col-12 tableFixHead'>
                                            <table className='table align-middle table-hover'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalFilterDetailBody, 'VendorNo', 'ModalFilterDetailBody')} className='bg-dark'>Vendor No <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalFilterDetailBody, 'VendorName', 'ModalFilterDetailBody')} className='bg-dark'>Vendor Name <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalFilterDetailBody, 'PurchaseOrderNo', 'ModalFilterDetailBody')} className='bg-dark'>Purchase Order No<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalFilterDetailBody, 'PurchaseOrderDate', 'ModalFilterDetailBody')} className='bg-dark'>Receipt Date <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalFilterDetailBody, 'QuantityInvoiced', 'ModalFilterDetailBody')} className='bg-dark'>Quantity Receipt<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalFilterDetailBody, 'UnitCost', 'ModalFilterDetailBody')} className='bg-dark'>Last Unit Cost <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {
                                                        this.state.ModalFilterDetailBody.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-center'>{item.VendorNo}</td>
                                                                <td className='text-center'>{item.PurchaseName}</td>
                                                                <td className='text-center'>{item.PurchaseOrderNo}</td>
                                                                <td className='text-center'>{item.PurchaseOrderDate ? DateFormatMAS90(item.PurchaseOrderDate) : 'Not available'}</td>
                                                                <td className='text-center'>{item.QuantityInvoiced ? NumberFormat(pricesFormat(item.QuantityInvoiced)) : 0}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.UnitCost ? NumberFormat(pricesFormat(item.UnitCost)) : '0.00'}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='col-10'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 titleTotal'>
                                                    <p>Vendor No:</p>
                                                </div>
                                                <div className='col-12 TitleText'>
                                                    <p>{this.state.currentItem.ItemCode}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='col-12 titleTotal'>
                                                <p>Vendor Name: </p>
                                            </div>
                                            <div className='col-12 TitleText'>
                                                <p>{this.state.currentItem.ItemCodeDesc}</p>
                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 col-12 pt-4 pb-4">
                                            <ExcelDocument
                                                hidden={this.state.ModalBody.length === 0}
                                                archname={'Item List per Vendor ' + this.state.currentItem + " " + getValueCookie('Company') + ' ' + getDateFromReports()}
                                                sheetname={'Item List ' + getValueCookie('Company')}
                                                data={getDataSet(this.state.ModalBody, ['Item Code', 'Product Line', 'Description', 'Last Receipt Date', 'Last Receipt Quantity', 'Last Unit Cost($)'], ['ItemCode', 'ProductLineDesc', 'ItemCodeDesc', 'LastReceiptDate', 'LastReceiptQuantity', 'LastUnitCost'])}
                                            />
                                        </div>
                                        <div className='col-12 col-12 input-group input-group-lg pt-2 pb-3'>
                                            <input type="text" autoComplete='off' className="form-control" id='searchItemVendorModal' placeholder={'Search Item by ItemCode, Product Line, Description...'} onChange={this.searchVendorModal} />
                                        </div>
                                        <div className='col-12 tableFixHead'>
                                            <table className='table align-middle table-hover'>
                                                <thead className='thead'>
                                                    <tr className='text-light text-center'>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCode', 'ModalBodyFilter')} className='bg-dark'>Item Code <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ProductLineDesc', 'ModalBodyFilter')} className='bg-dark'>Product Line<BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'ItemCodeDesc', 'ModalBodyFilter')} className='bg-dark'>Description <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptDate', 'ModalBodyFilter')} className='bg-dark'>Last Receipt Date <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastReceiptQuantity', 'ModalBodyFilter')} className='bg-dark'>Last Receipt Quantity <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                        <th className='bg-dark'></th>
                                                        <th onClick={() => this.OrderInformation(this.state.ModalBodyFilter, 'LastUnitCost', 'ModalBodyFilter')} className='bg-dark'>Last Unit Cost <BsFileArrowUpFill /><BsFileArrowDownFill /></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='tbody'>
                                                    {

                                                        this.state.ModalBodyFilter.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className='text-center'>{item.ItemCode}</td>
                                                                <td className='text-center'>{item.ProductLineDesc}</td>
                                                                <td className='text-start'>{item.ItemCodeDesc}</td>
                                                                <td className='text-center'>{item.LastReceiptDate ? DateFormatMAS90(item.LastReceiptDate) : 'Not available'}</td>
                                                                <td className='text-center'>{item.LastReceiptQuantity ? NumberFormat(pricesFormat(item.LastReceiptQuantity)) : 0}</td>
                                                                <td className='text-end'>$</td>
                                                                <td className='text-end'>{item.LastUnitCost ? NumberFormat(pricesFormat(item.LastUnitCost)) : '0.00'}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div></div>
                        }
                        <div className='col-1'></div>
                    </div>
                </ModalOrders>

            </div>
        )
    }
}
