import React, { Component } from 'react'
import { BsArrowRepeat } from "react-icons/bs";
import { getInformationWithData } from '../../../services/CABE';
import "../../../css/inventory-style.css"
import "../../../css/general-style.css"
import '../../../css/table-responsive.css'
import LoadingWindow from '../../../components/LoadingWindow';
import { NumberFormat } from '../../../functions/generalFunctions';
import { pricesFormat } from '../../../functions/pricesFormat';
import { DateFormatMAS90, getDateFromReports } from '../../../functions/dateFormat';
import ExcelDocument from '../../../components/ExcelDocument';
import { getValueCookie } from '../../../services/cookieService';
import { getDataSet } from '../../../functions/generateDataSetExcel';

export default class OpenInvoicesReport extends Component {
  constructor(props) {
    super(props)
    this.ModalLoading = React.createRef();
  }

    state = {
      invoices: [],
      products: [],
      ExcelInfo: []
    }

  async getInvoices(){
    const data = {
        idCompany: Number(getValueCookie('CompanyId')),
        SalespersonNo: getValueCookie('SalesPerson'),
        CustomerNo: "*"
    }
    this.ModalLoading.current.showState(true);
    const result = await getInformationWithData('/invoice/header', data)
    if (result.status.code === 1) {
        const temporal = result.data;
        this.setState({ invoices: temporal })
        let currentInvoices = temporal;
        currentInvoices.forEach(invoice => {
          this.getSaleOrder(invoice);
        });
    }
    this.ModalLoading.current.showState(false);
  }
  
  // Get items from the invoices
  async getSaleOrder(invoice){
      const orderData = {
        idCompany: Number(getValueCookie('CompanyId')),
        OrderNo: invoice.SalesOrderNo
    }
    const getInvoiceDetail = await getInformationWithData('/sales/get/detail', orderData)
    if (getInvoiceDetail.status.code ===1) {
      const products = getInvoiceDetail.data.Tabla2
      this.setState((prevState) => {
        let updatedProducts = [...prevState.products];
        // Check if a product is already on the array
        products.forEach(item => {
          const isProductExistIndex = updatedProducts.findIndex(
            (product) => product.ItemCode === item.ItemCode 
          );

          if(isProductExistIndex !== -1){
            updatedProducts[isProductExistIndex].QuantityShipped += item.QuantityShipped;
          } else {
            item.orderedDate = invoice.InvoiceDueDate
            updatedProducts.push(item)
          }
        });
        return {products: updatedProducts};
      })
    }
    
  }

  render() {
    return (
      <div className='container'>
        <LoadingWindow ref={this.ModalLoading} />
         <div class="justify-content-center">
          <div class="col-12">
            <p className='text-center display-1 pb-2' >Report of Open Invoices</p>
          </div>
          <div class="col-12 text-center pb-4 pt-4">
              <button onClick={() => this.getInvoices()} type="button" className="btn blueButton btn-lg mb-4">Load Report <BsArrowRepeat /></button>
              <ExcelDocument
                  hidden={this.state.invoices.length === 0}
                  archname={'Items in Open Invoices ' + getValueCookie('Company') + ' ' + getDateFromReports()}
                  sheetname={'Items ' + getValueCookie('Company')}
                  data={getDataSet(this.state.products, ['Item Code', 'Description', 'Total Ordered', 'Total Shipped', 'Unit Price ($)'], ['ItemCode', 'ItemCodeDesc', 'QuantityOrdered', 'QuantityShipped', 'UnitPrice'])}
                  sheetname2={['All Detail']}
                  data2={[
                      getDataSet(this.state.products,['Sales Order','Date', 'Item Code','Description','Quantity Ordered','Unit Price ($)'],['SalesOrderNo', 'orderedDate', 'ItemCode','ItemCodeDesc','QuantityOrdered','UnitPrice'])
                  ]}
              />
          </div>
        </div>
        <div>
          {
            // Render not posted invoices
            this.state.invoices.length > 0?
            <div>
              <table class="table">
                <thead class="table-dark">
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Customer No</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Invoice date</th>
                    <th scope="col">Sales Person</th>
                    <th scope="col">Total Amt</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.invoices.map((invoice, index) => (
                  <tr key={index}>
                    <th>{invoice.InvoiceNo}</th>
                    <th>{invoice.CustomerNo}</th>
                    <th>{invoice.BillToName}</th>
                    <th>{DateFormatMAS90(invoice.InvoiceDate)}</th>
                    <th>{invoice.SalespersonNo}</th>
                    <th>$ {invoice.NonTaxableAmt}</th>
                  </tr>
                    ))
                  }
                </tbody>
              </table>
              <br />
              <table class="table">
                <thead class="table-dark">
                  <tr>
                    <th scope="col">Item Code</th>
                    <th scope="col">Description</th>
                    <th scope="col">Total Ordered</th>
                    <th scope="col">Total Shipped</th>
                    <th scope="col">Total Amt</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.products.map((product, index) => (
                  <tr key={index} class={product.QuantityShipped === 0 ? "table-warning" : ""}>
                    <th>{product.ItemCode}</th>
                    <th>{product.ItemCodeDesc}</th>
                    <th>{product.QuantityOrdered}</th>
                    <th>{product.QuantityShipped}</th>
                    <th>{NumberFormat(pricesFormat(product.UnitPrice * product.QuantityShipped))}</th>
                  </tr>
                    ))
                  }
                </tbody>
              </table>

            </div>
            : <div></div>
          }

        </div>
      </div>
    )
  }
}