import React, { Component } from 'react'
import { getInformationWithData } from '../services/CABE.js'
import LoadingWindow from '../components/LoadingWindow';
import { getValueCookie } from '../services/cookieService.js';
import { BsArrowRepeat } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { ActualDate, DateFormatMAS90 } from '../functions/dateFormat.js';
import "../css/general-style.css"
import '../css/table-responsive.css'
import { pricesFormat } from '../functions/pricesFormat.js';
import { NumberFormat, intervalTimeInDays } from '../functions/generalFunctions.js';
import { getIndexElement } from '../functions/searchInObject.js';
import ModalOrders from './ModalComponent.js';

export default class extends Component {

    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }
    state = {
        invoiceList: [],
        invoiceListFilter: [],
        invoiceShow:[],
        showModal: false,
        currentPage: 0,
        ProductsPerPage: 20,
        TotalPages: 0,
        total: 0
    }

    async getInvoicesHeader(refresh) {
        await this.handleModalOpen()
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            CustomerNo: '*'
        }


        const buscador = document.getElementById('Invoice_busqueda_' + this.props.nombrePadre);

        if (refresh || this.state.invoiceList.length <= 0) {
            await this.ModalLoading.current.showState(true);
            this.setState({ invoiceList: [], invoiceListFilter: [] });
            //Bandera 1
            let respuesta;
            if (this.props.nombrePadre === 'InvoiceListSalesPersonHistory') {
                respuesta = await getInformationWithData('/invoice/history/header', data);
            } else {
                respuesta = await getInformationWithData('/invoice/header', data);
            }
           
            if (respuesta.status.code === 1) {
                respuesta.data = respuesta.data.slice(0,100)
                this.setState({ invoiceList: respuesta.data, invoiceListFilter: respuesta.data });

            }
            this.ModalLoading.current.showState(false);
        }
        await this.ResetPagination()

        setTimeout(() => { buscador.focus(); }, 300);

    }

    async getInvoiceDetail(InvoiceHeader) {
        await this.handleModalClose()
        //Aqui asignamos el producto al estado del padre
        const Father = this.props.Padre
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            InvoiceNo: InvoiceHeader.InvoiceNo,
            CustomerNo: InvoiceHeader.CustomerNo,
            HeaderSeqNo: '000000',
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
        }
        const balanceData = {
            idCompany: Number(getValueCookie('CompanyId')),
            idcompany: getValueCookie('CompanyId'),
            Mode: true,
            InvoiceType: 1,
            Status: -1,
            company: getValueCookie('Company'),
            SalesOrderNo: InvoiceHeader.SalesOrderNo,
            OrderNo: InvoiceHeader.SalesOrderNo,
            CustomerNo: InvoiceHeader.CustomerNo,
            username: getValueCookie('userName'),
        }
        await this.ModalLoading.current.showState(true);
        const customerNo = await getInformationWithData('/customer/getByCustomerNo', data)
        const balance = await getInformationWithData('/customer/getBalance', balanceData)

        //BANDERA1
        let result;
        if (this.props.nombrePadre === 'InvoiceListSalesPersonHistory') {
            result = await getInformationWithData('/invoice/history/detail', data);
        } else {
            result = await getInformationWithData('/invoice/detail', data)
        }

        const result2 = await getInformationWithData('/customer/getByCustomerNo', data)
        const invent = await getInformationWithData('/Items/get', data)

        if (getValueCookie('CompanyId') === '6') {
            data.company = 'Mayaland Atlanta'
            data.idcompany = '3'
            const inventM = await getInformationWithData('/Items/get', data);
            invent.status.code = inventM.status.code
            for (const item of inventM.data) {
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(inventM.data)
        }

        if (result.status.code === 1 && result2.status.code === 1) {
            if (this.props.nombrePadre === 'InvoiceListSalesPerson' || this.props.nombrePadre === 'InvoiceListSalesPersonHistory') {
                Father.clearAll()
                if (this.props.nombrePadre === 'InvoiceListSalesPersonHistory') {
                    Father.setState({ enablePrint: false, addTitle: 'REPRINT-' })
                }
                const temporal = Father.state.Invoice
                temporal.Warehouse = InvoiceHeader.WarehouseCode
                temporal.InvoiceNo = InvoiceHeader.InvoiceNo
                temporal.InvoiceDate = InvoiceHeader.InvoiceDate
                temporal.SalespersonNo = InvoiceHeader.SalespersonNo
                temporal.SalesOrderNo = InvoiceHeader.SalesOrderNo
                temporal.Comment = InvoiceHeader.Comment
                temporal.FreightAmt = InvoiceHeader.FreightAmt
                temporal.InvoiceType = InvoiceHeader.InvoiceType
                temporal.Customer = result2.data[0]
                temporal.BatchNo = InvoiceHeader.BatchNo?InvoiceHeader.BatchNo:''
                temporal.ApplyToInvoiceNo = InvoiceHeader.ApplyToInvoiceNo
                let prod = []
                let flagDupl=false
                result.data.forEach(item => {
                    const index = getIndexElement(invent.data, 'itemCode', item.ItemCode)
                    const dupl=getIndexElement(prod,'itemCode',item.ItemCode)
                    let desc = ''
                    let upc = 0
                    let comp=1563
                    if(!flagDupl&&index!==-1){
                        flagDupl=true
                    }

                    if (index !== -1) {
                        
                        desc = (!(invent.data[index].completeDesc) || invent.data[index].completeDesc === "null") ? invent.data[index].abbreviatedDesc : invent.data[index].completeDesc
                        comp = invent.data[index].IdCompany?invent.data[index].IdCompany:1563
                        if (!desc) {

                            desc = invent.data[index].abbreviatedDesc
                        }

                        if (invent.data[index].caseupc !== "null" && invent.data[index].caseupc !== "" && invent.data[index].caseupc !== "undefined" && invent.data[index].caseupc) {
                            upc = invent.data[index].caseupc
                        } else if (invent.data[index].upc !== "null" && invent.data[index].upc !== "" && invent.data[index].upc !== "undefined" && invent.data[index].upc) {
                            upc = invent.data[index].upc

                        } else if (invent.data[index].upc2 !== "null" && invent.data[index].upc2 !== "" && invent.data[index].upc2 !== "undefined" && invent.data[index].upc2) {
                            upc = invent.data[index].upc2
                        }



                    } else {
                        desc = item.ItemCodeDesc
                    }

                    let type = ''
                    let HaveUPC=false
                    if ((!(temporal.SalesOrderNo)) && (item.CommentText)) {
                        
                        let t = item.CommentText.split('||')
                       
                        if (t.length === 4) {
                            type = t[1]
                            HaveUPC=t[3]==='1'?true:false
                        }
                    }

                    const itemJson = {
                        itemCode: item.ItemCode,
                        abbreviatedDesc: desc,
                        IdCompany:comp,
                        upc: upc,
                        quantity: 0,
                        ShipWeight: Number(item.LineWeight),
                        StandardUnitCost: item.UnitCost,
                        LastTotalUnitCost: item.UnitCost,
                        UnitPrice: item.UnitPrice,
                        upc2: item.upc2 ? item.upc2 : 0,
                        caseupc: item.caseupc ? item.caseupc : 0,
                        BIN: "",
                        totalWeight: Number(item.QuantityShipped) * Number(item.LineWeight),
                        Comment: item.CommentText,
                        unitCost: Number(item.UnitCost),
                        quantityOrdered: item.QuantityOrdered,
                        quantityReceived: 0,
                        quantityShipped: item.QuantityShipped,
                        totalCost: Number(item.QuantityShipped) * Number(item.UnitPrice),
                        totalFreight: 0,
                        RegisterType: type,
                        HaveUPC: HaveUPC,
                        Duplicate:dupl===-1?'':'bg-danger',
                        Visible: (item.QuantityOrdered !== 0 || item.QuantityShipped !== 0) ? 1 : 0
                    }
                    if(InvoiceHeader.InvoiceType === 'CM'){
                        itemJson.ShipWeight*=-1
                        itemJson.ShipWeight*=-1
                        itemJson.quantityShipped*=-1
                        itemJson.totalCost*=-1
                        itemJson.totalWeight*=-1
                    }
                    prod.push(itemJson)
                });

                const customerNo = result2.data[0]

                balance.data.forEach(element => {
                    element.PaymentDelayDays = intervalTimeInDays(DateFormatMAS90(element.InvoiceDueDate), ActualDate())
                    element.InvoiceDate = DateFormatMAS90(element.InvoiceDate)
                    element.InvoiceDueDate = DateFormatMAS90(element.InvoiceDueDate)
                    element.CustomerName = customerNo.CustomerName
                });

                const temporalBalance = Father.state.Balance
                temporalBalance.HeaderBalance = balance.data
                temporalBalance.TermsCode = customerNo.TermsCodeDesc

                temporal.Duplicate=flagDupl
                Father.setState({ Invoice: temporal, oldInvoice: true, Products: prod, Balance: temporalBalance })
                Father.refreshInputs()
                Father.calculateTotals()
                Father.handleModalOpen("showModal2")

            }
        }
        if (InvoiceHeader.InvoiceType !== 'CM') {
            this.ModalLoading.current.showState(false);
        }
    }

    contiene(parametro, busqueda) {
        if (parametro) {
            return parametro.toString().toLowerCase().includes(busqueda.toLocaleLowerCase())
        } else {
            return false
        }
    }

    searchInvoice = async e => {

        let busqueda = e.target.value;

        let orders = this.state.invoiceList.filter((order_) => {
            if (!order_ || order_.BillToName == null) return null;

            if(order_.BillToName === null){
                order_.BillToName = '';
            }

            if (((this.contiene(order_.BillToName, busqueda) || this.contiene(order_.InvoiceNo, busqueda)))) {
                return order_
            } else {
                return null
            }
        });
        await this.setState({ invoiceListFilter: orders });
        await this.ResetPagination()
    }
    handleModalOpen = async () => {
        await this.setState({ showModal: true })
    }

    handleModalClose = () => {
        const resetPage = this.state.invoiceList
        this.setState({ invoiceListFilter: resetPage });
        this.setState({ showModal: false })
        this.setState({ total: 0});
    }


    async ResetPagination() {
        let totalPages = Math.round(this.state.invoiceListFilter.length / this.state.ProductsPerPage)
        let seeProds = this.state.invoiceListFilter.slice(0, 0 + this.state.ProductsPerPage)
        await this.setState({
            invoiceShow: seeProds,
            currentPage: 0,
            ProductsPerPage: 30,
            TotalPages: totalPages
        })

    }

    async nextPage() {
        if ((this.state.currentPage + this.state.ProductsPerPage) <= this.state.invoiceListFilter.length) {
            await this.setState({ currentPage: this.state.currentPage + this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async prevPage() {
        if (this.state.currentPage > 0) {
            await this.setState({ currentPage: this.state.currentPage - this.state.ProductsPerPage })
        }
        this.updateSeeProducts()
        this.backToTop()
    }

    async updateSeeProducts() {
        let seeProds = this.state.invoiceListFilter.slice(this.state.currentPage, this.state.currentPage + this.state.ProductsPerPage)
        await this.setState({
            invoiceShow: seeProds,
        })
        this.calculateTotal(seeProds)

    }
    backToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const buscador = document.getElementById('Invoice_busqueda_' + this.props.nombrePadre);
        setTimeout(() => { buscador.focus(); }, 300);
    }

    calculateTotal(productList) {
        
        let total = productList.filter(element => element && element.NonTaxableAmt != null)
        .reduce((acc, element) => acc + element.NonTaxableAmt, 0);
        return total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    };

    render() {
        return (
            <>
                <button type="button" disabled={this.props.disabled} hidden={this.props.hidden} className={this.props.nombrePadre === 'InvoiceListSalesPerson' ? "btn purpleButton btn-lg w-100" : "btn blackButton btn-lg w-100"} onClick={() => this.getInvoicesHeader(this.props.nombrePadre==='InvoiceListSalesPerson')}><AiOutlineFileText /> {this.props.nombrePadre === 'InvoiceListSalesPerson' ? 'Invoice List' : 'Posted Invoices'}</button>
                <LoadingWindow ref={this.ModalLoading} />
                <ModalOrders title={'Invoice List'} show={this.state.showModal} close={() => this.handleModalClose()}>
                    <div className="form-group pt-2">
                        <div className='row form-group pt-4'>

                            <div className='col-sm-12'>
                                <div className="input-group input-group-lg flex-nowrap">
                                    <input className='form-control w-75 display-inline' placeholder='Search by...' id={'Invoice_busqueda_' + this.props.nombrePadre} onKeyUp={this.searchInvoice} />
                                    <span className="input-group-text">
                                        <button className='btn greenButton btn-lg' onClick={() => this.getInvoicesHeader(true)}><BsArrowRepeat /></button>
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div className='row text-center pt-4'>
                            <div className='col-sm-12 tableFixHead'>
                                <table className='table align-middle table-hover'>
                                    <thead className='table-dark thead'>
                                        <tr className='text-center'>
                                            <th>Invoice</th>
                                            <th>Type</th>
                                            <th>Sales Person</th>
                                            <th>Date</th>
                                            <th>Customer No</th>
                                            <th>Bill To Name</th>
                                            <th>Sales Order No</th>
                                            <th></th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.invoiceShow.map((element, e) => {
                                                if (element && element.InvoiceNo) {
                                                    return (
                                                        <tr key={e} onClick={() => this.getInvoiceDetail(element)}>
                                                            <td className='text-center'>{element.InvoiceNo}</td>
                                                            <td className='text-center'>{element.InvoiceType}</td>
                                                            <td className='text-center'>{element.SalespersonNo}</td>
                                                            <td className='text-center'>{DateFormatMAS90(element.InvoiceDate)}</td>
                                                            <td className='text-center'>{element.CustomerNo}</td>
                                                            <td className='text-start'>{element.BillToName}</td>
                                                            <td className='text-center'>{element.SalesOrderNo?element.SalesOrderNo:"N/A"}</td>
                                                            <td className='text-end'>$</td>
                                                            <td className='text-end'>{NumberFormat(pricesFormat(element.NonTaxableAmt))}</td>
                                                        </tr>
                                                    )
                                                }
                                                return null;
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-end p-2 bg-light rounded">
                                    <strong>Total: ${this.calculateTotal(this.state.invoiceShow)}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalOrders>


            </>
        )
    }
}
